import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { FG1NotificationService } from '@services';
import { isNil } from 'lodash';
import { AppService } from '@services';

@Injectable({
    providedIn: 'root',
})
export class SubNavTabFeatureFlagGuard implements CanActivate {
    constructor(private appService: AppService, private fg1NotificationService: FG1NotificationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (!this.isRouteFeatureFlagProtected(route)) {
            return of(true);
        }
        return this.appService.getEvent().pipe(
            filter(e => !!e),
            first(),
            map(event => event.eventFeatureFlags[route.data.featureFlag]),
            map(Boolean),
            map(isEnabled => {
                const shouldActivateOnEnabled = isNil(route.data.shouldActivateOnFeatureFlag)
                    ? true
                    : Boolean(route.data.shouldActivateOnFeatureFlag);
                if ((isEnabled && shouldActivateOnEnabled) || (!isEnabled && !shouldActivateOnEnabled)) {
                    return true;
                } else {
                    this.fg1NotificationService.warn("You're not authorized to access this page.", 'Unauthorized');
                    return false;
                }
            })
        );
    }

    // checks if the current route is in the array of feature flag protected routes passed to the guard
    isRouteFeatureFlagProtected(route: ActivatedRouteSnapshot) {
        return route.data.routes.includes(route.params.subTabNavLink);
    }
}
