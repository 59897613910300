import { Injectable } from '@angular/core';
import { PresentationJudges } from './judging.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
    DEFAULT_GENERAL_CONFIG,
    DEFAULT_MEDIA_FIELDS_CONFIG,
    DEFAULT_PRESENTATION_FIELDS_CONFIG,
    DEFAULT_PRESENTER_FIELDS_CONFIG,
    Event,
    GetMyPresentationsParams,
    GetPresentationsParams,
    Presentation,
    PresentationEntity,
    PresentationFormConfig,
    PresentationPaginationFilters,
} from '@models';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { toHttpParams } from '@functions';
import { TWO_MINUTES_CACHE } from '@constants';
import { map } from 'rxjs/operators';
import decode from 'decode-html';
import { cloneDeep } from 'lodash';

const MAX_CACHE_COUNT = 50;
export const presentationCacheBuster$ = new Subject<void>();

@Injectable({
    providedIn: 'root',
})
export class PresentationService {
    private SERVICE_BASE_URL = environment.SERVICE_BASE_URL;
    private url = `${environment.SERVICE_BASE_URL}/presentations`;

    public previewStream$ = new BehaviorSubject<PresentationJudges>(null);

    private defaultConfig = {
        general: { ...DEFAULT_GENERAL_CONFIG },
        presenterFields: { ...DEFAULT_PRESENTER_FIELDS_CONFIG },
        presentationFields: { ...DEFAULT_PRESENTATION_FIELDS_CONFIG },
        mediaFields: { ...DEFAULT_MEDIA_FIELDS_CONFIG },
    } as PresentationFormConfig;

    private displayedColumns: Array<string[]> = [
        ['editDeleteControls', 'createDate', 'title'],
        ['presenter1FirstName', 'presenter1LastName', 'presenter1Email', 'presenter1Level', 'presenter1Major'],
        ['abstract', 'subjects', 'primaryMediaLink', 'secondaryMediaLink'],
        ['presenter2FirstName', 'presenter2LastName', 'presenter2Email', 'presenter2Level', 'presenter2Major'],
        ['presenter3FirstName', 'presenter3LastName', 'presenter3Email', 'presenter3Level', 'presenter3Major'],
        ['presenter4FirstName', 'presenter4LastName', 'presenter4Email', 'presenter4Level', 'presenter4Major'],
        ['presenter5FirstName', 'presenter5LastName', 'presenter5Email', 'presenter5Level', 'presenter5Major'],
        ['presenter6FirstName', 'presenter6LastName', 'presenter6Email', 'presenter6Level', 'presenter6Major'],
        ['presenter7FirstName', 'presenter7LastName', 'presenter7Email', 'presenter7Level', 'presenter7Major'],
        ['presenter8FirstName', 'presenter8LastName', 'presenter8Email', 'presenter8Level', 'presenter8Major'],
        [],
    ];

    constructor(private http: HttpClient) {}

    public getDisplayedColumns(event: Event) {
        const _displayedColumns = cloneDeep(this.displayedColumns);

        if (event.eventConfig.isAbstractEnabled) {
            const abstractCols = ['presentationStatus', 'stage', 'stageStatus'];
            abstractCols.forEach(column => {
                if (!_displayedColumns[0].includes(column)) _displayedColumns[0].push(column);
            });
        }
        if (event.presentationFormConfig && event.presentationFormConfig.presentationFields.extraFields.length > 0) {
            _displayedColumns[10].push(
                ...event.presentationFormConfig.presentationFields.extraFields.map(
                    field => `xtra_presentation_${field.hash}`
                )
            );
        }

        // flatten displayedColumns
        return _displayedColumns.reduce((a, b) => {
            a.push(...b);
            return a;
        }, []);
    }

    public getDefaultConfig() {
        return this.defaultConfig;
    }

    public getPreviewStream(): Observable<PresentationJudges> {
        return this.previewStream$;
    }

    public openPreview(presentation: PresentationJudges) {
        this.previewStream$.next(presentation);
    }

    public loadPresentations(
        params: Partial<GetPresentationsParams>
    ): Observable<{ presentations: Presentation[]; total: number }> {
        return this.getPresentations(params).pipe(
            map(({ presentations, total }) => {
                presentations = presentations.map(presentation => {
                    presentation.abstract = decode(presentation.abstract);

                    presentation.tags = presentation.tags || [];
                    presentation.tags = presentation.tags.map(tag => {
                        tag.isActive = false;
                        return tag;
                    });
                    presentation.presenters = presentation.presenters.map(presenter => {
                        presenter.level = presenter.level === 'NULL' ? '' : presenter.level;
                        presenter.major = presenter.major === 'NULL' ? '' : presenter.major;
                        return presenter;
                    });

                    return presentation;
                });
                return {
                    total,
                    presentations,
                };
            })
        );
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: presentationCacheBuster$,
    })
    public getPresentationsFilters(params: Partial<GetPresentationsParams>): Observable<PresentationPaginationFilters> {
        return this.http.get<PresentationPaginationFilters>(`${this.SERVICE_BASE_URL}/presentations/filters`, {
            params: toHttpParams(params),
        });
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: presentationCacheBuster$,
    })
    getPresentations(
        params: Partial<GetPresentationsParams>
    ): Observable<{ presentations: Presentation[]; total: number }> {
        return this.http.get<{ presentations: Presentation[]; total: number }>(
            `${this.SERVICE_BASE_URL}/presentations/v2`,
            { params: toHttpParams(params) }
        );
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: presentationCacheBuster$,
    })
    getMyPresentations(
        params?: Partial<GetMyPresentationsParams>
    ): Observable<{ presentations: PresentationEntity[]; total: number }> {
        return this.http.get<{ presentations: PresentationEntity[]; total: number }>(
            `${this.SERVICE_BASE_URL}/presentations/v2/my-submissions`,
            { params: toHttpParams(params) }
        );
    }

    @CacheBuster({
        cacheBusterNotifier: presentationCacheBuster$,
    })
    public updatePresentation(eventId: number, presHash: string, formData: FormData) {
        const httpOptions = {
            observe: 'response' as const,
        };

        return this.http.put(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_PUT_UPDATE_PRESENTATION}?eventId=${eventId}`.replace(
                '{presentationHash}',
                presHash
            ),
            formData,
            httpOptions
        );
    }

    public closePreview() {
        this.previewStream$.next(null);
    }

    public saveBooklet(eventId: number, template: string): Observable<any> {
        return this.http.post(
            `${this.SERVICE_BASE_URL}/booklet/${eventId}`,
            { template },
            { headers: { 'Content-Type': 'application/json' } }
        );
    }

    public downloadBooklet(eventId: number): Observable<any> {
        return this.http.get(`${this.SERVICE_BASE_URL}/booklet/${eventId}`, {
            headers: { 'Content-Type': 'application/json' },
            responseType: 'blob',
        });
    }

    public getPresentation(presentationId: number, relations: string[] = []) {
        return this.http.get<PresentationEntity>(`${this.url}/${presentationId}`, {
            params: toHttpParams({ relations }),
        });
    }

    public exportPosters(eventId: number) {
        return this.http.get<{ url: string }>(`${this.url}/export/posters`, {
            params: toHttpParams({ eventId }),
        });
    }
}
