import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
    @Input() photo: string;
    @Input() height: number = 35; // avatar width x height in px
    @Input() alt: string;
    styles: any;
    isClickable: boolean = false; // sets whether to show a pointer cursor on the avatar or a regular cursor

    ngOnInit() {
        this.styles = { 'background-image': this.photo, height: `${this.height}px` };
    }
}
