import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { throwError, Observable } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { AuthenticationService } from '../authentication.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    private authenticationService: AuthenticationService;

    constructor(private injector: Injector) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authenticationService = this.injector.get(AuthenticationService, undefined);
        return next.handle(request).pipe(catchError(err => this.refresh(err, request, next)));
    }

    private refresh(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('refresh');

        // If no token or not 401, just continue throwing original error
        if (!token || err.status !== 401) {
            return throwError(err);
        }

        return this.authenticationService.refreshToken(token).pipe(
            switchMap((response: any) => next.handle(this.addToken(request, response.token))),
            // The first event indicates the request has been dispatched to the backend.
            // The second event is the response.
            take(2)
        );
    }

    addToken<T>(req: HttpRequest<T>, token: string) {
        if (token) {
            return req.clone({
                setHeaders: {
                    authorization: `Bearer ${token}`,
                },
            });
        }
        return req;
    }
}
