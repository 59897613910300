import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toHttpParams } from '@functions';
import { EventJudges, Form, Presentation, PresentationJudge, PresentationJudgeRelation } from '@models';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { DEFAULT_CACHE_OPTIONS, judgingCacheBuster$, MAX_CACHE_COUNT, TWO_MINUTES_CACHE } from '@constants';

export interface PresentationJudges extends Partial<Presentation> {
    presentationId: number;
    title: string;
    assignedForms: Form[];
    selected?: boolean;
    submissionsCount: number;
}

@Injectable({
    providedIn: 'root',
})
export class JudgingService {
    private SERVICE_BASE_URL = environment.SERVICE_BASE_URL;
    private readonly JUDGING_URL = `${this.SERVICE_BASE_URL}/judging`;

    constructor(private http: HttpClient) {}

    getEventJudges(eventId: number): Observable<EventJudges> {
        return this.http.get<EventJudges>(`${this.SERVICE_BASE_URL}/judging`, {
            params: toHttpParams({
                eventId,
            }),
        });
    }

    @Cacheable({
        ...DEFAULT_CACHE_OPTIONS,
        cacheBusterObserver: judgingCacheBuster$,
    })
    getJudgingEnabledStatus(eventId: number): Observable<{ isEnabled: boolean }> {
        return this.http.get<any>(`${this.SERVICE_BASE_URL}/judging/status`, {
            params: toHttpParams({
                eventId,
            }),
        });
    }

    @CacheBuster({
        cacheBusterNotifier: judgingCacheBuster$,
    })
    assignPresentationJudge(eventId: number, presentationId: number, consumerId: number) {
        return this.http.post<PresentationJudgeRelation>(
            `${this.SERVICE_BASE_URL}/judging`,
            { presentationId, consumerId },
            {
                params: toHttpParams({
                    eventId,
                }),
            }
        );
    }

    @CacheBuster({
        cacheBusterNotifier: judgingCacheBuster$,
    })
    deletePresentationAssignment(eventId: number, presentationJudgeId: number) {
        return this.http.delete<PresentationJudgeRelation>(`${this.SERVICE_BASE_URL}/judging/${presentationJudgeId}`, {
            params: toHttpParams({
                eventId,
            }),
        });
    }

    @CacheBuster({
        cacheBusterNotifier: judgingCacheBuster$,
    })
    deleteEventJudge(eventId: number, consumerId: number) {
        return this.http.delete<PresentationJudgeRelation>(`${this.SERVICE_BASE_URL}/judging/judges`, {
            params: toHttpParams({
                eventId,
                consumerId,
            }),
        });
    }

    @Cacheable({
        ...DEFAULT_CACHE_OPTIONS,
        cacheBusterObserver: judgingCacheBuster$,
    })
    getPresentations(eventId: number): Observable<PresentationJudges[]> {
        return this.http.get<PresentationJudges[]>(`${this.JUDGING_URL}/presentations`, {
            params: toHttpParams({ eventId }),
        });
    }

    getAssignedPresentationsForJudge(eventId: number) {
        return this.http.get<PresentationJudge[]>(`${this.SERVICE_BASE_URL}/judges/presentations`, {
            params: toHttpParams({ eventId }),
        });
    }
}
