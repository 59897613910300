import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AssetService, AuthenticationService, FG1NotificationService, GenerateSignedURLOptions } from '@services';
import { isFileSizeValid, isValidFormUploadExtension } from '@functions';
import { CurrentUser, Event, FileUploadEvent, Form, getFileExtensionFromMap, UploadFileExtensions } from '@models';

import { v4 as uuid } from 'uuid';
import { Subscription } from 'rxjs';
import { finalize, takeUntil, tap, first } from 'rxjs/operators';
import { CompleteNgUnsubscribeComponent } from '@utils';
@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent extends CompleteNgUnsubscribeComponent implements OnInit {
    @Input() existingFilename: string;
    @Input() key: string;
    @Input() bucket: string;
    @Input() disabled: boolean;
    @Input() text = 'Select File';
    @Input() options: GenerateSignedURLOptions = {
        action: 'write',
        ttlSeconds: 300,
    };
    @Input() description: string;
    @ViewChild('fileInput') fileInput;
    @Input() fileSizeLimit: number;
    @Input() allowCancelUpload = true;
    @Input() showProgress = true;
    @Input() event: Event;
    @Input() form: Form;
    @Input() extension: UploadFileExtensions;
    @Output() onFileSelected: EventEmitter<FileUploadEvent> = new EventEmitter();
    @Output() onUploadFinished: EventEmitter<FileUploadEvent> = new EventEmitter();
    @Output() onUploadInProgress: EventEmitter<boolean> = new EventEmitter();

    loading: boolean;
    selectedFile: File;
    progress = 0;
    uploading = false;
    uploadProgress$: Subscription;
    filename: string;
    consumer: CurrentUser;

    allowedExtension = '';
    fileId: string;

    constructor(
        private assetService: AssetService,
        private fg1NotificationService: FG1NotificationService,
        private authService: AuthenticationService
    ) {
        super();
    }

    ngOnInit() {
        this.key = this.key || uuid();
        this.authService
            .getCurrentUser()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(consumer => (this.consumer = consumer));
        if (this.extension) {
            this.allowedExtension = getFileExtensionFromMap(this.extension);
        }
        if (this.existingFilename) {
            this.selectedFile = new File([''], this.existingFilename);
            this.filename = this.existingFilename;
            this.onUploadFinished.emit(this.file);
        }
    }

    changeImage() {
        this.fileInput.nativeElement.click();
    }

    onFileChange(event) {
        const files = event.target.files;
        const file = files.item(0);
        if (!file) {
            return;
        }
        this.loading = true;
        this.selectedFile = file;
        this.filename = this.generateFileName(file);

        const valid = isValidFormUploadExtension(file, this.extension);
        const isSizeValid = isFileSizeValid(file, 20);

        if (this.fileSizeLimit && !isSizeValid) {
            this.loading = false;
            return this.fg1NotificationService.error('Please choose a smaller file');
        }

        if (this.extension && !valid) {
            this.loading = false;
            return this.fg1NotificationService.error('Please choose a valid file type');
        }
        this.onFileSelected.emit(this.file);

        this.upload();
    }

    cancel() {
        this.uploadProgress$.unsubscribe();
        this.uploading = false;
        this.selectedFile = null;
        this.filename = null;
        this.fileInput.nativeElement.value = null;
    }

    createAsset() {
        return this.assetService
            .createAsset({
                eventId: this.event ? this.event.eventId : null,
                formId: this.form ? this.form.formId : null,
                consumerId: this.consumer ? this.consumer.userId : null,
                bucket: this.bucket,
                size: this.selectedFile.size,
                mimetype: this.selectedFile.type,
                name: this.filename,
            })
            .pipe(first())
            .subscribe(
                () => {},
                err => this.fg1NotificationService.serverError(err)
            );
    }

    private async generateSignedURL() {
        const ext = this.selectedFile.name.split('.').pop();
        this.fileId = uuid();
        const name = `${this.fileId}.${ext}`;
        this.filename = name;
        return this.assetService
            .generateSignedURL(name, this.bucket, {
                action: 'write',
                contentType: this.selectedFile.type,
                ttlSeconds: 3600, // one hour to accommodate slow uploads/large files
            })
            .toPromise();
    }

    async upload() {
        let signedURL;
        try {
            signedURL = await this.generateSignedURL();
        } catch (error) {
            this.fg1NotificationService.serverError(error);
        }
        if (!signedURL) {
            return;
        }
        this.uploading = true;
        this.onUploadInProgress.emit(true);
        this.uploadProgress$ = this.assetService
            .uploadToSignedURL(signedURL.url, this.selectedFile)
            .pipe(
                takeUntil(this.ngUnsubscribe$),
                finalize(() => (this.loading = false))
            )
            .pipe(
                tap(({ progress, done }) => {
                    this.progress = progress;
                    this.uploading = !done;
                })
            )
            .pipe(
                finalize(() => {
                    this.createAsset();
                    this.onUploadInProgress.emit(false);
                    this.onUploadFinished.emit(this.file);
                })
            )
            .subscribe(
                () => {},
                err => this.fg1NotificationService.serverError(err)
            );
    }

    triggerFileSelect() {
        this.fileInput.nativeElement.click();
    }

    get file(): FileUploadEvent {
        return {
            name: this.filename,
            key: this.key,
            file: this.selectedFile,
            fileId: this.filename,
        };
    }

    /**
     * Generate a unique file name that will be used to store the file on google cloud storage
     *
     * @param file
     * @private
     */
    private generateFileName(file: File) {
        const ext = file.name.split('.').pop();
        this.filename = `${uuid()}.${ext}`;
        return this.filename;
    }
}
