<!--Hero Area Start -->
<section class="hero_area">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="hero_txt">
                    <h2>The simplest way to host amazing academic events.</h2>
                    <p>
                        Symposium is an all-in-one events platform designed for research organizations. With all the
                        functionality you need and without the hassle of complex event management software, you can
                        focus on bringing your community together to do what matters: share, inspire, innovate.
                    </p>
                </div>
                <div class="hero_txt_2">
                    <p>Trusted by 150+ leading organizations</p>
                </div>
                <div class="hero_brands">
                    <div class="flex">
                        <div class="flex-item">
                            <img
                                src="assets/images/home/university/jhu.png"
                                style="width: 100%; max-width: none; position: relative; top: 1px"
                                alt="Johns Hopkins University"
                            />
                        </div>
                        <div class="flex-item">
                            <img
                                src="assets/images/home/university/hudsonalpha.png"
                                width="120"
                                style="position: relative; top: -2px"
                                alt="HudsonAlpha"
                            />
                        </div>
                        <div class="flex-item">
                            <img src="assets/images/home/university/caltech.png" width="70" alt="Caltech" />
                        </div>
                        <div class="flex-item">
                            <img
                                src="assets/images/home/university/utk.png"
                                width="80"
                                alt="The University of Tennessee"
                            />
                        </div>
                        <div class="flex-item">
                            <img src="assets/images/home/university/northwestern.png" width="100" alt="Northwestern" />
                        </div>
                        <div class="flex-item">
                            <img
                                src="assets/images/home/university/texas-a&m.png"
                                width="100"
                                style="position: relative; top: 2px"
                                alt="Texas A&M University"
                            />
                        </div>
                        <div class="flex-item">
                            <img src="assets/images/home/university/carleton.png" width="90" alt="Carleton" />
                        </div>
                        <div class="flex-item">
                            <img src="assets/images/home/university/epscor.png" width="110" alt="EPSCOR" />
                        </div>
                    </div>
                </div>
                <div class="hero_btns">
                    <a class="btn btn-secondary" role="button" (click)="openVideoDialog()"
                        >Play Video <i class="fas fa-caret-right"></i
                    ></a>
                    <a
                        href="/contact"
                        target="_blank"
                        class="btn btn-primary"
                        role="button"
                        >Schedule Demo</a
                    >
                </div>
            </div>
            <div class="col-md-6">
                <img
                    class="hero_video_image"
                    src="assets/images/home/hero-video-image.png"
                    (click)="openVideoDialog()"
                    alt="video overview of Symposium"
                />
            </div>
        </div>
    </div>
</section>
<!-- Content Sharing Area Start -->
<section class="timeline_sec_1" id="presentationsSection">
    <div class="container">
        <div class="row hideOverflowOnMobile flex-column-reverse flex-md-row">
            <div class="col-md-6" data-aos="fade-right">
                <div class="timeline_sec_pic">
                    <div class="timeline_sec_pic_box">
                        <video
                            *ngIf="selectedFeaturesPresentationsSection === 'abstract'"
                            style="width: 100%"
                            autoplay
                            loop
                            playsinline
                            [muted]="true"
                        >
                            <source
                                src="https://storage.googleapis.com/symposium-homepage-video-assets/abstract.mp4"
                                type="video/mp4"
                            />
                        </video>
                        <video
                            *ngIf="selectedFeaturesPresentationsSection === 'presentations'"
                            style="width: 100%"
                            autoplay
                            loop
                            playsinline
                            [muted]="true"
                        >
                            <source
                                src="https://storage.googleapis.com/symposium-homepage-video-assets/presentations-commenting.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </div>
            <div id="presentations-section" class="col-md-6" data-aos="fade-left">
                <div class="timeline_txtbox_1">
                    <h5>Uniquely built for content sharing</h5>
                    <h3>Curate all types of content for asynchronous presentation and discussion</h3>
                    <p>
                        With Symposium, organizers can easily collect, review, and share content in a variety of
                        presentation formats for asynchronous viewing and discussion. It’s the easiest way to bring your
                        community together from around the world, at their convenience, to share the diverse
                        perspectives and ideas that make conversations rich.
                    </p>
                </div>
                <div class="timeline_item_area">
                    <div
                        class="timeline_item"
                        [ngClass]="{ selected: selectedFeaturesPresentationsSection === 'presentations' }"
                        (click)="selectedFeaturesPresentationsSection = 'presentations'"
                    >
                        <h4>Presentations and Commenting</h4>
                        <p>
                            Showcase posters, slide decks, 3D models, essays, reports, exhibits, performances, demos,
                            and more. Participants can come together around content and have lasting conversations via
                            threaded comments.
                        </p>
                    </div>

                    <div
                        class="timeline_item"
                        [ngClass]="{ selected: selectedFeaturesPresentationsSection === 'abstract' }"
                        (click)="selectedFeaturesPresentationsSection = 'abstract'"
                    >
                        <h4>Abstract Management</h4>
                        <p>
                            A multi-stage workflow for organizers to receive, evaluate, and accept content for
                            presentation at your event. You can recruit reviewers, match reviewers with submissions,
                            create evaluation forms, collect feedback, and advance submissions to the next stage.
                        </p>
                    </div>
                </div>
                <div class="testimonial_item">
                    <p>Symposium is an innovative tool that will fundamentally change how research is disseminated.</p>

                    <div class="testimonial_item_info">
                        <img src="assets/images/home/christina-photo.png" alt="img" />
                        <h4>Christina Hassija</h4>
                        <p>California State University San Bernardino</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Live Sessions Start -->
<section class="timeline_sec_1 timeline_grey_bkgd" id="liveSessionsSection">
    <div class="container">
        <div class="dot-sets-wrapper">
            <img class="dot-sets-left" aria-hidden='true'  src="assets/images/home/set-1.png" />
        </div>
        <div class="row hideOverflowOnMobile">
            <div id="live-sessions-section" class="col-md-6 col-sm-12" data-aos="fade-right">
                <div class="timeline_txtbox_1">
                    <h5>Fully integrated live session features</h5>
                    <h3>Make connection and conversation easy through seamless live interactions</h3>
                    <p>
                        Our live session features enable organizers to host video conferences, virtual tables, webinars,
                        and live streams directly within the browser on Symposium, without the need for any third-party
                        applications. Live sessions are equipped with a variety of inbuilt features to make sessions
                        engaging and productive.
                    </p>
                </div>
                <div class="timeline_item_area" style="border-bottom: 0">
                    <div
                        class="timeline_item"
                        [ngClass]="{ selected: selectedFeaturesLiveSessionsSection === 'meeting' }"
                        (click)="selectedFeaturesLiveSessionsSection = 'meeting'"
                    >
                        <h4>Video conferencing</h4>
                        <p>Ideal for meetings and group discussions involving all participants.</p>
                    </div>
                    <div
                        class="timeline_item"
                        [class.selected]="selectedFeaturesLiveSessionsSection === 'tables'"
                        (click)="selectedFeaturesLiveSessionsSection = 'tables'"
                    >
                        <h4>Virtual tables</h4>
                        <p>Built for networking, as well as topic-based small group discussions.</p>
                    </div>
                    <div
                        class="timeline_item"
                        [ngClass]="{ selected: selectedFeaturesLiveSessionsSection === 'webinars' }"
                        (click)="selectedFeaturesLiveSessionsSection = 'webinars'"
                    >
                        <h4>Webinars and live streams</h4>
                        <p>For keynotes, panels, and more in both virtual and hybrid settings.</p>
                    </div>
                </div>
                <!--
                 <div class="testimonial_item">
                    <p>Symposium is an innovative tool that will fundamentally change how research is disseminated.</p>

                    <div class="testimonial_item_info">
                        <img src="assets/images/home/christina-photo.png" alt="img" />
                        <h4>Christina Hassija</h4>
                        <p>California State University San Bernardino</p>
                    </div>
                </div>
                -->
            </div>
            <div class="col-md-6 col-sm-12" data-aos="fade-left">
                <div class="timeline_sec_pic">
                    <div class="timeline_sec_pic_box timeline_sec_pic_box_right">
                        <video
                            *ngIf="selectedFeaturesLiveSessionsSection === 'meeting'"
                            style="width: 100%"
                            autoplay
                            loop
                            playsinline
                            [muted]="true"
                        >
                            <source
                                src="https://storage.googleapis.com/symposium-homepage-video-assets/meetings.mp4"
                                type="video/mp4"
                            />
                        </video>
                        <div *ngIf="selectedFeaturesLiveSessionsSection === 'meeting'">
                            <div class="flex feature-mini-list" data-aos="fade-in">
                                <div class="flex-item">
                                    <ul class="fa-ul">
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Moderator controls
                                            and waiting room
                                        </li>
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Screen sharing
                                        </li>
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Interactive
                                            whiteboard
                                        </li>
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Raise hand
                                        </li>
                                    </ul>
                                </div>
                                <div class="flex-item">
                                    <ul class="fa-ul">
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Group chat
                                        </li>
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Participant directory
                                        </li>
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Screen recording
                                        </li>
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Layout preference
                                            options for participants
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <video
                            *ngIf="selectedFeaturesLiveSessionsSection === 'tables'"
                            style="width: 100%"
                            autoplay
                            loop
                            playsinline
                            [muted]="true"
                        >
                            <source
                                src="https://storage.googleapis.com/symposium-homepage-video-assets/tables.mp4"
                                type="video/mp4"
                            />
                        </video>
                        <div *ngIf="selectedFeaturesLiveSessionsSection === 'tables'">
                            <div class="flex feature-mini-list" data-aos="fade-in">
                                <div class="flex-item">
                                    <ul class="fa-ul">
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Screen sharing
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <video
                            *ngIf="selectedFeaturesLiveSessionsSection === 'webinars'"
                            style="width: 100%"
                            autoplay
                            loop
                            playsinline
                            [muted]="true"
                        >
                            <source
                                src="https://storage.googleapis.com/symposium-homepage-video-assets/live-stream-webinar.mp4"
                                type="video/mp4"
                            />
                        </video>
                        <div *ngIf="selectedFeaturesLiveSessionsSection === 'webinars'">
                            <div class="flex feature-mini-list" data-aos="fade-in">
                                <div class="flex-item">
                                    <ul class="fa-ul">
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Screen sharing
                                        </li>
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Interactive
                                            whiteboard
                                        </li>
                                    </ul>
                                </div>
                                <div class="flex-item">
                                    <ul class="fa-ul">
                                        <li>
                                            <span class="fa-li"><i class="fas fa-check"></i></span>Screen recording
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectedFeaturesLiveSessionsSection === 'webinars'"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Private/Repository Area Start -->
<section class="timeline_sec_1" id="repositorySection">
    <div class="container">
        <div class="dot-sets-wrapper">
            <img
                aria-hidden='true'
                class="dot-sets-right"
                style="top: -135px; right: -29px; width: 100px"
                src="assets/images/home/set-2.png"
            />
        </div>
        <div class="row hideOverflowOnMobile flex-column-reverse flex-md-row">
            <div class="col-md-6" data-aos="fade-right">
                <div class="timeline_sec_pic">
                    <div class="timeline_sec_pic_box">
                        <video
                            *ngIf="selectedFeaturesRepositorySection === 'private'"
                            style="width: 100%; max-height: 550px"
                            autoplay
                            loop
                            playsinline
                            [muted]="true"
                        >
                            <source
                                src="https://storage.googleapis.com/symposium-homepage-video-assets/access-settings.mp4"
                                type="video/mp4"
                            />
                        </video>
                        <video
                            *ngIf="selectedFeaturesRepositorySection === 'repository'"
                            style="width: 100%; max-height: 550px"
                            autoplay
                            loop
                            playsinline
                            [muted]="true"
                        >
                            <source
                                src="https://storage.googleapis.com/symposium-homepage-video-assets/repository.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </div>
            <div class="col-md-6" data-aos="fade-left">
                <div id="repository-section" class="timeline_txtbox_1">
                    <h5>Designed to maximize visibility and engagement</h5>
                    <h3>Take your events to new heights with unlimited reach and extended interactions</h3>
                    <p>
                        Symposium makes it easy for attendees to participate in events, engage others, and sustain
                        interactions that lead to meaningful connections, from wherever they are. You can better involve
                        your organization’s internal community, as well as external partners, donors, recruiters,
                        prospective students, and others.
                    </p>
                </div>
                <div class="timeline_item_area">
                    <div
                        class="timeline_item"
                        [ngClass]="{ selected: selectedFeaturesRepositorySection === 'private' }"
                        (click)="selectedFeaturesRepositorySection = 'private'"
                    >
                        <h4>Secure Public & Private Events</h4>
                        <p>
                            With flexible viewing settings, you can restrict access or make your event visible to the
                            world.
                        </p>
                    </div>
                    <div
                        class="timeline_item"
                        [ngClass]="{ selected: selectedFeaturesRepositorySection === 'repository' }"
                        (click)="selectedFeaturesRepositorySection = 'repository'"
                    >
                        <h4>Flexible uptime and digital repository</h4>
                        <p>
                            Keep events and content up for as long as you’d like to sustain continuous interaction. You
                            can also archive events after they have ended within Symposium’s digital repository to
                            preserve a record of event content.
                        </p>
                    </div>
                </div>
                <div class="testimonial_item">
                    <p>
                        With Symposium, our annual exposition garnered a ~10x increase in traffic relative to our
                        previous in-person sessions. We were able to better involve students on our sister campus in
                        Qatar, and engage prospective students and families in ways that were previously not possible.
                    </p>
                    <div class="testimonial_item_info">
                        <img src="assets/images/home/megan-photo_cmp.png" alt="img" />
                        <h4>Megan Wood</h4>
                        <p>Northwestern University</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Augment Area Start -->
<section class="augment_area">
    <div class="container">
        <div class="dot-sets-wrapper">
            <img
                aria-hidden='true'
                class="dot-sets-left"
                style="top: -103px; width: 120px; left: -50px"
                src="assets/images/home/set-3.png"
            />
        </div>
        <div class="row">
            <div class="col-lg-9">
                <div id="rich-features-section" class="timeline_txtbox_1">
                    <h5>An all-in-one solution</h5>
                    <h3>Equipped with a rich feature set to meet your varied needs</h3>
                </div>
                <div class="timeline_item_area" style="margin-left: -15px; border-bottom: 0">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="additional-features-item">
                                <div class="timeline_item_icon">
                                    <img src="assets/images/home/registration-ticketing-icon.png" alt="img" />
                                </div>
                                <h4>Registration and ticketing*</h4>
                                <p>
                                    Create a custom registration page and custom forms on Symposium to simplify your
                                    organizing experience along with the registration and event access for participants.
                                    <br />
                                    <span style="font-size: 0.7em">*Ticketing is coming soon.</span>
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="additional-features-item">
                                <div class="timeline_item_icon">
                                    <img src="assets/images/home/judging-icon.png" alt="img" />
                                </div>
                                <h4>Judging workflow</h4>
                                <p>
                                    An end-to-end workflow to help you easily recruit judges, assign them to submitted
                                    presentations, create and assign evaluation forms, and moderate and share judging
                                    feedback with presenters.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="additional-features-item">
                                <div class="timeline_item_icon">
                                    <img src="assets/images/home/donations-icon.png" alt="img" />
                                </div>
                                <h4>Donations engine</h4>
                                <p>
                                    A non-intrusive mechanism for you to fundraise and accept gifts from your attendees,
                                    partners, and other stakeholders to fundraise for your program or organization,
                                    directly from your event site.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="additional-features-item">
                                <div class="timeline_item_icon">
                                    <img src="assets/images/home/data-analytics-icon.png" alt="img" />
                                </div>
                                <h4>Data and analytics</h4>
                                <p>
                                    Gain insights on presenters, participants, and the interactions during each event to
                                    benchmark, strategize, and build even more successful events.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="additional-features-item">
                                <div class="timeline_item_icon">
                                    <img src="assets/images/home/abstract-booklet-icon.png" alt="img" />
                                </div>
                                <h4>Abstract booklet</h4>
                                <p>
                                    Conveniently compile a booklet of abstracts and other content from submissions to
                                    your event, which you can then customize and publish.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 additional-features-image-wrapper">
                <img
                    class="additional-features-image"
                    src="assets/images/home/additional-features.png"
                    alt="Additional features image"
                />
            </div>
        </div>
        <div class="row" style="border-top: 1px solid #d2d2d2">
            <div class="col-md-12">
                <div class="testimonial_item clone_color_2">
                    <p>
                        What was an emergency solution quickly became a tremendously valued opportunity to transform our
                        symposia going forward, even when we're back to face-to-face events. We hope to complement
                        traditional presentations with Symposium to expand the audience, engagement, and accessibility
                        for our entire campus community.
                    </p>
                    <div class="testimonial_item_info">
                        <img src="assets/images/home/jenny-photo_cmp.png" alt="img" />
                        <h4>Jenny Shanahan</h4>
                        <p>Bridgewater State University</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Organizer Testimonials Start -->
<section class="team_area">
    <div class="container">
        <div class="dot-sets-wrapper">
            <img class="dot-sets-right" aria-hidden='true' style="top: -118px; width: 206px" src="assets/images/home/set-4.png" />
        </div>
        <div class="custome_title">
            <h5>Don’t take our word for it</h5>
            <h3>Organizers achieve so much more with Symposium</h3>
        </div>
        <div class="row">
            <div class="col-md-4"></div>
        </div>
    </div>
    <app-carousel></app-carousel>
    <div style="text-align: center; margin-top: 45px">
        See our <a routerLink="case-studies" class="fg1-yellow-link" target="_blank">Case Studies</a>
    </div>
</section>
<!-- Events Area Start -->
<section class="events_area">
    <div class="container">
        <div class="dot-sets-wrapper">
            <img  aria-hidden='true' class="dot-sets-left" style="top: -103px; width: 117px" src="assets/images/home/set-5.png" />
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="timeline_txtbox_1">
                    <h5>Build amazing virtual and hybrid experiences</h5>
                    <h3>Power all your events with Symposium</h3>
                    <p>
                        Symposium is a versatile platform, capable of hosting all kinds of events. Customize your event
                        to create a unique and impactful experience for all your participants.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Get Started Area Start -->
<section class="get_started_area">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="timeline_txtbox_1">
                    <h3>Ready to get started?</h3>
                    <p>
                        We offer a variety of packages to meet a range of needs. Once you select your package, you may
                        get started on setting up your first event immediately. We provide a comprehensive set of
                        tutorials and resources so you have everything you need to masterfully craft your event.
                    </p>
                    <a routerLink="/pricing">Get Started ❯</a>
                </div>
            </div>
            <div class="col-md-3">
                <div class="timeline_txtbox_1">
                    <div class="timeline_item">
                        <div class="timeline_item_icon">
                            <img src="assets/images/home/demo-icon_cmp.png" alt="img" />
                        </div>
                        <h4>Schedule a demo</h4>
                        <p style="padding-right: 10px">
                            Interested in seeing the full capabilities of Symposium and having your questions answered?
                        </p>
                        <a href="/contact" target="_blank">Schedule Demo ❯</a>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="timeline_txtbox_1">
                    <div class="timeline_item">
                        <div class="timeline_item_icon">
                            <img src="assets/images/home/contactus-icon_cmp.png" alt="img" />
                        </div>
                        <h4>Contact us</h4>
                        <p>
                            If you have custom needs or any questions, feel free to contact us. We’ll be in touch
                            shortly.
                        </p>
                        <a routerLink="/contact">Contact Us ❯</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
