import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BillingType, ClientSector, FAQ, SubscriptionTier } from '@models';
import { environment } from '@env';
import { Cacheable } from 'ts-cacheable';
import { DEFAULT_CACHE_OPTIONS } from '@constants';
import { toHttpParams } from '@functions';

const pricingCacheBuster$ = new Subject<void>();

@Injectable({ providedIn: 'root' })
export class PricingService {
    PRICING_URL = `${environment.SERVICE_BASE_URL}/pricing`;

    public faqs$: ReplaySubject<FAQ[]>;
    public selectedBillingType$: ReplaySubject<BillingType>;

    constructor(private http: HttpClient) {
        this.faqs$ = new ReplaySubject<FAQ[]>(1);
        this.selectedBillingType$ = new ReplaySubject<BillingType>(1);

        this.fetchFAQs();
        this.updateSelectedBillingType(BillingType.RECURRING);
    }

    private fetchFAQs() {
        this.http.get<FAQ[]>(`${this.PRICING_URL}/faqs`).subscribe(faqs => {
            this.faqs$.next(faqs);
        });
    }

    public updateSelectedBillingType(type: BillingType): void {
        this.selectedBillingType$.next(type);
    }

    @Cacheable({
        ...DEFAULT_CACHE_OPTIONS,
        cacheBusterObserver: pricingCacheBuster$,
    })
    public getPricing(sector?: ClientSector): Observable<SubscriptionTier[]> {
        return this.http.get<SubscriptionTier[]>(`${this.PRICING_URL}`, { params: toHttpParams({ sector }) });
    }
}
