<div class="row final-evaluations-container" style="margin-right: 5px">
    <div [ngClass]="isPreviewOpened ? 'col-md-7 col-sm-12' : 'col-md-12'">
        <h6 class="judging-section-title">View {{ title }} Responses</h6>
        <div class="forms-table-container"
            [style.overflow]="!isLoading && (!finalResponses || finalResponses.length === 0) ? 'hidden' : ''">
            <div [class]="
                    isLoading
                        ? 'pres-table-loading'
                        : !finalResponses || finalResponses.length === 0
                        ? 'pres-table-empty'
                        : ''
                ">
                <p *ngIf="finalResponses?.length === 0">
                    There are no responses for this event.
                </p>
            </div>
            <div class="forms-table">
                <mat-table [dataSource]="dataSource" matSortDisableClear mat-elevation-z8 matSort
                    matSortDirection="asc">
                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                    <mat-row [id]="'pres_row_' + row.submissionId" *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ selected: row.submissionId === selectedResponse?.submissionId }"></mat-row>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell class="form-col form-col-0" *matHeaderCellDef></mat-header-cell>
                        <mat-cell class="form-col form-col-0" *matCellDef="let response; let i = index">
                            <button mat-fab mat-icon-button class="fab-icon-button mat-elevation-z eye-preview-icon"
                                (click)="openPreview(response)">
                                <span class="material-icons primary">visibility</span>
                            </button>
                            <button mat-fab mat-icon-button (click)="onDelete(response.submissionId)"
                                class="fab-icon-button mat-elevation-z">
                                <span class="material-icons-outlined secondary">delete</span>
                            </button>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="judgeName">
                        <mat-header-cell class="form-col" *matHeaderCellDef mat-sort-header>{{
                            isJudging ? 'Judge' : 'Reviewer'
                            }}</mat-header-cell>
                        <mat-cell class="form-col" *matCellDef="let response">
                            {{ response.consumer.fullName }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                        <mat-header-cell class="form-col" *matHeaderCellDef mat-sort-header>Submitted At ({{ timezone
                            }})
                        </mat-header-cell>
                        <mat-cell class="form-col" *matCellDef="let response">
                            {{ response.createdAt | utcToLocalDate }}
                        </mat-cell>
                    </ng-container>
                </mat-table>
            </div>
            <div class="forms-table-footer">
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
            </div>
        </div>
    </div>

    <div class="col-md-5 col-sm-12" *ngIf="isPreviewOpened">
        <app-final-notes-preview [response]="selectedResponse" [allResponses]="finalResponses"
            (closePreview)="closePreview($event)" (toggleResponse)="onToggleResponse($event)">
        </app-final-notes-preview>
    </div>
</div>