import { Component } from '@angular/core';

import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-bootstrap-confirm-dialog',
    templateUrl: './bootstrap-confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class BootstrapConfirmDialogComponent extends ConfirmDialogComponent { }
