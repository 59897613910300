import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DonationConfig } from '@models';
import { CompleteNgUnsubscribeComponent } from '@utils';
import { DonationConfigService, EventService, FG1NotificationService } from '@services';
import { first, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-completion-message',
    templateUrl: './completion-message.component.html',
    styleUrls: ['./completion-message.component.scss'],
})
export class CompletionMessageComponent extends CompleteNgUnsubscribeComponent implements OnInit {
    readonly maxCompletionHeadingLength = 50;
    readonly maxCompletionBodyLength = 500;
    form: FormGroup;
    donationConfig: DonationConfig;
    completionHeadingLength = 0;
    completionBodyLength = 0;
    isLoading: boolean;

    constructor(
        private fb: FormBuilder,
        private donationConfigService: DonationConfigService,
        private eventService: EventService,
        private fg1Notification: FG1NotificationService
    ) {
        super();
    }

    createForm() {
        return this.fb.group({
            completionHeading: ['', [Validators.required, Validators.maxLength(this.maxCompletionHeadingLength)]],
            completionBody: ['', [Validators.required, Validators.maxLength(this.maxCompletionBodyLength)]],
        });
    }

    ngOnInit() {
        this.form = this.createForm();

        this.donationConfigService
            .donationConfig()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(({ donationConfig }) => {
                this.completionHeadingLength = donationConfig.completionHeading.length;
                this.completionBodyLength = donationConfig.completionBody.length;
                this.donationConfig = donationConfig;
                this.form.patchValue(donationConfig, { emitEvent: false });
            });

        this.form.controls.completionHeading.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((value: string) => {
                this.completionHeadingLength = value ? value.length : 0;
                this.donationConfigService.updateDonationConfig({
                    completionHeading: value,
                });
            });

        this.form.controls.completionBody.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((value: string) => {
                this.completionBodyLength = value ? value.length : 0;
                this.donationConfigService.updateDonationConfig({
                    completionBody: value,
                });
            });
    }

    submit() {
        this.donationConfigService
            .saveDonationConfig({
                completionHeading: this.donationConfig.completionHeading,
                completionBody: this.donationConfig.completionBody,
            })
            .pipe(first())
            .subscribe(
                () => {
                    this.fg1Notification.success('Successfully updated');
                },
                () => this.fg1Notification.error('Something went wrong')
            );
    }
}
