<div *ngIf="shouldShowRolloutAlert" class="rollout-header">
    <div class="container">
        <div class="row">
            <span class="banner-text"><a class="hide-anchor" (click)="dismissRolloutAlert()">&times;</a></span>
        </div>
    </div>
</div>

<ng-container *onlyForPlatform="'native'">
    <ion-menu side="start" menuId="sidebar" content-id="content">
        <ion-header>
            <ion-toolbar color="primary">
                <ion-title>Menu</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <ion-list>
                <ion-item button (click)="openPage('discover')">Discover Events</ion-item>
                <ion-item button (click)="openPage('account')">My account</ion-item>
                <ion-item button (click)="openPage('login')" *ngIf="!currentUser">Log In</ion-item>
                <ion-item button (click)="openPage('signup')" *ngIf="!currentUser">Sign Up</ion-item>
                <ion-item button (click)="logoutOnSidebar()" *ngIf="currentUser">Logout</ion-item>
                <ion-item button (click)="openPage('pricing')">
                    <ion-label> Pricing </ion-label>
                </ion-item>
                <ion-item button (click)="openPage('contact')">Contact Us</ion-item>
                <ion-item button (click)="openPage('privacy')">Privacy Policy</ion-item>
                <ion-item button (click)="openPage('terms')">Terms of Use</ion-item>
            </ion-list>
        </ion-content>
    </ion-menu>

    <div class="ion-page" [ngClass]="{ 'stay-top': !!currentEvent }" id="content">
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-menu-button *ngIf="!isPresentationPage">
                        <span class="material-icons-outlined secondary"> menu </span>
                    </ion-menu-button>
                    <span class="material-icons-outlined back-icon" *ngIf="isPresentationPage" (click)="goBackwards()"
                        >arrow_back</span
                    >
                </ion-buttons>
                <ng-container *ngIf="currentEvent">
                    <ion-title class="event-title">{{ currentEvent.name }}</ion-title>
                    <ion-buttons slot="end">
                        <ion-button (click)="openPage('discover')">Discover events</ion-button>
                    </ion-buttons>
                </ng-container>
                <ng-container *ngIf="!currentEvent && currentUser">
                    <ion-title [matMenuTriggerFor]="menu" class="home-title">Home</ion-title>
                    <mat-menu #menu="matMenu">
                        <button routerLink="events/admin" mat-menu-item>Events I've organized</button>
                        <button routerLink="events" mat-menu-item>Events I'm registered for</button>
                    </mat-menu>
                </ng-container>
            </ion-toolbar>
        </ion-header>
    </div>
</ng-container>
<!--
    NOTE: IF YOU MAKE A CHANGE IN DESKTOP HEADER, YOU WILL LIKELY NEED TO MAKE THE CORRESPONDING CHANGE IN MOBILE HEADER OR
    VICE VERSA. THERE IS DUPLICATE CODE HERE BETWEEN DESKTOP AND MOBILE FOR READABILITY/MAINTAINABILITY.
-->

<!-- Mobile Nav -->
<app-mobile-nav
    *onlyForPlatform="'native'"
    [event]="currentEvent"
    [isMyEventsPage]="isMyEventsPage"
    [isJudgeForEvent]="isJudgeForEvent"
></app-mobile-nav>

<!-- Desktop Header -->
<ng-container *onlyForPlatform="'web'">
    <div *onlyForScreen="['lg', 'xlg']" class="background-white">
        <div class="container">
            <div class="d-flex justify-content-between"  role='navigation'>
                <!-- Left (Logo and Custom Event Logo) -->
                <div class="height-90 width-33-pc">
                    <div class="d-flex align-items-center height-90">
                        <a [routerLink]="logoRouteLink" class="white-space-nowrap">
                            <div
                                #eventLogo
                                *ngIf="currentEvent?.logoImgName"
                                class="navbar-event-logo-container pl-4 ml-xl-0 pl-xl-0"
                            >
                                <img
                                    class="navbar-event-logo-img"
                                    [src]="eventAssetsRemotePath + currentEvent?.logoImgName"
                                    [alt]="currentEvent.name"
                                    (load)="isEventLogoLoaded = true; eventLogo.style.display = 'inline'"
                                />
                            </div>
                            <img
                                [class]="
                                    currentEvent && currentEvent.logoImgName && isEventLogoLoaded
                                        ? 'my-auto app-logo-with-event'
                                        : 'my-auto app-logo ml-xl-0'
                                "
                                [src]="symposiumLogoV2Path"
                                alt="Symposium by ForagerOne"
                            />
                        </a>
                    </div>
                </div>

                <!-- Middle (Links) -->
                <div class="height-90 width-33-pc">
                    <ul class="d-flex justify-content-center align-items-center height-90 no-list" >
                        <app-skip-navigation></app-skip-navigation>
                        <!-- Home Page Link -->
                        <li *ngIf="!currentEvent" class="px-4" style="z-index: 1">
                            <a
                                [routerLink]="'/'"
                                routerLinkActive="active-nav-link"
                                [routerLinkActiveOptions]="{ exact: true }"
                                class="fg1-nav-link"
                                >Home</a
                            >
                        </li>

                        <!-- Product Page Link -->
                        <li
                            tabindex='0'
                            *ngIf="!currentEvent"
                            (keyup.enter)='trigger.toggleMenu()'
                            [matMenuTriggerFor]="productMenu"
                            class="px-4"
                            style="z-index: 1; margin-right: -10px"
                        >
                            <div class="product-dropdown-wrapper">
                                <a class="fg1-nav-link">Features</a>
                                <mat-icon  aria-label='Expand Features list' aria-hidden="false" class="arrow-down">keyboard_arrow_down</mat-icon>
                            </div>
                        </li>

                        <!-- Case Studies Link -->
                        <li *ngIf="!currentEvent" class="px-4" style="z-index: 1">
                            <a routerLink="case-studies" routerLinkActive="active-nav-link" class="fg1-nav-link"
                                >Case Studies</a
                            >
                        </li>

                        <mat-menu
                            class="product-menu" #productMenu="matMenu"
                        >
                            <div class="row"
                                 (keydown.shift.tab)="$event.stopPropagation()"
                                 (keydown.tab)="$event.stopPropagation()">
                                <div class="col-6" style="padding-right: 6px">
                                    <ng-container *ngFor="let menuItem of menuItemsLeft">
                                        <div
                                            pageScroll
                                            mat-menu-item
                                            routerLink="/"
                                            [href]="menuItem.scrollToElement"

                                            class="menu-item-wrapper"
                                            [pageScrollDuration]="menuItem.scrollDuration"
                                            (keyup.enter)='scrollToElement(menuItem.scrollToElement)'
                                            (pageScrollFinish)="scrollToFeature(menuItem.scrollToFeatureName)"
                                        >
                                            <div class="float-left">
                                                <img [src]="menuItem.iconURL" alt="Product Icon" class="menu-item-icon" />
                                            </div>
                                            <div class="menu-item-text">
                                                <span class="menu-item-name">{{ menuItem.name }}</span>
                                                <span class="menu-item-description">{{ menuItem.description }}</span>
                                            </div>
                                            <div class="clear"></div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="col-6">
                                    <ng-container *ngFor="let menuItem of menuItemsRight">
                                        <div
                                            pageScroll
                                            mat-menu-item
                                            routerLink="/"
                                            [href]="menuItem.scrollToElement"
                                            class="menu-item-wrapper"
                                            [pageScrollDuration]="menuItem.scrollDuration"
                                            (pageScrollFinish)="scrollToFeature(menuItem.scrollToFeatureName)"
                                        >
                                            <div class="float-left">
                                                <img [src]="menuItem.iconURL" alt="Product Icon" class="menu-item-icon" />
                                            </div>
                                            <div class="menu-item-text">
                                                <span class="menu-item-name">{{ menuItem.name }}</span>
                                                <span class="menu-item-description">{{ menuItem.description }}</span>
                                            </div>
                                            <div class="clear"></div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </mat-menu>

                        <!-- Pricing Page Link -->
                        <li *ngIf="!currentEvent" class="px-4" style="z-index: 1">
                            <a routerLink="/pricing" routerLinkActive="active-nav-link" class="fg1-nav-link">Pricing</a>
                        </li>

                        <!-- Discover Events Link -->
                        <li *ngIf="!currentEvent" class="px-4" style="z-index: 1">
                            <a routerLink="discover" routerLinkActive="active-nav-link" class="fg1-nav-link"
                                >Discover Events</a
                            >
                        </li>

                        <!-- My Events Dropdown -->
                        <ng-container *ngIf="!currentEvent && currentUser">
                            <!-- If the user is either event admin or super admin (has subscription)-->
                            <li *ngIf="isAdmin" [matMenuTriggerFor]="menu" class="px-4" style="z-index: 1">
                                <a
                                    [class]="
                                        !currentEventPage && isMyEventsPage
                                            ? 'active-nav-link pointer white-space-nowrap'
                                            : 'white-space-nowrap pointer'
                                    "
                                    >My Events</a
                                >

                                <!-- Otherwise do not show the dropdwon just redirect to events for which is registered-->
                            </li>

                            <li *ngIf="!isAdmin" class="px-4" style="z-index: 1">
                                <a
                                    routerLink="events"
                                    routerLinkActive="active-nav-link white-space-nowrap"
                                    class="fg1-nav-link"
                                    >My Events</a
                                >
                            </li>

                            <mat-menu #menu="matMenu">
                                <button routerLink="events/admin" mat-menu-item>Events I've organized</button>
                                <button routerLink="events" mat-menu-item>Events I'm registered for</button>
                            </mat-menu>
                        </ng-container>

                        <!-- Exit Event Link -->
                        <li
                            *ngIf="currentEvent"
                            (click)="exitEvent()"
                            class="exit-event-container px-4 white-space-nowrap pointer"
                            style="z-index: 1"
                        >
                            <div class="back-arrow-container">
                                <div class="material-icons">arrow_back_ios</div>
                            </div>
                            <span class="exit-text white-space-nowrap">Exit Event</span>
                        </li>

                        <li *ngIf="currentEvent && shouldShowWelcomePage(currentEvent)" class="px-4" style="z-index: 1">
                            <a
                                [routerLink]="logoRouteLink"
                                [class]="
                                    currentEventPage === 'welcome'
                                        ? 'selected-event-nav-link white-space-nowrap'
                                        : 'fg1-nav-link'
                                "
                                >Welcome Page</a
                            >
                        </li>

                        <li *ngIf="currentEvent && hasPresentations" class="px-4" style="z-index: 1">
                            <a
                                [routerLink]="['/', currentEvent.eventCode, 'presentations']"
                                [class]="
                                    currentEventPage === 'presentations'
                                        ? 'selected-event-nav-link white-space-nowrap'
                                        : 'fg1-nav-link'
                                "
                                >Presentations</a
                            >
                        </li>

                        <li *ngIf="currentEvent" class="px-4" style="z-index: 1">
                            <a
                                [routerLink]="['/', currentEvent.eventCode, 'live-sessions']"
                                [class]="
                                    currentEventPage === 'live-sessions'
                                        ? 'selected-event-nav-link white-space-nowrap'
                                        : 'fg1-nav-link'
                                "
                                >Live Sessions</a
                            >
                        </li>

                        <li *ngIf="isJudgeForEvent || isAbstractJudge" class="px-2" style="font-size: 12px; z-index: 1">
                            |
                        </li>


                        <li *ngIf="isJudgeForEvent" class="px-4" style="z-index: 1">
                            <a
                                [routerLink]="['/', currentEvent.eventCode, 'judging']"
                                [class]="
                                    currentEventPage === 'judging'
                                        ? 'selected-event-nav-link white-space-nowrap'
                                        : 'fg1-nav-link'
                                "
                                >Judging</a
                            >
                        </li>

                        <li *ngIf="isAbstractJudge" class="px-4" style="z-index: 1">
                            <a
                                [routerLink]="['/', currentEvent.eventCode, 'abstract']"
                                [class]="
                                    currentEventPage === 'abstract'
                                        ? 'selected-event-nav-link white-space-nowrap'
                                        : 'fg1-nav-link'
                                "
                                >Reviews</a
                            >
                        </li>

                        <div *ngIf="currentEvent && isViewerAdmin" class="px-4" style="z-index: 1">
                            <a
                                class="btn btn-secondary"
                                [routerLink]="'/' + currentEvent.eventCode + '/admin/event-setup'"
                                >Manage Event</a
                            >
                        </div>
                    </ul>
                </div>

                <!-- Right (Account) -->
                <div class="height-90 width-33-pc">
                    <ul class="d-flex justify-content-end align-items-center height-90 no-list">
                        <!-- Avatar/Account Dropdown -->
                        <li *ngIf="currentUser">
                            <button mat-button [matMenuTriggerFor]="menu">
                                <div class="avatar-container">
                                    <div
                                        mat-card-avatar
                                        [ngStyle]="{ 'background-image': profileImgUrl }"
                                        class="avatar"
                                    >
                                        <mat-icon *ngIf="profileImgUrl === 'none'" aria-hidden="false">person</mat-icon>
                                    </div>
                                    <mat-icon  aria-label='Expand account dropdown' aria-hidden="false">keyboard_arrow_down</mat-icon>
                                </div>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button (click)="goMyAccount()" mat-menu-item>My Account</button>
                                <button (click)="goMySubmissions()" mat-menu-item>My Submissions</button>
                                <button (click)="logout()" mat-menu-item>Sign out</button>
                            </mat-menu>
                        </li>

                        <!-- Login -->
                        <li *ngIf="!currentUser" class="px-4">
                            <a
                                [routerLink]="[currentEvent?.eventCode ? currentEvent.eventCode : '', 'login']"
                                class="fg1-nav-link"
                                routerLinkActive="active-nav-link"
                                >Login</a
                            >
                        </li>

                        <!-- Signup -->
                        <li *ngIf="!currentUser" class="px-4">
                            <a
                                [routerLink]="[currentEvent?.eventCode ? currentEvent.eventCode : '', 'signup']"
                                class="fg1-nav-link"
                                routerLinkActive="active-nav-link"
                                >Sign Up</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Mobile Header -->
<ng-container *onlyForPlatform="'web'">
    <div *onlyForScreen="['xs', 'sm', 'md']" class="background-white">
        <div class="container navbar-light">
            <div class="d-flex justify-content-between">
                <!-- Left (Logo and Custom Event Logo) -->
                <div class="height-90 width-50-pc">
                    <div class="d-flex align-items-center height-90">
                        <a [routerLink]="logoRouteLink">
                            <div
                                #eventLogo
                                *ngIf="currentEvent?.logoImgName"
                                class="navbar-event-logo-container pl-4 ml-xl-0 pl-xl-0"
                            >
                                <img
                                    class="navbar-event-logo-img"
                                    [src]="eventAssetsRemotePath + currentEvent?.logoImgName"
                                    [alt]="currentEvent.name"
                                    (load)="isEventLogoLoaded = true; eventLogo.style.display = 'inline'"
                                />
                            </div>
                            <img
                                [class]="
                                    currentEvent && currentEvent.logoImgName && isEventLogoLoaded
                                        ? 'my-auto app-logo-with-event'
                                        : 'my-auto app-logo ml-xl-0'
                                "
                                [src]="symposiumLogoV2Path"
                                alt="Symposium by ForagerOne"
                            />
                        </a>
                    </div>
                </div>

                <div class="height-90 width-50-pc">
                    <div class="d-flex justify-content-end align-items-center height-90">
                        <!-- Hamburger Menu Icon -->
                        <button
                            class="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapsableContent"
                            aria-controls="collapsableContent"
                            aria-expanded="false"
                            aria-label="Expand or Collapse Menu"
                        >
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Dropdown (Links) -->
            <div class="collapse navbar-collapse" id="collapsableContent">
                <ul class="no-list">
                    <!-- Home Page Link -->
                    <li *ngIf="!currentEvent" class="py-2">
                        <a
                            [routerLink]="'/'"
                            routerLinkActive="active-nav-link"
                            [routerLinkActiveOptions]="{ exact: true }"
                            class="fg1-nav-link"
                            >Home</a
                        >
                    </li>

                    <!-- Product Page Link -->
                    <li *ngIf="!currentEvent" (keyup.enter)='trigger.toggleMenu()'  [matMenuTriggerFor]="productMenu" class="py-2">
                        <div class="product-dropdown-wrapper"
                             tabindex='0'
                        >
                            <a class="fg1-nav-link">Features</a>
                            <mat-icon  aria-label='Expand Features list' aria-hidden="false" class="arrow-down">keyboard_arrow_down</mat-icon>
                        </div>
                    </li>

                    <!-- Case Studies Link -->
                    <li *ngIf="!currentEvent" class="py-2">
                        <a routerLink="case-studies" routerLinkActive="active-nav-link" class="fg1-nav-link"
                            >Case Studies</a
                        >
                    </li>

                    <mat-menu class="product-menu" #productMenu="matMenu">
                        <ng-container *ngFor="let menuItem of menuItems">
                            <div
                                pageScroll
                                mat-menu-item
                                routerLink="/"
                                [href]="menuItem.scrollToElement"
                                class="menu-item-wrapper"
                                [pageScrollDuration]="menuItem.scrollDuration"
                                (pageScrollFinish)="scrollToFeature(menuItem.scrollToFeatureName)"
                            >
                                <div class="float-left">
                                    <img [src]="menuItem.iconURL" alt="Product Icon" class="menu-item-icon" />
                                </div>
                                <div class="menu-item-text">
                                    <span class="menu-item-name">{{ menuItem.name }}</span>
                                    <span class="menu-item-description">{{ menuItem.description }}</span>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </ng-container>
                    </mat-menu>

                    <!-- Pricing Page Link -->
                    <li *ngIf="!currentEvent" class="py-2">
                        <a routerLink="/pricing" routerLinkActive="active-nav-link" class="fg1-nav-link">Pricing</a>
                    </li>

                    <!-- Discover Events Link -->
                    <li *ngIf="!currentEvent" class="py-2">
                        <a routerLink="discover" routerLinkActive="active-nav-link" class="fg1-nav-link"
                            >Discover Events</a
                        >
                    </li>

                    <!-- My Events Dropdown -->
                    <ng-container *ngIf="!currentEvent && currentUser">
                        <!-- If the user is either event admin or super admin (has subscription)-->
                        <li *ngIf="isAdmin" class="py-2" [matMenuTriggerFor]="menu">
                            <a
                                [class]="
                                    !currentEventPage && isMyEventsPage
                                        ? 'active-nav-link pointer white-space-nowrap'
                                        : 'white-space-nowrap pointer'
                                "
                                >My Events</a
                            >
                        </li>

                        <!-- Otherwise do not show the dropdwon just redirect to events for which is registered-->
                        <li *ngIf="!isAdmin" class="py-2">
                            <a
                                routerLink="events"
                                routerLinkActive="active-nav-link white-space-nowrap"
                                class="fg1-nav-link"
                                >My Events</a
                            >
                        </li>

                        <mat-menu #menu="matMenu">
                            <button routerLink="events/admin" mat-menu-item>Events I've organized</button>
                            <button routerLink="events" mat-menu-item>Events I'm registered for</button>
                        </mat-menu>
                    </ng-container>

                    <!-- Exit Event Link -->
                    <li
                        *ngIf="currentEvent"
                        (click)="exitEvent()"
                        class="exit-event-container py-2 white-space-nowrap pointer"
                    >
                        <div class="back-arrow-container" style="right: 0">
                            <div class="material-icons">arrow_back_ios</div>
                        </div>
                        <span class="exit-text white-space-nowrap">Exit Event</span>
                    </li>

                    <li *ngIf="currentEvent && shouldShowWelcomePage(currentEvent)" class="py-2">
                        <a
                            [routerLink]="logoRouteLink"
                            [class]="
                                currentEventPage === 'welcome'
                                    ? 'selected-event-nav-link white-space-nowrap'
                                    : 'fg1-nav-link'
                            "
                            >Welcome Page</a
                        >
                    </li>

                    <li *ngIf="currentEvent" class="py-2">
                        <a
                            [routerLink]="['/', currentEvent.eventCode, 'presentations']"
                            [class]="
                                currentEventPage === 'presentations'
                                    ? 'selected-event-nav-link white-space-nowrap'
                                    : 'fg1-nav-link'
                            "
                            >Presentations</a
                        >
                    </li>

                    <li *ngIf="currentEvent" class="py-2">
                        <a
                            [routerLink]="['/', currentEvent.eventCode, 'live-sessions']"
                            [class]="
                                currentEventPage === 'live-sessions'
                                    ? 'selected-event-nav-link white-space-nowrap'
                                    : 'fg1-nav-link'
                            "
                            >Live Sessions</a
                        >
                    </li>

                    <li *ngIf="isJudgeForEvent" class="py-2">
                        <a
                            [routerLink]="'/' + currentEvent.eventCode + '/judging'"
                            [class]="
                                currentEventPage === 'judging'
                                    ? 'selected-event-nav-link white-space-nowrap'
                                    : 'fg1-nav-link'
                            "
                            >Judging</a
                        >
                    </li>

                    <li *ngIf="isAbstractJudge" class="py-2">
                        <a
                            [routerLink]="'/' + currentEvent.eventCode + '/abstract'"
                            [class]="
                                currentEventPage === 'abstract'
                                    ? 'selected-event-nav-link white-space-nowrap'
                                    : 'fg1-nav-link'
                            "
                            >Reviews</a
                        >
                    </li>

                    <li *ngIf="currentEvent && isViewerAdmin" class="py-2">
                        <a [routerLink]="'/' + currentEvent.eventCode + '/admin/event-setup'" class="fg1-nav-link"
                            >Manage Event</a
                        >
                    </li>

                    <!-- My Account/Sign Out -->
                    <li *ngIf="currentUser" (click)="goMyAccount()" class="py-2 pointer" style="font-size: 14px">
                        My Account
                    </li>

                    <li *ngIf="currentUser" (click)="goMySubmissions()" class="py-2 pointer" style="font-size: 14px">
                        My Submissions
                    </li>

                    <li *ngIf="currentUser" (click)="logout()" class="py-2 pointer" style="font-size: 14px">
                        Sign out
                    </li>

                    <!-- Login -->
                    <li *ngIf="!currentUser" class="py-2">
                        <a
                            [routerLink]="[currentEvent?.eventCode ? currentEvent.eventCode : '', 'login']"
                            class="fg1-nav-link"
                            routerLinkActive="active-nav-link"
                            >Login</a
                        >
                    </li>

                    <!-- Signup -->
                    <li *ngIf="!currentUser" class="py-2">
                        <a
                            [routerLink]="[currentEvent?.eventCode ? currentEvent.eventCode : '', 'signup']"
                            class="fg1-nav-link"
                            routerLinkActive="active-nav-link"
                            >Sign Up</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</ng-container>
