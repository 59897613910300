<div class="footer">
    <div class="container">
        <div class="container">
            <div class="float-left">
                <div>Symposium&#8482; by ForagerOne &copy; {{ currentYear }}</div>
            </div>
            <div class="footer-menu float-right">
                <a href="https://foragerone.com/about">About</a>
                <a routerLink="/contact">Contact Us</a>
                <a [routerLink]="getTermsOfServiceRouterLink()">Terms of Service</a>
                <a [routerLink]="getPrivacyPolicyRouterLink()">Privacy Policy</a>
            </div>
        </div>
    </div>
</div>
