<button class="btn {{ btnClass }}" [disabled]="loading">
    <ng-container *ngIf="text; else useInnerTemplate">
        <span *ngIf="!loading">{{ text }}</span>
    </ng-container>
    <div class="" *ngIf="loading">
        <mat-spinner diameter="24"></mat-spinner>
    </div>
</button>

<ng-template #useInnerTemplate>
    <ng-container *ngIf="!loading">
        <ng-content></ng-content>
    </ng-container>
</ng-template>
