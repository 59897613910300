import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
    constructor() {}

    ngOnInit() {
        // Users slick-carousel (see index.html)
        $('.team_carousel').slick({
            slidesToShow: 3,
            dots: true,
            adaptiveHeight: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
    }
}
