import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-video',
    templateUrl: './video-dialog.component.html',
    styleUrls: ['./video-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoDialogComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
