<!-- additional presenter fixed fields -->
<app-form-preview-input-field [uniqueFieldKey]="presenterFieldKey + '_firstName'" label="First Name" [isRequired]="true"
    [description]="presenterConfig?.firstNameDescription" type="text" [data]="presenterData" dataFieldName="firstName"
    [formErrs]="formErrs" [isInputDisabled]="isInputDisabled" [editorStatus]="editorStatus">
</app-form-preview-input-field>
<app-form-preview-input-field [uniqueFieldKey]="presenterFieldKey + '_lastName'" label="Last Name" [isRequired]="true"
    [description]="presenterConfig?.lastNameDescription" type="text" [data]="presenterData" dataFieldName="lastName"
    [formErrs]="formErrs" [isInputDisabled]="isInputDisabled" [editorStatus]="editorStatus">
</app-form-preview-input-field>
<app-form-preview-input-field [uniqueFieldKey]="presenterFieldKey + '_email'" label="Email" [isRequired]="true"
    [description]="presenterConfig?.emailDescription" type="email" [data]="presenterData" dataFieldName="email"
    [formErrs]="formErrs" [isInputDisabled]="isInputDisabled" [editorStatus]="editorStatus">
</app-form-preview-input-field>
<app-form-preview-multi-select-field *ngIf="shouldShowFieldOnSubmissionForm(presenterConfig?.level)"
    [uniqueFieldKey]="presenterFieldKey + '_level'" [label]="presenterConfig?.level.label"
    [isRequired]="presenterConfig?.level.isRequired" [description]="presenterConfig?.level.description"
    [type]="presenterConfig?.level.type" [optionLabels]="presenterConfig?.level.optionLabels" [data]="presenterData"
    dataFieldName="level" [formErrs]="formErrs" [isInputDisabled]="isInputDisabled" [editorStatus]="editorStatus">
</app-form-preview-multi-select-field>
<app-form-preview-input-field *ngIf="shouldShowFieldOnSubmissionForm(presenterConfig?.major)"
    [uniqueFieldKey]="presenterFieldKey + '_major'" [label]="presenterConfig?.major.label"
    [isRequired]="presenterConfig?.major.isRequired" [description]="presenterConfig?.major.description"
    [type]="presenterConfig?.major.type" [data]="presenterData" dataFieldName="major" [formErrs]="formErrs"
    [isInputDisabled]="isInputDisabled" [editorStatus]="editorStatus"></app-form-preview-input-field>

<!-- presenter extra fields -->
<div *ngFor="let extraField of presenterConfig?.extraFields; index as extraFieldIdx">
    <div *ngIf="shouldShowFieldOnSubmissionForm(extraField)">
        <app-form-preview-input-field *ngIf="['text', 'number', 'wysiwyg'].includes(extraField.type)"
            [uniqueFieldKey]="presenterFieldKey + '_extra_' + extraFieldIdx" [label]="extraField.label"
            [isRequired]="extraField.isRequired" [description]="extraField.description" [type]="extraField.type"
            [quillModulesConfig]="quillModulesGeneralConfig" [data]="presenterData.extraValues"
            [dataFieldName]="extraField.hash" [formErrs]="formErrs" [editorStatus]="editorStatus"
            [isInputDisabled]="isInputDisabled"></app-form-preview-input-field>
        <app-form-preview-multi-select-field *ngIf="['radio', 'checkbox'].includes(extraField.type)"
            [uniqueFieldKey]="presenterFieldKey + '_extra_' + extraFieldIdx" [label]="extraField.label"
            [isRequired]="extraField.isRequired" [description]="extraField.description" [type]="extraField.type"
            [optionLabels]="presenterConfig?.extraFields[extraFieldIdx].optionLabels" [data]="presenterData.extraValues"
            [dataFieldName]="extraField.hash" [formErrs]="formErrs" [isInputDisabled]="isInputDisabled"
            [editorStatus]="editorStatus"></app-form-preview-multi-select-field>
        <app-form-preview-likert-scale-field *ngIf="['scale'].includes(extraField.type)"
            [uniqueFieldKey]="'presentation_extra_' + extraFieldIdx" [label]="extraField.label"
            [isRequired]="extraField.isRequired" [description]="extraField.description" [type]="extraField.type"
            [optionLabels]="presenterConfig?.extraFields[extraFieldIdx].optionLabels" [data]="presenterData.extraValues"
            [dataFieldName]="extraField.hash" [formErrs]="formErrs"></app-form-preview-likert-scale-field>
    </div>
</div>