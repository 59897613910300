<div class="editor-container">
    <div class="section-header">
        <div>
            {{ currentEvent.presentationFormConfig.presenterFields.presenterTitle }}
            Information
        </div>
        <app-form-preview-presenter-fields
            id="mainPresenterFields"
            presenterFieldKey="presenter_0"
            [presenterConfig]="currentEvent.presentationFormConfig.presenterFields"
            [presenterData]="formValues.presenterData[0]"
            [formErrs]="formErrs"
            [editorStatus]="editorStatus"
            [isInputDisabled]="isInputDisabled"
        ></app-form-preview-presenter-fields>

        <hr />
        <div
            *ngIf="currentEvent.presentationFormConfig.presenterFields.maxAddlPresenters > 0"
            class="additional-presenters-header"
        >
            <div>
                Additional
                {{ currentEvent.presentationFormConfig.presenterFields.presenterTitle }}s
            </div>
            <span class="description"
                >Add up to
                {{ currentEvent.presentationFormConfig.presenterFields.maxAddlPresenters }}
                additional
                {{ currentEvent.presentationFormConfig.presenterFields.presenterTitle }}
                {{ currentEvent.presentationFormConfig.presenterFields.maxAddlPresenters > 1 ? 's' : '' }}
                for this event.</span
            >
        </div>

        <!-- additional presenter accordion -->
        <mat-accordion
            *ngIf="formValues.presenterData.length > 1"
            #addlPresentersAccordion="matAccordion"
            id="addlPresentersAccordion"
            [displayMode]="'flat'"
            multi
        >

            <mat-expansion-panel
                [id]="'presenterPanel_' + (presenterIdx + 1)"
                *ngFor="let presenterData of formValues.presenterData.slice(1); index as presenterIdx"
                class="presenter-panel"
                hideToggle
                [expanded]="true"
                (afterExpand)="openPresenterPanel(presenterIdx + 1)"
                (afterCollapse)="closePresenterPanel(presenterIdx + 1)"
            >
                <mat-expansion-panel-header class="presenter-panel-header">
                    <div class="presenter-divider-container">
                        <div class="presenter-icon-container">
                            <mat-icon
                                [matTooltip]="
                                    presenterData.firstName
                                        ? presenterData.firstName + ' ' + presenterData.lastName
                                        : currentEvent.presentationFormConfig.presenterFields.presenterTitle +
                                          ' ' +
                                          (presenterIdx + 2)
                                "
                                matTooltipPosition="right"
                                matTooltipClass="presenter-tooltip"
                                >assignment_ind</mat-icon
                            >
                        </div>
                        <div class="presenter-divider-text">
                            {{ 'Presenter ' + (presenterIdx + 2) }}
                            <div class="panel-toggle-container">
                                <mat-icon *ngIf="!currentlyOpenPresenterIndexes.includes(presenterIdx + 1)"
                                    >keyboard_arrow_right</mat-icon
                                >
                                <mat-icon *ngIf="currentlyOpenPresenterIndexes.includes(presenterIdx + 1)"
                                    >keyboard_arrow_down</mat-icon
                                >
                            </div>
                        </div>
                        <div class="presenter-remove-icon-container">
                            <mat-icon class="presenter-remove-icon" (click)="handleRemovePresenter(presenterIdx + 1)"
                                >clear</mat-icon
                            >
                        </div>
                        <mat-divider class="presenter-divider"></mat-divider>
                    </div>
                </mat-expansion-panel-header>
                <mat-card class="presenter-card">
                    <mat-card-content>
                        <app-form-preview-presenter-fields
                            [presenterFieldKey]="'presenter_' + (presenterIdx + 1)"
                            [presenterConfig]="currentEvent.presentationFormConfig.presenterFields"
                            [presenterData]="presenterData"
                            [formErrs]="formErrs"
                            [editorStatus]="editorStatus"
                            [isInputDisabled]="isInputDisabled"
                        ></app-form-preview-presenter-fields>
                    </mat-card-content>
                </mat-card>
            </mat-expansion-panel>
        </mat-accordion>

        <div
            *ngIf="
                currentEvent.presentationFormConfig.presenterFields.maxAddlPresenters > 0 &&
                currentEvent.presentationFormConfig.presenterFields.maxAddlPresenters + 1 >
                    formValues.presenterData.length
            "
        >
            <div class="row justify-content-center">
                <button [disabled]="isInputDisabled" class="btn btn-secondary col-auto" (click)="handleAddPresenter()">
                    Add Presenter {{ formValues.presenterData.length + 1 }}
                </button>
            </div>
        </div>

        <br />
        <hr *ngIf="currentEvent.presentationFormConfig.presenterFields.maxAddlPresenters > 0" />

        <!-- presentation fields -->
        <div class="presentation-info-header">
            <div>Presentation Information</div>
        </div>

        <!-- presentation title -->
        <app-form-preview-input-field
            uniqueFieldKey="title"
            [label]="currentEvent.presentationFormConfig.presentationFields.title.label"
            [isRequired]="currentEvent.presentationFormConfig.presentationFields.title.isRequired"
            [description]="currentEvent.presentationFormConfig.presentationFields.title.description"
            type="wysiwyg"
            [quillModulesConfig]="quillModulesTitleConfig"
            [data]="formValues.presentationData"
            dataFieldName="title"
            [formErrs]="formErrs"
            [editorStatus]="editorStatus"
            [isInputDisabled]="isInputDisabled"
        ></app-form-preview-input-field>

        <!-- presentation mentor -->
        <app-form-preview-input-field
            *ngIf="currentEvent?.presentationFormConfig?.presentationFields?.mentor"
            uniqueFieldKey="mentor"
            [label]="currentEvent.presentationFormConfig.presentationFields.mentor.label"
            [isRequired]="currentEvent.presentationFormConfig.presentationFields.mentor.isRequired"
            [description]="currentEvent.presentationFormConfig.presentationFields.mentor.description"
            type="text"
            [data]="formValues.presentationData"
            dataFieldName="mentor"
            [formErrs]="formErrs"
            [editorStatus]="editorStatus"
            [isInputDisabled]="isInputDisabled"
        ></app-form-preview-input-field>

        <!-- presentation abstract -->
        <app-form-preview-input-field
            uniqueFieldKey="abstract"
            [label]="currentEvent.presentationFormConfig.presentationFields.abstract.label"
            [isRequired]="currentEvent.presentationFormConfig.presentationFields.abstract.isRequired"
            [description]="currentEvent.presentationFormConfig.presentationFields.abstract.description"
            type="wysiwyg"
            [quillModulesConfig]="quillModulesAbstractConfig"
            [data]="formValues.presentationData"
            dataFieldName="abstract"
            [formErrs]="formErrs"
            [editorStatus]="editorStatus"
            [isInputDisabled]="isInputDisabled"
        ></app-form-preview-input-field>

        <!-- presentation subjects -->
        <app-form-preview-multi-select-field
            *ngIf="currentEvent.presentationFormConfig.presentationFields.subjects.isDisplayed"
            uniqueFieldKey="subjects"
            [label]="currentEvent.presentationFormConfig.presentationFields.subjects.label"
            [isRequired]="currentEvent.presentationFormConfig.presentationFields.subjects.isRequired"
            [description]="currentEvent.presentationFormConfig.presentationFields.subjects.description"
            [type]="currentEvent.presentationFormConfig.presentationFields.subjects.type"
            [optionLabels]="currentEvent.presentationFormConfig.presentationFields.subjects.optionLabels"
            [data]="formValues.presentationData"
            dataFieldName="subjects"
            [formErrs]="formErrs"
            [editorStatus]="editorStatus"
            [isInputDisabled]="isInputDisabled"
        ></app-form-preview-multi-select-field>

        <!-- presentation extra fields -->
        <div
            *ngFor="
                let extraField of currentEvent.presentationFormConfig.presentationFields?.extraFields;
                index as extraFieldIdx
            "
        >
            <div *ngIf="extraField.isDisplayed">
                <app-form-preview-input-field
                    *ngIf="['text', 'number', 'wysiwyg'].includes(extraField.type)"
                    [uniqueFieldKey]="'presentation_extra_' + extraFieldIdx"
                    [label]="extraField.label"
                    [isRequired]="extraField.isRequired"
                    [isShownInSummary]="extraField.isShownInSummary"
                    [description]="extraField.description"
                    [type]="extraField.type"
                    [quillModulesConfig]="quillModulesGeneralConfig"
                    [data]="formValues.presentationData.extraValues"
                    [dataFieldName]="extraField.hash"
                    [formErrs]="formErrs"
                    [editorStatus]="editorStatus"
                    [isInputDisabled]="isInputDisabled"
                ></app-form-preview-input-field>
                <app-form-preview-multi-select-field
                    *ngIf="['radio', 'checkbox'].includes(extraField.type)"
                    [uniqueFieldKey]="'presentation_extra_' + extraFieldIdx"
                    [label]="extraField.label"
                    [isRequired]="extraField.isRequired"
                    [isShownInSummary]="extraField.isShownInSummary"
                    [description]="extraField.description"
                    [type]="extraField.type"
                    [optionLabels]="
                        currentEvent.presentationFormConfig.presentationFields?.extraFields[extraFieldIdx].optionLabels
                    "
                    [data]="formValues.presentationData.extraValues"
                    [dataFieldName]="extraField.hash"
                    [formErrs]="formErrs"
                    [editorStatus]="editorStatus"
                    [isInputDisabled]="isInputDisabled"
                ></app-form-preview-multi-select-field>
                <app-form-preview-likert-scale-field
                    *ngIf="['scale'].includes(extraField.type)"
                    [uniqueFieldKey]="'presentation_extra_' + extraFieldIdx"
                    [label]="extraField.label"
                    [isRequired]="extraField.isRequired"
                    [description]="extraField.description"
                    [type]="extraField.type"
                    [optionLabels]="
                        currentEvent.presentationFormConfig.presentationFields?.extraFields[extraFieldIdx].optionLabels
                    "
                    [data]="formValues.presentationData.extraValues"
                    [dataFieldName]="extraField.hash"
                    [formErrs]="formErrs"
                    [editorStatus]="editorStatus"
                ></app-form-preview-likert-scale-field>
            </div>
        </div>

        <!-- media fields -->
        <div class="section-header">
            <div>Presentation Media</div>
        </div>
        <div mat-form-field class="media-options-wrapper editor-field-indent" floatLabel="always">
            <div id="presentationType" class="label-container">
                <mat-label> Media Type<span class="required-star">*</span> </mat-label>
            </div>
            <mat-radio-group [(ngModel)]="formValues.presentationType" class="radio-group media-type-radio" [disabled]='isInputDisabled'>
                <div *ngIf="poster.isDisplayed">
                    <mat-radio-button id="poster-radio-btn" value="poster" class="radio-button"></mat-radio-button>
                    <div class="radio-btn-label-container">
                        <div class="radio-btn-label">
                            {{ poster.label }}
                        </div>
                    </div>
                </div>
                <div *ngIf="oral.isDisplayed">
                    <mat-radio-button id="oral-radio-btn" value="oral" class="radio-button"></mat-radio-button>
                    <div class="radio-btn-label-container">
                        <div class="radio-btn-label">
                            {{ oral.label }}
                        </div>
                    </div>
                </div>
                <div *ngIf="exhibition.isDisplayed">
                    <mat-radio-button
                        id="exhibition-radio-btn"
                        value="exhibition"
                        class="radio-button"
                    ></mat-radio-button>
                    <div class="radio-btn-label-container">
                        <div class="radio-btn-label">
                            {{ exhibition.label }}
                        </div>
                    </div>
                </div>
            </mat-radio-group>
            <small *ngIf="formErrs.presentationType?.isRequired" class="err-text">This field is required!</small>
        </div>

        <!-- poster media fields -->
        <div *ngIf="formValues.presentationType === 'poster'" id="adminPoster" class="media-group">
            <div class="poster-label-spacer"></div>
            <mat-label class="poster-label editor-field-indent"
                >{{ poster.label }}<span class="required-star">*</span></mat-label
            >
            <div class="description editor-field-indent">
                {{ poster.posterUploadDescription }}
            </div>
            <button (click)="posterPosterUploadInput.click()" class="btn btn-primary upload editor-field-indent">
                Upload
            </button>
            <input
                id="adminPosterPoster"
                #posterPosterUploadInput
                type="file"
                (change)="handleFilesChange('posterPoster', $event.target.files)"
                hidden
            />
            <div *ngIf="formFiles.posterPoster" class="uploaded-pdf-icon-container">
                <div>
                    <mat-icon class="pdf-icon">picture_as_pdf</mat-icon>
                    <mat-icon class="remove-file" (click)="removeFile('posterPoster')">highlight_off</mat-icon>
                </div>
            </div>
            <div *ngIf="formFiles.posterPoster" class="uploaded-file-name">
                {{ formFiles.posterPoster.item(0).name }}
            </div>
            <div
                *ngIf="formValues.presentationMediaData?.currentPosterURL && !posterPosterObjectURL"
                class="description"
            >
                (Showing current poster)
            </div>
            <div style="height: 21px; width: 100%"></div>
            <ngx-extended-pdf-viewer
                *ngIf="posterPosterObjectURL || formValues.presentationMediaData?.currentPosterURL"
                id="adminPosterPreviewViewer"
                [src]="posterPosterObjectURL || formValues.presentationMediaData?.currentPosterURL"
                [showDownloadButton]="false"
                [showPrintButton]="false"
                [showOpenFileButton]="false"
                [showPropertiesButton]="false"
                [showBookmarkButton]="false"
                [showPresentationModeButton]="true"
                [enablePrint]="false"
                useBrowserLocale="true"
                height="500px"
            >
            </ngx-extended-pdf-viewer>
            <br /><br />
            <div [ngClass]="formErrs.posterPoster ? 'show-err' : 'hide-err'">
                <small *ngIf="!formErrs.posterPoster?.isValid" class="err-text">This field is required!</small>
                <small *ngIf="formErrs.posterPoster?.isValid" class="err-text">{{
                    formErrs.posterPoster.errMsg
                }}</small>
            </div>

            <ng-container *ngIf="!shouldUseVideoFileUpload(poster); else posterVideoUpload">
                <app-form-preview-input-field
                    *ngIf="poster.isOptionalFieldDisplayed"
                    uniqueFieldKey="adminPosterVoiceoverVideoLink"
                    dataFieldName="voiceoverVideoLink"
                    label="Voiceover Video"
                    [isRequired]="false"
                    [description]="poster.voiceoverVideoLinkDescription"
                    type="text"
                    [data]="formValues.presentationMediaData"
                    [editorStatus]="editorStatus"
                    [formErrs]="formErrs"
                ></app-form-preview-input-field>
            </ng-container>
        </div>

        <!-- oral media fields -->
        <div *ngIf="formValues.presentationType === 'oral'" id="adminOral" class="media-group">
            <app-form-preview-input-field
                *ngIf="!shouldUseVideoFileUpload(oral); else oralVideoUpload"
                uniqueFieldKey="adminOralPresentationVideoLink"
                [label]="oral.label + ' Presentation Video'"
                [isRequired]="true"
                [description]="oral.presentationVideoLinkDescription"
                type="text"
                [data]="formValues.presentationMediaData"
                dataFieldName="presentationVideoLink"
                [editorStatus]="editorStatus"
                [formErrs]="formErrs"
            ></app-form-preview-input-field>

            <br />

            <div *ngIf="formFiles.oralSlides" class="uploaded-pdf-icon-container">
                <div>
                    <mat-icon class="pdf-icon">picture_as_pdf</mat-icon>
                    <mat-icon class="remove-file" (click)="removeFile('oralSlides')">highlight_off</mat-icon>
                </div>
            </div>
            <div *ngIf="formFiles.oralSlides" class="uploaded-file-name">
                {{ formFiles.oralSlides.item(0).name }}
            </div>
            <div [ngClass]="formErrs.oralSlides ? 'show-err' : 'hide-err'">
                <small *ngIf="formErrs.oralSlides?.isValid" class="err-text">{{ formErrs.oralSlides.errMsg }}</small>
            </div>
        </div>

        <!-- exhibition media fields -->
        <div *ngIf="formValues.presentationType === 'exhibition'" id="adminExhibition" class="media-group">
            <app-form-preview-input-field
                *ngIf="!shouldUseVideoFileUpload(exhibition); else exhibitionVideoUpload"
                class="editor-field-indent"
                uniqueFieldKey="adminExhibitionPresentationVideoLink"
                [label]="exhibition.label"
                [isRequired]="true"
                [description]="exhibition.presentationVideoLinkDescription"
                type="text"
                [data]="formValues.presentationMediaData"
                dataFieldName="presentationVideoLink"
                [editorStatus]="editorStatus"
                [formErrs]="formErrs"
            ></app-form-preview-input-field>

            <app-form-preview-input-field
                *ngIf="exhibition.isOptionalFieldDisplayed && !shouldUseVideoFileUpload(exhibition)"
                class="editor-field-indent"
                uniqueFieldKey="adminExhibitionVoiceoverVideoLink"
                label="Voiceover Video"
                [isRequired]="false"
                [description]="exhibition.voiceoverVideoLinkDescription"
                type="text"
                [data]="formValues.presentationMediaData"
                [editorStatus]="editorStatus"
                dataFieldName="voiceoverVideoLink"
                [formErrs]="formErrs"
            ></app-form-preview-input-field>
        </div>
    </div>
</div>

<ng-template #exhibitionVideoUpload>
    <app-submission-file-upload-preview
        class="editor-field-indent"
        key="presentationVideoLink"
        [existingFilename]="mediaFiles.presentationVideoLink"
        (onUploadInProgress)="uploadInProgress($event)"
        (onUploadFinished)="onUploadFinished($event)"
        *ngIf="exhibition.isOptionalFieldDisplayed"
        [event]="currentEvent"
        [required]="true"
        [description]="exhibition.presentationVideoLinkDescription"
        [field]="exhibition"
    ></app-submission-file-upload-preview>

    <app-submission-file-upload-preview
        class="editor-field-indent"
        key="voiceoverVideoLink"
        [existingFilename]="mediaFiles.voiceoverVideoLink"
        label="Voiceover Video"
        (onUploadInProgress)="uploadInProgress($event)"
        (onUploadFinished)="onUploadFinished($event)"
        *ngIf="exhibition.isOptionalFieldDisplayed"
        [description]="exhibition.voiceoverVideoLinkDescription"
        [event]="currentEvent"
        [required]="true"
        [field]="exhibition"
    ></app-submission-file-upload-preview
></ng-template>

<ng-template #oralVideoUpload>
    <app-submission-file-upload-preview
        class="editor-field-indent"
        key="presentationVideoLink"
        [existingFilename]="mediaFiles.presentationVideoLink"
        [label]="oral.label + ' Presentation Video'"
        (onUploadInProgress)="uploadInProgress($event)"
        (onUploadFinished)="onUploadFinished($event)"
        [description]="oral.presentationVideoLinkDescription"
        [event]="currentEvent"
        [field]="oral"
        [required]="true"
    >
    </app-submission-file-upload-preview>
</ng-template>

<ng-template #posterVideoUpload>
    <app-submission-file-upload-preview
        class="editor-field-indent"
        *ngIf="poster.isOptionalFieldDisplayed"
        [existingFilename]="mediaFiles.voiceoverVideoLink"
        key="voiceoverVideoLink"
        (onUploadInProgress)="uploadInProgress($event)"
        (onUploadFinished)="onUploadFinished($event)"
        [event]="currentEvent"
        [required]="true"
        [description]="poster.voiceoverVideoLinkDescription"
        [field]="poster"
    ></app-submission-file-upload-preview>
</ng-template>
