import { enableProdMode, ɵresetCompiledComponents } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from '@env';
import { Integrations } from '@sentry/tracing';
import LogRocket from 'logrocket';
import { hmrBootstrap } from './hmr';

if (environment.production) {
    initSentryIO();
    LogRocket.init(environment.logrocket);
    enableProdMode();
}
const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
    if (module['hot']) {
        module['hot'].accept();
        module['hot'].dispose(() => ɵresetCompiledComponents());
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap().catch(err => console.log(err));
}

function initSentryIO() {
    Sentry.init({
        dsn: 'https://6c1ad59fc2e140d897915afb74397815@o465912.ingest.sentry.io/5479463',
        environment: environment.ANGULAR_ENV + '-frontend',
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            new Integrations.BrowserTracing({
                tracingOrigins: [
                    'https://symposium.foragerone.com',
                    'https://staging-symposium.foragerone.com',
                    'http://beta-symposium.foragerone.com',
                    'https://o465912.ingest.sentry.io/api',
                ],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
