import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { CarouselModule } from '../carousel/carousel.module';
import { RouterModule } from '@angular/router';
import { VideoDialogModule } from 'src/app/dialogs/video-dialog/video-dialog.module';

@NgModule({
    imports: [CommonModule, CarouselModule, RouterModule, VideoDialogModule],
    exports: [HomeComponent],
    declarations: [HomeComponent],
})
export class HomeModule {}
