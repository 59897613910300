import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env';
import {
    BillingType,
    CreateEventPayload,
    DEFAULT_PAGINATION_PARAMS,
    DonationConfig,
    Event,
    EventConfig,
    EventEmail,
    EventsResponse,
    GetEventsParams,
    Registrant,
} from '@models';
import { Router } from '@angular/router';
import moment from 'moment';
import { getMaxEventDate, shouldShowWelcomePage, toHttpParams } from '@functions';
import { TWO_MINUTES_CACHE } from '@constants';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { saveAs } from 'file-saver';
import { PreviousRouteService } from './previous-route-service';
import { PresentationService } from './presentation.service';

const MAX_CACHE_COUNT = 50;
const eventCacheBuster$ = new Subject<void>();

@Injectable({
    providedIn: 'root',
})
export class EventService {
    SERVICE_BASE_URL = environment.SERVICE_BASE_URL;
    eventsUrl = `${environment.SERVICE_BASE_URL}/events`;

    predefinedRoutes: string[] = [
        'discover',
        'events',
        'create-event',
        'login',
        'reset-password',
        'pricing',
        'signup',
        'saml-callback',
        'authentication-handler',
        'privacy-policy',
        'terms-of-service',
        'account',
    ];

    constructor(
        private http: HttpClient,
        private router: Router,
        private previousRouteService: PreviousRouteService,
        private presentationService: PresentationService
    ) {
        this.predefinedRoutes = this.router.config
            .map(c => c.path)
            // not start with ':', and not contain '/'
            .filter(path => /^[^/:]+/.test(path));
    }

    public checkEventCodeExists(eventCode: string): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            observe: 'response' as const,
        };

        return this.predefinedRoutes.includes(eventCode)
            ? of(false)
            : this.http.get(`${this.eventsUrl}/check-eventcode/${eventCode}`, httpOptions).pipe(
                  map(_ => true),
                  catchError(_ => of(false))
              );
    }

    public createEvent(event: CreateEventPayload): Observable<any> {
        return this.http.post<any>(`${this.eventsUrl}`, event);
    }

    public getEventRegistrants(eventId: number): Observable<Registrant[]> {
        return this.http.get<Registrant[]>(`${this.eventsUrl}/registrants?eventId=${eventId}`);
    }

    public exportEventRegistrants(eventId: number, filename: string) {
        return this.http.get(`${this.eventsUrl}/registrants/export?eventId=${eventId}`, { responseType: 'blob' }).pipe(
            tap(blob => {
                if (blob && blob.size > 0) {
                    saveAs(blob, filename);
                }
            })
        );
    }

    public getEventEmails(eventId: string): Observable<EventEmail[]> {
        return this.http.get<EventEmail[]>(`${this.eventsUrl}/${eventId}/emails`);
    }

    @CacheBuster({
        cacheBusterNotifier: eventCacheBuster$,
    })
    public updateEvent(eventCode: string, formData: FormData) {
        const httpOptions = { observe: 'response' as const };

        return this.http.put(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_PUT_UPDATE_EVENT}`.replace(
                '{eventCode}',
                eventCode
            ),
            formData,
            httpOptions
        );
    }

    /**
     * Update an event using the v2 version of the endpoint that accepts JSON instead of formData
     */
    @CacheBuster({
        cacheBusterNotifier: eventCacheBuster$,
    })
    public updateEventV2(eventId: number, event: Partial<Event>) {
        return this.http.put(`${this.eventsUrl}/v2/${eventId}`, event);
    }

    public blockEventUser(consumerId: number, eventId: number, block = true) {
        return this.http.post(`${this.eventsUrl}/block`, {
            consumerId,
            eventId,
            block,
        });
    }

    public isLaunched(event: Event): boolean {
        const currentDate = new Date();
        const eventStartDate = new Date(event.startDate);

        return currentDate > eventStartDate;
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: eventCacheBuster$,
    })
    public getEvents(params: Partial<GetEventsParams>): Observable<EventsResponse> {
        return this.http
            .get<EventsResponse>(`${this.eventsUrl}`, { params: toHttpParams(params) })
            .pipe(
                map(response => {
                    response.events.forEach(event => {
                        if (event.startDate) {
                            event.startDate = moment.utc(event.startDate).local() as any;
                        }

                        if (!!event.eventConfig?.registrationLaunchDate) {
                            event.eventConfig.registrationLaunchDate = moment
                                .utc(event.eventConfig.registrationLaunchDate)
                                .local() as any;
                        }

                        event.eventFeatureFlags = {
                            eventId: event.eventId,
                            useLatestSubmission: (event as any).useLatestSubmission,
                            accessCustomSubmissionFormEditor: (event as any).accessCustomSubmissionFormEditor,
                            editCoverPhoto: (event as any).editCoverPhoto,
                            editEventLogo: (event as any).editEventLogo,
                            editSplashScreen: (event as any).editSplashScreen,
                            editPresentation: (event as any).editPresentation,
                            networkingHoursLimit: (event as any).networkingHoursLimit,
                            eventUptimeInDays: (event as any).eventUptimeInDays,
                            coverDonationsTransactionFees: (event as any).coverDonationsTransactionFees,
                        };
                    });
                    return response;
                })
            );
    }

    @CacheBuster({
        cacheBusterNotifier: eventCacheBuster$,
    })
    public updateDonationConfig(eventId: number, donationConfig: Partial<DonationConfig>): Observable<DonationConfig> {
        return this.http.put<DonationConfig>(`${this.eventsUrl}/${eventId}/donationConfig`, donationConfig);
    }

    @CacheBuster({
        cacheBusterNotifier: eventCacheBuster$,
    })
    public updateEventConfig(eventId: number, eventConfig: Partial<EventConfig>): Observable<EventConfig> {
        return this.http.put<EventConfig>(`${this.eventsUrl}/${eventId}/config`, eventConfig);
    }

    public isEventLaunched(event: Event) {
        const today = new Date();
        const parsedDate = new Date(event.startDate);
        return today > parsedDate;
    }

    public isRegistrationDateAfterEventLaunch(event: Event) {
        return (
            !event.eventConfig?.registrationLaunchDate || event.eventConfig?.registrationLaunchDate > event.startDate
        );
    }

    /**
     * @description get the event end date based on the subscription tier
     * @param event
     */
    public getEventEndDate(event: Event) {
        const {
            eventFeatureFlags,
            subscription: { subscriptionTier },
        } = event;
        if (BillingType.RECURRING === subscriptionTier.billingType) {
            return event.endDate && moment(event.endDate).toDate();
        }
        return moment(
            getMaxEventDate(
                event.startDate,
                eventFeatureFlags.eventUptimeInDays || subscriptionTier.eventUptimeInDays
            ) || event.endDate
        ).toDate();
    }

    public navigateToEvent(event: Event, shouldRegister: boolean, isFromRegistrationPage: boolean = false) {
        if (!event) {
            return;
        }
        if (shouldRegister) {
            const redirectURL = shouldShowWelcomePage(event)
                ? event.eventCode
                : `${event.eventCode}/${event.presentationsCount > 0 ? 'presentations' : 'live-sessions'}`;
            this.previousRouteService.updateCurrentURL(redirectURL);
            return this.router.navigate(['/', event.eventCode, isFromRegistrationPage ? 'register' : 'registration']);
        }

        if (shouldShowWelcomePage(event)) {
            return this.router.navigate(['/', event.eventCode]);
        }

        if (event.presentationsCount <= 0) {
            return this.router.navigate(['/', event.eventCode, 'live-sessions']);
        }

        return this.router.navigate(['/', event.eventCode, 'presentations']);
    }

    public eventHasPresentations(eventId): Observable<boolean> {
        return this.presentationService
            .getPresentations({
                ...DEFAULT_PAGINATION_PARAMS,
                ignoreAbstractFilters: true,
                eventId: eventId,
            })
            .pipe(map(({ total }) => total > 0));
    }
}
