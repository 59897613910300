import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TableColumns, TableObject } from '@models';
import { cloneDeep, map } from 'lodash';
import { CompleteNgUnsubscribeComponent } from '@utils';
import { v4 as uuid } from 'uuid';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-symposium-custom-table',
    templateUrl: './symposium-custom-table.component.html',
    styleUrls: ['./symposium-custom-table.component.scss'],
})
export class SymposiumCustomTableComponent extends CompleteNgUnsubscribeComponent implements OnChanges {
    @Input() tableObjectOriginal: TableObject;
    @Input() currentPage = 1;
    @Input() pageSize = 10;
    @Input() disabled: boolean = false;
    @Output() selected = new EventEmitter<number[]>();
    @Output() statusToggle = new EventEmitter<[number, boolean]>();
    @Output() unassign = new EventEmitter<{
        tableType: string;
        parent: number;
        child: number;
    }>();

    POSITIVE = 'POSITIVE';
    NEGATIVE = 'NEGATIVE';
    public paginationId = uuid();
    public isAllItemsSelected: boolean;
    public query: string;
    tableObject: TableObject;
    private searchableColumns: { dataLabel: string; type: string }[] = [];

    get showSelectAllCheckbox() {
        return this.tableObject.columns.find(column => column.type === 'checkbox');
    }

    get haveStatusToggle() {
        return this.tableObject.columns.find(column => column.type === 'statusToggle');
    }

    get headerLeftPadding() {
        const checkboxLeftPadding = this.showSelectAllCheckbox ? 10 : 0;
        const statusToggleLeftPadding = this.haveStatusToggle ? 10 : 0;
        return `${checkboxLeftPadding + statusToggleLeftPadding}px`;
    }

    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnChanges(): void {
        this.tableObject = cloneDeep(this.tableObjectOriginal);

        // Update selected items
        const selected = this.tableObject.data.filter(item => item.selected);
        this.isAllItemsSelected = this.tableObject.data.length === selected.length && this.tableObject.data.length > 0;

        // Update searchable columns
        this.searchableColumns = this.tableObject.columns
            .filter(column => column.searchable)
            .map(column => ({
                dataLabel: column.dataLabel,
                type: column.type,
            }));

        // Update searching
        if (this.query && this.query !== '') {
            this.tableObject.data = this.filterTable(this.query.toLocaleLowerCase(), this.tableObject.data);
        }
        this.cdr.detectChanges();
    }

    expand(row: any) {
        // Expands row children
        // const row = this.tableObject.data$.find(row => row[this.tableObject.metadata.uniqueKey] === uniqueKey);
        row.expandChildren = !row.expandChildren;
    }

    sort(columnName: string) {
        // Rotate through sorting order
        const column = this.tableObject.columns.find(column => column.dataLabel === columnName);
        if (column.sort === '') {
            // Do descending sorting
            this.tableObject.data = this.tableObject.data.sort((a, b) => {
                if (column.type === 'timestamp') {
                    return a[columnName] > b[columnName] ? 1 : -1;
                } else if (column.type === 'text') {
                    return a[columnName].localeCompare(b[columnName]);
                } else if (column.type === 'num-children') {
                    return (a.children ? a.children.length : 0) > (b.children ? b.children.length : 0) ? 1 : -1;
                }
            });
            column.sort = 'desc';
        } else if (column.sort === 'desc') {
            // Do ascending sorting
            this.tableObject.data = this.tableObject.data.sort((a, b) => {
                if (column.type === 'timestamp') {
                    return b[columnName] > a[columnName] ? 1 : -1;
                } else if (column.type === 'text') {
                    return b[columnName].localeCompare(a[columnName]);
                } else if (column.type === 'num-children') {
                    return (b.children ? b.children.length : 0) > (a.children ? a.children.length : 0) ? 1 : -1;
                }
            });
            column.sort = 'asc';
        } else {
            // Go back to default sort (sorting by uniqueKey)
            this.tableObject.data = this.tableObject.data.sort((a, b) => {
                if (typeof a[this.tableObject.metadata.uniqueKey] === 'string') {
                    return a[this.tableObject.metadata.uniqueKey].localeCompare(b[this.tableObject.metadata.uniqueKey]);
                }
                return a[this.tableObject.metadata.uniqueKey] > b[this.tableObject.metadata.uniqueKey];
            });
            column.sort = '';
        }

        // Clear any other columns that have sorting
        this.tableObject.columns.forEach(column => {
            if (column.sort !== '' && column.dataLabel !== columnName) {
                column.sort = '';
            }
        });
    }

    search(query: string) {
        // Search through all data
        this.query = query;
        if (this.query && this.query !== '') {
            this.tableObject.data = this.filterTable(this.query.toLocaleLowerCase(), this.tableObjectOriginal.data);
        } else {
            this.tableObject.data = this.tableObjectOriginal.data;
        }
        this.cdr.detectChanges();
    }

    emitSelectedItems() {
        // filter selected items
        const selected = this.tableObject.data.filter(item => item.selected);
        // emit unique key from the selected items based on the table config
        const selectedIds = map(selected, this.tableObject.metadata.uniqueKey);
        this.selected.emit(selectedIds);
    }

    emitRowAndStatus(row: any, statusToggle: 'POSITIVE' | 'NEGATIVE') {
        row.statusToggle = statusToggle;
        if (
            !this.tableObject.metadata ||
            !this.tableObject.metadata.uniqueKey ||
            !this.tableObject.metadata.statusKey
        ) {
            return;
        }
        row.status = this.tableObject.metadata.statusKey[statusToggle.toLowerCase()];
        this.statusToggle.emit([row[this.tableObject.metadata.uniqueKey], statusToggle === 'POSITIVE']);
    }

    updateUnassign(tableType, parent, child) {
        this.unassign.emit({
            tableType,
            parent,
            child,
        });
    }

    updatePage(currentPage: number) {
        this.currentPage = currentPage;
    }

    get selectAllIndexes() {
        const start = (this.currentPage - 1) * this.pageSize;
        const end = (this.currentPage - 1) * this.pageSize + this.pageSize;
        return {
            start,
            end,
        };
    }

    onSelectAll({ checked }: MatCheckboxChange) {
        const { start, end } = this.selectAllIndexes;
        const items = this.tableObject.data.map((row, index) => {
            const selected = index >= start && index <= end ? checked : false;
            return {
                ...row,
                selected,
            };
        });
        this.tableObject.data = items;
        this.cdr.detectChanges();
        this.emitSelectedItems();
    }

    getColumns(row): Partial<TableColumns>[] {
        if (!this.tableObject.metadata.multipleChildConfigurations) {
            return this.tableObject.childColumns[0].columns;
        }

        const childColumn = this.tableObject.childColumns.find(
            childColumn => childColumn.metadata.uniqueKey === row.childrenUniqueKey
        );

        return childColumn ? childColumn.columns : [];
    }

    private filterTable(query: string, originalData: any[]) {
        return originalData.filter(row => {
            for (const searchableColumn of this.searchableColumns) {
                if (searchableColumn.type === 'timestamp') {
                    if (row[searchableColumn.dataLabel].format('M/D/YY, h:mm A').includes(query)) {
                        return true;
                    }
                } else if (searchableColumn.type === 'text') {
                    if (
                        row[searchableColumn.dataLabel] &&
                        row[searchableColumn.dataLabel].toLocaleLowerCase().includes(query)
                    ) {
                        return true;
                    }
                } else if (searchableColumn.type === 'num-children') {
                    if ((row.children ? row.children.length : 0).toString().includes(query)) {
                        return true;
                    }
                }
            }
            return false;
        });
    }

    chooseWidthPercent(column: TableColumns): string {
        if (column.type === 'statusToggle') {
            return `${+column.widthPercent + 2}%`;
        }
        return `${column.widthPercent}%`;
    }
}
