const presenters = new Array(6)
    .fill(null)
    .map((_, index) => [`presenter${index}FirstName`, `presenter${index}LastName`, `presenter${index}Email`]);

const BACKEND_SORTED_FIELDS = [
    'title',
    'abstract',
    'createDate',
    'presenter1Email',
    'presenter1FirstName',
    'presenter1LastName',
    ...presenters,
];

export const isBackendSorted = (field: string) => BACKEND_SORTED_FIELDS.includes(field);
