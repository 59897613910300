import { Injectable } from '@angular/core';

export const PRESENTATION_SORT_HASH = 'PRESENTATION_SORT_HASH';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private storage: Storage;

    constructor() {
        this.storage = localStorage;
    }

    public getItem(key: string): string {
        return this.storage.getItem(key);
    }

    public setItem(key, value: string) {
        this.storage.setItem(key, value.toString());
    }

    public getPresentationSortHash(): string {
        let hash = this.getItem(PRESENTATION_SORT_HASH);

        if (!hash) {
            hash = Math.floor(Math.random() * 70000).toString();
            this.setItem(PRESENTATION_SORT_HASH, hash);
        }

        return hash;
    }

    public setPresentationHash(hash: string) {
        this.setItem(PRESENTATION_SORT_HASH, hash);
    }
}
