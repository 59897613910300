import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Capacitor } from '@capacitor/core';

type PlatformMode = 'web' | 'android' | 'ios' | 'native';

@Directive({
    selector: '[onlyForPlatform]',
})
export class OnlyForPlatformDirective {
    @Input() set onlyForPlatform(mode: PlatformMode) {
        let shouldShow = false;
        if (mode === 'native' && Capacitor.isNativePlatform()) {
            shouldShow = true;
        } else {
            shouldShow = Capacitor.getPlatform() === mode;
        }

        if (shouldShow) {
            this.container.createEmbeddedView(this.templateRef);
        } else {
            this.container.clear();
        }
    }

    constructor(private templateRef: TemplateRef<any>, private container: ViewContainerRef) {}
}
