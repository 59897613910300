import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormEditorInputFieldComponent } from './input-field.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { PipesModule } from '@pipes';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
        MatIconModule,
        MatTooltipModule,
        MatOptionModule,
        MatSelectModule,
        PipesModule,
    ],
    exports: [FormEditorInputFieldComponent],
    declarations: [FormEditorInputFieldComponent],
})
export class FormEditorInputFieldModule {}
