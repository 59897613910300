import { PreviousRouteService } from './../services/previous-route-service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private prevRouteService: PreviousRouteService
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authenticationService.getCurrentUser().pipe(
            map((user: any) => {
                if (user) {
                    return true;
                }

                // navigate to login page
                this.prevRouteService.updateCurrentURL(state.url);
                this.router.navigate(['/login']);
                // you can save redirect url so after authing we can move them back to the page they requested
                return false;
            })
        );
    }
}
