// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr: false,
    firebaseConfig: {
        apiKey: 'AIzaSyCeOH1mGV7u25IjhahaagUVwoN8h-AwBOU',
        authDomain: 'foragersymposium.firebaseapp.com',
        databaseURL: 'https://foragersymposium.firebaseio.com',
        projectId: 'foragersymposium',
        storageBucket: 'foragersymposium.appspot.com',
        messagingSenderId: '1003527675603',
        appId: '1:1003527675603:web:df2f742501559101a93e26',
    },
    vonageApiKey: '47231654',
    videoSDKApiKey: '35de799b-f27a-42fa-bfd7-e29734ccea9b',
    logrocket: 'u1g6yi/symposium-beta',
    googleAnalytics: 'UA-113448567-1',
    googleTagManagerExtraParams: '&gtm_auth=DKC-YlAsIkFODUf5YXdkHg&gtm_preview=env-10&gtm_cookies_win=x',
    fullStory: null,
    ANGULAR_ENV: 'beta',
    APP_BASE_URL_PROTOCOL: 'https://',
    APP_BASE_URL: 'beta-symposium.foragerone.com',
    SERVICE_BASE_URL: 'https://symposium-api-beta-ivu2siwqia-uk.a.run.app/api', // TODO : do not switch back to regular urls due to caching issues
    SYMPOSIUM_LOGO_V2_PATH: '/assets/images/logo_v2.png',
    EVENT_ASSETS_REMOTE_PATH: 'https://storage.googleapis.com/fg1-event-assets-beta/',
    CONSUMER_ASSETS_REMOTE_PATH: 'https://storage.googleapis.com/fg1-user-assets/',
    SERVICE_ENDPOINT_POST_CREATE_LIVE_SESSION: '/live-sessions',
    SERVICE_ENDPOINT_PUT_UPDATE_LIVE_SESSION: '/live-sessions/{liveSessionId}',
    SERVICE_ENDPOINT_PUT_UPDATE_LIVE_SESSIONS_ORDER: '/live-sessions/{eventCode}/order',
    SERVICE_ENDPOINT_DELETE_DELETE_LIVE_SESSION: '/live-sessions/{liveSessionId}?eventId={eventId}',
    SERVICE_ENDPOINT_PUT_UPDATE_PRESENTATION: '/presentations/{presentationHash}',
    SERVICE_ENDPOINT_DELETE_DELETE_PRESENTATION: '/presentations/{presentationHash}?eventId={eventId}',
    SERVICE_ENDPOINT_GET_GET_EVENT: '/events/{eventCode}',
    SERVICE_ENDPOINT_PUT_UPDATE_EVENT: '/events/{eventCode}',
    SERVICE_ENDPOINT_PUT_ARCHIVE_EVENT: '/events/archive/{eventCode}',
    SERVICE_ENDPOINT_PUT_UNARCHIVE_EVENT: '/events/unarchive/{eventCode}',
    SERVICE_ENDPOINT_PUT_UPDATE_ALLOW_ALL_DOMAINS: '/events/{eventId}/allowAllDomains',
    SERVICE_ENDPOINT_POST_ADD_EVENT_EMAILS: '/events/{eventId}/emails',
    SERVICE_ENDPOINT_DELETE_EVENT_EMAIL: '/events/{eventId}/emails?eventEmailId={eventEmailId}',
    SERVICE_ENDPOINT_MY_EVENTS: '/events/my',
    SERVICE_ENDPOINT_MY_EMAILS: '/users/my-emails',
    SERVICE_ENDPOINT_EMAILS: '/users/emails',
    SERVICE_ENDPOINT_SET_EMAIL_PRIMARY: '/users/emails/{consumerEmailId}/set-primary',
    SERVICE_ENDPOINT_VERIFY_EMAIL: '/users/emails/verify',
    SERVICE_ENDPOINT_SPECIFIC_EMAIL: '/users/emails/{consumerEmailId}',
    SERVICE_ENDPOINT_SEND_EMAIL_VERIFICATION_CODE: '/users/emails/{consumerEmailId}/send-code',
    SERVICE_ENDPOINT_GET_GET_GLOBAL_EVENT_COUNT: '/events/count',
    SERVICE_ENDPOINT_UPDATE_USER: '/users/me',
    SERVICE_ENDPOINT_PROFILE_PHOTO: '/users/photo',
    SERVICE_ENDPOINT_UPDATE_PASSWORD: '/users/password',
    SERVICE_ENDPOINT_GET_GLOBAL_FEATURE_FLAGS: '/global-feature-flags',
    SERVICE_ENDPOINT_GET_ANALYTICS: '/analytics/{eventCode}',
    STRIPE_API_KEY: 'pk_test_oUnTyS7IDsNiRiSCDABSBRW300ReZ4NDXw',
    stripeConnectClientID: 'ca_Ix45d3WNBXQzj2aevtqewgBihDLXvoEn',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
