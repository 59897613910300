import { Subject } from 'rxjs';

export const speakerDetection = subscriber => {
    const isTalking = new Subject<boolean>();
    let activity = null;
    subscriber.on('audioLevelUpdated', event => {
        const now = Date.now();
        if (event.audioLevel > 0.1) {
            if (!activity) {
                activity = { timestamp: now, talking: false };
            } else if (activity.talking) {
                activity.timestamp = now;
            } else if (now - activity.timestamp > 300) {
                activity.talking = true;
                isTalking.next(true);
            }
        } else if (activity && now - activity.timestamp > 500) {
            if (activity.talking) {
                isTalking.next(false);
            }
            activity = null;
        }
    });
    return isTalking;
};
