import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VideoDialogComponent } from '@dialogs';
import { AppService } from '@services';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
    public iAmTheHomePageHack = true;
    public isACustomHome = true;
    public selectedFeaturesPresentationsSection: 'presentations' | 'abstract';
    public selectedFeaturesLiveSessionsSection: 'meeting' | 'tables' | 'webinars';
    public selectedFeaturesRepositorySection: 'private' | 'repository';
    private ngUnsubscribe$ = new Subject();
    private presentationsSectionOffset = 600;
    private liveSessionsSectionOffset = 900;
    private repositorySectionOffset = 1200;

    constructor(public appService: AppService, private dialog: MatDialog) {}

    ngOnInit() {
        this.presentationsSectionOffset =
            document.getElementById('presentationsSection').offsetTop - window.innerHeight / 2;
        this.liveSessionsSectionOffset =
            document.getElementById('liveSessionsSection').offsetTop - window.innerHeight / 2;
        this.repositorySectionOffset = document.getElementById('repositorySection').offsetTop - window.innerHeight / 2;

        this.appService
            .getScrolledFeature()
            .pipe(
                filter(feature => !!feature),
                takeUntil(this.ngUnsubscribe$)
            )
            .subscribe(scrolledFeature => {
                if (scrolledFeature === 'presentations') {
                    this.selectedFeaturesPresentationsSection = 'presentations';
                } else if (scrolledFeature === 'abstract') {
                    this.selectedFeaturesPresentationsSection = 'abstract';
                } else if (scrolledFeature === 'meeting') {
                    this.selectedFeaturesLiveSessionsSection = 'meeting';
                } else if (scrolledFeature === 'tables') {
                    this.selectedFeaturesLiveSessionsSection = 'tables';
                } else if (scrolledFeature === 'webinars') {
                    this.selectedFeaturesLiveSessionsSection = 'webinars';
                } else if (scrolledFeature === 'private') {
                    this.selectedFeaturesRepositorySection = 'private';
                } else if (scrolledFeature === 'repository') {
                    this.selectedFeaturesRepositorySection = 'repository';
                }
            });
    }

    openVideoDialog() {
        this.dialog.open(VideoDialogComponent, {
            direction: 'ltr',
            width: '80%',
            backdropClass: 'backdrop-video-dialog',
            panelClass: 'panel-video-dialog',
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    @HostListener('window:scroll', ['$event'])
    onScroll($event: Event) {
        // Forces animation to start from beginning when you reach the section
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const isReachingPresentationsSection = scrollTop > this.presentationsSectionOffset;
        const isReachingLiveSessionsSection = scrollTop > this.liveSessionsSectionOffset;
        const isReachingRepositorySection = scrollTop > this.repositorySectionOffset;

        // In reverse order, so that only one section is triggered at a time as user scrolls
        if (isReachingRepositorySection && !this.selectedFeaturesRepositorySection) {
            this.selectedFeaturesRepositorySection = 'private';
        } else if (isReachingLiveSessionsSection && !this.selectedFeaturesLiveSessionsSection) {
            this.selectedFeaturesLiveSessionsSection = 'meeting';
        } else if (isReachingPresentationsSection && !this.selectedFeaturesPresentationsSection) {
            this.selectedFeaturesPresentationsSection = 'presentations';
        }
    }
}
