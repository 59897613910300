import { SubscriptionWithTier } from '@models';

/**
 *  checks whether the user can purchase a new event package or subscription
 * @param {SubscriptionWithTier[]} subscriptions
 * @param isSubscription
 * @returns {boolean}
 */
export const canPurchaseNewSubscriptionOrEvent = (subscriptions: SubscriptionWithTier[], isSubscription: boolean) => {
    if (isSubscription) return subscriptions.length === 0;
    return subscriptions.filter(s => s.billingType === 'recurring').length === 0;
};
