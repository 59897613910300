import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CustomInputFormField, FILE_EXTENSION_MAP, Form, PresenterData } from '@models';
import { EditorStatus } from 'src/app/submissions/interfaces';
import { NotificationsService } from 'angular2-notifications';
import { isValidFormUploadExtension } from '@functions';

@Component({
    selector: 'app-form-preview-input-field',
    templateUrl: './input-field.component.html',
    styleUrls: ['./input-field.component.scss'],
})
export class FormPreviewInputFieldComponent implements OnInit, OnChanges {
    @Input() uniqueFieldKey: string;
    @Input() label: string;
    @Input() isRequired: boolean;
    @Input() isShownInSummary: boolean;
    @Input() description: string;
    @Input() type: string;
    @Input() quillModulesConfig: any;
    @Input() data: PresenterData;
    @Input() field: CustomInputFormField;
    @Input() dataFieldName: string;
    @Input() form: Form;
    @Input() formErrs: any;
    @Input() isInputDisabled: boolean;
    @Input() editorStatus: EditorStatus = { isClean: true };
    @Output() isClean: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onFileSelect: EventEmitter<{ hash: string; file: File }> = new EventEmitter<{
        hash: string;
        file: File;
    }>();

    @ViewChild('fileInput') fileInput;
    selectedFile: File;
    fileExtensionsMap = FILE_EXTENSION_MAP;

    quillInitialValue: string; // used to populate editor without direct ngModel binding

    isShownInSummaryTooltipText =
        `Value(s) entered for this field will be shown ` + `in the summary of this Presentation's main view.`;

    constructor(private fg1NotificationService: NotificationsService) { }

    ngOnInit() {
        if (this.type === 'wysiwyg') {
            this.quillInitialValue = this.data[this.dataFieldName];
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.type === 'wysiwyg') {
            this.quillInitialValue = this.data[this.dataFieldName];
        }
    }

    private changeIsCleanEditorStatus(value: boolean): void {
        this.isClean.emit(value);
        if (!this.editorStatus) {
            this.editorStatus = { isClean: value };
            return;
        }

        this.editorStatus.isClean = value;
    }

    onChange() {
        this.changeIsCleanEditorStatus(false);
        if (this.type === 'number' && !isNaN(this.data[this.dataFieldName])) {
            this.data[this.dataFieldName] = Number(this.data[this.dataFieldName]);
        }
    }

    handleClear() {
        this.changeIsCleanEditorStatus(false);
        this.data[this.dataFieldName] = '';
    }

    handleEditorChanged(text: string, html: string, source: string) {
        if (source === 'api') { return; }

        // Don't trigger changes when we are programmatically setting the value
        // Only trigger changes when the user has changed the value
        this.changeIsCleanEditorStatus(false);
        if (!text.trim()) {
            this.data[this.dataFieldName] = '';
        } else {
            this.data[this.dataFieldName] = html;
        }
    }

    onFileChange(hash: string, files: FileList) {
        const file = files.item(0);
        const valid = isValidFormUploadExtension(file, this.field.extension);

        if (!valid) {
            return this.fg1NotificationService.error('Please choose a valid file type');
        }

        if (file && valid) {
            this.selectedFile = file;
            this.onFileSelect.emit({
                hash,
                file,
            });
        }
    }

    selectFile() {
        this.fileInput.nativeElement.click();
    }
}
