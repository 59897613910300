<!-- Begin custom table -->
<div style="margin-top: 1em">
    <div *ngIf="tableObject?.metadata?.title" class="float-left">
        <h6 class="judging-section-title" style="line-height: 44px">{{ tableObject.metadata.title }}</h6>
    </div>
    <form class="float-right" (ngSubmit)="search(searchInput.value)">
        <input
            #searchInput
            type="text"
            [placeholder]="tableObject?.metadata?.searchText || 'Search'"
            style="
                border: 2px solid #e3a300;
                border-radius: 0.5em;
                padding: 0.5em 1em;
                margin: 0 1em 1em;
                position: relative;
                top: 2px;
            "
        />
        <button class="btn btn-primary">Search</button>
    </form>
</div>
<div class="clear"></div>
<div style="display: flex; border-bottom: 1px solid #ccc; padding-bottom: 10px"
    [ngStyle]="{'padding-left': headerLeftPadding}">
    <ng-container *ngFor="let column of tableObject.columns">
        <ng-container *ngIf="column.type === 'checkbox'">
            <div [ngStyle]="{ width: chooseWidthPercent(column) }" class="column-label"
                (click)="sort(column.dataLabel)">
                <mat-checkbox [(ngModel)]="isAllItemsSelected" (change)="onSelectAll($event)">
                </mat-checkbox>
            </div>
        </ng-container>
        <ng-container *ngIf="column.type !== 'checkbox'">
            <div *ngIf="!column.noSort" [ngStyle]="{ width: chooseWidthPercent(column) }" class="column-label mr-3"
                (click)="sort(column.dataLabel)">
                <span style="font-weight: bold">{{ column.name }}</span>
                <ng-container *ngIf="column.sort && column.sort !== 'desc'"> &#8593; </ng-container>
                <ng-container *ngIf="column.sort && column.sort !== 'asc'"> &#8595; </ng-container>
            </div>
            <div *ngIf="column.noSort" [ngStyle]="{ width: chooseWidthPercent(column) }" class="column-label mr-3">
                <span style="font-weight: bold">{{ column.name }}</span>
                <ng-container *ngIf="column.sort && column.sort !== 'desc'"> &#8593; </ng-container>
                <ng-container *ngIf="column.sort && column.sort !== 'asc'"> &#8595; </ng-container>
            </div>
        </ng-container>
    </ng-container>
</div>
<ng-container *ngIf="tableObject.data as rows">
    <div
        *ngFor="
            let row of rows
                | paginate
                    : { totalItems: rows.length, itemsPerPage: pageSize, currentPage: currentPage, id: paginationId }
        ">
        <div style="display: flex" class="custom-row parent" [ngClass]="{ 'expanded': row.expandChildren }">
            <div [class]="column?.type === 'statusToggle' ? column.customClass : column.customClass + ' mr-3'" *ngFor="let column of tableObject.columns"
                [ngStyle]="{ width: chooseWidthPercent(column), 'overflow-x': column.chip ? 'hidden' : 'initial' }">
                <ng-container *ngIf="column.type === 'checkbox'">
                    <mat-checkbox style="margin-left: 10px" [(ngModel)]="row.selected" (change)="emitSelectedItems()">
                    </mat-checkbox>
                </ng-container>
                <ng-container *ngIf="column.type === 'statusToggle'">
                    <div class="status-toggle">
                        <mat-icon
                            [ngClass]="{
                                'material-icons-outlined': row.statusToggle !== POSITIVE,
                                'unselected-status': row.statusToggle !== POSITIVE,
                                disabled: disabled
                            }"
                            class="positive d-inline"
                            (click)="emitRowAndStatus(row, 'POSITIVE')"
                        >
                            check_circle</mat-icon
                        >
                        <mat-icon
                            [ngClass]="{
                                'material-icons-outlined': row.statusToggle !== NEGATIVE,
                                'unselected-status': row.statusToggle !== NEGATIVE,
                                disabled: disabled
                            }"
                            class="negative d-inline"
                            (click)="emitRowAndStatus(row, 'NEGATIVE')"
                        >cancel</mat-icon>
                    </div>
                </ng-container>
                <ng-container *ngIf="column.type === 'expand'">
                    <span
                        (click)="expand(row)"
                        [ngStyle]="{ 'padding-left': haveStatusToggle ? '10px' : 'initial' }"
                        class="expand-and-collapse-arrow"
                        style="cursor: pointer"
                    >
                        <img
                            *ngIf="!row.expandChildren"
                            src="/assets/images/table-images/expand-black.png"
                            alt="Expand arrow"
                        />
                        <img
                            *ngIf="row.expandChildren"
                            src="/assets/images/table-images/collapse-white.png"
                            alt="Collapse arrow"
                        />
                    </span>
                </ng-container>
                <ng-container *ngIf="column.type === 'timestamp'">
                    {{ row[column.dataLabel] | date: 'short' }}
                </ng-container>
                <ng-container *ngIf="column.type === 'num-children'">
                    {{ row.children ? row.children.length : 0 }}
                </ng-container>
                <ng-container *ngIf="column.type === 'text'">
                    <ng-container *ngIf="!column.chip; else chip">
                        {{ row[column.dataLabel] | stripHtmlTags }}
                    </ng-container>
                    <ng-template #chip>
                        <mat-chip-list>
                            <mat-chip [class]="row.chipClass">
                                {{ row[column.dataLabel] | stripHtmlTags }}
                            </mat-chip>
                        </mat-chip-list>
                    </ng-template>
                </ng-container>
            </div>
        </div>
        <!-- Children -->
        <div
            *ngIf="row.expandChildren"
            style="margin-left: 94px; border-left: 1px solid #ccc; margin-top: 5px; padding-left: 10px"
        >
            <ng-container *ngIf="row?.children.length <= 0; else childrenRows">
                <p>
                    {{ tableObject?.metadata?.noChildrenText || 'No data available.' }}
                </p>
            </ng-container>
            <ng-template #childrenRows>
                <div style="display: flex; padding: 5px 0 0">
                    <div *ngFor="let column of getColumns(row)" [ngStyle]="{ width: chooseWidthPercent(column) }">
                        <span style="font-weight: bold">{{ column.name }}</span>
                    </div>
                </div>
                <div *ngFor="let child of row.children">
                    <div style="display: flex" class="custom-row">
                        <div
                            [class]="column.customClass"
                            *ngFor="let column of getColumns(row)"
                            [ngStyle]="{
                                width: chooseWidthPercent(column),
                                'overflow-x': column.chip || column.type === 'checkbox' ? 'hidden' : 'initial'
                            }"
                        >
                            <ng-container *ngIf="column.type === 'timestamp'">
                                {{ child[column.dataLabel] | date: 'short' }}
                            </ng-container>
                            <ng-container *ngIf="column.type === 'text'">
                                <ng-container *ngIf="!column.chip; else childChip">
                                    {{ child[column.dataLabel] | stripHtmlTags }}
                                </ng-container>
                                <ng-template #childChip>
                                    <mat-chip-list>
                                        <mat-chip [class]="child.chipClass">
                                            {{ child[column.dataLabel] | stripHtmlTags }}
                                        </mat-chip>
                                    </mat-chip-list>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngIf="column.type === 'button'">
                                <button
                                    (click)="
                                        updateUnassign(
                                            tableObject.metadata.tableType,
                                            row[tableObject.metadata.uniqueKey],
                                            child[tableObject.metadata.childUniqueKey]
                                        )
                                    "
                                    class="btn btn-primary"
                                >
                                    Unassign
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>
<!-- End custom table -->

<!--pagination-->
<pagination-controls [id]="paginationId" (pageChange)="updatePage($event)" [maxSize]="pageSize"></pagination-controls>
<!-- end pagination-->
