import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-skip-navigation',
    templateUrl: './skip-navigation.component.html',
    styleUrls: ['./skip-navigation.component.scss'],
})
export class SkipNavigationComponent implements OnInit {
    constructor(private router: Router) {}

    @Input() skipTo = 'main';

    ngOnInit() {}

    get link() {
        return this.router.url.endsWith(`#${this.skipTo}`) ? this.router.url : `${this.router.url}#${this.skipTo}`;
    }
}
