<div [class]="isOpened ? 'widget-wrapper active' : 'widget-wrapper'" *ngIf="shouldDisplayWidget">
    <mat-icon class="close lock-icon secondary" (click)="togglePopup()">close</mat-icon>
    <div class="content-container" *ngIf="showThumbnail && isThumbnailDisplayed && donationConfig" (click)="togglePopup()">
        <div class="thumbnail-wrapper">
            <div class="content-wrapper">
                <img src="assets/images/donation_widget_kare_cmp.png" alt="Care image" />

                <strong>{{ donationConfig.widgetTitle }}</strong>

                <div class="widget-body">
                    <p>{{ widgetBody }}</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isOpened" style="margin-left: -30px" id="tabs-wrapper">
        <app-message-widget *ngIf="isEditMode"></app-message-widget>
        <app-donation-popup [donationConfig]="donationConfig" *ngIf="isPopupMode"></app-donation-popup>
    </div>
</div>
