import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { PresenterData, SelectOption } from '@models';
import { EditorStatus } from 'src/app/submissions/interfaces';

@Component({
    selector: 'app-form-preview-multi-select-field',
    templateUrl: './multi-select-field.component.html',
    styleUrls: ['./multi-select-field.component.scss'],
})
export class FormPreviewMultiSelectFieldComponent implements OnInit {
    @Input() uniqueFieldKey: string;
    @Input() label: string;
    @Input() isRequired: boolean;
    @Input() isShownInSummary: boolean;
    @Input() description: string;
    @Input() type: string;
    @Input() optionLabels: Array<string>;
    @Input() options: SelectOption[];
    @Input() data: PresenterData;
    @Input() dataFieldName: string;
    @Input() formErrs: any;
    @Input() isInputDisabled: boolean;
    @Input() editorStatus: EditorStatus = { isClean: true };
    @Output() isClean: EventEmitter<boolean> = new EventEmitter<boolean>();

    isShownInSummaryTooltipText =
        `Value(s) entered for this field will be shown ` + `in the summary of this Presentation's main view.`;

    constructor() {}
    //TODO: rename to reflect that this is the preview component
    ngOnInit() {
        this.isClean.emit(true);

        if (
            this.optionLabels &&
            this.optionLabels.length > 0 &&
            (!this.options || this.options.length === 0 || this.options.find(option => !option.value))
        ) {
            this.options = this.optionLabels.map(option => ({
                value: option,
                label: option,
            }));
        }
    }

    public changeIsCleanEditorStatus(value: boolean) {
        this.isClean.emit(value);
        if (!this.editorStatus) {
            this.editorStatus = { isClean: value };
            return;
        }

        this.editorStatus.isClean = value;
    }

    handleClear() {
        this.changeIsCleanEditorStatus(false);

        switch (this.type) {
            case 'radio':
                this.data[this.dataFieldName] = '';
                break;
            case 'checkbox':
                this.data[this.dataFieldName] = [];
                break;
        }
    }

    transform(value: string): string {
        if (!value) {
            return '';
        }
        const tmp = value.split(' ').join('_');
        return tmp;
    }

    get isEmpty(): boolean {
        return !this.data[this.dataFieldName] || !this.data[this.dataFieldName].length;
    }

    get isClearable(): boolean {
        return this.isInputDisabled || this.isEmpty;
    }

    get isInvalid(): boolean {
        return this.isRequired && this.isEmpty;
    }
}
