import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppService, FG1NotificationService } from '@services';
import { catchError, first, map } from 'rxjs/operators';
import { Event } from '@models';
import { getEventCodeFromParams } from '@functions';

const PAGE_EVENT_DISABLE_NOTIFICATION = 'Event is currently inactive.';

@Injectable({
    providedIn: 'root',
})
export class IsEventNotActivatedGuard implements CanActivate {
    constructor(
        private appService: AppService,
        private fg1NotificationService: FG1NotificationService,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
        const eventCodeOrHash = getEventCodeFromParams(next) || next.url.slice(-1)[0].toString();
        return this.appService
            .getEventIfChanged(eventCodeOrHash)
            .pipe(first())
            .pipe(
                map((event: Event) => {
                    // check if event has been disabled by support
                    if (!event.isActivated) {
                        this.fg1NotificationService.warn(PAGE_EVENT_DISABLE_NOTIFICATION, 'Page Not Found');
                        this.router.navigate(['/'], { replaceUrl: true });
                        return false;
                    }
                    return true;
                }),
                catchError(err => {
                    const message =
                            err.statusCode === 500
                                ? 'An error occurred. Please contact administrator.'
                                : err.message;

                    this.fg1NotificationService.error(message);
                    this.router.navigate(['/'], { replaceUrl: true });
                    return of(false);
                })
            );
    }
}
