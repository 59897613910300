import { Pipe, PipeTransform } from '@angular/core';
import { Form } from '@models';

@Pipe({
    name: 'formTitle',
})
export class FormTitlePipe implements PipeTransform {
    transform(form: Form): string {
        let title = ' ';
        if (!form) return title;
        if (form.type === 'abstract_notes' || form.type === 'final_notes') {
            title = 'Final Notes';
        }
        if (form.type === 'review') {
            title = 'Review';
        }

        if (form.type === 'abstract_recruitment') {
            title = 'Recruitment';
        }
        return title;
    }
}
