<div class="row">
    <div class="col">
        <div *ngIf="type !== 'wysiwyg' && type !== 'file'">
            <mat-form-field class="form-field-wrapper" floatLabel="always">
                <div class="help-tooltip-icon-container">
                    <mat-label>{{ label }}<span *ngIf="isRequired" class="required-star">*</span></mat-label>
                    <mat-icon
                        *ngIf="isShownInSummary"
                        [matTooltip]="isShownInSummaryTooltipText"
                        matTooltipPosition="right"
                        >help
                    </mat-icon>
                </div>
                <div class="description">{{ description }}</div>
                <input
                    [(ngModel)]="data[dataFieldName]"
                    [id]="uniqueFieldKey"
                    (ngModelChange)="onChange()"
                    matInput
                    [type]="type"
                    placeholder="Type Here"
                    [alt]="label"
                    [disabled]="isInputDisabled"
                />
                <div class="clear-button-container">
                    <button
                        mat-button
                        class="clear-button"
                        [disabled]="isInputDisabled || !data[dataFieldName]"
                        mat-icon-button
                        aria-label="Clear"
                        (click)="handleClear()"
                    >
                        <span class="material-icons">cancel</span>
                    </button>
                </div>
            </mat-form-field>
        </div>
        <div *ngIf="type === 'file'">
            <div class="help-tooltip-icon-container">
                <mat-label>{{ label }}<span *ngIf="isRequired" class="required-star">*</span></mat-label>
                <mat-icon *ngIf="isShownInSummary" [matTooltip]="isShownInSummaryTooltipText" matTooltipPosition="right"
                    >help
                </mat-icon>
            </div>
            <div class="description">{{ description }}</div>
            <input
                #fileInput
                [(ngModel)]="data[dataFieldName]"
                [id]="uniqueFieldKey"
                style="display: none"
                (change)="onFileChange(dataFieldName, $event.target.files)"
                [type]="type"
                [accept]="fileExtensionsMap[field.extension]"
                placeholder="Select File"
                [alt]="label"
                [disabled]="isInputDisabled"
            />
            <div [class.pb-3]="formErrs[uniqueFieldKey]">
                <button mat-raised-button color="primary" class="file-select-button" (click)="selectFile()">
                    <mat-icon>attach_file</mat-icon>
                    <ng-container *ngIf="!selectedFile; else fileName"
                        >Select File {{ field.extension | fileExtension }}</ng-container
                    >
                    <ng-template #fileName>{{ selectedFile.name | limitTo: 60 }}</ng-template>
                </button>
            </div>
        </div>
        <div *ngIf="type === 'wysiwyg'" class="quill-container">
            <div class="help-tooltip-icon-container">
                <mat-label>{{ label }}<span *ngIf="isRequired" class="required-star">*</span></mat-label>
                <mat-icon *ngIf="isShownInSummary" [matTooltip]="isShownInSummaryTooltipText" matTooltipPosition="right"
                    >help
                </mat-icon>
            </div>
            <div class="description">{{ description }}</div>
            <quill-editor
                [sanitize]="true"
                [id]="uniqueFieldKey"
                [placeholder]="'Enter ' + label"
                [(ngModel)]="quillInitialValue"
                (onContentChanged)="handleEditorChanged($event.text, $event.html, $event.source)"
                [modules]="quillModulesConfig"
                [disabled]="isInputDisabled"
            ></quill-editor>
            <br />
        </div>
        <div [ngClass]="formErrs[uniqueFieldKey] ? 'show-err' : 'hide-err'">
            <small *ngIf="!formErrs[uniqueFieldKey]?.isValid" class="err-text">This field is required!</small>
            <small *ngIf="formErrs[uniqueFieldKey]?.isValid" class="err-text">{{
                formErrs[uniqueFieldKey].errMsg
            }}</small>
        </div>
    </div>
</div>
