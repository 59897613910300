<div class="presentations-table-container">
    <div [class]="loading ? 'pres-table-loading' : presentations.length === 0 ? 'pres-table-empty' : ''">
        <div
            *ngIf="presentations.length === 0"
            role='alert'
            aria-label='You currently have no submitted presentations associated with your account.'
             >You currently have no submitted presentations associated with your account.</div>
    </div>
    <div class="presentations-table">
        <!-- material data table for presentations -->
        <mat-table mat-elevation-z8 [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
            matSortDirection="asc" matSortDisableClear>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

            <mat-row [id]="'pres_row_' + row.presentationId" *matRowDef="let row; columns: displayedColumns"></mat-row>

            <ng-container matColumnDef="editDeleteControls">
                <mat-header-cell class="pres-edit-row" *matHeaderCellDef></mat-header-cell>
                <mat-cell class="pres-edit-row" *matCellDef="let presentation">
                    <span class="material-icons pres-ctrl-icon pres-edit-icon"
                        [id]="'pres_row_edit_' + presentation.presentationId"
                        (click)="onEditPresentationClick(presentation)"
                        [matTooltip]="getLockedTooltipText(presentation)">
                        {{ getLockedState(presentation) === 'none' ? 'edit' : 'visibility' }}
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="createDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted&nbsp;At ({{ timezone }})</mat-header-cell>
                <mat-cell *matCellDef="let presentation">{{ presentation.createDate | utcToLocalDate }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell style="min-width: 15vw" *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
                <mat-cell style="min-width: 15vw" *matCellDef="let presentation"><span
                        [innerHTML]="presentation.title | stripHtmlTags"></span></mat-cell>
            </ng-container>

            <ng-container matColumnDef="event">
                <mat-header-cell class="pres-col-title" *matHeaderCellDef mat-sort-header>Event</mat-header-cell>
                <mat-cell class="pres-col-title" *matCellDef="let presentation">
                    {{ presentation?.event?.name }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="areRequiredFieldsCompleted">
                <mat-header-cell class='required-fields' *matHeaderCellDef mat-sort-header>Required fields for the
                    current stage completed?
                    <mat-icon class="material-symbols-outlined ml-1" 
                        [matTooltip]="requiredFieldsCompletedText" aria-hidden="false"> help </mat-icon>
                </mat-header-cell>
                <mat-cell class='required-fields' *matCellDef="let presentation">
                    <mat-chip-list class="stage-status">
                        <ng-container *ngIf="
                            getLockedState(presentation) === 'rejected';
                            else completeOrIncomplete
                        ">
                            <mat-chip class="accepted">
                                Complete
                            </mat-chip>
                        </ng-container>
                        <ng-template #completeOrIncomplete>
                            <mat-chip
                                [ngClass]="{ 'accepted': presentation.completed, 'rejected': !presentation.completed }">
                                {{ presentation.completed ? 'Complete' : 'Incomplete' }}
                            </mat-chip>
                        </ng-template>
                    </mat-chip-list>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="stageStatus">
                <mat-header-cell class="pres-col-title" *matHeaderCellDef>Abstract Stage Status
                    <mat-icon class="material-symbols-outlined ml-1" 
                        [matTooltip]="abstractStageStatusText" aria-hidden="false"> help </mat-icon>
                </mat-header-cell>
                <mat-cell class="pres-col-title" *matCellDef="let presentation">
                    <mat-chip-list class="stage-status">
                        <ng-container *ngIf="presentation?.event?.eventConfig?.isAbstractEnabled; else noAbstractChip">
                            <ng-container *ngIf="presentation.presentationStatuses">
                                <mat-chip *ngIf="presentation | presentationStage as stage" class="{{ stage.status }}">
                                    <ng-container *ngIf="
                                        stage.status === 'accepted' &&
                                        stage.stage === presentation.event?.eventConfig?.lastAbstractStage;
                                        else generalStageText
                                    ">
                                        Accepted for Event
                                    </ng-container>
                                    <ng-template #generalStageText>
                                        Stage {{ stage.stage }}, {{ stage.status | titlecase }}
                                    </ng-template>
                                </mat-chip>
                            </ng-container>
                        </ng-container>
                        <ng-template #noAbstractChip>
                            <mat-chip>
                                N/A
                            </mat-chip>
                        </ng-template>
                    </mat-chip-list>
                </mat-cell>
            </ng-container>

        </mat-table>
    </div>
</div>
