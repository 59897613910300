import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { Donation, DonationConfig, Event, Fund } from '@models';
import { filter, first, tap, map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import slugify from 'slugify';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { AuthenticationService } from './authentication.service';

declare var Stripe: any;

interface CreateDonationPayload {
    amount: number;
    fundId: number;
    coverTransactionFee: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class DonationsService {
    private url: string = `${environment.SERVICE_BASE_URL}/donations`;

    card: any;
    stripe: any;

    // behavior subject to hold details for the donation form
    currentDonationSubject = new BehaviorSubject<Partial<Donation>>(null);

    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
        // if we have user data prefill form data with the first/last name and email of the user
        this.authenticationService
            .getCurrentUser()
            .pipe(
                filter(e => !!e),
                first()
            )
            .subscribe(({ firstName, lastName, email }) =>
                this.currentDonationSubject.next({
                    firstName,
                    lastName,
                    email,
                })
            );
    }

    get currentDonation() {
        return this.currentDonationSubject.asObservable();
    }

    public updateCurrentDonation(donation: Donation) {
        this.currentDonationSubject.next(donation);
    }

    public resetDonationForm() {
        this.currentDonationSubject.next(null);
    }

    public createDonationCard(fund: Fund) {
        const stripe = Stripe(environment.STRIPE_API_KEY, {
            stripeAccount: fund.stripeAccountId,
        });
        const elements = stripe.elements();
        const style = {
            base: {
                color: '#32325d',
            },
        };

        // payment card created through stripe elements and mounted to a pre-defined spot in the html
        const card = elements.create('card', { style, hidePostalCode: true });
        card.mount('#card-element');

        this.card = card;
        this.stripe = stripe;
        return {
            card,
            stripe,
        };
    }

    public confirmDonationPayment(
        clientSecret: string,
        billing_details: {} = {}
    ): Promise<{ paymentIntent: any; error: any }> {
        return this.stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: this.card,
                billing_details,
            },
        });
    }

    public createDonation(payload: CreateDonationPayload) {
        return this.http.post<{ clientSecret: string }>(this.url, payload);
    }

    public exportEventDonations(event: Event) {
        return this.http
            .get(`${this.url}/export?eventId=${event.eventId}`, { responseType: 'blob', observe: 'response' })
            .pipe(
                tap(blob => {
                    const filename = `${slugify(event.name, { lower: true })}-donations.csv`;
                    saveAs(blob.body, filename);
                })
            );
    }

    public uploadPhoto(eventId: number, file: Blob, fileName?: string): Observable<DonationConfig> {
        const formData: FormData = new FormData();
        formData.append('photo', file, fileName || `${uuid()}.png`);
        return this.http
            .post<DonationConfig>(`${environment.SERVICE_BASE_URL}/events/${eventId}/donationConfig/photo`, formData, {
                observe: 'response',
            })
            .pipe(map(res => res.body));
    }
}
