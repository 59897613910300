<div>
    <div mat-form-field class="form-field-wrapper media-options-wrapper" style="max-width: 500px; margin: 0">
        <div class="help-tooltip-icon-container">
            <mat-label>{{ label }}<span *ngIf="isRequired" class="required-star">*</span></mat-label>
        </div>
        <div class="description">{{ description }}</div>

        <mat-radio-group [(ngModel)]="data[dataFieldName]" (ngModelChange)="changeIsCleanEditorStatus(false)">
            <div class="flex-container row" style="margin-left: 0; margin-right: 0">
                <div *ngFor="let option of optionLabels" class="flex-item likert-wrapper">
                    <div class="likert-label">
                        {{ option.label }}
                    </div>
                    {{ option.value }}
                    <div>
                        <mat-radio-button
                            [disabled]='isInputDisabled'
                            style="position: relative; left: 4px"
                            type="radio"
                            [id]="uniqueFieldKey + '_' + option.value"
                            [value]="option.value + ''"
                            [checked]="+data[dataFieldName] === +option.value"
                        ></mat-radio-button>
                    </div>
                </div>
            </div>
        </mat-radio-group>
    </div>
</div>

<div [ngClass]="formErrs[uniqueFieldKey] ? 'show-err' : 'hide-err'" style="width: 500px; margin: 15px auto">
    <small class="err-text">This field is required!</small>
</div>
