import { Component, Input, OnInit } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner/progress-spinner';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
    @Input() diameter: number = 22;
    @Input() showOverlay: boolean = false;
    @Input() show: boolean = false;
    @Input() mode: ProgressSpinnerMode = 'indeterminate';

    constructor() {}

    ngOnInit(): void {
        // set the loading spinner to a bigger size if there is no background overlay,
        // smaller size spinner doesn't show up well against white backgrounds
        if (!this.showOverlay) {
            this.diameter = 40;
        }
    }
}
