import { Component, OnInit } from '@angular/core';
import { DonationConfigService, FG1NotificationService } from '@services';
import { DonationConfig } from '@models';
import { CompleteNgUnsubscribeComponent } from '@utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-introduction-message',
    templateUrl: './introduction-message.component.html',
    styleUrls: ['./introduction-message.component.scss'],
})
export class IntroductionMessageComponent extends CompleteNgUnsubscribeComponent implements OnInit {
    donationConfig: DonationConfig;
    maxIntroductionBodyLength = 500;
    maxIntroductionHeadingLength = 50;
    introductionBodyLength = 0;
    introductionHeadingLength = 0;
    form: FormGroup;
    isLoading: boolean;

    constructor(
        private donationConfigService: DonationConfigService,
        private fb: FormBuilder,
        private fg1Notification: FG1NotificationService
    ) {
        super();
    }

    createForm() {
        return this.fb.group({
            introductionHeading: ['', [Validators.required, Validators.maxLength(this.introductionHeadingLength)]],
            introductionBody: ['', [Validators.required, Validators.maxLength(this.introductionBodyLength)]],
        });
    }

    ngOnInit() {
        this.form = this.createForm();

        this.donationConfigService
            .donationConfig()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(({ donationConfig }) => {
                this.introductionHeadingLength = donationConfig.introductionHeading.length;
                this.introductionBodyLength = donationConfig.introductionBody.length;
                this.donationConfig = donationConfig;
                this.form.patchValue(donationConfig, { emitEvent: false });
            });

        this.form.controls.introductionHeading.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((value: string) => {
                this.introductionHeadingLength = value ? value.length : 0;
                this.donationConfigService.updateDonationConfig({
                    introductionHeading: value,
                });
            });

        this.form.controls.introductionBody.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe((value: string) => {
                this.donationConfigService.updateDonationConfig({
                    introductionBody: value,
                });
            });
    }

    async submit() {
        this.donationConfigService
            .saveDonationConfig({
                introductionHeading: this.donationConfig.introductionHeading,
                introductionBody: this.donationConfig.introductionBody,
            })
            .pipe(first())
            .subscribe(
                () => {
                    this.fg1Notification.success('Successfully updated');
                },
                () => this.fg1Notification.error('Something went wrong')
            );
    }
}
