import { Pipe, PipeTransform } from '@angular/core';
import { Event, PresentationEntity, PresentationStatus } from '@models';
import { last, sortBy } from 'lodash';

@Pipe({
    name: 'presentationStage',
})
export class PresentationStagePipe implements PipeTransform {
    transform(presentation: PresentationEntity, event?: Event): PresentationStatus {
        if (!presentation || !presentation.presentationStatuses) {
            return null;
        }
        const sortedStatuses = sortBy(presentation.presentationStatuses, stageStatus => stageStatus.stage);
        return last(sortedStatuses);
    }
}
