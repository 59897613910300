import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Capacitor } from '@capacitor/core';

import { AppService } from './services/app.service';
import { AuthenticationService } from './services/authentication.service';
import { environment } from '../environments/environment';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { CurrentUser } from './models/currentUser';
import LogRocket from 'logrocket';
import * as FullStory from '@fullstory/browser';

declare const ga: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public currentUser: CurrentUser;
    public homePage = false;
    public customHomePage = false;
    public discoverPage = false;
    public overflowScreen = false;
    public isEventPageAndMobile = false;
    private ngUnsubscribe$ = new Subject();
    public isLoading: boolean;

    constructor(
        private authenticationService: AuthenticationService,
        private appService: AppService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document
    ) {
        const head: Element = this.document.getElementsByTagName('head')[0];
        const body: Element = this.document.getElementsByTagName('body')[0];

        if (environment.ANGULAR_ENV !== 'local') {
            this.initGoogleAnalytics(head);
        }

        if (environment.production) {
            this.initHotjar(head);
        }

        this.initGoogleTagManager(head, body, environment.googleTagManagerExtraParams);
        this.initFullStory();
        this.initHeap(head, environment.production);
    }

    ngOnInit() {
        // Delay is needed: https://blog.angular-university.io/angular-debugging/
        this.appService.loading.pipe(delay(0)).subscribe(isLoading => (this.isLoading = isLoading));

        this.authenticationService
            .getCurrentUser()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(currentUser => {
                if (this.appService && currentUser && this.appService.getConsumerId() !== currentUser.userId) {
                    this.appService.setConsumerId(currentUser.userId);
                    // reload rollout feature flags
                    this.appService.fetchRolloutFeatureFlags();
                }
                this.currentUser = currentUser;
                if (currentUser) {
                    LogRocket.identify(`${currentUser.userId}`, {
                        name: currentUser.firstName + ' ' + currentUser.lastName,
                        email: currentUser.email,
                    });

                    FullStory.identify(`${currentUser.userId}`, {
                        displayName: `${currentUser.firstName} ${currentUser.lastName}`,
                        email: currentUser.email,
                    });
                }
            });

        // Google Analytics for all environments, except local
        if (environment.ANGULAR_ENV !== 'local') {
            this.router.events.subscribe(event => {
                // This is super hacky but is the only way we can record page title properly for presentations in Google Analytics
                if (event instanceof NavigationEnd && !event.urlAfterRedirects.includes('/project/')) {
                    ga('set', 'page', event.urlAfterRedirects);
                    ga('send', 'pageview');
                }
            });

            this.authenticationService.getCurrentUser().subscribe((consumer: any) => {
                if (!!consumer) {
                    ga('set', 'dimension2', String(consumer.userId));
                    ga('set', 'dimension3', String(consumer.institutionId));
                }
            });
        }
    }

    initGoogleAnalytics(head: Element) {
        const googleAnalyticsScript = this.document.createElement('script');
        googleAnalyticsScript.type = 'text/javascript';
        googleAnalyticsScript.innerHTML = `
      (function(i,s,o,g,r,a,m){i[\'GoogleAnalyticsObject\']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,\'script\',\'https://www.google-analytics.com/analytics.js\',\'ga\')
    `;
        head.appendChild(googleAnalyticsScript);

        ga('create', environment.googleAnalytics, 'auto');
    }

    initGoogleTagManager(head: Element, body: Element, tagManagerExtraParams: string) {
        const googleTagManagerScript = this.document.createElement('script');
        googleTagManagerScript.type = 'text/javascript';
        googleTagManagerScript.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl
            ${!!tagManagerExtraParams ? "+'" + tagManagerExtraParams + "'" : ''};
            f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5Z9G964');
        `;
        head.appendChild(googleTagManagerScript);

        const googleTagManagerBodyScript = this.document.createElement('noscript');
        const googleTagManagerIFrame = this.document.createElement('iframe');
        googleTagManagerIFrame.setAttribute(
            'src',
            `https://www.googletagmanager.com/ns.html?id=GTM-5Z9G964${tagManagerExtraParams || ''}`
        );
        googleTagManagerIFrame.setAttribute('height', '0');
        googleTagManagerIFrame.setAttribute('width', '0');
        googleTagManagerIFrame.setAttribute('style', 'display:none;visibility:hidden');
        googleTagManagerBodyScript.appendChild(googleTagManagerIFrame);
        console.log(googleTagManagerBodyScript);
        body.appendChild(googleTagManagerBodyScript);
    }

    initFullStory() {
        FullStory.init({
            orgId: 'CT2JF',
            devMode: !environment.production,
        });
    }

    initHotjar(head: Element) {
        const hotjarScript = this.document.createElement('script');
        hotjarScript.type = 'text/javascript';
        hotjarScript.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2156438,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
        head.appendChild(hotjarScript);
    }

    initHeap(head: Element, isProduction: boolean) {
        const heapScript = this.document.createElement('script');
        heapScript.type = 'text/javascript';
        if (isProduction) {
            heapScript.innerHTML = `
                window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                heap.load("4004595049");
            `;
        } else {
            heapScript.innerHTML = `
                window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                heap.load("501722985");
            `;
        }
        head.appendChild(heapScript);
    }

    onActivate(event: any): void {
        this.homePage = !!event.iAmTheHomePageHack;
        this.customHomePage = !!event.isACustomHome;
        this.discoverPage = !!event.iAmTheDiscoverPageHack;
        this.isEventPageAndMobile = Capacitor.isNativePlatform() && !!event.iAmWithinAnEvent;
        // this.overflowScreen = Capacitor.isNativePlatform() && !!event.isOverflowingPage;
        this.overflowScreen = Capacitor.isNativePlatform() && true;
    }

    get isFullPageCSS() {
        return this.homePage && !this.customHomePage;
    }

    get isWhiteBackground() {
        return this.homePage || this.discoverPage;
    }
}
