import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DeleteFormConditionDialogComponent } from 'src/app/dialogs/delete-form-condition-dialog/delete-form-condition-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
  ],
  exports: [DeleteFormConditionDialogComponent],
  declarations: [DeleteFormConditionDialogComponent],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }],
})
export class DeleteFormConditionDialogModule { }
