import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { QuillModule } from 'ngx-quill';

import { FormEditorLikertScaleFieldModule } from './fields/likert-scale-field/likert-scale-field.module';
import { GeneralizedFormEditorComponent } from './generalized-form-editor.component';
import { FormEditorInputFieldModule } from './fields/input-field/input-field.module';
import { FormEditorMultiSelectModule } from './fields/multi-select-field/multi-select-field.module';
import { FormEditorMediaFieldsModule } from './fields/media-fields/media-fields.module';
import { DeleteSubmissionExtraFieldDialogModule } from '../../../../dialogs/delete-submission-extra-field-dialog/delete-submission-extra-field-dialog.module';
import { ConditionalComponent } from './conditional/conditional.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DeleteFormConditionDialogModule } from 'src/app/dialogs/delete-form-condition-dialog/delete-form-condition-dialog.module';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { EditToggleComponent } from '../../edit-toggle/edit-toggle.component';
import { GeneralizedFormPreviewModule } from '../generalized-form-preview/generalized-form-preview.module';

@NgModule({
    imports: [
        CommonModule,
        FormEditorInputFieldModule,
        FormEditorMultiSelectModule,
        FormEditorMediaFieldsModule,
        FormEditorLikertScaleFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatExpansionModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatOptionModule,
        MatSelectModule,
        DragDropModule,
        QuillModule.forRoot(),
        DeleteSubmissionExtraFieldDialogModule,
        DeleteFormConditionDialogModule,
        GeneralizedFormPreviewModule,
    ],
    declarations: [GeneralizedFormEditorComponent, ConditionalComponent, EditToggleComponent],
    exports: [GeneralizedFormEditorComponent],
})
export class GeneralizedFormEditorModule {}
