import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { abstractCacheBuster$, formCacheBuster$, MAX_CACHE_COUNT, TWO_MINUTES_CACHE } from '@constants';
import {
    ConsumerPresentation,
    FormPresentationAssignment,
    GetAbstractPresentationsParams,
    GetPresentationsReviewsParams,
    PaginationParams,
    PresentationEntity,
    PresentationFormSubmission,
    StageStatus,
} from '@models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { toHttpParams } from '@functions';
import { JudgeItem } from '../event-admin-dash/judging/judges/dnd-ui.types';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import { first } from 'rxjs/operators';

interface Stages {
    selectedStage: number;
    currentStage: number;
    activeStage: number;
    childTab: number;
}

@Injectable({
    providedIn: 'root',
})
export class AbstractService {
    private url: string = `${environment.SERVICE_BASE_URL}/abstract`;

    private activeTabs$ = new BehaviorSubject<Partial<Stages>>(this.loadActiveTabs());
    private activeStage$ = new BehaviorSubject<number>(1);
    private reloadPresentationsAndReviewers$ = new Subject<void>();

    constructor(private http: HttpClient, private appService: AppService) {
        this.appService
            .getEvent(true)
            .pipe(first())
            .subscribe(event => {
                this.updateActiveTabs({
                    currentStage: event.eventConfig.currentAbstractStage,
                });
            });
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: abstractCacheBuster$,
    })
    getPresentations(
        params: Partial<GetAbstractPresentationsParams>
    ): Observable<{ presentations: PresentationEntity[]; total: number }> {
        return this.http.get<{ presentations: PresentationEntity[]; total: number }>(`${this.url}/presentations`, {
            params: toHttpParams(params),
        });
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: abstractCacheBuster$,
    })
    getReviewers(params: Partial<GetAbstractPresentationsParams>): Observable<{ judges: JudgeItem[]; total: number }> {
        return this.http.get<{ judges: JudgeItem[]; total: number }>(`${this.url}/reviewers`, {
            params: toHttpParams(params),
        });
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: abstractCacheBuster$,
    })
    getSubmissions(params: {
        eventId: number;
        stage?: number;
    }): Observable<{ submissions: PresentationFormSubmission[]; total: number }> {
        return this.http.get<{ submissions: PresentationFormSubmission[]; total: number }>(`${this.url}/submissions`, {
            params: toHttpParams(params),
        });
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: abstractCacheBuster$,
    })
    getPresentationsReviews(params: Partial<GetPresentationsReviewsParams>): Observable<any> {
        return this.http.get<any>(`${this.url}/tabulated-results`, {
            params: toHttpParams(params),
        });
    }

    @CacheBuster({
        cacheBusterNotifier: abstractCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: formCacheBuster$,
    })
    public updatePresentationStatus(status: StageStatus, presentationIds: number[], stage: number, eventId: number) {
        return this.http.post(`${this.url}/update-presentation-status`, { presentationIds, status, stage, eventId });
    }

    // @Cacheable({
    //     maxAge: TWO_MINUTES_CACHE,
    //     maxCacheCount: MAX_CACHE_COUNT,
    //     cacheBusterObserver: abstractCacheBuster$,
    // })
    getJudgeAssignedPresentations(eventId: number, pagination: Partial<PaginationParams> = {}) {
        return this.http.get<{ presentations: ConsumerPresentation[]; total: number }>(
            `${this.url}/assigned-presentations`,
            { params: toHttpParams({ eventId, ...pagination }) }
        );
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: abstractCacheBuster$,
    })
    getJudgeAssignedPresentationsForAdmin(eventId: number, stage: number, pagination: Partial<PaginationParams> = {}) {
        return this.http.get<{ presentations: ConsumerPresentation[]; total: number }>(
            `${this.url}/admin-assigned-presentations`,
            { params: toHttpParams({ eventId, stage, ...pagination }) }
        );
    }

    @CacheBuster({
        cacheBusterNotifier: abstractCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: formCacheBuster$,
    })
    public assignJudgeToPresentation(presentationIds: number[], consumerIds: number[], stage: number, eventId: number) {
        return this.http.post(`${this.url}/assign-judge`, { consumerIds, presentationIds, stage, eventId });
    }

    @CacheBuster({
        cacheBusterNotifier: abstractCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: formCacheBuster$,
    })
    public unassignJudgeToPresentation(
        presentationIds: number[],
        consumerIds: number[],
        stage: number,
        eventId: number
    ) {
        return this.http.post(`${this.url}/unassign-judge`, { consumerIds, presentationIds, stage, eventId });
    }

    @CacheBuster({
        cacheBusterNotifier: abstractCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: formCacheBuster$,
    })
    public assignFormToPresentations(payload: FormPresentationAssignment) {
        return this.http.post(`${this.url}/assign-forms`, payload);
    }

    public reloadPresentationsAndReviewers() {
        // Force cache bust so that next tab that is active will reload presentations properly
        abstractCacheBuster$.next();
        this.reloadPresentationsAndReviewers$.next();
    }

    public getPresentationsAndReviewersReloader(): Observable<void> {
        return this.reloadPresentationsAndReviewers$.asObservable();
    }

    public updateActiveTabs(tabs: Partial<Stages> = {}) {
        const currentTabs = {
            ...this.activeTabs$.value,
            ...tabs,
            childTab: 0,
        };
        this.persistActiveTabs(currentTabs);
        return this.activeTabs$.next(currentTabs);
    }

    public getActiveTabs(): Observable<Partial<Stages>> {
        return this.activeTabs$.asObservable();
    }

    public getActiveStage(): Observable<number> {
        return this.activeStage$.asObservable();
    }

    public getEvaluationFormForStage(presentation: Partial<PresentationEntity>, stage: number) {
        if (!presentation || !stage) {
            return null;
        }
        const { evaluationForms } = presentation;

        if (!evaluationForms || evaluationForms.length === 0) {
            return null;
        }

        // evaluation form for the given stage
        return evaluationForms.find(ef => ef?.form?.stage === stage)?.form;
    }

    private persistActiveTabs(tabs) {
        try {
            localStorage.setItem('ACTIVE_TABS', JSON.stringify(tabs));
        } catch {}
    }

    private loadActiveTabs() {
        const defaultConfig = { selectedStage: null, childTab: 0, activeStage: null };
        try {
            const activeTabs = localStorage.getItem('ACTIVE_TABS');
            const tabsToReturn = activeTabs ? JSON.parse(activeTabs) : defaultConfig;
            tabsToReturn['childTab'] = 0;
            return tabsToReturn;
        } catch {
            return defaultConfig;
        }
    }
}
