import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cancel',
    templateUrl: './cancel-dialog.component.html',
    styleUrls: ['./cancel-dialog.component.scss'],
})
export class CancelDialogComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
