import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxTrumbowygModule } from 'ngx-trumbowyg';
import { FormPreviewPresenterFieldsComponent } from './presenter-fields.component';
import { FormPreviewInputFieldModule } from '../input-field/input-field.module';
import { FormPreviewMultiSelectFieldModule } from '../multi-select-field/multi-select-field.module';
import { FormPreviewLikertScaleFieldModule } from '../likert-scale-field/likert-scale-field.module';

@NgModule({
    imports: [
        CommonModule,
        FormPreviewInputFieldModule,
        FormPreviewMultiSelectFieldModule,
        FormPreviewLikertScaleFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatExpansionModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        NgxTrumbowygModule,
    ],
    declarations: [FormPreviewPresenterFieldsComponent],
    exports: [FormPreviewPresenterFieldsComponent],
})
export class FormPreviewPresenterFieldsModule {}
