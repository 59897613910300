import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class PreviousRouteService {
    private previousURL: string = undefined;
    private currentURL: string = undefined;

    constructor(private router: Router) {
        this.currentURL = this.router.url;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (!['/login', '/signup'].includes(event.url)) {
                    if (event.url.startsWith('/authentication-handler')) {
                        if (!this.currentURL)
                            this.setPreviousURL('/account/profile');
                    } else {
                        this.updateCurrentURL(event.url);
                    }
                }

            }
        });
    }

    public getPreviousURL() {
        return this.previousURL;
    }

    public setPreviousURL(url: string) {
        this.previousURL = url;
    }

    public updateCurrentURL(currentURL: string) {
        this.previousURL = this.currentURL;
        this.currentURL = currentURL;
    }
}
