import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { NotificationsService } from 'angular2-notifications';
import { getErrorMessageFromResponse } from '@functions';
import { HttpError } from '@models';

@Injectable({
    providedIn: 'root',
})
export class FG1NotificationService {
    private defaultOptions = {
        timeOut: 5000,
        showProgressBar: false,
        pauseOnHover: true,
        clickToClose: true,
        theClass: 'fg1-notification',
    };

    constructor(private notifications: NotificationsService) {
        if (Capacitor.isNativePlatform()) {
            this.defaultOptions.theClass += ' fg1-notification--mobile';
        }
    }

    public success(message: string, title = 'Success', options = this.defaultOptions) {
        this.notifications.success(title, message, options);
    }

    public warn(message: string, title = 'Warning', options = this.defaultOptions) {
        this.notifications.warn(title, message, options);
    }

    public error(message: string, title = 'Error', options = this.defaultOptions) {
        this.notifications.error(title, message, options);
    }

    public info(message: string, title = 'Information', options = this.defaultOptions) {
        this.notifications.info(title, message, options);
    }

    public serverError(error: HttpError) {
        this.error(getErrorMessageFromResponse(error));
    }
}
