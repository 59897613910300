import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RolloutFeatureFlag } from '../models/rolloutFeatureFlag';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

@Injectable({
    providedIn: 'root',
})
export class RolloutFeatureFlagService {
    baseUrl = `${environment.SERVICE_BASE_URL}/feature-flags`;

    constructor(private http: HttpClient) {}

    public getRolloutFeatureFlags(visitorId: string, consumerId: number): Observable<RolloutFeatureFlag[]> {
        return this.http.get<RolloutFeatureFlag[]>(`${this.baseUrl}/rollout/${visitorId}/${consumerId}`);
    }

    public addRolloutShowRecord(visitorId: string, name: string, consumerId: number) {
        return this.http.post(`${this.baseUrl}/rollout`, {
            visitorId,
            name,
            consumerId,
        });
    }
}
