import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripHtmlTags',
    pure: true,
})
export class StripHtmlTagsPipe implements PipeTransform {
    transform(value: string, except?: string[]): string {
        if (!value) return '';

        if (typeof value !== 'string') return value;

        if (except && Array.isArray(except)) {
            const regex = new RegExp(`(<\/?(?:${except.join('|')})[^>]*>)|<[^>]+>`, 'ig');

            return value.replace(regex, '$1');
        }

        return value.replace(/(<([^>]+)>)/gi, '');
    }
}
