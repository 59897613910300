import { Capacitor } from '@capacitor/core';
import { Routes } from '@angular/router';

export const setupRoutes = (routes: Routes) => {
    if (Capacitor.isNativePlatform()) {
        const homeRouteIndex = routes.findIndex(route => route.path === '');

        if (homeRouteIndex >= 0) {
            routes[homeRouteIndex] = {
                path: '',
                pathMatch: 'full',
                redirectTo: '/discover'
            }
        }
    }

    return routes;
};
