import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as countryList from 'country-list';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isEmailValid } from '@functions';

enum SupportedFields {
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    address = 'address',
    zip = 'zip',
    city = 'city',
    state = 'state',
    country = 'country',
}

type Fields = Record<SupportedFields, string>;

@Component({
    selector: 'app-personal-information',
    templateUrl: './personal-information.component.html',
    styleUrls: ['./personal-information.component.scss'],
})
export class PersonalInformationComponent implements OnInit {
    @Input() form: FormGroup;
    filteredCountries$: Observable<string[]>;
    supportedFields: Fields;

    countries: string[];

    constructor() {
        this.countries = countryList.getNames();
        this.supportedFields = SupportedFields;
    }

    ngOnInit() {
        this.filteredCountries$ = this.form.controls.country.valueChanges.pipe(
            startWith(''),
            map(country => (country ? this._filter(country) : this.countries.slice()))
        );
    }

    get isCountryValid(): boolean {
        if (!this.form.controls.country.touched) {
            return true;
        }

        const selectedCountry = this.form.controls.country.value.toLowerCase();
        const _isCountryValid = !!this.countries.find(country => {
            return selectedCountry === country.toLowerCase();
        });

        if (!_isCountryValid) {
            this.form.controls.country.setErrors({ incorrect: true });
        }

        return selectedCountry && _isCountryValid && this.isFieldValid(this.supportedFields.country);
    }

    isFieldValid(key: string): boolean {
        if (!this.form.controls[key].touched) {
            return true;
        }

        return this.form.controls[key].valid;
    }

    public hasRequiredError(key: string) {
        const item = this.form.get(key);
        return item && item.errors && item.errors.required && item.touched;
    }

    get isEmailValid(): boolean {
        return !this.form.controls[this.supportedFields.email].touched || isEmailValid(this.form.controls.email.value);
    }

    private _filter(country: string): string[] {
        const filterValue = country.toLowerCase();

        return this.countries.filter(_country => _country.toLowerCase().indexOf(filterValue) === 0);
    }
}
