/**
 * This function is same as PHP's nl2br() with default parameters.
 *
 * @param str Input text
 * @param replaceMode Use replace instead of insert
 * @param isXhtml Use XHTML
 * @return Filtered text
 */
export function nl2br(str: string, replaceMode = true, isXhtml = true) {
    const breakTag = isXhtml ? '<br />' : '<br>';
    const replaceStr = replaceMode ? '$1' + breakTag : '$1' + breakTag + '$2';
    return (str + '').replace(/([^\n\r>]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}
