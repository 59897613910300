import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseMediaConfig, Event, FileUploadEvent, UploadFileExtensions } from '@models';
import { getBucketByEnv } from '@functions';
import { GlobalFeatureFlagService } from '@services';
import { CompleteNgUnsubscribeComponent } from '@utils';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-submission-file-upload-preview',
    templateUrl: './submission-file-upload-preview.component.html',
    styleUrls: ['./submission-file-upload-preview.component.scss'],
})
export class SubmissionFileUploadPreviewComponent extends CompleteNgUnsubscribeComponent implements OnInit {
    @Input() existingFilename: string;
    @Input() key: string;
    @Input() field: BaseMediaConfig;
    @Input() event: Event;
    @Input() description: string;
    @Input() required: boolean;
    @Input() label: string;
    @Input() disabled: boolean;
    @Output() onFileSelected: EventEmitter<FileUploadEvent> = new EventEmitter();
    @Output() onUploadFinished: EventEmitter<FileUploadEvent> = new EventEmitter();
    @Output() onUploadInProgress: EventEmitter<boolean> = new EventEmitter();

    extensions = UploadFileExtensions;
    showVideoUpload: boolean;

    constructor(private featureFlagService: GlobalFeatureFlagService) {
        super();
    }

    ngOnInit() {
        this.featureFlagService
            .getFeatureFlagStatus()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(flags => (this.showVideoUpload = flags['video-upload']));
    }

    get assetsBucket() {
        return getBucketByEnv('fg1-event-assets');
    }

    public fileSelected(event: FileUploadEvent) {
        this.onFileSelected.emit(event);
        this.onUploadInProgress.emit(true);
    }

    public uploadFinished($event: FileUploadEvent) {
        this.onUploadFinished.emit($event);
        this.onUploadInProgress.emit(false);
    }

    public uploadInProgress($event: boolean) {
        this.onUploadInProgress.emit($event);
    }
}
