import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteSubmissionExtraFieldDialogComponent } from '@dialogs';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

@NgModule({
    imports: [CommonModule, MatDialogModule],
    exports: [DeleteSubmissionExtraFieldDialogComponent],
    declarations: [DeleteSubmissionExtraFieldDialogComponent],
    providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }],
})
export class DeleteSubmissionExtraFieldDialogModule {}
