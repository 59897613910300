import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { calculateTotalFundDonations, toHttpParams } from '@functions';
import { AccountLink, Fund } from '@models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FundsService {
    private url: string = `${environment.SERVICE_BASE_URL}/donations/funds`;

    constructor(private http: HttpClient) {}

    public getFunds(eventId: number): Observable<Fund[]> {
        return this.http
            .get<Fund[]>(this.url, { params: toHttpParams({ eventId }) })
            .pipe(
                map(funds =>
                    funds.map(fund => ({
                        ...fund,
                        totalDonations: calculateTotalFundDonations(fund),
                    }))
                )
            );
    }

    public createFund(fund: Partial<Fund>): Observable<Fund> {
        return this.http.post<Fund>(this.url, fund);
    }

    public updateFund(fundId: number, fund: Partial<Fund>) {
        return this.http.put<Fund>(`${this.url}/${fundId}`, fund);
    }

    public connectFund(fund: Fund) {
        return this.http.post<AccountLink>(`${this.url}/${fund.fundId}/connect`, {
            eventId: fund.eventId,
        });
    }

    public removeFund(fund: Fund) {
        return this.http.delete(`${this.url}/${fund.fundId}`, {
            params: toHttpParams({ eventId: fund.eventId }),
        });
    }

    /**
     * request an oath connection for the stripe account
     * @param fund
     */
    public requestConnection(fund: Fund) {
        const params = {
            response_type: 'code',
            scope: 'read_write',
            state: fund.fundId,
            client_id: environment.stripeConnectClientID,
        };
        const url = `https://connect.stripe.com/oauth/authorize?response_type=${params.response_type}&client_id=${params.client_id}&scope=${params.scope}&state=${params.state}`;
        return window.open(url, '_blank');
    }
}
