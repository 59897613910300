import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FG1NotificationService } from '@services';
import { GlobalFeatureFlagService } from '../services/global-feature-flag.service';
import { isNil } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class GlobalFeatureFlagGuard implements CanActivate {
    constructor(
        private featureflagService: GlobalFeatureFlagService,
        private fg1NotificationService: FG1NotificationService
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.featureflagService.getFeatureFlagStatus().pipe(
            map(featureFlagStatus => {
                const isEnabled = !!featureFlagStatus[route.data.featureFlag];

                const shouldActivateOnEnabled = isNil(route.data.shouldActivateOnFeatureFlag)
                    ? true
                    : Boolean(route.data.shouldActivateOnFeatureFlag);

                if ((isEnabled && shouldActivateOnEnabled) || (!isEnabled && !shouldActivateOnEnabled)) {
                    return true;
                } else {
                    this.fg1NotificationService.warn("You're not authorized to access this page.", 'Unauthorized');
                    return false;
                }
            })
        );
    }
}
