import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import {
    Subscription,
    SubscriptionAdmin,
    SubscriptionEmail,
    SubscriptionQuota,
    SubscriptionWithTier,
} from 'src/app/models';
import { FG1NotificationService } from './fg1-notification-service';
import { toHttpParams } from '@functions';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { DEFAULT_CACHE_OPTIONS } from '@constants';

const subscriptionCacheBuster$ = new Subject();

@Injectable({
    providedIn: 'root',
})
export class SubscriptionService {
    baseUrl = `${environment.SERVICE_BASE_URL}/subscriptions`;

    private consumerSubscription$: ReplaySubject<SubscriptionWithTier[]>;
    private consumerSubscription: Observable<SubscriptionWithTier[]>;
    private subscriptionEmails$: ReplaySubject<SubscriptionEmail[]>;

    constructor(private http: HttpClient, private fg1NotificationService: FG1NotificationService) {
        this.subscriptionEmails$ = new ReplaySubject<SubscriptionEmail[]>(1);
        this.consumerSubscription$ = new ReplaySubject<SubscriptionWithTier[]>(1);
        this.consumerSubscription = this.consumerSubscription$.asObservable();
    }

    // returns the subscription that the current user is attached to
    public getConsumerSubscriptions(): ReplaySubject<SubscriptionWithTier[]> {
        return this.consumerSubscription$;
    }

    public getEmailsSubscription(): ReplaySubject<SubscriptionEmail[]> {
        return this.subscriptionEmails$;
    }

    private clearConsumerSubscriptions() {
        this.consumerSubscription$.next(null);
    }

    @Cacheable({
        ...DEFAULT_CACHE_OPTIONS,
        cacheBusterObserver: subscriptionCacheBuster$,
    })
    public getSubscription(subscriptionId: number, relations: string[] = []) {
        return this.http.get<Subscription>(`${this.baseUrl}/${subscriptionId}`, {
            params: toHttpParams({ relations }),
        });
    }

    public getSubscriptions() {
        return this.http.get<SubscriptionWithTier[]>(this.baseUrl).subscribe(
            response => {
                this.consumerSubscription$.next(response);
            },
            _ => {
                this.clearConsumerSubscriptions();
            }
        );
    }

    public getSubscriptionAdmin() {
        return this.http.get<SubscriptionAdmin>(`${this.baseUrl}/enterprise-admin`);
    }

    public quota() {
        return this.http.get<SubscriptionQuota>(`${this.baseUrl}/quota`);
    }

    public getSubscriptionEmails() {
        return this.http.get<SubscriptionEmail[]>(`${this.baseUrl}/emails`).subscribe(
            response => {
                this.subscriptionEmails$.next(response);
            },
            _ => {
                this.subscriptionEmails$.next([]);
            }
        );
    }

    public removeSubscriptionAdmin(id: number) {
        return this.http.delete(`${this.baseUrl}/enterprise-admin/${id}`).subscribe(
            _ => {
                this.getSubscriptionEmails();
                subscriptionCacheBuster$.next(true);
                this.fg1NotificationService.success('Successfully removed from the list');
            },
            ({ error }) => {
                this.fg1NotificationService.error(error.message);
            }
        );
    }

    @CacheBuster({
        cacheBusterNotifier: subscriptionCacheBuster$,
    })
    public addSubscriptionAdmin(email: string) {
        return this.http.post<SubscriptionEmail>(`${this.baseUrl}/enterprise-admin`, { email });
    }
}
