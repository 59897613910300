import { Tag } from './tag';
import { TimeStamp } from './timestamp';
import { EvaluationForm, Form } from './form';
import { FormSubmission } from './submission';
import { PresentationType } from './presentation-form-data';
import { Event as EventEntity } from './event';

export type VideoType = 'youtube' | 'upload' | null;

export interface Presenter {
    firstName: string;
    lastName: string;
    email: string;
    major: string;
    level: string;
    isMainPresenter: boolean;
}

export interface SubmissionFormExtraValues {
    presenters: any[];
    presentation: Presentation;
}

export interface PresentationForm extends TimeStamp {
    id: number;
    formId: number;
    presentationId: number;
}

interface PresentersFields {
    presenterFirstName: string;
    presenterLastName: string;
    presenterEmail: string;
    presenterYear: string;
    presenterMajor: string;
    presenter2FirstName: string;
    presenter2LastName: string;
    presenter2Email: string;
    presenter2Year: string;
    presenter2Major: string;
    presenter3FirstName: string;
    presenter3LastName: string;
    presenter3Email: string;
    presenter3Year: string;
    presenter3Major: string;
    presenter4FirstName: string;
    presenter4LastName: string;
    presenter4Email: string;
    presenter4Year: string;
    presenter4Major: string;
    presenter5FirstName: string;
    presenter5LastName: string;
    presenter5Email: string;
    presenter5Year: string;
    presenter5Major: string;
    presenter6FirstName: string;
    presenter6LastName: string;
    presenter6Email: string;
    presenter6Year: string;
    presenter6Major: string;
    presenter7FirstName: string;
    presenter7LastName: string;
    presenter7Email: string;
    presenter7Year: string;
    presenter7Major: string;
    presenter8FirstName: string;
    presenter8LastName: string;
    presenter8Email: string;
    presenter8Year: string;
    presenter8Major: string;
}

export interface PresentationEntity extends Presentation, PresentersFields {
    event?: EventEntity;
    originalPosterLink?: any;
    voiceoverType: string;
    insertRun?: any;
    debugPresentationType: string;
    deleteDate?: any;
    metadataInstitutionName?: any;
    internalSymposiumTitle?: any;
    customPresentationType?: any;
    posterVideoId?: any;
    thumbnailPic?: any;
    posterPdfId?: any;
    presentationDate?: any;
    isCommentsPrivate: number;
    isJudgeable: number;
    supervisorConsentText?: any;
    consumerId: number;
    formSubmissions?: Form[];
    presentationStatuses: PresentationStatus[];
    evaluationForms: EvaluationForm[];
    evaluationForm: Form;
    reviews?: AbstractReviews;
    completed: boolean; // is the presentation completed according to the required fields of the current stage
    rejected: boolean; // is the presentation rejected in a previous stage
}

interface AbstractReviews {
    fields: { label: string; hash: string }[];
    responses: any[];
}

export type StageStatus = 'none' | 'accepted' | 'rejected' | null;

export interface PresentationStatus extends TimeStamp {
    id: number;
    stage: number;
    presentationId: number;
    status: StageStatus;
    presentation?: PresentationEntity;
}

export interface Presentation {
    presentationId: number;
    institutionId: number;
    eventId: number;
    institutionSetUniquePresentationId?: string;
    hash: string;
    presenters: Array<any>;
    title: string;
    mentor: string;
    abstract: string;
    subjects: Array<string>;
    voiceoverId: string;
    originalVoiceoverLink: string;
    presentationVideoId: string;
    stage: number;
    primaryPresenterBiography: string;
    originalPresentationVideoLink: string;
    posterId: string;
    slidesId: string;
    presentationType: PresentationType;
    posterType: string;
    posterFileURL?: string;
    posterThumbnailImageURL?: string;
    slidesFileURL?: string;
    tags: Tag[];
    extraValues: any;
    submissionFormExtraValues?: SubmissionFormExtraValues;
    createDate: Date | string;
    lastUpdated: string;
    customFields: any;
    videoSDKMeetingId: string;
    videoType: VideoType;
}

// used to de-couple admin presentation editor model from active model reflected in presentation table of same view
export const clonePresentation = (pres: Presentation): Presentation => {
    if (!pres) {
        return pres;
    }

    const cloned: Presentation = { ...pres };

    // clone deep fields/objects
    cloned.presenters = pres.presenters.map(presenter => ({ ...presenter }));
    cloned.subjects = !pres.subjects ? pres.subjects : pres.subjects.map(subject => subject);
    cloned.extraValues =
        !pres.extraValues || !pres.extraValues.presenters || !pres.extraValues.presentation
            ? pres.extraValues
            : {
                  presenters: pres.extraValues.presenters.map(presenter => {
                      const presenterFields = {};
                      Object.keys(presenter).forEach(key => {
                          // check for array
                          if (presenter[key].length && typeof presenter[key] !== 'string') {
                              presenterFields[key] = presenter[key].map(val => val);
                          } else {
                              presenterFields[key] = presenter[key];
                          }
                      });
                      return presenterFields;
                  }),
                  presentation: {},
              };
    if (!pres.extraValues || !pres.extraValues.presentation) {
        return cloned;
    }
    Object.keys(pres.extraValues.presentation).forEach(key => {
        // check for array
        if (pres.extraValues.presentation[key].length && typeof pres.extraValues.presentation[key] !== 'string') {
            cloned.extraValues.presentation[key] = pres.extraValues.presentation[key].map(val => val);
        } else {
            cloned.extraValues.presentation[key] = pres.extraValues.presentation[key];
        }
    });

    return cloned;
};

export interface PresentationPaginationFilters {
    filters: Tag[];
    itemsPerPage: number;
}

export interface PaginatedPresentations {
    presentations: Presentation[];
}

export interface PaginationParams {
    limit: number;
    page: number;
}

export const DEFAULT_PAGINATION_PARAMS: PaginationParams = {
    limit: 10,
    page: 1,
};

export interface GetMyPresentationsParams extends PaginationParams {
    eventId: number;
    relations?: string[];
}

export interface GetAbstractPresentationsParams extends PaginationParams {
    eventId: number;
    stage: number;
    relations: string[];
}

export interface GetPresentationsReviewsParams extends PaginationParams {
    eventId: number;
    stage: number;
}

export interface GetPresentationsParams extends PaginationParams {
    eventCodeOrHash: string;
    eventId?: number;
    q?: string;
    subjects?: number[];
    tagIds?: number[];
    tags?: number[];
    presentationSortHash?: number;
    ignoreAbstractFilters: boolean; // ignore abstract management filtering presentations
    sort?: string; // field:asc , field:desc
}

export interface FormPresentationAssignment {
    presentationsIds: number[];
    formId: number;
    eventId: number;
    stage: number;
}

export interface PresentationFormSubmission extends TimeStamp {
    id: number;
    submissionId: number;
    presentationId: number;
    presentation: PresentationEntity;
    submission: FormSubmission;
}
