<div class="content-container" *ngIf="donationConfig">
    <div class="col-12">
        <form [formGroup]="form" class="input-button-group-sm" autocomplete="off">
            <div class="form-group input-group-md">
                <label for="heading">Heading</label>

                <input
                    formControlName="introductionHeading"
                    [value]="donationConfig.introductionHeading"
                    [maxLength]="maxIntroductionHeadingLength"
                    type="text"
                    class="form-control mt-2"
                    id="heading"
                    aria-describedby="eventNameHelp"
                    style="width: 100%"
                />
                <small class="form-text text-muted chars-info">
                    <span class="typed-chars">{{ introductionHeadingLength }}</span
                    >/{{ maxIntroductionHeadingLength }} characters
                </small>
            </div>

            <div class="form-group input-group-md">
                <label for="body">Body</label>

                <textarea
                    formControlName="introductionBody"
                    [value]="donationConfig.introductionBody"
                    [maxLength]="maxIntroductionBodyLength"
                    class="form-control"
                    id="body"
                    rows="5"
                ></textarea>
                <small class="form-text text-muted chars-info">
                    <span class="typed-chars">{{ introductionBodyLength }}</span
                    >/{{ maxIntroductionBodyLength }} characters
                </small>
            </div>
        </form>

        <div class="save-button">
            <button class="btn btn-primary" (click)="submit()">Save changes</button>
        </div>
    </div>
</div>
