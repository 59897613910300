import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Event, PageMetadata, Presentation, PresentationEntity } from '@models';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { environment } from '@env';
import { stripHtmlTags } from '@functions';
import { DEFAULT_SEO_METADATA, getMetadataByPage, SeoPage } from '@constants';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    constructor(private meta: Meta, private title: Title, private router: Router) {}

    public addPresentationTags(presentation: PresentationEntity | Presentation) {
        this.setTitle(presentation.title);
        //@ts-ignore
        const author = `${presentation.presenterFirstName} ${presentation.presenterLastName}`;
        this.updateMetadata({
            author,
            description: presentation.abstract,
            keywords: presentation.subjects,
            title: presentation.title,
            date: presentation.createDate,
            image: presentation.posterThumbnailImageURL,
        });
    }

    public addEventTags(event: Event, override: { title?: string; description?: string } = null) {
        try {
            const description =
                override && override.description
                    ? stripHtmlTags(override.description)
                    : stripHtmlTags(event.splashContent);
            const eventAssetsRemotePath = environment.EVENT_ASSETS_REMOTE_PATH;

            const image = eventAssetsRemotePath + event.splashCoverImgName;

            const title = override && override.title ? override.title : event.name;
            this.setTitle(title);

            this.updateMetadata({
                author: event.organizedBy,
                date: event.startDate,
                description,
                title,
                image,
            });
        } catch (error) {
            console.log('[error adding seo tags]', error);
        }
    }

    public setTitle(input: string = '') {
        const title = this.createPageTitle(input);
        return this.title.setTitle(title);
    }

    public addTags(metadata: Partial<PageMetadata>) {
        this.setTitle(metadata.title);
        return this.updateMetadata(metadata);
    }

    public addTagsToPage(page: SeoPage) {
        const medatdata = getMetadataByPage(page);
        this.addTags(medatdata);
    }

    private updateMetadata(metadata: Partial<PageMetadata>): void {
        try {
            const pageMetadata: PageMetadata = { ...DEFAULT_SEO_METADATA, ...metadata };
            const metatags: MetaDefinition[] = this.generateMetadata(pageMetadata);
            this.setTitle(metadata.title);
            this.meta.addTags(metatags);
        } catch (error) {
            console.log('[error adding seo tags]', error);
        }
    }

    private createPageTitle(title: string = '') {
        return `${stripHtmlTags(title)} ${title.length > 1 ? '-' : ''} Symposium`.trim();
    }

    private generateMetadata(metadata: PageMetadata): MetaDefinition[] {
        const keywords = metadata.keywords ? metadata.keywords.join(', ') : '';
        const date = metadata.date ? moment(metadata.date).format('YYYY-MM-DD') : null;
        const title = this.createPageTitle(metadata.title);
        const description = metadata.description ? stripHtmlTags(metadata.description) : null;
        return [
            { property: 'og:title', content: title },
            { property: 'og:type', content: metadata.type },
            { property: 'og:description', content: description },
            { property: 'og:author', content: metadata.author },
            { name: 'title', content: title },
            { name: 'description', content: description },
            { name: 'author', content: metadata.author },
            { name: 'keywords', keywords },
            { property: 'og:image', content: metadata.image },
            {
                property: 'og:url',
                content: `${environment.APP_BASE_URL_PROTOCOL}${environment.APP_BASE_URL}/${this.router.url}`,
            },
            { name: 'robots', content: 'index, follow' },
            { name: 'date', content: date, scheme: 'YYYY-MM-DD' },
            { name: 'twitter:title', content: title },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:site', content: '@symposium' },
            { name: 'twitter:image', content: metadata.twitterImage || metadata.image },
        ];
    }
}
