import { forIn, get, set, sortBy } from 'lodash';
import {
    Event,
    Presentation,
    PresentationData,
    PresentationEntity,
    PresentationFormConfig,
    PresentationFormData,
    Tag,
} from '@models';
import { validatePresentationSubmissionForm } from './form.fn';

export const transformTagsToFilters = (tags: Tag[] = [], activeFilters: number[] = []) => {
    const filters = {};
    for (let tag of tags) {
        tag = {
            ...tag,
            isActive: activeFilters.includes(tag.tagId),
        };
        let currTags = get(filters, [tag.type], []);
        currTags.push(tag);
        set(filters, [tag.type], currTags);
    }

    const sortedFilters: any = {};
    forIn(filters, (value, key) => {
        sortedFilters[key] = sortBy(value, 'name');
    });
    return sortedFilters;
};

export const parseArrayFromQueryParams = (param: string) =>
    param
        ? param
              .split(',')
              .map(Number)
              .filter(id => id && typeof id === 'number')
        : [];

export const isArrayQueryParam = (param: string) => parseArrayFromQueryParams(param).length > 0;

export const isSymposiumVideoPreview = (
    event: { presentationFormConfig: PresentationFormConfig },
    presentationType: string
) => {
    if (!event || !presentationType) return false;
    const { exhibition, poster, oral } = event.presentationFormConfig.mediaFields;
    if (presentationType === 'oral') {
        return oral.videoType === 'upload';
    } else if (presentationType === 'exhibition') {
        return exhibition.videoType === 'upload';
    } else {
        return poster.videoType === 'upload';
    }
};

export const safelyParseJSON = (input: string | Object) => {
    try {
        if (typeof input === 'object') return input;
        if (typeof input === 'string') {
            return JSON.stringify(input);
        }
        throw new Error(`Unsupported type ${typeof input}`);
    } catch (e) {
        return null;
    }
};
export const presentationToFormValues = (presentation: PresentationEntity | Presentation) => {
    const formValues = {
        presenterData: [],
        presentationData: {
            presentationId: presentation.presentationId,
            title: presentation.title,
            abstract: presentation.abstract,
            mentor: presentation.mentor,
            subjects: presentation.subjects || [],
            extraValues: presentation.submissionFormExtraValues?.presentation
                ? presentation.submissionFormExtraValues?.presentation
                : {},
        } as PresentationData,
        presentationType:
            presentation.presentationType ||
            (presentation as PresentationEntity).debugPresentationType ||
            (presentation as PresentationEntity).customPresentationType,
        presentationMediaData: {
            voiceoverVideoLink: presentation.originalVoiceoverLink,
            presentationVideoLink: presentation.originalPresentationVideoLink,
            currentPosterURL: presentation.posterFileURL,
        },
    } as PresentationFormData;

    for (const [index, presenter] of presentation.presenters.entries() || []) {
        const extraValues =
            presentation.submissionFormExtraValues && presentation.submissionFormExtraValues.presenters[index]
                ? presentation.submissionFormExtraValues.presenters[index]
                : {};
        formValues.presenterData.push({
            ...presenter,
            extraValues,
        });
    }

    // add presenters

    return formValues;
};

export const mapPresentationsCompleted = (presentations: PresentationEntity[] = [], event, stage: number) => {
    return presentations.map(presentation => {
        return {
            ...presentation,
            completed: isPresentationCompleted(presentation, {}, event, true, stage),
        };
    });
};

export const isPresentationCompleted = (
    presentation: PresentationEntity | Presentation,
    formFiles: Partial<{ posterPoster: FileList; oralSlides: FileList }>,
    event: Event,
    isEditAndSafe: boolean = false,
    preferredStage: number = null
) => {
    if (!presentation || !event) {
        return false;
    }
    const formValues = presentationToFormValues(presentation);
    const presentationType =
        presentation.presentationType || (presentation as PresentationEntity).debugPresentationType;
    if (isEditAndSafe) {
        if (presentationType === 'poster' && presentation.posterId) {
            const list = new DataTransfer();
            const file = new File([''], 'Uploaded File');
            list.items.add(file);
            formFiles['posterPoster'] = list.files;
        }
    }
    const { hasPresenterErrs, ...formErrs } = validatePresentationSubmissionForm(
        formValues,
        event,
        formFiles,
        10,
        isEditAndSafe,
        preferredStage
    );
    const isPresentersValid = hasPresenterErrs.length === 0;
    const isFormValid = Object.keys(formErrs).length === 0;
    return isFormValid && isPresentersValid;
};

export const isPresentationRejected = (presentation: PresentationEntity, currentStage: number) => {
    return (
        !!presentation.presentationStatuses.find(ps => currentStage >= ps.stage && ps.status === 'rejected') ||
        presentation.presentationStatuses.reduce((a, b) => (a.stage > b.stage ? a : b)).stage < currentStage
    );
};
