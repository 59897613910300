import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { BaseMediaConfig, Event, FileUploadEvent, PresentationFormData, PresenterData } from '@models';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Subject } from 'rxjs';
import { FG1NotificationService } from '@services';
import { DeleteSubmissionAddlPresenterDialogComponent } from '@dialogs';
import { set as _set } from 'lodash';

const fileUploadLimitMB = 10;

@Component({
    selector: 'app-event-admin-dash-presentations-edit',
    templateUrl: './event-admin-dash-presentations-edit.component.html',
    styleUrls: ['./event-admin-dash-presentations-edit.component.scss'],
})
export class EventAdminDashPresentationsEditComponent implements OnInit, OnDestroy {
    // input data
    @Input() formValues: PresentationFormData & { presentationMediaData: { currentPosterURL: string } };
    @Output() onFormValueChanged = new EventEmitter<PresentationFormData>();
    @Output() onUploadInProgress = new EventEmitter<boolean>();
    @Input() formFiles: any;
    @Input() currentEvent: Event;
    @Input() formErrs: any = {};
    @Input() isInputDisabled: boolean;
    @Input() editorStatus: any;

    @ViewChild('addlPresentersAccordion')
    addlPresentersAccordion: MatAccordion;
    @ViewChild('posterPosterUploadInput')
    posterPosterUploadInput: ElementRef;

    private ngUnsubscribe$ = new Subject();
    posterPosterObjectURL: string;

    // ngx-quill configurations
    quillModulesGeneralConfig = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled formatting buttons

            [{ align: [] }], // text alignment

            [{ color: [] }, { background: [] }], // dropdown with default colors from theme

            [{ font: [] }], // font

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

            [{ header: [1, 2, 3, 4, 5, 6, false] }], // html headers

            [{ list: 'bullet' }, { list: 'ordered' }], // lists

            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

            ['blockquote'], // quote

            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

            ['link'], // links

            ['clean'], // remove formatting button
        ],
    };

    // special quill configs
    quillModulesTitleConfig = {
        toolbar: [
            ['italic'], // toggled formatting buttons

            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

            ['clean'], // remove formatting button
        ],
    };

    quillModulesAbstractConfig = {
        toolbar: [
            ['bold', 'italic'], // toggled formatting buttons

            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

            ['link'], // links

            [{ list: 'bullet' }, { list: 'ordered' }], // lists

            ['clean'], // remove formatting button
        ],
    };

    currentlyOpenPresenterIndexes: Array<number> = [];
    uploading: boolean;

    constructor(
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private fg1NotificationService: FG1NotificationService
    ) {}

    ngOnInit() {
        // reset errors
        this.formErrs = {};

        // add existing additional presenters to list of open panels
        this.currentlyOpenPresenterIndexes.push(
            ...this.formValues.presenterData.filter((presenter, idx) => idx > 0).map((presenter, idx) => idx + 1)
        );
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    handleAddPresenter() {
        this.formValues.presenterData.push({ extraValues: {} } as PresenterData);
        this.currentlyOpenPresenterIndexes.push(this.formValues.presenterData.length - 1);
        this.cdr.detectChanges();
        document
            .querySelector(`#presenterPanel_${this.formValues.presenterData.length - 1} > mat-expansion-panel-header`)
            .scrollIntoView();
    }

    public shouldUseVideoFileUpload(item: BaseMediaConfig) {
        return item.videoType === 'upload';
    }

    openPresenterPanel(idx: number) {
        if (idx > 0 && !this.currentlyOpenPresenterIndexes.includes(idx)) {
            this.currentlyOpenPresenterIndexes.push(idx);
            document.querySelector(`#presenterPanel_${idx} > mat-expansion-panel-header`).scrollIntoView();
        }
    }

    closePresenterPanel(idx: number) {
        if (this.currentlyOpenPresenterIndexes.includes(idx)) {
            this.currentlyOpenPresenterIndexes.splice(this.currentlyOpenPresenterIndexes.indexOf(idx), 1);
        }
    }

    async handleRemovePresenter(idx: number) {
        // dialog confirmation
        const dialogRef = this.dialog.open(DeleteSubmissionAddlPresenterDialogComponent, {
            direction: 'ltr',
            data: {
                presenterTitle: this.currentEvent.presentationFormConfig.presenterFields.presenterTitle,
                presenterNumber: idx + 1,
            },
        });
        const result = await dialogRef.afterClosed().toPromise();

        // if cancelled, abort
        if (!result) {
            return;
        }

        // remove presenter
        this.formValues.presenterData.splice(idx, 1);
    }

    handleFilesChange(fileName: string, files: FileList) {
        if (files) {
            this.formFiles[fileName] = files;

            // validate
            const posterFile: File = (this.formFiles[fileName] as FileList).item(0);
            if (fileName === 'posterPoster') {
                if (this.posterPosterObjectURL) {
                    URL.revokeObjectURL(this.posterPosterObjectURL);
                }
                this.posterPosterObjectURL = URL.createObjectURL(posterFile);
            }
            if (posterFile.type !== 'application/pdf') {
                this.fg1NotificationService.warn('File is not a valid PDF.', 'Invalid file');
                this.formErrs[fileName] = {
                    isValid: true,
                    errMsg: 'File is not a valid PDF.',
                };
                this.removeFile(fileName);
            } else if (posterFile.size > fileUploadLimitMB * 1024 * 1024) {
                this.fg1NotificationService.warn('File is too large.', 'Invalid file');
                this.formErrs[fileName] = { isValid: true, errMsg: 'File is too large.' };
                this.removeFile(fileName);
            } else {
                this.formErrs[fileName] = undefined;
            }
        } else {
            this.removeFile(fileName);
        }
    }

    removeFile(fileName: string) {
        if (this.formFiles[fileName]) {
            delete this.formFiles[fileName];
            URL.revokeObjectURL(this.posterPosterObjectURL);
            this.posterPosterObjectURL = null;
            (this.posterPosterUploadInput.nativeElement as HTMLInputElement).value = null;
        }
    }
    get exhibition() {
        return this.currentEvent.presentationFormConfig.mediaFields.exhibition;
    }

    get poster() {
        return this.currentEvent.presentationFormConfig.mediaFields.poster;
    }

    get oral() {
        return this.currentEvent.presentationFormConfig.mediaFields.oral;
    }

    get mediaFiles() {
        let presentationVideoLink = null;
        let voiceoverVideoLink = null;

        if (this.formValues.presentationType === 'oral') {
            presentationVideoLink = this.formValues.presentationMediaData['presentationVideoLink'];
        }

        if (this.formValues.presentationType === 'poster') {
            voiceoverVideoLink = this.formValues.presentationMediaData['voiceoverVideoLink'];
        }

        if (this.formValues.presentationType === 'exhibition') {
            presentationVideoLink = this.formValues.presentationMediaData['presentationVideoLink'];
            voiceoverVideoLink = this.formValues.presentationMediaData['voiceoverVideoLink'];
        }

        return {
            presentationVideoLink,
            voiceoverVideoLink,
        };
    }

    onUploadFinished(event: FileUploadEvent) {
        _set(this.formValues.presentationMediaData, event.key, event.fileId);
        this.uploading = false;
        this.onFormValueChanged.emit(this.formValues);
    }

    uploadInProgress(uploading: boolean) {
        this.onUploadInProgress.emit(uploading);
    }
}
