import { saveAs } from 'file-saver';

type ToStringFunction = (data: any) => string;

export type ExportableDataOption = {
    filename: string;
    exclude?: string[];
    heading?: { [name: string]: string };
    toStrings?: { [name: string]: ToStringFunction };
};

export function downloadFileAsCsv(data: Array<Record<string, any>>, option: ExportableDataOption) {
    const { filename, exclude = [], heading = {}, toStrings = {} } = option;
    const header = Object.keys(data[0]).filter(key => exclude.indexOf(key) < 0);

    const csv = data.map(row => {
        const rowValue =
            '"' +
            header
                .map(name => {
                    let value = row[name];
                    value = toStrings[name] ? toStrings[name](value) : value;

                    if (typeof value === 'string') {
                        // Why do some string have leading and closing quotes?
                        if (value[0] === '"') {
                            value = value.substring(1);
                        }
                        if (value[value.length - 1] === '"') {
                            value = value.substring(0, value.length - 1);
                        }
                        return value.replace(/"/g, '""');
                    }

                    return value;
                })
                .join('","') +
            '"';

        return rowValue;
    });

    const headerMapped =
        '"' +
        header
            .map(name => {
                let value = heading[name] ? heading[name] : name;
                return value.replace(/"/g, '""');
            })
            .join('","') +
        '"';
    csv.unshift(headerMapped);

    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, filename);
}
