import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormSubmission, GeneralInterface, Presentation } from '@models';

@Component({
    selector: 'app-final-notes-preview',
    templateUrl: './final-notes-preview.component.html',
    styleUrls: ['./final-notes-preview.component.scss'],
})
export class FinalNotesPreviewComponent {
    @Input() set response(data: FormSubmission) {
        this.currentResponse = this.shallowCopy(data);
    }
    @Input() allResponses: FormSubmission[];
    @Output() toggleResponse: EventEmitter<FormSubmission> = new EventEmitter();
    @Output() closePreview: EventEmitter<FormSubmission> = new EventEmitter();

    formValues: Record<string, any>;
    formErrs: any = {};
    presentation: Presentation;
    currentResponse: FormSubmission;

    constructor() {}

    get allEntitiesDto(): GeneralInterface<FormSubmission>[] {
        return this.allResponses.map(r => ({
            id: r.submissionId,
            title: r.form?.name || '',
            entity: r,
        }));
    }

    get selectedEntityDto(): GeneralInterface<FormSubmission> {
        return {
            id: this.currentResponse.submissionId,
            title: this.currentResponse.form?.name || '',
            entity: this.currentResponse,
        };
    }

    get currentIndex() {
        return this.allResponses.findIndex(r => r.submissionId === this.currentResponse.submissionId);
    }

    onClose(response: FormSubmission) {
        this.closePreview.emit(response);
    }

    onToggleResponse(selected: FormSubmission) {
        if (!selected) {
            return;
        }

        this.currentResponse = this.shallowCopy(selected);

        this.toggleResponse.emit(selected);
    }

    private shallowCopy(response: FormSubmission) {
        return {
            ...response,
            response: { ...response.response },
        };
    }
}
