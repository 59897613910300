import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, zip, of } from 'rxjs';
import { catchError, filter, first, switchMap } from 'rxjs/operators';
import { AuthenticationService, FG1NotificationService, FormsService } from '@services';
import { CurrentUser, EvaluationForm } from '@models';
import { AppService } from '@services';
import { PreviousRouteService } from '../services/previous-route-service';

@Injectable()
export class JudgePresentationGuard implements CanActivate {
    constructor(
        private appService: AppService,
        private formsService: FormsService,
        private prevRouteService: PreviousRouteService,
        private fg1NotificationService: FG1NotificationService,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
        return this.authenticationService
            .refreshUserFromToken()
            .pipe(
                switchMap(_ =>
                    zip(
                        this.authenticationService.getCurrentUser().pipe(
                            filter(e => !!e),
                            first()
                        ),
                        this.formsService.getEvaluationForm(next.params.evaluationFormId).pipe(
                            filter(e => !!e),
                            first()
                        )
                    )
                )
            )
            .pipe(
                switchMap(([currentUser, evaluationForm]: [CurrentUser, EvaluationForm]) => {
                    const isAssignedPresentation = currentUser.judgePresentations.find(assignment =>
                        assignment.presentations.includes(evaluationForm.presentationId)
                    );

                    if (!isAssignedPresentation) {
                        this.fg1NotificationService.error(`You're not authorized to access this evaluation form`);
                    }

                    return of(!!isAssignedPresentation);
                }),
                catchError(e => {
                    this.router.navigate(['/'], { replaceUrl: true });
                    return of(false);
                })
            );
    }
}
