import { Injectable } from '@angular/core';
import { fromEvent, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export type ScreenMode = 'xs' | 'sm' | 'md' | 'lg' | 'xlg';

export type IConfig = {
    [x in ScreenMode]: number;
};

@Injectable({
    providedIn: 'root',
})
export class ResponsiveService {
    constructor() {}

    public getWindowSizeObservable(): Observable<number> {
        return fromEvent(window, 'resize').pipe(
            map(event => (event.target as Window).innerWidth),
            startWith(window.innerWidth)
        );
    }

    // From Bootstrap doc
    // Extra small: xs – 480px
    // Small: sm – 768px
    // Medium: md – 992px
    // Large: lg – 1200px

    public getConfigObservable(): Observable<IConfig> {
        return of({
            xs: 480,
            sm: 768,
            md: 992,
            lg: 1200,
            xlg: 1200, // greater than 1200
        });
    }
}
