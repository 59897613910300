<div id="content-container">
    <app-donation-widget-wrapper>
        <app-donation [donationConfig]="donationConfig" (submitted)="onDonate()" *ngIf="step === 1"></app-donation>
        <app-donation-thank-you
            [donationConfig]="donationConfig"
            (submitted)="onMakeAnotherGift()"
            *ngIf="step === 2"
        ></app-donation-thank-you>
    </app-donation-widget-wrapper>
</div>
