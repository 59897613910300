<input style="display: none" #fileInput type="file" (change)="onFileChange($event)" [accept]="allowedExtension" />
<div>
    <div class="description" *ngIf="description">{{ description }}</div>
    <mat-progress-bar
        class="pb-2 pt-2"
        mode="determinate"
        *ngIf="uploading && showProgress"
        [value]="progress"
    ></mat-progress-bar>
    <button
        mat-raised-button
        color="primary"
        class="file-select-button"
        [disabled]="disabled"
        (click)="triggerFileSelect()"
    >
        <mat-icon>attach_file</mat-icon>
        <ng-container *ngIf="!selectedFile; else fileName">{{ text }} {{ extension | fileExtension }}</ng-container>
        <ng-template #fileName>{{ selectedFile.name | limitTo: 60 }}</ng-template>
    </button>
    <button
        *ngIf="uploading && allowCancelUpload"
        matTooltip="Cancel Upload"
        (click)="cancel()"
        mat-icon-button
        color="warn"
    >
        <mat-icon>cancel</mat-icon>
    </button>
</div>
