import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { ResponsiveService, ScreenMode } from '../../services/responsive.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import { IConfig } from '../../services/responsive.service';

@Directive({
    selector: '[onlyForScreen]',
})
export class OnlyForScreenDirective implements OnInit, OnDestroy {
    modeCheck$: Observable<ScreenMode>;
    modeCheckSubscription: Subscription;
    modes: ScreenMode[] = [];
    created = false;

    @Input() set onlyForScreen(mode: ScreenMode | ScreenMode[]) {
        if (Array.isArray(mode)) {
            this.modes = mode;
        } else {
            this.modes = [mode];
        }
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private responsiveService: ResponsiveService
    ) {}

    ngOnInit() {
        this.modeCheck$ = combineLatest([
            this.responsiveService.getWindowSizeObservable(),
            this.responsiveService.getConfigObservable(),
        ]).pipe(
            map(([viewportWidth, config]: [number, IConfig]) => {
                let curMode: ScreenMode = 'md';
                if (config.xs >= viewportWidth) {
                    curMode = 'xs';
                } else if (config.xs < viewportWidth && config.sm >= viewportWidth) {
                    curMode = 'sm';
                } else if (config.sm < viewportWidth && config.md >= viewportWidth) {
                    curMode = 'md';
                } else if (config.md < viewportWidth && config.lg >= viewportWidth) {
                    curMode = 'lg';
                } else if (config.lg < viewportWidth) {
                    curMode = 'xlg';
                }
                return curMode;
            }),
            shareReplay(1)
        );

        this.modeCheckSubscription = this.modeCheck$
            .pipe(
                tap(mode => {
                    if (this.modes.includes(mode) && !this.created) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                        this.created = true;
                    } else if (!this.modes.includes(mode) && this.created) {
                        this.created = false;
                        this.viewContainer.clear();
                    }
                })
            )
            .subscribe();
    }

    ngOnDestroy() {
        if (this.modeCheckSubscription) {
            this.modeCheckSubscription.unsubscribe();
        }
    }
}
