import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { MAX_CACHE_COUNT, TWO_MINUTES_CACHE } from '@constants';
import { Observable, Subject } from 'rxjs';
import { Sponsor, SponsorCategory } from '@models';
import { toHttpParams } from '@functions';

const sponsorsCacheBuster = new Subject<void>();

@Injectable({
    providedIn: 'root',
})
export class SponsorsService {
    private url: string = `${environment.SERVICE_BASE_URL}/events`;

    constructor(private http: HttpClient) {}

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: sponsorsCacheBuster,
    })
    getSponsors(eventId: number): Observable<SponsorCategory[]> {
        return this.http.get<SponsorCategory[]>(`${this.url}/${eventId}/sponsors`, {
            params: toHttpParams({ eventId }),
        });
    }

    @CacheBuster({
        cacheBusterNotifier: sponsorsCacheBuster,
    })
    createSponsor(sponsor: Partial<Sponsor>): Observable<Sponsor> {
        return this.http.post<Sponsor>(`${this.url}/${sponsor.eventId}/sponsors`, sponsor);
    }

    @CacheBuster({
        cacheBusterNotifier: sponsorsCacheBuster,
    })
    createSponsorCategory(sponsorCategory: Partial<SponsorCategory>): Observable<Sponsor> {
        return this.http.post<Sponsor>(`${this.url}/${sponsorCategory.eventId}/sponsors/category`, sponsorCategory);
    }

    @CacheBuster({
        cacheBusterNotifier: sponsorsCacheBuster,
    })
    deleteSponsorsCategory(eventId: number, categoryId: number) {
        return this.http.delete<Sponsor>(`${this.url}/${eventId}/sponsors/category/${categoryId}`);
    }

    @CacheBuster({
        cacheBusterNotifier: sponsorsCacheBuster,
    })
    deleteSponsor(eventId: number, sponsorId: number) {
        return this.http.delete<Sponsor>(`${this.url}/${eventId}/sponsors/${sponsorId}`);
    }

    @CacheBuster({
        cacheBusterNotifier: sponsorsCacheBuster,
    })
    updateSponsorsSort(eventId: number, sponsorCategory: Partial<SponsorCategory>) {
        return this.http.put<Sponsor>(
            `${this.url}/${eventId}/sponsors/category/${sponsorCategory.sponsorCategoryId}/sponsors`,
            sponsorCategory
        );
    }
}
