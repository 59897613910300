import { Pipe, PipeTransform } from '@angular/core';
import { utcToLocalDate } from '@functions';

@Pipe({
    name: 'utcToLocalDate',
})
export class UtcToLocalDatePipe implements PipeTransform {
    transform(date: string, format: string = 'MM/DD/YY, hh:mma') {
        return utcToLocalDate(date).format(format);
    }
}
