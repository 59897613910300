import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormSubmission } from '@models';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@dialogs';
import { getLocalTimeZone } from '@functions';

@Component({
    selector: 'app-final-notes-evaluations',
    templateUrl: './final-notes-evaluations.component.html',
    styleUrls: ['./final-notes-evaluations.component.scss'],
})
export class FinalNotesEvaluationsComponent {
    @Input() set responses(responses: FormSubmission[]) {
        this.updateTable(responses);
    }
    @Input() isJudging: boolean = true;
    @Input() isLoading: boolean = false;
    @Output() delete = new EventEmitter<number>();
    @Input() title = 'Final Notes';

    public isPreviewOpened: boolean;
    public finalResponses: FormSubmission[];
    public selectedResponse: FormSubmission;
    public dataSource: MatTableDataSource<FormSubmission>;
    public readonly displayedColumns = ['actions', 'judgeName', 'createdAt'];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    timezone = getLocalTimeZone();

    constructor(private dialog: MatDialog) {}

    private updateTable(responses) {
        this.finalResponses = responses;
        this.dataSource = new MatTableDataSource(responses);
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'judgeName':
                    return item.consumer.fullName;
                default:
                    return item[property];
            }
        };
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    private openDialog(title: string, message: string): Promise<boolean> {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '250px',
            data: { message, title },
        });
        return dialogRef.afterClosed().toPromise();
    }

    public async onDelete(id: number) {
        const confirm = await this.openDialog(
            'Delete submission response',
            'Are you sure you want to delete this submission response? This action cannot be undone.'
        );
        if (confirm) {
            this.delete.emit(id);
        }
    }

    public openPreview(response: FormSubmission) {
        this.selectedResponse = response;
        this.isPreviewOpened = true;
    }

    public closePreview(response: FormSubmission) {
        this.isPreviewOpened = false;
        this.selectedResponse = null;
    }

    public onToggleResponse(response: FormSubmission) {
        this.selectedResponse = response;
    }
}
