import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormEditorMediaFieldsComponent } from './media-fields.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { VideoTypeSelectorComponent } from './video-type-selector';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
    ],
    exports: [FormEditorMediaFieldsComponent],
    declarations: [FormEditorMediaFieldsComponent, VideoTypeSelectorComponent],
})
export class FormEditorMediaFieldsModule {}
