import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumericDirective, OnlyForPlatformDirective, OnlyForScreenDirective } from './directives';
import {
    AvatarComponent,
    ConsentRequiredComponent,
    EditButtonComponent,
    EventAdminDashPresentationsEditComponent,
    FgButtonComponent,
    FinalNotesEvaluationsComponent,
    FinalNotesPreviewComponent,
    FormPreviewInputFieldModule,
    FormPreviewLikertScaleFieldModule,
    FormPreviewMultiSelectFieldModule,
    FormPreviewPresenterFieldsModule,
    GeneralizedFormPreviewModule,
    LoadingComponent,
    PackageFeatureIncludedComponent,
    PresentationPreviewComponent,
    PresentationRequiredFieldsStatus,
    PresentationsTableComponent,
    SubmissionFileUploadPreviewComponent,
    SymposiumCustomTableComponent,
    UploadFileComponent,
} from '@components';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '@pipes';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { EditWrapperModule } from '../event-admin-dash/shared';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SkipNavigationComponent } from './components/skip-navigation/skip-navigation.component';

const businessComponents = [];
const components = [
    EditButtonComponent,
    ConsentRequiredComponent,
    LoadingComponent,
    AvatarComponent,
    UploadFileComponent,
    PresentationsTableComponent,
    SymposiumCustomTableComponent,
    FgButtonComponent,
    FinalNotesEvaluationsComponent,
    PresentationPreviewComponent,
    EventAdminDashPresentationsEditComponent,
    SubmissionFileUploadPreviewComponent,
    FinalNotesPreviewComponent,
    PresentationRequiredFieldsStatus,
    PackageFeatureIncludedComponent,
];
const directives = [OnlyForScreenDirective, OnlyForPlatformDirective, NumericDirective];
const pipes = [];
const modules = [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    PipesModule,
    MatProgressBarModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatChipsModule,
    NgxPaginationModule,
    MatCheckboxModule,
    GeneralizedFormPreviewModule,
    FormPreviewMultiSelectFieldModule,
    MatRadioModule,
    MatFormFieldModule,
    NgxExtendedPdfViewerModule,
    FormPreviewInputFieldModule,
    FormPreviewLikertScaleFieldModule,
    FormPreviewPresenterFieldsModule,
    MatExpansionModule,
    MatDividerModule,
    EditWrapperModule,
    MatPaginatorModule,
];

@NgModule({
    declarations: [...businessComponents, ...components, ...directives, ...pipes, SkipNavigationComponent],
    imports: [...modules],
    exports: [...businessComponents, ...components, ...directives, ...pipes, ...modules, SkipNavigationComponent],
    providers: [],
})
export class SharedModule {}
