/**
 * validate file max file size
 * @param file
 * @param maxFileSize - max file size in MB
 */
import { getFileExtensionFromMap, UploadFileExtensions } from '@models';
import { environment } from '@env';

export const isFileSizeValid = (file: File, maxFileSize: number = 10) => maxFileSize > file.size / 1024 / 1024; // in MiB

export const getFileExtension = (file: File) => (file && file.name ? `.${file.name.split('.').pop()}` : null);

export const isValidFormUploadExtension = (file: File, input: UploadFileExtensions) => {
    if (input === UploadFileExtensions.ALL) {
        return true;
    }
    const extension = getFileExtensionFromMap(input);
    if (!extension) {
        throw Error('Unsupported Extension');
    }
    const allowedTypes = extension.split(',');
    const ex = getFileExtension(file);
    return allowedTypes.includes(ex) || allowedTypes.find(type => type.includes(file.type));
};

export const getBucketByEnv = (bucket: string) =>
    environment.ANGULAR_ENV === 'production' ? bucket : `${bucket}-beta`;
