<simple-notifications></simple-notifications>
<app-loading *ngIf="isLoading"></app-loading>
<div [ngStyle]="{ 'background-color': isWhiteBackground ? '#ffffff' : '#f1f1f1' }">
    <!-- <div class="header-shadow"></div> -->
    <div
        [ngClass]="{
            'home-page-screen': isFullPageCSS,
            'mobile-overflow': overflowScreen,
            'mobile-header': isEventPageAndMobile
        }"
        class="wrapper-to-fill-to-footer"
    >
        <app-custom-header [isHomePage]="homePage"></app-custom-header>

        <router-outlet id="main" (activate)="onActivate($event)"></router-outlet>
    </div>
    <app-footer *onlyForPlatform="'web'"></app-footer>
</div>
