import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripeTransactionPrice',
})
export class StripeTransactionPricePipe implements PipeTransform {
    transform(price: number, withTransactionFee: boolean = true): any {
        if (!price) return 0;
        if (!withTransactionFee) return price;
        return Number(price) + Number(price) * 0.029999 + 0.3;
    }
}
