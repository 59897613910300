import { ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@env';

export const getEventCodeFromParams = (route: ActivatedRouteSnapshot) => {
    if (!route || !route.params) {
        return '';
    }
    return route.params.eventCodeOrHash;
};

export const getOutsideLink = (url: string) => {
    const prefix = environment.APP_BASE_URL.endsWith(':4200') ? 'http' : 'https';

    if (url.startsWith('/')) {
        url = url.substr(1);
    }

    return `${prefix}://${environment.APP_BASE_URL}/${url}`;
}
