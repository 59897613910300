// lowerCase: 1,
// upperCase: 1,
// numeric: 1,
// requirementCount: 6,
// min: 6,
// symbol: 0,

import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

// max: 30,
export const PASSWORD_CHECK_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,30}$/;

export function dateRangeValidator(minDate: Date, maxDate: Date, granularity: any = 'day'): ValidatorFn {
    return ({ value }: AbstractControl): { [key: string]: true } | null => {
        if (!value) return null;
        const controlAsMS = value.getTime();
        const invalid =
            moment(controlAsMS).isBefore(minDate, granularity) ||
            (maxDate && moment(controlAsMS).isAfter(maxDate, granularity));
        return invalid ? { outOfRange: true } : null;
    };
}

export const checkOverlap = (usedTimes: { startDateTime: Date; endDateTime: Date }[]): ValidatorFn => (
    fg: FormGroup
) => {
    const startValue = fg.get('startTime').value;
    const endValue = fg.get('endTime').value;
    const date = moment(fg.get('date').value);
    if (!date) return null;
    let invalid = null;
    if (endValue) {
        const end = moment(`${date.format('DD/MM')} ${endValue.hour}:${endValue.minute}`, 'DD/MM h:mm').unix();
        invalid = getOverlap(usedTimes, end) ? { endOverlap: true } : null;
    }
    if (startValue) {
        const start = moment(`${date.format('DD/MM')} ${startValue.hour}:${startValue.minute}`, 'DD/MM h:mm').unix();
        invalid = getOverlap(usedTimes, start) ? { startOverlap: true } : null;
    }
    return invalid;
};

export const dateMinNowValidator: ValidatorFn = (fg: FormGroup) => {
    const startValue = fg.get('startTime').value;
    const endValue = fg.get('endTime').value;
    const date = moment(fg.get('date').value);
    if (!date) return null;
    let invalid = null;
    const now = moment().unix();
    if (endValue) {
        const end = moment(
            `${date.format('DD/MM/YYYY')} ${endValue.hour}:${endValue.minute}`,
            'DD/MM/YYYY h:mm'
        ).unix();
        invalid = end > now ? null : { endMinNow: true };
    }
    if (startValue) {
        const start = moment(
            `${date.format('DD/MM/YYYY')} ${startValue.hour}:${startValue.minute}`,
            'DD/MM/YYYY h:mm'
        ).unix();
        invalid = start > now ? null : { startMinNow: true };
    }
    return invalid;
};

export const dateDifferenceValidator: ValidatorFn = (fg: FormGroup) => {
    const startValue = fg.get('startTime').value;
    const endValue = fg.get('endTime').value;
    if (!startValue || !endValue) return null;
    const start = moment(`${startValue.hour}:${startValue.minute}`, 'h:mm').unix();
    const end = moment(`${endValue.hour}:${endValue.minute}`, 'h:mm').unix();
    const invalid = start !== null && end !== null && start < end ? null : { range: true };
    return invalid;
};

export const whitespaceValidator: ValidationErrors = (control: AbstractControl) => {
    const isEmpty = (control.value || '').trim().length === 0;
    return isEmpty ? { whitespace: true } : null;
};

function getOverlap(usedTimes: { startDateTime: Date; endDateTime: Date }[], compareStamp: number) {
    return usedTimes.some(
        ({ startDateTime, endDateTime }) =>
            compareStamp >= moment(startDateTime).unix() && compareStamp <= moment(endDateTime).unix()
    );
}
