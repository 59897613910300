import { Component, Input } from '@angular/core';
import { DonationConfig } from '@models';

@Component({
    selector: 'app-donation-popup',
    templateUrl: './donation-popup.component.html',
    styleUrls: ['./donation-popup.component.scss'],
})
export class DonationPopupComponent {
    @Input() donationConfig: DonationConfig;
    step = 1;

    constructor() {}

    onMakeAnotherGift() {
        this.step = 1;
    }

    onDonate() {
        this.step = 2;
    }
}
