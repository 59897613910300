import { Component, Input, OnInit } from '@angular/core';

type ButtonType = 'primary' | 'secondary' | 'outline';

@Component({
    selector: 'fg-button',
    templateUrl: './fg-button.component.html',
    styleUrls: ['./fg-button.component.scss'],
})
export class FgButtonComponent implements OnInit {
    @Input() loading: boolean;
    @Input() text: string;
    @Input() type: ButtonType = 'primary';
    @Input() class: string = '';

    constructor() {}

    ngOnInit(): void {}

    get btnClass() {
        let buttonClass = '';

        switch (this.type) {
            case 'primary':
                buttonClass = 'btn-primary';
                break;
            case 'secondary':
                buttonClass = 'btn-secondary';
                break;
            case 'outline':
                buttonClass = 'btn-outline-primary';
                break;
        }
        return `${buttonClass} ${this.class}`;
    }
}
