import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpService } from 'src/app/services/http.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileResolverService implements Resolve<any> {
    constructor(private authService: AuthenticationService, private httpService: HttpService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
        return combineLatest([this.authService.getCurrentUser().pipe(first()), this.httpService.getUserEmails()]).pipe(
            map(res => {
                return { user: res[0], emails: res[1] };
            })
        );
    }
}
