import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { PhotoCropperConfig, PhotoCropperOutput } from '@models';
// Note -- can't import from @dialogs as members of @dialogs imports from @service
import { PhotoCropDialogComponent } from '../dialogs/photo-crop-dialog/photo-crop-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class PhotoCropService {
    private photoSubject: BehaviorSubject<PhotoCropperOutput> = new BehaviorSubject<PhotoCropperOutput>(null);

    constructor(private dialog: MatDialog) {}

    public cropImage(
        config: Partial<PhotoCropperConfig> = {},
        editLastCroppedImage: boolean = false
    ): Observable<PhotoCropperOutput | null> {
        return editLastCroppedImage ? this._editLastCroppedImage(config) : this.cropNewImage(config);
    }

    public resetLastCroppedImage() {
        this.photoSubject.next(null);
    }

    private _editLastCroppedImage(config: Partial<PhotoCropperConfig>) {
        return this.photoSubject.pipe(
            first(),
            switchMap(photo =>
                this.cropNewImage({
                    ...config,
                    imageBase64: photo && photo.original ? photo.original.base64 : null,
                })
            )
        );
    }

    private cropNewImage(config: Partial<PhotoCropperConfig> = {}) {
        return this.dialog
            .open(PhotoCropDialogComponent, {
                width: '500px',
                data: { ...config },
            })
            .afterClosed()
            .pipe(
                filter(e => !!e),
                tap(photo => this._updatePhoto(photo))
            );
    }

    private _updatePhoto(photo: PhotoCropperOutput) {
        this.photoSubject.next(photo);
    }
}
