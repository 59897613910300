import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from '@models';

type Feature = 'judging' | 'abstract' | 'analytics';

@Component({
    selector: 'app-package-feature-included',
    templateUrl: './package-feature-included.component.html',
    styleUrls: ['./package-feature-included.component.scss'],
})
export class PackageFeatureIncludedComponent implements OnInit {
    @Input() subscription: Subscription;
    @Input() feature: Feature;

    constructor() {}

    ngOnInit() {}

    get isFeatureEnabled() {
        if (!this.subscription) {
            return false;
        }
        const { subscriptionTier } = this.subscription;
        if (this.feature === 'judging') {
            return subscriptionTier.enabledJudging;
        }
        if (this.feature === 'abstract') {
            return subscriptionTier.enableAbstract;
        }
        if (this.feature === 'analytics') {
            return subscriptionTier.accessAnalytics;
        }
        return false;
    }

    get featureCover() {
        if (this.feature === 'judging') {
            return '/assets/images/judging-demo_cmp.png';
        }
        if (this.feature === 'abstract') {
            return '/assets/images/abstract_demo.png';
        }
        if (this.feature === 'analytics') {
            return '/assets/images/analytics-demo_cmp.png';
        }
        return '';
    }
}
