import { GeneralizedFormConfig, InputType, PhotoCropperConfig, SocialMedia } from '@models';
import { TrumbowygOptions } from 'ngx-trumbowyg/lib/configs/trumbowyg-options';
import { v4 as uuid } from 'uuid';
import { Subject } from 'rxjs';

export enum PhotoCropperShape {
    SQUARE = 1,
    VERTICAL_RECTANGLE = 0.666666667, // calculated value for 2/3 aspect ratio
    HORIZONTAL_RECTANGLE = 1.5, // calculated value for 3/2 aspect ratio,
}

export const DEFAULT_PHOTO_CROPPER_CONFIG: PhotoCropperConfig = {
    mode: 'crop',
    maintainAspectRatio: true,
    customAspectRatio: null,
    shape: PhotoCropperShape.SQUARE,
    imageFile: null,
    imageBase64: null,
};

export enum WidgetMode {
    ThumbnailPreview = 'ThumbnailPreview',
    EditWidgetBody = 'EditWidgetBody',
    Popup = 'Popup',
}

export const TWO_MINUTES_CACHE = 1200000;
export const MAX_CACHE_COUNT = 50;
export const abstractCacheBuster$ = new Subject<void>();
export const formCacheBuster$ = new Subject<void>();
export const judgingCacheBuster$ = new Subject<void>();

export const YOUTUBE_VIDEO_ID_REGEX = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

export const DEFAULT_CACHE_OPTIONS = {
    maxAge: TWO_MINUTES_CACHE,
    maxCacheCount: MAX_CACHE_COUNT,
};

export const FORM_INPUT_TYPES: InputType[] = ['text', 'number', 'wysiwyg', 'file', 'date', 'datetime'];

export const DEFAULT_REGISTRATION_CONFIG: GeneralizedFormConfig = {
    extraFields: [],
    general: {
        instructions: "Please fill out the following form if you'd like to register for this event.",
    },
};

export const DEFAULT_JUDGING_SHARING_OPTIONS = {
    description: 'Include judges’ responses to this field in final “Share Feedback”',
    canShare: false,
    tooltipText:
        'If you enable this, judges\' answers to this question will be shared with presenters through the "Share Feedback" tab. If you do not want this question and its responses to be shared with presenters, do not enable this.',
};

export const DEFAULT_REVIEWER_SHARING_OPTIONS = {
    description: 'Include reviewers’ responses to this field in final “Share Feedback”',
    canShare: false,
    tooltipText:
        'If you enable this, reviewers\' answers to this question will be shared with presenters through the "Share Feedback" tab. If you do not want this question and its responses to be shared with presenters, do not enable this.',
};

export type InputButtonType = 'input' | 'multi-select' | 'likert-scale' | 'file-upload' | 'date';

export const DEFAULT_EDITOR_OPTIONS: TrumbowygOptions = {
    svgPath: '/assets/images/trumbowyg_icons.svg',
    btns: [['strong', 'em', 'underline'], ['link'], ['unorderedList', 'orderedList']],
    removeformatPasted: true,
    autogrow: false,
};

export const DEFAULT_SOCIAL_MEDIA_OPTIONS: SocialMedia[] = [
    {
        type: 'facebook',
        link: '',
        hash: uuid(),
    },
    {
        type: 'twitter',
        link: '',
        hash: uuid(),
    },
    {
        type: 'instagram',
        link: '',
        hash: uuid(),
    },
    {
        type: 'linkedin',
        link: '',
        hash: uuid(),
    },
    {
        type: 'youtube',
        link: '',
        hash: uuid(),
    },
    {
        type: 'globe',
        link: '',
        hash: uuid(),
    },
];

export enum RegistrationTooltips {
    EVENT_NAME = 'The event name is inputted here. Contact us at support@foragerone.com (or via our chat box) for changes to your event name.',
    ORGANIZER_INFORMATION = 'The organizer information is inputted here. Contact us at support@foragerone.com (or via our chat box) for changes to this information',
    EVENT_DATE = 'The event start and end information provided under Event Setup > Event information is inputted here. Please modify those sections to make changes to this information',
    EVENT_LOCATION = 'The event location provided under Event Setup > Event information is inputted here. Please modify those sections to make changes to this information.',
    EVENT_REGISTRATION = 'The subtext under Event Registration cannot be customized. The subtext and button will differ depending on whether your event is set to public or private under your Access Settings. Please refer to the Access Settings page for more information.',
}

export enum EventLocationType {
    VIRTUAL = 'virtual',
    IN_PERSON = 'in-person',
    HYBRID = 'hybrid',
}

export type RegistrationTemplate =
    | 'publicAndFree'
    | 'privateAndFree'
    | 'publicAndPaid'
    | 'privateAndPaid'
    | 'loadingSpinner'
    | 'notAllowedToRegister'
    | 'loggedOut'
    | 'attendeesLimitExceeded'
    | 'notActive'
    | 'registered'
    | 'archived'
    | 'notLaunched'
    | 'customRegistration';

export * from './submissions.constants';
export * from './seo.constants';
