import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnfinishedChangesDialogComponent } from '@dialogs';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [CommonModule, MatDialogModule],
    declarations: [UnfinishedChangesDialogComponent],
    exports: [UnfinishedChangesDialogComponent],
})
export class UnfinishedChangesDialogModule {}
