import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeneralizedFormPreviewComponent } from './generalized-form-preview.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormPreviewInputFieldModule } from './fields/input-field/input-field.module';
import { FormPreviewMultiSelectFieldModule } from './fields/multi-select-field/multi-select-field.module';
import { FormPreviewPresenterFieldsModule } from './fields/presenter-fields/presenter-fields.module';
import { FormPreviewLikertScaleFieldModule } from './fields/likert-scale-field/likert-scale-field.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormPreviewInputFieldModule,
        FormPreviewMultiSelectFieldModule,
        FormPreviewPresenterFieldsModule,
        FormPreviewLikertScaleFieldModule,
        MatTooltipModule,
    ],
    declarations: [GeneralizedFormPreviewComponent],
    exports: [GeneralizedFormPreviewComponent],
})
export class GeneralizedFormPreviewModule {}
