import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { FG1NotificationService } from '@services';
import { isNil } from 'lodash';
import { SubscriptionService } from '../services/subscription.service';
@Injectable({
    providedIn: 'root',
})
export class SubscriptionGuard implements CanActivate {
    constructor(
        private subscriptionService: SubscriptionService,
        private fg1NotificationService: FG1NotificationService,
        private router: Router
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.subscriptionService.getConsumerSubscriptions().pipe(
            // map(subscriptions=> Boolean(subscriptions.length)),
            map(isEnabled => {
                const shouldActivateOnEnabled = isNil(route.data.shouldActivateOnFeatureFlag)
                    ? true
                    : Boolean(route.data.shouldActivateOnFeatureFlag);
                if ((isEnabled && shouldActivateOnEnabled) || (!isEnabled && !shouldActivateOnEnabled)) {
                    return true;
                } else {
                    this.fg1NotificationService.warn("You're not authorized to access this page.", 'Unauthorized');
                    return this.router.createUrlTree(['/']);
                }
            })
        );
    }
}
