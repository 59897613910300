<ng-container *ngIf="!isFeatureEnabled">
    <p>
        This feature is not included in your package.
        <a href="/contact" target="_blank" class="link">Contact us</a> to upgrade your package to access this
        feature.
    </p>

    <div class="demo-image-container">
        <img [src]="featureCover" alt="demo judging portal" />
    </div>
</ng-container>
