<div class="team_carousel_area">
    <div class="container">
        <div class="team_carousel">
             <div class="team_item_main">
                <div class="team_item">
                    <div class="team_item_header">
                        <p>
                            How Northwestern University grew participation by 20x, reduced cost by 60%, and took their programming to new levels by rethinking their annual expo experience with Symposium
                        </p>
                        <div class="name_and_university" style="display:inline-block">
                            <img class="worker_face" src="assets/images/home/megan.png" alt="img" />
                            <img class="worker_face" src="assets/images/home/peter.png" alt="img" />
                            <div style="display:inline-block">
                                <h5>Megan Novak Wood & Peter Civetta</h5>
                                <h6>Northwestern University</h6>
                            </div>
                        </div>
                    </div>
                    <div class="team_item_txt">
                        <p>
                            “We are projected to still save $3,000 from our pre-COVID format, while functionally offering more programming than we did prior to the pandemic.” — Peter Civetta
                        </p>
                        <p>
                            “I no longer have a day and a half long process of manually sorting the [judges’] paper comments … With Symposium, it takes me about 3 hours … I’ll never go back to the old format for judging.” — Megan Novak Wood
                        </p>
                    </div>
                    <div class="read_article">
                        <a href="/case-studies/feature/working-smarter-how-northwestern-grew-participation-reduced-cost-and-elevated-their-event-experience" target="_blank">Read Article <i class="fas fa-angle-right"></i></a>
                    </div>
                </div>
            </div>
            
            <div class="team_item_main">
                <div class="team_item">
                    <div class="team_item_header">
                        <p>
                           Missy Thompson’s case for hybrid events—how she bolstered a sense of community, enhanced the event experience, and created opportunities for marketing at Fort Lewis College
                        </p>
                        <div class="name_and_university" style="display:inline-block">
                            <img class="worker_face" src="assets/images/home/missy.png" alt="img" />
                            <div style="display:inline-block">
                                <h5>Missy Thompson</h5>
                                <h6>Fort Lewis College</h6>
                            </div>
                        </div>
                    </div>
                    <div class="team_item_txt">
                        <p>
                            “Having the virtual aspect has provided the opportunity for us to showcase works with alumni, donors, key stakeholders, graduate school recruiters and family/friends of students. It has also offered greater flexibility and leaves us with a centralized repository of student work that provides great marketability.” — Missy Thompson
                        </p>
                    </div>
                    <div class="read_article">
                        <a href="/case-studies/feature/a-case-for-hybrid-events-community-marketability-and-more" target="_blank">Read Article <i class="fas fa-angle-right"></i></a>
                    </div>
                </div>
            </div>
            
            <div class="team_item_main">
                <div class="team_item">
                    <div class="team_item_header">
                        <p>
                           Creating more meaningful experiences by escaping the confines of traditional, strictly in-person experiences, while saving 33% for John Carroll University
                        </p>
                        <div class="name_and_university" style="display:inline-block">
                            <img class="worker_face" src="assets/images/home/erica.png" alt="img" />
                            <img class="worker_face" src="assets/images/home/carole.png" alt="img" />
                            <img class="worker_face" src="assets/images/home/anita.png" alt="img" />
                            <div style="display:inline-block">
                                <h5>Erica Kennedy, Carole Krus & Anita Iveljic</h5>
                                <h6>John Carroll University</h6>
                            </div>
                        </div>
                    </div>
                    <div class="team_item_txt">
                        <p>
                            “[Student presenters] still interact with their audience - and judges! - through discussion rooms or chat functions, gaining experience in new forms of interaction that will be a part of academic and work cultures for the foreseeable future. We have been able to recruit judges for our events from literally all over the world, allowing experts in their fields and local community members to interact with and mentor our students.” — Carole Krus
                        </p>
                    </div>
                    <div class="read_article">
                        <a href="/case-studies/feature/escaping-the-confines-of-traditional-strictly-in-person-events" target="_blank">Read Article <i class="fas fa-angle-right"></i></a>
                    </div>
                </div>
            </div>
            
            <div class="team_item_main">
                <div class="team_item">
                    <div class="team_item_header">
                        <p>
                            How Anne Pate of Southwestern Oklahoma State University transformed the learning process for her class during the COVID-19 pandemic and developed practices to improve student outcomes
                        </p>
                        <div class="name_and_university" style="display:inline-block">
                            <img class="worker_face" src="assets/images/home/anne.png" alt="img" />
                            <div style="display:inline-block">
                                <h5>Anne Pate</h5>
                                <h6>Southwestern Oklahoma State University</h6>
                            </div>
                        </div>
                    </div>
                    <div class="team_item_txt">
                        <p>
                            “Having the presentations available asynchronously via Symposium has provided me with more flexibility in my schedule to allow for a more structured learning process while developing the presentations and applying the knowledge to real-world datasets.” — Anne Pate
                        </p>
                    </div>
                    <div class="read_article">
                        <a href="/case-studies/feature/going-beyond-events-symposium-in-the-classroom" target="_blank">Read Article <i class="fas fa-angle-right"></i></a>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
