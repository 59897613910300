import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { FG1NotificationService, HttpService } from '@services';
import { isNil } from 'lodash';
import { getEventCodeFromParams } from '@functions';

@Injectable({
    providedIn: 'root',
})
export class EventFeatureFlagGuard implements CanActivate {
    constructor(private httpService: HttpService, private fg1NotificationService: FG1NotificationService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const eventCodeOrHash = getEventCodeFromParams(route) || route.url.slice(-1)[0].toString();

        return this.httpService.getFindEvent(eventCodeOrHash).pipe(
            first(),
            map(x => x && x.body.eventFeatureFlags[route.data.featureFlag]),
            map(Boolean),
            map(isEnabled => {
                const shouldActivateOnEnabled = isNil(route.data.shouldActivateOnFeatureFlag)
                    ? true
                    : Boolean(route.data.shouldActivateOnFeatureFlag);
                if ((isEnabled && shouldActivateOnEnabled) || (!isEnabled && !shouldActivateOnEnabled)) {
                    return true;
                } else {
                    this.fg1NotificationService.warn("You're not authorized to access this page.", 'Unauthorized');
                    return false;
                }
            })
        );
    }
}
