import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import {
    CommentPayload,
    Consumer,
    ConsumerEmail,
    CurrentUser,
    Event,
    EventEmail,
    GetPresentationsParams,
    LiveSession,
    LoginResponse,
    NewInstitution,
    Presentation,
    RegisterPayload,
    RegisterResponse,
} from '@models';
import { toHttpParams } from '@functions';
import { nl2br } from '@utils';
import decode from 'decode-html';
import { isNil } from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { presentationCacheBuster$ } from './presentation.service';
import { DEFAULT_CACHE_OPTIONS } from '@constants';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    SERVICE_BASE_URL = environment.SERVICE_BASE_URL;

    constructor(private http: HttpClient) {}

    public postCreateLiveSession(liveSession: LiveSession, eventId: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            observe: 'response' as const,
        };

        return this.http.post(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_POST_CREATE_LIVE_SESSION}`,
            {
                eventId,
                title: liveSession.name,
                description: liveSession.description,
                sessionStart: liveSession.sessionStart,
                sessionEnd: liveSession.sessionEnd,
                presentationLink: liveSession.linkUrl,
                linkType: liveSession.sessionLinkType,
                disableCameras: liveSession.disableCameras,
                disableShareScreen: liveSession.disableShareScreen,
                disableMicrophones: liveSession.disableMicrophones,
                askToJoin: liveSession.askToJoin,
                moderatorEmails: liveSession.moderatorEmails,
                participants: liveSession.participants,
                sessionId: liveSession.sessionId,
            },
            httpOptions
        );
    }

    public joinLiveSession(liveSessionId: number): Observable<{ meetingId: string }> {
        return this.http.get<{ meetingId: string }>(`${this.SERVICE_BASE_URL}/live-sessions/${liveSessionId}/join`);
    }

    public getLiveSessions(
        eventCodeOrHash: string
    ): Observable<(LiveSession & { startTime?: Date; endTime?: Date })[]> {
        const params = toHttpParams({ eventCodeOrHash });
        return this.http
            .get<LiveSession[]>(`${this.SERVICE_BASE_URL}/live-sessions`, { params })
            .pipe(
                // sort by start time
                map(liveSessions =>
                    liveSessions.sort((a, b) => {
                        if (a.liveSessionOrder == b.liveSessionOrder) {
                            return new Date(a.sessionStartTime).valueOf() - new Date(b.sessionStartTime).valueOf();
                        }
                        return a.liveSessionOrder - b.liveSessionOrder;
                    })
                ),
                map(liveSessions =>
                    liveSessions.map(
                        liveSession =>
                            ({
                                ...liveSession,
                                moderatorEmails: liveSession.moderatorEmails || [],
                                sessionDate:
                                    liveSession.sessionStartTime &&
                                    moment.utc(liveSession.sessionStartTime).local().format('YYYY-MM-DD'),
                                sessionStartTime:
                                    liveSession.sessionStartTime &&
                                    moment.utc(liveSession.sessionStartTime).local().format('h:mma'),
                                sessionEndTime:
                                    liveSession.sessionEndTime &&
                                    moment.utc(liveSession.sessionEndTime).local().format('h:mma'),
                                timezone:
                                    liveSession.sessionStartTime &&
                                    momentTimezone().tz(momentTimezone.tz.guess()).format('z'),
                                sessionStart: new Date(liveSession.sessionStartTime),
                                sessionEnd: liveSession.sessionEndTime ? new Date(liveSession.sessionEndTime) : null,
                                startTime: moment.utc(liveSession.sessionStartTime).toDate(),
                                endTime: moment.utc(liveSession.sessionEndTime).toDate(),
                            } as LiveSession & { startTime?: Date; endTime?: Date })
                    )
                )
            );
    }

    public putUpdateLiveSession(liveSession: LiveSession, eventId: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            observe: 'response' as const,
        };

        return this.http.put(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_PUT_UPDATE_LIVE_SESSION}`.replace(
                `{liveSessionId}`,
                String(liveSession.liveSessionId)
            ),
            {
                eventId,
                title: liveSession.name,
                description: liveSession.description,
                sessionStart: liveSession.sessionStart,
                sessionEnd: liveSession.sessionEnd,
                presentationLink: liveSession.linkUrl,
                linkType: liveSession.sessionLinkType,
                disableCameras: liveSession.disableCameras,
                disableShareScreen: liveSession.disableShareScreen,
                disableMicrophones: liveSession.disableMicrophones,
                askToJoin: liveSession.askToJoin,
                moderatorEmails: liveSession.moderatorEmails,
                participants: liveSession.participants,
            },
            httpOptions
        );
    }

    public putUpdateLiveSessionsOrder(eventCode: number, liveSessionIds: number[]) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            observe: 'response' as const,
        };

        return this.http.put(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_PUT_UPDATE_LIVE_SESSIONS_ORDER}`.replace(
                `{eventCode}`,
                String(eventCode)
            ),
            { liveSessionIds },
            httpOptions
        );
    }

    public deleteLiveSession(liveSession: LiveSession, eventId: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            observe: 'response' as const,
        };

        return this.http.delete(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_DELETE_DELETE_LIVE_SESSION}`
                .replace(`{liveSessionId}`, String(liveSession.liveSessionId))
                .replace(`{eventId}`, String(eventId)),
            httpOptions
        );
    }

    public updateLiveSessionType(liveSessionId: number, sessionType: string) {
        return this.http.put(
            `${this.SERVICE_BASE_URL}/live-sessions/{liveSessionId}/update-live-session-type`.replace(
                `{liveSessionId}`,
                String(liveSessionId)
            ),
            {
                sessionType,
            }
        );
    }

    public postCreatePresentation(eventId: number, formData: FormData) {
        return this.http.post(this.SERVICE_BASE_URL + `/presentations?eventId=${eventId}`, formData);
    }

    public getIsValidPresentationHash(hash: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.SERVICE_BASE_URL}/presentations/is-valid-presentation-hash?hash=${hash}`);
    }

    public checkSSOEmail(email: string) {
        return this.http.get<{
            continueNormalAuthenticationFlow: boolean;
            emailTaken: boolean;
            institutionId: number;
            ssoRemovedMessage: string;
        }>(`${this.SERVICE_BASE_URL}/authentication/sso-email?email=${email}`);
    }

    public samlAuthentication(institutionId: number): Observable<boolean> {
        return this.http.get<boolean>(
            `${this.SERVICE_BASE_URL}/authentication/saml-authentication?institutionId=${institutionId}`
        );
    }

    public samlCallback(code: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.SERVICE_BASE_URL}/authentication/saml-callback?code=${code}`);
    }

    public getSinglePresentation(presentationIdOrHash: number): Observable<Presentation> {
        return this.http.get<Presentation>(
            `${this.SERVICE_BASE_URL}/presentations/single?presentationIdOrHash=${presentationIdOrHash}`
        );
    }

    @Cacheable({
        ...DEFAULT_CACHE_OPTIONS,
        cacheBusterObserver: presentationCacheBuster$,
    })
    public getPresentations(
        params: Partial<GetPresentationsParams>
    ): Observable<{ total: number; presentations: Presentation[] }> {
        return this.http
            .get<{ total: number; presentations: Presentation[] }>(`${this.SERVICE_BASE_URL}/presentations`, {
                params: toHttpParams(params),
            })
            .pipe(
                map(({ presentations, total }) => {
                    presentations = presentations.map(presentation => {
                        presentation.abstract = decode(presentation.abstract);

                        presentation.tags = presentation.tags || [];
                        presentation.tags = presentation.tags.map(tag => {
                            tag.isActive = false;
                            return tag;
                        });
                        presentation.presenters = presentation.presenters.map(presenter => {
                            presenter.level = presenter.level === 'NULL' ? '' : presenter.level;
                            presenter.major = presenter.major === 'NULL' ? '' : presenter.major;
                            return presenter;
                        });
                        return presentation;
                    });
                    return { presentations, total };
                })
            );
    }

    public deletePresentation(presentation: Presentation, eventId: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            observe: 'response' as const,
        };

        return this.http.delete(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_DELETE_DELETE_PRESENTATION}`
                .replace(`{presentationHash}`, String(presentation.hash))
                .replace(`{eventId}`, String(eventId)),
            httpOptions
        );
    }

    /**
     * @deprecated
     * @param institutionId
     * @param query
     */
    public getEvents(institutionId?: number, query = ''): Observable<Event[]> {
        return this.http
            .get<Event[]>(`${this.SERVICE_BASE_URL}/events?institutionId=${institutionId || ''}&query=${query}`)
            .pipe(
                map(events => {
                    events.map(event => {
                        if (event.startDate) {
                            event.startDate = moment.utc(event.startDate).local() as any;
                        }

                        event.eventFeatureFlags = {
                            eventId: event.eventId,
                            useLatestSubmission: (event as any).useLatestSubmission,
                            accessCustomSubmissionFormEditor: (event as any).accessCustomSubmissionFormEditor,
                            editCoverPhoto: (event as any).editCoverPhoto,
                            editEventLogo: (event as any).editEventLogo,
                            editSplashScreen: (event as any).editSplashScreen,
                            editPresentation: (event as any).editPresentation,
                            networkingHoursLimit: (event as any).networkingHoursLimit,
                            eventUptimeInDays: (event as any).eventUptimeInDays,
                            coverDonationsTransactionFees: (event as any).coverDonationsTransactionFees,
                        };
                    });
                    return events;
                })
            );
    }

    public getUserEvents(isAdmin: 0 | 1): Observable<Event[]> {
        return this.http
            .get<Event[]>(`${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_MY_EVENTS}/${isAdmin}`)
            .pipe(
                map(events => {
                    events.map(event => {
                        if (event.startDate) {
                            event.startDate = moment.utc(event.startDate).local() as any;
                        }

                        event.eventFeatureFlags = {
                            eventId: event.eventId,
                            useLatestSubmission: (event as any).useLatestSubmission,
                            accessCustomSubmissionFormEditor: (event as any).accessCustomSubmissionFormEditor,
                            editCoverPhoto: (event as any).editCoverPhoto,
                            editEventLogo: (event as any).editEventLogo,
                            editSplashScreen: (event as any).editSplashScreen,
                            editPresentation: (event as any).editPresentation,
                            networkingHoursLimit: (event as any).networkingHoursLimit,
                            eventUptimeInDays: (event as any).eventUptimeInDays,
                            coverDonationsTransactionFees: (event as any).coverDonationsTransactionFees,
                        };
                    });
                    return events;
                })
            );
    }

    public getUserEmails(): Observable<ConsumerEmail[]> {
        return this.http.get<ConsumerEmail[]>(`${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_MY_EMAILS}`);
    }

    public addUserEmail(email: string): Observable<void> {
        return this.http.post<void>(`${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_EMAILS}`, { email });
    }

    public setPrimaryEmail(consumerEmailId: string): Observable<any> {
        return this.http.put<void>(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_SET_EMAIL_PRIMARY}`.replace(
                '{consumerEmailId}',
                consumerEmailId
            ),
            {}
        );
    }

    public verifyAdditionalEmail(token: string): Observable<void> {
        return this.http.post<void>(`${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_VERIFY_EMAIL}`, { token });
    }

    public deleteEmail(consumerEmailId: string): Observable<void> {
        return this.http.delete<void>(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_SPECIFIC_EMAIL}`.replace(
                '{consumerEmailId}',
                consumerEmailId
            )
        );
    }

    public sendVerificationCode(consumerEmailId: string): Observable<void> {
        return this.http.put<void>(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_SEND_EMAIL_VERIFICATION_CODE}`.replace(
                '{consumerEmailId}',
                consumerEmailId
            ),
            {}
        );
    }

    public updateUser(consumer: Consumer): Observable<void> {
        return this.http.put<void>(`${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_UPDATE_USER}`, consumer);
    }

    // gets event that should exist
    public getGetEvent(eventCode: string): Observable<Event> {
        return this.http
            .get<Event>(
                `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_GET_GET_EVENT}`.replace(
                    '{eventCode}',
                    eventCode
                )
            )
            .pipe(
                map(ev => {
                    ev.startDate = ev.startDate ? new Date(ev.startDate) : ev.startDate;
                    ev.endDate = ev.endDate ? new Date(ev.endDate) : ev.endDate;

                    ev.eventFeatureFlags = {
                        eventId: ev.eventId,
                        useLatestSubmission: (ev as any).useLatestSubmission,
                        accessCustomSubmissionFormEditor: (ev as any).accessCustomSubmissionFormEditor,
                        editCoverPhoto: (ev as any).editCoverPhoto,
                        editEventLogo: (ev as any).editEventLogo,
                        editSplashScreen: (ev as any).editSplashScreen,
                        editPresentation: (ev as any).editPresentation,
                        networkingHoursLimit: (ev as any).networkingHoursLimit,
                        eventUptimeInDays: (ev as any).eventUptimeInDays,
                        coverDonationsTransactionFees: (ev as any).coverDonationsTransactionFees,
                    };
                    return ev;
                })
            );
    }

    // gets event that may not exist, returns response rather than just body
    public getFindEvent(eventCode: string) {
        const httpOptions = { observe: 'response' as const };

        return this.http
            .get<Event>(
                `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_GET_GET_EVENT}`.replace(
                    '{eventCode}',
                    eventCode
                ),
                httpOptions
            )
            .pipe(
                map(res => {
                    res.body.startDate = res.body.startDate ? new Date(res.body.startDate) : res.body.startDate;
                    res.body.endDate = res.body.endDate ? new Date(res.body.endDate) : res.body.endDate;

                    if (res.body.eventConfig?.registrationLaunchDate) {
                        res.body.eventConfig.registrationLaunchDate = new Date(
                            res.body.eventConfig.registrationLaunchDate
                        );
                    }

                    res.body.eventFeatureFlags = {
                        eventId: res.body.eventId,
                        useLatestSubmission: (res.body as any).useLatestSubmission,
                        accessCustomSubmissionFormEditor: (res.body as any).accessCustomSubmissionFormEditor,
                        editCoverPhoto: (res.body as any).editCoverPhoto,
                        editEventLogo: (res.body as any).editEventLogo,
                        editSplashScreen: (res.body as any).editSplashScreen,
                        editPresentation: (res.body as any).editPresentation,
                        networkingHoursLimit: (res.body as any).networkingHoursLimit,
                        eventUptimeInDays: (res.body as any).eventUptimeInDays,
                        coverDonationsTransactionFees: (res.body as any).coverDonationsTransactionFees,
                    };
                    return res;
                })
            );
    }

    // gets event global count (without 'demo' in the event code)
    public getEventGlobalCount(): Observable<number> {
        return this.http
            .get<any>(`${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_GET_GET_GLOBAL_EVENT_COUNT}`)
            .pipe(map(res => res.total as number));
    }

    public putArchiveEvent(eventCode: string) {
        const httpOptions = { observe: 'response' as const };

        return this.http.put(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_PUT_ARCHIVE_EVENT}`.replace(
                '{eventCode}',
                eventCode
            ),
            {},
            httpOptions
        );
    }

    public putUnarchiveEvent(eventCode: string) {
        const httpOptions = { observe: 'response' as const };

        return this.http.put(
            `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_PUT_UNARCHIVE_EVENT}`.replace(
                '{eventCode}',
                eventCode
            ),
            {},
            httpOptions
        );
    }

    public allowAllDomainsForEvent(eventId: string, body: any) {
        const url = `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_PUT_UPDATE_ALLOW_ALL_DOMAINS}`.replace(
            '{eventId}',
            eventId
        );
        return this.http.put(url, body);
    }

    public updateProfilePhoto(formData: FormData): Observable<any> {
        return this.http.put(`${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_PROFILE_PHOTO}`, formData);
    }

    public updatePassword(oldPassword: string, newPassword: string): Observable<any> {
        return this.http.put(`${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_UPDATE_PASSWORD}`, {
            oldPassword,
            newPassword,
        });
    }

    public getNewInstitutions(): Observable<NewInstitution[]> {
        return this.http.get<NewInstitution[]>(this.SERVICE_BASE_URL + '/new-institutions').pipe(
            map((institutions: any[]): NewInstitution[] => {
                return institutions.map((institution: any) => {
                    return {
                        ...institution,
                        id: institution.institutionId,
                        fullName: institution.name,
                        ssoEnabled: institution.ssoEnabled,
                        allowSSOSubdomains: institution.allowSSOSubdomains,
                    };
                });
            })
        );
    }

    public getComments(posterId: number, hash: any, eventCodeOrHash: string): Observable<Comment[]> {
        let url = `${this.SERVICE_BASE_URL}/comments?eventCodeOrHash=${eventCodeOrHash}&posterId=${posterId}`;
        if (posterId !== hash && !isNil(hash)) {
            url = `${url}&hash=${hash}`;
        }
        return this.http.get<Comment[]>(url).pipe(
            map((comments: any) => {
                return comments.map((topLevelComment: any) => {
                    return this.mapToCommentObject(topLevelComment);
                });
            })
        );
    }

    mapToCommentObject(rawComment: any) {
        return {
            consumerId: rawComment.consumerId,
            consumerFirstName: rawComment.firstName,
            consumerLastName: rawComment.lastName,
            commentId: rawComment.commentId,
            comment: nl2br(rawComment.comment),
            commentTimeStamp: rawComment.createDate,
            commentIsDeleted: !rawComment.deleteDate && !rawComment.hiddenByAdminDate ? false : true,
            commentIsFlagged: !!rawComment.flaggedByUserDate,
            childComments: rawComment.comments
                ? rawComment.comments.map(comment => this.mapToCommentObject(comment))
                : [],
        };
    }

    // authentication calls
    public getUserFromToken() {
        return this.http.get<CurrentUser>(`${this.SERVICE_BASE_URL}/authentication/user`);
    }

    public refreshToken(refreshToken: string) {
        return this.http.post(`${this.SERVICE_BASE_URL}/authentication/refresh`, {
            refreshToken,
        });
    }

    public postComment(body: CommentPayload) {
        return this.http.post(`${this.SERVICE_BASE_URL}/comments`, body);
    }

    public flagComment(body: any) {
        return this.http.post(`${this.SERVICE_BASE_URL}/flagComment`, body);
    }

    public postReply(parentCommentId: number, body: CommentPayload) {
        return this.http.post(`${this.SERVICE_BASE_URL}/${parentCommentId}/reply`, body);
    }

    public deleteComment(body: any) {
        return this.http.post(`${this.SERVICE_BASE_URL}/deleteComment`, body);
    }

    public submit(formData: FormData) {
        return this.http.post(`${this.SERVICE_BASE_URL}/alternative-presentations`, formData);
    }

    public login(body: any): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${this.SERVICE_BASE_URL}/authentication/login`, body);
    }

    public signup(body: any) {
        return this.http.post<{ message: string; consumerId: number }>(
            this.SERVICE_BASE_URL + '/authentication/signup',
            body
        );
    }

    public institutionSignup(body: any) {
        return this.http.post(this.SERVICE_BASE_URL + '/authentication/signup-institution', body);
    }

    public register(body: RegisterPayload): Observable<RegisterResponse> {
        return this.http.post<RegisterResponse>(this.SERVICE_BASE_URL + '/authentication/register', body);
    }

    public forgotPassword(body: any) {
        return this.http.post(this.SERVICE_BASE_URL + '/authentication/forgot-password', body);
    }

    public sendConfirmationEmail(body: any) {
        return this.http.post(this.SERVICE_BASE_URL + '/authentication/signup', body);
    }

    public sendEmail(body: any) {
        return this.http.post(this.SERVICE_BASE_URL + '/pricing/sendemail', body);
    }

    public logout() {
        return this.http.post(this.SERVICE_BASE_URL + '/authentication/logout', {});
    }

    public verifyEmail(body: any) {
        return this.http.post(this.SERVICE_BASE_URL + '/authentication/verify-email', body);
    }

    public resendVerificationEmail(email: string) {
        return this.http.post(this.SERVICE_BASE_URL + '/authentication/resend-email', {
            email,
        });
    }

    public changePassword(body: any) {
        return this.http.post(this.SERVICE_BASE_URL + '/authentication/change-password', body);
    }

    public getGlobalFeatureFlags(): Observable<any> {
        return this.http.get<any[]>(`${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_GET_GLOBAL_FEATURE_FLAGS}`);
    }

    public addEmailsForEvent(eventId: string, emails: string[]): Observable<EventEmail[]> {
        const url = `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_POST_ADD_EVENT_EMAILS}`.replace(
            '{eventId}',
            eventId
        );
        return this.http.post<EventEmail[]>(url, { emails, eventId });
    }

    public deleteEmailFromEvent(eventId: number, eventEmailId: number): Observable<EventEmail[]> {
        const url = `${this.SERVICE_BASE_URL}${environment.SERVICE_ENDPOINT_DELETE_EVENT_EMAIL}`
            .replace('{eventId}', eventId.toString())
            .replace(`{eventEmailId}`, eventEmailId.toString());
        return this.http.delete<EventEmail[]>(url);
    }

    public getExportedPresentations(eventId: number) {
        return this.http.get<Array<Record<string, any>>>(
            this.SERVICE_BASE_URL + '/presentations/export?eventId=' + eventId
        );
    }

    public getPresentationVideoConferencing(eventId: number): Observable<any> {
        return this.http.get<any>(
            this.SERVICE_BASE_URL + '/live-sessions/presentations-video-conferencing?eventId=' + eventId
        );
    }

    public updatePresentationVideoConferencing(eventId: number, data: any): Observable<any> {
        return this.http.post<any>(
            `${this.SERVICE_BASE_URL}/live-sessions/presentations-video-conferencing/${eventId}`,
            data
        );
    }
}
