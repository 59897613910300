import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { DonationWidgetComponent } from './donation-widget.component';
import { MessageWidgetModule } from './message-widget/message-wiget.module';
import { PipesModule } from '@pipes';
import { DonationPopupComponent } from './donation-popup/donation-popup.component';
import { DonationThankYouComponent } from './donation-thank-you/donation-thank-you.component';
import { DonationWidgetSharedModule } from './shared/donation-widget-shared.module';
import { DonationComponent } from './donation-component/donation.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { PersonalInformationComponent } from './donation-component/presonal-information/personal-information.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../../shared';

@NgModule({
    imports: [
        MatTabsModule,
        CommonModule,
        PipesModule,
        MessageWidgetModule,
        DonationWidgetSharedModule,
        MatRadioModule,
        ReactiveFormsModule,
        MatInputModule,
        MatAutocompleteModule,
        MatIconModule,
        SharedModule,
    ],
    exports: [DonationWidgetComponent],
    declarations: [
        DonationWidgetComponent,
        DonationPopupComponent,
        DonationThankYouComponent,
        DonationComponent,
        PersonalInformationComponent,
    ],
    providers: [],
})
export class DonationWidgetModule {}
