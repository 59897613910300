import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-cancel',
    templateUrl: './delete-submission-addl-presenter-dialog.component.html',
    styleUrls: ['./delete-submission-addl-presenter-dialog.component.scss'],
})
export class DeleteSubmissionAddlPresenterDialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {}
}
