<div *ngIf="form">
    <p class="instructions" [innerHtml]="form.config.general.instructions" *ngIf="!isWrapped"></p>

    <div *ngIf="form.config.extraFields">
        <div *ngFor="let extraField of form.config.extraFields; index as extraFieldIdx">
            <div *ngIf="extraField.isDisplayed && validateConditionals(extraField.conditionalQuestionAnswerPairs)">
                <app-form-preview-input-field
                    *ngIf="inputTypes.includes(extraField.type)"
                    [uniqueFieldKey]="'presentation_extra_' + extraFieldIdx"
                    [label]="extraField.label"
                    [form]="form"
                    [field]="extraField"
                    (onFileSelect)="onFileSelect($event)"
                    [isRequired]="extraField.isRequired"
                    [isShownInSummary]="extraField.isShownInSummary"
                    [description]="extraField.description"
                    [type]="extraField.type"
                    [quillModulesConfig]="quillModulesGeneralConfig"
                    [data]="formValues"
                    [dataFieldName]="extraField.hash"
                    [editorStatus]="editorStatus"
                    [formErrs]="formErrs"
                    [isInputDisabled]="isDisabled"
                    (isClean)="onIsCleanUpdated($event)"
                ></app-form-preview-input-field>
                <app-form-preview-multi-select-field
                    *ngIf="['radio', 'checkbox'].includes(extraField.type)"
                    [uniqueFieldKey]="'presentation_extra_' + extraFieldIdx"
                    [label]="extraField.label"
                    [isRequired]="extraField.isRequired"
                    [isShownInSummary]="extraField.isShownInSummary"
                    [description]="extraField.description"
                    [type]="extraField.type"
                    [optionLabels]="extraField.optionLabels"
                    [options]="extraField.options"
                    [data]="formValues"
                    [dataFieldName]="extraField.hash"
                    [formErrs]="formErrs"
                    [isInputDisabled]="isDisabled"
                    (isClean)="onIsCleanUpdated($event)"
                ></app-form-preview-multi-select-field>
                <app-form-preview-likert-scale-field
                    *ngIf="['scale'].includes(extraField.type)"
                    [uniqueFieldKey]="'presentation_extra_' + extraFieldIdx"
                    [label]="extraField.label"
                    [isRequired]="extraField.isRequired"
                    [description]="extraField.description"
                    [type]="extraField.type"
                    [optionLabels]="extraField.optionLabels"
                    [data]="formValues"
                    [dataFieldName]="extraField.hash"
                    [formErrs]="formErrs"
                    (isClean)="onIsCleanUpdated($event)"
                ></app-form-preview-likert-scale-field>
            </div>
        </div>

        <p *ngIf="form.type === 'recruitment'">
            Upon submission, you will automatically be registered for this event. You will see your assigned
            presentation(s) to evaluate as a judge only after the event organizer has assigned them to you and the event
            organizer has launched the event.
        </p>
        <div
            class="submit-btn-container text-center"
            *ngIf="!isWrapped"
            [matTooltip]="isPreview ? 'You may not submit as this is only a preview.' : ''"
        >
            <button class="btn btn-primary" mat-raised-button [disabled]="isPreview || isDisabled" (click)="onSubmit()">
                {{ submitText }}
            </button>
        </div>
    </div>
</div>
