import { Pipe, PipeTransform } from '@angular/core';
import { FILE_UPLOAD_EXTENSIONS, UploadFileExtensions } from '@models';

@Pipe({
    name: 'fileExtension',
})
export class FileExtensionPipe implements PipeTransform {
    transform(input: string, isAdmin: boolean = false): string {
        const extension = FILE_UPLOAD_EXTENSIONS.find(type => type.value === input);
        let description = '';
        if (extension) {
            description = `(${extension.description})`;
        }
        // If the extension type is ALL and we're not in the admin form editor don't show the text `(no restriction)` to the user
        if (extension.value === UploadFileExtensions.ALL && !isAdmin) {
            description = '';
        }
        return description;
    }
}
