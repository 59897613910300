import { PageMetadata } from '@models';

export const DEFAULT_SEO_METADATA: PageMetadata = {
    title: 'Symposium By ForagerOne',
    description:
        "Symposium is the simplest way to host amazing academic events. We're an all-in-one virtual and hybrid events platform designed for research organizations.",
    author: 'Symposium',
    keywords: ['Symposium', 'Events', 'Virtual-Events', 'Research'], //add default keywords
    type: 'website',
    date: null,
    image: 'https://storage.googleapis.com/symposium-public-assets/social-card-linkedin.png',
    twitterImage: 'https://storage.googleapis.com/symposium-public-assets/social-card-twitter.png',
};
const PRICING_PAGE_METADATA: Partial<PageMetadata> = {
    ...DEFAULT_SEO_METADATA,
    title: 'Our Offerings',
    description: 'A variety of packages for individuals, departments, and organizations to meet a range of needs.',
};

const HOME_PAGE_METADATA: Partial<PageMetadata> = {
    ...DEFAULT_SEO_METADATA,
};

export type SeoPage = 'pricing' | 'home' | 'caseStudies' | null;
export const getMetadataByPage = (page: SeoPage) => {
    switch (page) {
        case 'pricing':
            return PRICING_PAGE_METADATA;
        case 'home':
            return HOME_PAGE_METADATA;
        case 'caseStudies':
            return DEFAULT_SEO_METADATA;
        default:
            return DEFAULT_SEO_METADATA;
    }
};
