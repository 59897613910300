import { Component, OnInit, ViewChild } from '@angular/core';
import { PresentationEntity } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { FG1NotificationService, PresentationService, SeoService } from '@services';
import { CompleteNgUnsubscribeComponent } from '@utils';
import { map, takeUntil } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { isPresentationCompleted } from '@functions';

@Component({
    selector: 'app-my-submissions',
    templateUrl: './my-submissions.component.html',
    styleUrls: ['./my-submissions.component.scss'],
})
export class MySubmissionsComponent extends CompleteNgUnsubscribeComponent implements OnInit {
    public displayedColumns: Array<any> = [
        // Button
        'editDeleteControls',
        // Title + Submitted Date
        'createDate',
        'title',
        // Event related data
        'event',
        'areRequiredFieldsCompleted',
        'stageStatus',
    ];

    public dataSource: MatTableDataSource<PresentationEntity>;
    public loading: boolean;
    public presentations: PresentationEntity[];
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private presentationService: PresentationService,
        private router: Router,
        private fg1NotificationService: FG1NotificationService,
        private seoService: SeoService
    ) {
        super();
    }

    ngOnInit() {
        this.loadPresentations();
        this.seoService.addTags({
            title: 'My Submissions',
        });
    }

    loadPresentations() {
        this.loading = true;
        this.presentationService
            .getMyPresentations()
            .pipe(
                map(({ presentations }) =>
                    presentations.map(presentation => {
                        return {
                            ...presentation,
                            completed: isPresentationCompleted(presentation, {}, presentation.event, true),
                        };
                    })
                )
            )
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(
                presentations => {
                    this.presentations = presentations;
                    this.dataSource = new MatTableDataSource(presentations);
                    this.loading = false;
                },
                err => {
                    this.fg1NotificationService.serverError(err);
                    this.loading = false;
                }
            );
    }

    public onEditPresentation(presentation: PresentationEntity) {
        return this.router.navigate([presentation.event.eventCode, 'submissions', presentation.presentationId]);
    }
}
