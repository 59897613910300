<div id="content-container">
    <div id="container-wrapper">
        <div class="side-image">
            <img [src]="photo" alt="" />
        </div>
        <div class="tabs-container">
            <div class="tabs-container__container">
                <ng-content> </ng-content>
            </div>
        </div>
    </div>
</div>
