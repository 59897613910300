import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-unfinished-changes-dialog',
    templateUrl: './unfinished-changes-dialog.component.html',
    styleUrls: ['./unfinished-changes-dialog.component.scss'],
})
export class UnfinishedChangesDialogComponent implements OnInit {
    title = 'You have unsaved changes.';

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.title) {
            this.title = data.title;
        }
    }

    ngOnInit() {}
}
