<div class="row">
    <div class="col">
        <mat-label class="label">{{ label || field.label }}<span *ngIf="required" class="required-star">*</span>
        </mat-label>
        <p class="description">{{ description }}</p>
        <app-upload-file [disabled]='disabled' [existingFilename]="existingFilename" [key]="key" (onFileSelected)="fileSelected($event)"
            (onUploadFinished)="uploadFinished($event)" (onUploadInProgress)="uploadInProgress($event)"
            [bucket]="assetsBucket" [extension]="extensions.VIDEO" [event]="event"></app-upload-file>
    </div>
</div>
