export interface GlobalFeatureFlag {
    id: number;
    name: GlobalFeatureFlagEnum;
    consumers: number[];
    releaseToAll: boolean;
}

export enum GlobalFeatureFlagEnum {
    EpaPortal = 'epa_portal', // Enterprise admin portal (Had to rename weirdly because of a weird bug)
    TestPricing = 'test_pricing',
    Analytics = 'analytics',
    Judging = 'judging',
    PresentationsExport = 'presentations_export',
    Networking = 'networking',
    Donations = 'donations',
    AbstractBooklet = 'abstract_booklet',
    Registration = 'registration',
    LiveSessions = 'live-sessions',
    VideoUpload = 'video-upload',
    ABSTRACT = 'abstract',
}
