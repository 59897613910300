import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, zip } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { AppService, AuthenticationService } from '@services';
import { CurrentUser, Event } from '@models';
import { getEventCodeFromParams } from '@functions';

@Injectable()
export class JudgeEventGuard implements CanActivate {
    constructor(
        private appService: AppService,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
        const eventCodeOrHash = getEventCodeFromParams(next) || next.url.slice(-1)[0].toString();

        return zip(
            this.authenticationService.getCurrentUser().pipe(first()),
            this.appService.getEventIfChanged(eventCodeOrHash).pipe(first())
        ).pipe(
            switchMap(([currentUser, event]: [CurrentUser, Event]) => {
                const consumerEvent = currentUser.consumerEvents[event.eventId];
                return of(consumerEvent && (consumerEvent.isJudge || consumerEvent.isAbstractJudge));
            }),
            catchError(err => {
                console.log('err: ' + err);
                this.router.navigate(['/'], { replaceUrl: true });
                return of(false);
            })
        );
    }
}
