import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, zip } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { AppService, AuthenticationService, FG1NotificationService } from '@services';
import { getEventCodeFromParams } from '@functions';

const PAGE_NOT_FOUND_NOTIF = 'We could not find the page you requested.';
const EVENT_DEACTIVATED_NOTIF = 'This event has been deactivated.  For more information, please contact support.';

@Injectable()
export class EventAdminGuard implements CanActivate {
    constructor(
        private appService: AppService,
        private fg1NotificationService: FG1NotificationService,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
        const eventCodeOrHash = getEventCodeFromParams(next) || next.url.slice(-1)[0].toString();

        return zip(
            this.authenticationService.getCurrentUser().pipe(first()),
            this.appService.getEventIfChanged(eventCodeOrHash).pipe(first())
        ).pipe(
            switchMap(zipped => {
                const [currentUser, event] = zipped;
                const isAdminOfEvent = currentUser?.adminEventIds?.includes(event.eventId);
                const isAdminOfSubscription = currentUser?.subscriptionIds?.includes(event.subscriptionId);

                // refresh currentUser if the current event is not listed as an admin when sharing the same subscription
                if (isAdminOfSubscription && !isAdminOfEvent) {
                    return zip(this.authenticationService.refreshUserFromToken().pipe(first()), of(event));
                }
                return zip(of(currentUser), of(event));
            }),
            switchMap(zipped => {
                const [currentUser, event] = zipped;
                const isAdminOfEvent = currentUser?.adminEventIds?.includes(event.eventId);

                this.appService.setEvent(event);

                // check if event has been disabled by support
                if (!event.isActivated && !isAdminOfEvent) {
                    // admin, specific message to contact support
                    // if (currentUser && currentUser.adminEventIds && currentUser.adminEventIds.includes(event.eventId)) {
                    //   // this.fg1NotificationService.warn(EVENT_DEACTIVATED_NOTIF, 'Contact Support');
                    //   return true;
                    // } else {
                    //   //not admin, generic redirect msg
                    //   this.fg1NotificationService.warn(PAGE_NOT_FOUND_NOTIF, 'Page Not Found');
                    // }
                    this.router.navigate(['/'], { replaceUrl: true });
                    return of(false);
                }

                // check if user is event admin
                if (isAdminOfEvent) {
                    // -- GUARD PASS --

                    // navigate to custom home if splash content exists
                    if (event.splashCoverImgName || event.splashVideoLink || event.splashContent) {
                        const finalSegment =
                            next.url[next.url.length - 1].toString().toLowerCase() === eventCodeOrHash
                                ? 'custom-home'
                                : next.url[next.url.length - 1].toString();
                        if (finalSegment === 'custom-home') {
                            this.router.navigate(['/', eventCodeOrHash, finalSegment], {
                                skipLocationChange: true,
                            });
                        }
                    }
                    return of(true);
                }

                // not admin, generic redirect msg
                this.fg1NotificationService.warn(PAGE_NOT_FOUND_NOTIF, 'Page Not Found');
                this.router.navigate(['/'], { replaceUrl: true });
                return of(false);
            }),
            catchError(err => {
                console.log('err: ' + err);
                this.fg1NotificationService.warn(PAGE_NOT_FOUND_NOTIF, 'Page Not Found');
                this.router.navigate(['/'], { replaceUrl: true });
                return of(false);
            })
        );
    }
}
