import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PresentationJudges, PresentationService } from '@services';
import { Event, PresentationData, PresentationFormData, PresenterData } from '@models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-presentation-preview',
    templateUrl: './presentation-preview.component.html',
    styleUrls: ['./presentation-preview.component.scss'],
})
export class PresentationPreviewComponent implements OnInit, OnDestroy {
    @Input() currentEvent: Event;
    public presentation: PresentationJudges;
    public isOpened: boolean;
    public presenterData: PresentationFormData;
    private ngUnsubscribe$ = new Subject();

    constructor(private cdr: ChangeDetectorRef, private presentationService: PresentationService) {}

    ngOnInit() {
        this.presentationService
            .getPreviewStream()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(presentation => {
                this.presentation = null;
                this.cdr.detectChanges();
                this.presenterData = this.updateSelectedPresFormValues(presentation);
                this.presentation = presentation;
                this.cdr.detectChanges();
                this.isOpened = !!presentation;
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    public hide() {
        this.presentationService.closePreview();
    }

    // copied from: presentations/event-admin-dash-presentations.component.ts
    public updateSelectedPresFormValues(presentation: PresentationJudges) {
        if (!presentation) {
            return;
        }
        const formValues = {
            presenterData: [],
            presentationData: {
                title: presentation.title,
                abstract: presentation.abstract,
                mentor: presentation.mentor,
                subjects: presentation.subjects,
                extraValues: presentation.extraValues ? presentation.extraValues.presentation : {},
            } as PresentationData,
        } as PresentationFormData;

        // add presenters
        formValues.presenterData.push(
            ...presentation.presenters.map((presenter, idx) => {
                // add extra values if any
                return {
                    firstName: presenter.firstName,
                    lastName: presenter.lastName,
                    email: presenter.email,
                    level: presenter.level,
                    major: presenter.major,
                    extraValues:
                        presentation.extraValues && presentation.extraValues.presenters[idx]
                            ? presentation.extraValues.presenters[idx]
                            : {},
                } as PresenterData;
            })
        );

        return formValues;
    }
}
