export interface Subscription {
    subscriptionId: number;
    superAdminId: number;
    lastUpdated: string;
    deleteDate: string;
    eventsLeft: number;
    organizationId?: number;
    organization?: string;
    description: string;
    institutionId: number;
    startDate: string;
    stripeSubscriptionId: string;
    createDate: string;
    confirmationDate: string;
    endDate: string;
    subscriptionTier?: SubscriptionTier;
}

export enum BillingType {
    SINGLE_EVENT = 'single',
    RECURRING = 'recurring',
}

export enum ClientSector {
    EDUCATION = 'education',
    CORPORATION = 'corporation',
    PROFESSIONAL = 'professional',
}

export enum PlanType {
    LITE = 'lite',
    PLUS = 'plus',
    ENTERPRISE = 'enterprise',
    PREMIUM = 'premium',
    PROFESSIONAL_SINGLE = 'professionalSingle',
    PROFESSIONAL_ANNUAL = 'professionalAnnual',
}

export interface SubscriptionTier {
    subscriptionTiersId?: number;
    name: string;
    description: string;
    billingType: BillingType;
    clientSector: ClientSector;
    planType: PlanType;
    eventsNumberLimit: number;
    canArchiveEvent: boolean;
    numAdminAccounts: number;
    eventUptimeInDays: number;
    presentationsLimit: number;
    creditsLimit: number;
    attendeesLimit: number;
    editCoverPhoto: boolean;
    editEventLogo: boolean;
    editSplashScreen: boolean;
    editPresentation: boolean;
    enableAbstract: boolean;
    enabledJudging: boolean;
    accessCustomSubmissionFormEditor: boolean;
    accessAnalytics: boolean;
    createDate: string;
    lastUpdated: string;
    deleteDate: string;
    networkingHoursLimit: number;
}

export interface StripeRecurring {
    aggregate_usage: null;
    interval: 'year';
    interval_count: 1;
    trial_period_days: null;
    usage_type: 'licensed';
}

export enum BillingType {
    recurring = 'recurring',
    single = 'single',
}

export interface StripePlan {
    id: string;
    object: string;
    active: boolean;
    billing_scheme: string;
    created: number;
    currency: string;
    livemode: boolean;
    lookup_key: string;
    metadata: {
        plan_type: 'lite' | 'plus' | 'premium' | 'professionalSingle' | 'professionalAnnual';
    };
    nickname: string;
    product: string;
    recurring: StripeRecurring;
    tiers_mode: any;
    transform_quantity: any;
    type: 'one_time' | 'recurring';
    unit_amount: number;
    unit_amount_decimal: string;
    feeId?: string;
}

export interface BillingPlan {
    lite?: StripePlan;
    plus?: StripePlan;
    premium?: StripePlan;
    professionalSingle?: StripePlan;
    professionalAnnual?: StripePlan;
}

export interface PlansResponse {
    single: BillingPlan;
    recurring: BillingPlan;
}

export type SubscriptionTierResponse = SubscriptionTier & StripePlan;

export interface SubscriptionTierPlan {
    lite?: SubscriptionTierResponse;
    plus?: SubscriptionTierResponse;
    premium?: SubscriptionTierResponse;
    professionalSingle?: SubscriptionTierResponse;
    professionalAnnual?: SubscriptionTierResponse;
}

export interface SubscriptionTierBillingType {
    recurring: SubscriptionTierPlan;
    single: SubscriptionTierPlan;
}

export interface SubscriptionAddons {
    subscriptionAddonsId: number;
    subscriptionId: number;
    createDate: string;
    lastUpdated: string;
    deleteDate: string;
}

export interface SubscriptionQuota {
    subscription: SubscriptionWithTier;
    canPurchaseNewPackage: boolean;
    current: {
        events: number;
        presentations: number;
        uptimeInDays: number;
        liveSessions: number;
        adminAccounts: number;
    };
    left: {
        events: number;
        presentations: number;
        uptimeInDays: number;
        liveSessions: number;
        adminAccounts: number;
    };
}

export type SubscriptionWithTier = Subscription & SubscriptionTier;

export interface SubscriptionEmail {
    subscriptionEmailId: number;
    subscriptionAdminId: number;
    subscriptionId: number;
    superAdminId: number;
    email: string;
    adminConsumerId?: number;
    createDate: string;
    lastUpdated: string;
    verifiedAt?: string;
    firstName?: string;
    lastName?: string;
}

export interface SubscriptionAdmin {
    subscriptionAdminId?: number;
    consumerId: number;
    subscriptionId: number;
    isSuperAdmin: boolean;
    createDate?: string;
    lastUpdated?: string;
    deleteDate?: string;
}
