<div class="avatar-container" [class.not-clickable]='!isClickable'>
    <img *ngIf="photo" [src]="photo" mat-card-avatar [ngStyle]="styles" class="avatar" [alt]="alt" />
    <mat-icon
        class="avatar placeholder-avatar"
        [ngStyle]="{ 'background-image': photo }"
        mat-card-avatar
        *ngIf="!photo"
        aria-hidden="false">
        person
    </mat-icon>
</div>
