import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { TWO_MINUTES_CACHE } from '@constants';
import { Asset, Form } from '@models';
import { toHttpParams } from '@functions';
import { Observable, Subject } from 'rxjs';
import { environment } from '@env';
import { filter, map } from 'rxjs/operators';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { InterceptorSkipHeader } from './interceptors';

const assetCacheBuster$ = new Subject<void>();

export interface GenerateSignedURLOptions {
    action: 'read' | 'write';
    contentType?: string;
    ttlSeconds?: number;
}
@Injectable({
    providedIn: 'root',
})
export class AssetService {
    url: string = `${environment.SERVICE_BASE_URL}/assets`;

    constructor(private http: HttpClient) {}

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: 1000,
        cacheBusterObserver: assetCacheBuster$,
    })
    getAssetByName(name: string, includeURL = false) {
        return this.http.get<Asset>(this.url, { params: toHttpParams({ name, includeURL }) });
    }

    @CacheBuster({
        cacheBusterNotifier: assetCacheBuster$,
    })
    public upload(eventId: number, file: File, form?: Form): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        if (form) {
            formData.append('formId', String(form.formId));
        }
        return this.http
            .post<any>(`${environment.SERVICE_BASE_URL}/events/${eventId}/files`, formData, {
                observe: 'response',
            })
            .pipe(map(res => res.body));
    }

    public generateSignedURL(
        name: string,
        bucket: string,
        options: GenerateSignedURLOptions = {
            action: 'read',
            ttlSeconds: 300,
        }
    ) {
        return this.http.post<{ url: string; ttlSeconds: number; contentType: string }>(`${this.url}/sign`, {
            ...options,
            name,
            bucket,
        });
    }

    public uploadToSignedURL(url: string, file: File): Observable<{ progress: number; done: boolean }> {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, 'true');
        return this.http.put(url, file, { headers, reportProgress: true, observe: 'events' }).pipe(
            filter(event => event.type === HttpEventType.UploadProgress),
            //@ts-ignore
            map((event: { loaded: number; total: number; type: HttpEventType }) => {
                const progress = Math.round((event.loaded / event.total) * 100);
                const done = event.loaded === event.total;
                return {
                    progress,
                    done,
                };
            })
        );
    }

    public createAsset(asset: Partial<Asset>) {
        return this.http.post<Asset>(this.url, asset);
    }

    public deleteAsset(id: number) {
        return this.http.delete<Asset>(`${this.url}/${id}`);
    }
}
