import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';
import { Event, Institution, NewInstitution, RolloutFeatureFlag } from '@models';
import { dequal } from 'dequal';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { HttpService } from './http.service';
import { RolloutFeatureFlagService } from './rollout-feature-flag.service';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    // This is the current institution being viewed.
    private institution$: BehaviorSubject<Institution> = new BehaviorSubject<Institution>(null);
    private newInstitutions$ = new BehaviorSubject<NewInstitution[]>([]);
    private rolloutShows$ = new BehaviorSubject<RolloutFeatureFlag[]>([]);

    // current event being viewed
    private event$: BehaviorSubject<Event> = new BehaviorSubject<Event>(null);
    private loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // Homepage scrolling for judging, tables, and donations features
    private scrolledFeature$: BehaviorSubject<String> = new BehaviorSubject<String>(null);

    // unique visitorId retrieved by fingerprint.js
    private visitorId: string;
    private consumerId: number;

    constructor(
        private httpService: HttpService,
        private rolloutFFService: RolloutFeatureFlagService,
        private titleService: Title
    ) {
        this.fetchNewInstitutions();
        this.fetchRolloutFeatureFlags();
    }

    private async loadVisitorId() {
        // We recommend to call `load` at application startup.
        const fp = await FingerprintJS.load();

        // The FingerprintJS agent is ready.
        // Get a visitor identifier when you'd like to.
        const result = await fp.get();

        // This is the visitor identifier:
        this.visitorId = result.visitorId;
    }

    async fetchRolloutFeatureFlags() {
        if (!this.visitorId) {
            await this.loadVisitorId();
        }

        this.rolloutFFService
            .getRolloutFeatureFlags(this.visitorId, this.consumerId)
            .subscribe(response => this.rolloutShows$.next(response));
    }

    fetchNewInstitutions() {
        this.httpService.getNewInstitutions().subscribe(response => this.newInstitutions$.next(response));
    }

    public setInstitution(institution: Institution) {
        this.institution$.next(institution);
        this.titleService.setTitle(
            institution ? `${institution.fullName} | Symposium by ForagerOne` : 'Symposium by ForagerOne'
        );
    }

    public getInstitution() {
        return this.institution$;
    }

    public getNewInstitutions() {
        return this.newInstitutions$;
    }

    public getEvent(emitTruthyEventsOnly: boolean = false) {
        return !emitTruthyEventsOnly ? this.event$ : this.event$.pipe(filter(e => !!e));
    }

    public getVisitorId() {
        return this.visitorId;
    }

    public getConsumerId() {
        return this.consumerId;
    }

    public setConsumerId(consumerId: number) {
        this.consumerId = consumerId;
    }

    public getRolloutFeatureFlags() {
        return this.rolloutShows$;
    }

    public getEventIfChanged(eventCode: string) {
        if (this.event$.value && this.event$.value.eventCode === eventCode) {
            return this.event$;
        }

        // event changed, get from http service
        const updatedEventSubject = new Subject<Event>();
        this.httpService
            .getFindEvent(eventCode)
            .pipe(map(res => res.body as Event))
            .subscribe(updatedEventSubject);
        return updatedEventSubject;
    }

    public setEvent(event: Event): void {
        const currentEvent = this.event$.getValue();

        // Update event only if the actual event data is updated
        const isEventDataEqual = dequal(currentEvent, event);

        if (!isEventDataEqual) {
            this.event$.next(event);
        }
    }

    private clearEvent() {
        this.setEvent(null);
    }

    public getScrolledFeature(): any {
        return this.scrolledFeature$.pipe(delay(200));
    }

    public setScrolledFeature(feature: string) {
        this.scrolledFeature$.next(feature);
    }

    get loading() {
        return this.loading$.asObservable();
    }
    public setLoading(loading = true) {
        return this.loading$.next(loading);
    }
    public showLoading() {
        return this.loading$.next(true);
    }
    public hideLoading() {
        return this.loading$.next(false);
    }
}
