import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DonationConfig } from '@models';

@Component({
    selector: 'app-donation-thank-you',
    templateUrl: './donation-thank-you.component.html',
    styleUrls: ['./donation-thank-you.component.scss'],
})
export class DonationThankYouComponent {
    @Output() submitted = new EventEmitter<boolean>();
    @Input() donationConfig: DonationConfig;

    constructor() {}

    public makeAnotherGift() {
        this.submitted.emit(true);
    }
}
