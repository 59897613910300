import { Component, OnInit } from '@angular/core';
import { CompleteNgUnsubscribeComponent } from '@utils';
import { DonationConfigService } from '@services';
import { DonationConfig } from '@models';
import { takeUntil } from 'rxjs/operators';
import { environment } from '@env';

@Component({
    selector: 'app-donation-widget-wrapper',
    templateUrl: './donation-widget-wrapper.component.html',
    styleUrls: ['./donation-widget-wrapper.component.scss'],
})
export class DonationWidgetWrapperComponent extends CompleteNgUnsubscribeComponent implements OnInit {
    donationConfig: DonationConfig;

    constructor(private donationConfigService: DonationConfigService) {
        super();
    }

    ngOnInit() {
        this.donationConfigService
            .donationConfig()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(({ donationConfig }) => {
                this.donationConfig = donationConfig;
            });
    }

    get photo() {
        return this.donationConfig && this.donationConfig.photo
            ? `${environment.EVENT_ASSETS_REMOTE_PATH}${this.donationConfig.photo}`
            : '/assets/images/donations_demo_cmp.png';
    }
}
