import { ExpertiseArea } from './judge';

export interface Consumer {
    consumerId: number;
    firstName: string;
    lastName: string;
    fullName?: string;
    email: string;
    major: string;
    year: number;
    institutionId: number;
    adminEventIds: Array<number>;
    createDate: string;
    lastUpdated: string;
    lastLoginDate: string;
    signedUpDate: string;
    profileImgName: string;
    subscriptionIds: number[];
    isTestAccount: boolean;
    consumerEvents: ConsumerEvent[];
    expertiseAreas: ExpertiseArea[];
}

export interface ConsumerEvent {
    consumerId: number;
    eventId: number;
    isAdmin: boolean;
    isJudge: boolean;
    isAbstractJudge: boolean;
    registrationMetadata: Record<string, any>;
    createDate: Date | string;
    lastUpdated: Date | string;
}

export function getConsumerNames(consumers: Consumer[]): string[] {
    return consumers.map(consumer => `${consumer.firstName} ${consumer.lastName}`);
}
