<div class="container">
    <div class="row">
        <button *ngIf="!hideChoosePhotoOption" (click)="imageInput.click()" class="btn btn-primary upload">
            Choose Photo
        </button>
        <input
            #imageInput
            type="file"
            accept="image/png,image/gif,image/jpeg"
            (change)="fileSelectedEvent($event)"
            hidden
        />
        <image-cropper
            format="png"
            [imageChangedEvent]="fileSelected"
            [imageFile]="config.imageFile"
            [maintainAspectRatio]="config.maintainAspectRatio"
            [imageBase64]="config.imageBase64"
            [aspectRatio]="config.customAspectRatio || config.shape"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
    </div>
    <mat-divider></mat-divider>
    <div class="preview-container" *ngIf="cropped">
        <h5>Preview</h5>
        <img class="preview" [src]="cropped.base64" alt="" />
    </div>

    <div class="row pt-4">
        <div class="col actions">
            <button class="btn btn-secondary" mat-dialog-close>Cancel</button>
            <button class="btn btn-primary" [disabled]="!canCrop" (click)="crop()">Crop</button>
        </div>
    </div>
</div>
