import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { HttpService } from './http.service';
import { catchError, finalize, map, take, tap } from 'rxjs/operators';
import { CurrentUser, IsAllowedToRegister } from '@models';
import { SubscriptionService } from './subscription.service';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { toHttpParams } from '@functions';
import { globalCacheBusterNotifier } from 'ts-cacheable';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private readonly currentUser$: ReplaySubject<CurrentUser>;
    public currentUser: Observable<CurrentUser>;
    private url: string = `${environment.SERVICE_BASE_URL}/authentication`;

    constructor(
        public httpService: HttpService,
        public http: HttpClient,
        public subscriptionService: SubscriptionService
    ) {
        this.currentUser$ = new ReplaySubject<CurrentUser>(1);
        this.currentUser = this.currentUser$.asObservable();
    }

    public getCurrentUser(): ReplaySubject<CurrentUser> {
        return this.currentUser$;
    }

    public clearCurrentUser() {
        this.currentUser$.next(null);
    }

    public setCurrentUser(user: CurrentUser) {
        this.subscriptionService.getSubscriptionEmails();
        this.subscriptionService.getSubscriptions();
        this.currentUser$.next({
            userId: +user.userId,
            ...user,
        });
    }

    //@todo for debugging. need to refactor
    public refreshUserFromToken() {
        return this.httpService.getUserFromToken().pipe(
            tap(
                response => this.currentUser$.next(response),
                () => this.clearCurrentUser()
            )
        );
    }

    public getUserFromToken() {
        this.httpService.getUserFromToken().subscribe(
            (response: CurrentUser) =>
                response.message
                    ? this.clearCurrentUser()
                    : this.setCurrentUser({
                          ...response,
                      }),
            err => this.clearCurrentUser()
        );
    }

    public updateToken(newToken: string) {
        localStorage.setItem('token', newToken);
    }

    public getToken() {
        return localStorage.getItem('token');
    }

    public isAllowedToRegister(eventId: number, consumerId: number): Observable<IsAllowedToRegister> {
        return this.http.get<IsAllowedToRegister>(`${this.url}/isAllowedToRegister`, {
            params: toHttpParams({
                eventId,
                consumerId,
            }),
        });
    }

    public refreshToken(refreshToken: string) {
        return this.httpService.refreshToken(refreshToken).pipe(
            tap((response: any) => {
                if (!response.message) {
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('refresh', response.refresh);
                }
            }),
            catchError(err => of([]))
        );
    }

    public addNewAdminEventId(eventId: number) {
        return this.getCurrentUser().pipe(
            take(1),
            map(response => {
                this.setCurrentUser({
                    ...response,
                });
            })
        );
    }

    public logout() {
        this.httpService
            .logout()
            .pipe(finalize(() => globalCacheBusterNotifier.next()))
            .subscribe(response => {
                localStorage.removeItem('token');
                localStorage.removeItem('refresh');
                globalCacheBusterNotifier.next();
                this.currentUser$.next(null);
            });
    }

    public getAssertAuthCookie() {
        const toCookie = (keyVal: string): any => {
            const [key, value] = keyVal.split('=');
            return { key, value: decodeURIComponent(value) };
        };
        const cookies: any[] = document.cookie.split('; ').map(toCookie);

        const assertAuth = cookies.find(x => x.key === 'assert-auth' && x.value.length > 0);
        let auth: any = null;
        try {
            auth = JSON.parse(assertAuth.value.replace('j:', ''));
        } catch (er) {}
        return auth;
    }

    public deleteAssertAuthCookie() {
        const daysInThePastToExpireCookie = 10;
        let expires = '';
        const date = new Date();
        date.setTime(date.getTime() + -daysInThePastToExpireCookie * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;

        document.cookie = `${'assert-auth'}=${expires}; path=/`;
    }
}
