import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import * as moment from 'moment-timezone';

import { AppService } from '@services';
import { Event } from '@models';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    event: Event;
    currentYear: string;

    constructor(private appService: AppService, public router: Router) {}

    ngOnInit() {
        this.currentYear = moment.utc().local().format('YYYY');
        this.appService
            .getEvent()
            .pipe(filter(event => !!event))
            .pipe(first())
            .subscribe(event => (this.event = event));
    }

    getTermsOfServiceRouterLink() {
        return this.event ? [this.event.eventCode, 'terms-of-service'] : ['terms-of-service'];
    }

    getPrivacyPolicyRouterLink() {
        return this.event ? [this.event.eventCode, 'privacy-policy'] : ['privacy-policy'];
    }
}
