import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, zip } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import {
    AppService,
    AuthenticationService,
    FG1NotificationService,
    HttpService,
    PreviousRouteService,
} from '@services';
import { Event, PrivacyLevel } from '@models';
import { getEventCodeFromParams, shouldShowWelcomePage } from '@functions';
import moment from 'moment';
import { get as _get } from 'lodash';

const PAGE_NOT_FOUND_NOTIF = 'We could not find the page you requested.';
const PRIVATE_SYMPOSIUM_NOTIF = 'This is a private event. Please sign up using an authorized email to participate.';
const CURRENTLY_BLOCKED_NOTIF =
    'You have been blocked by the administrators of this Symposium from accessing this Symposium. ' +
    'Please contact them if you wish to regain access.';

@Injectable()
export class EventGuard implements CanActivate {
    constructor(
        private appService: AppService,
        private prevRouteService: PreviousRouteService,
        private fg1NotificationService: FG1NotificationService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private httpService: HttpService
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
        const eventCodeOrHash = getEventCodeFromParams(next) || next.url.slice(-1)[0].toString();
        const urlSegment = [...next.parent.url, ...next.url];
        const url = urlSegment[urlSegment.length - 1];

        // flag used to load the event without checking the user registration for the event
        if (next.data.skipRegistrationCheck) {
            this.appService
                .getEventIfChanged(eventCodeOrHash)
                .pipe(first())
                .subscribe((event: Event) => this.appService.setEvent(event));
            return true;
        }

        return zip(
            this.authenticationService.getCurrentUser().pipe(first()),
            this.appService.getEventIfChanged(eventCodeOrHash).pipe(first())
        ).pipe(
            switchMap(zipped => {
                const [currentUser, event] = zipped;
                const isAdminOfEvent = currentUser?.adminEventIds?.includes(event.eventId);
                const isAdminOfSubscription = currentUser?.subscriptionIds?.includes(event.subscriptionId);

                // refresh currentUser if the current event is not listed as an admin when sharing the same subscription
                if (isAdminOfSubscription && !isAdminOfEvent) {
                    return zip(this.authenticationService.refreshUserFromToken().pipe(first()), of(event));
                }
                return zip(of(currentUser), of(event));
            }),
            switchMap(zipped => {
                const [currentUser, event] = zipped;
                const isAdminOfEvent = currentUser?.adminEventIds?.includes(event.eventId);

                const presentationsIsAbstractReviewerFor = currentUser?.abstractPresentations?.find(
                    abstractPresentation => abstractPresentation.eventId === event.eventId
                )?.presentations;
                const isSinglePresentationPage = next.data.isSinglePresentationPage;

                // update app service with found event
                this.appService.setEvent(event);

                // ensures that this logic is run only under `/${eventCode}` route, not `/${eventCOde}/presentations`
                if (urlSegment.length === 1 && !shouldShowWelcomePage(event) && event.presentationsCount > 0) {
                    return of(this.router.parseUrl(`/${eventCodeOrHash}/presentations`));
                }

                if (
                    !(
                        isAdminOfEvent ||
                        (isSinglePresentationPage &&
                            presentationsIsAbstractReviewerFor &&
                            presentationsIsAbstractReviewerFor.includes(Number(next.params.id)))
                    ) &&
                    !this.isEventLaunched(event) &&
                    !next.data.skipLaunchedCheck
                ) {
                    this.router.navigate([eventCodeOrHash, 'registration'], {
                        replaceUrl: true,
                    });
                    return of(false);
                }

                // check if event has been disabled by support
                if (!event.isActivated) {
                    // admin, specific message to contact support
                    // if (currentUser && currentUser.adminEventIds && currentUser.adminEventIds.includes(event.eventId)) {
                    //   // this.fg1NotificationService.warn(EVENT_DEACTIVATED_NOTIF, 'Contact Support');
                    //   return true;
                    // } else {
                    //   //not admin, generic redirect msg
                    //   this.fg1NotificationService.warn(PAGE_NOT_FOUND_NOTIF, 'Page Not Found');
                    // }
                    // not admin, generic redirect msg
                    this.fg1NotificationService.warn(PAGE_NOT_FOUND_NOTIF, 'Page Not Found');
                    this.router.navigate(['/'], { replaceUrl: true });
                    return of(false);
                }

                // check if user is blocked for event
                if (currentUser && currentUser.blockedEventIds && currentUser.blockedEventIds.includes(event.eventId)) {
                    this.fg1NotificationService.warn(CURRENTLY_BLOCKED_NOTIF);
                    this.router.navigate(['/'], { replaceUrl: true });
                    return of(false);
                }

                // check if user is event admin
                if (currentUser && currentUser.adminEventIds && currentUser.adminEventIds.includes(event.eventId)) {
                    // -- GUARD PASS --

                    // navigate to custom home if splash content exists
                    if (shouldShowWelcomePage(event)) {
                        const finalSegment =
                            url.toString().toLowerCase() === eventCodeOrHash ? 'custom-home' : url.toString();
                        if (finalSegment === 'custom-home') {
                            this.router.navigate(['/', eventCodeOrHash, finalSegment], {
                                skipLocationChange: true,
                            });
                        }
                    }
                    return of(true);
                }

                // handle if public event
                if (event.privacyLevel === PrivacyLevel.PUBLIC) {
                    // -- GUARD PASS --

                    // navigate to custom home if splash content exists
                    if (shouldShowWelcomePage(event)) {
                        const finalSegment =
                            url.toString().toLowerCase() === eventCodeOrHash ? 'custom-home' : url.toString();
                        if (finalSegment === 'custom-home') {
                            this.router.navigate(['/', eventCodeOrHash, finalSegment], {
                                skipLocationChange: true,
                            });
                        }
                    }
                    return of(true);
                } else if (event.privacyLevel === PrivacyLevel.INSTITUTION_HASH) {
                    // if privacy is InstitutionHash then allow if user is registered
                    if (currentUser && currentUser.eventIds && currentUser.eventIds.includes(event.eventId)) {
                        // -- GUARD PASS --

                        // navigate to custom home if splash content exists
                        if (shouldShowWelcomePage(event)) {
                            const finalSegment =
                                url.toString().toLowerCase() === eventCodeOrHash ? 'custom-home' : url.toString();
                            if (finalSegment === 'custom-home') {
                                this.router.navigate(['/', eventCodeOrHash, finalSegment], {
                                    skipLocationChange: true,
                                });
                            }
                        }
                        return of(true);
                    } else if (event.hash === eventCodeOrHash) {
                        // -- GUARD PASS --

                        // navigate to custom home if splash content exists
                        if (shouldShowWelcomePage(event)) {
                            const finalSegment =
                                url.toString().toLowerCase() === eventCodeOrHash ? 'custom-home' : url.toString();
                            if (finalSegment === 'custom-home') {
                                this.router.navigate(['/', eventCodeOrHash, finalSegment], {
                                    skipLocationChange: true,
                                });
                            }
                        }
                        return of(true);
                    } else if (!currentUser) {
                        this.fg1NotificationService.error(PRIVATE_SYMPOSIUM_NOTIF, 'Private Event');
                        this.prevRouteService.updateCurrentURL(state.url);
                        return of(this.router.createUrlTree([event.eventCode, 'signup']));
                    } else {
                        this.handleSignupRequisite(event, state);
                    }
                } else if (event.privacyLevel === PrivacyLevel.PRIVATE) {
                    if (!currentUser) {
                        this.fg1NotificationService.error(PRIVATE_SYMPOSIUM_NOTIF, 'Private Event');
                        this.prevRouteService.updateCurrentURL(state.url);
                        return of(this.router.createUrlTree([event.eventCode, 'signup']));
                    } else if (!currentUser || !currentUser.eventIds || !currentUser.eventIds.includes(event.eventId)) {
                        return this.handleSignupRequisite(event, state);
                    } else {
                        // -- GUARD PASS --

                        // navigate to custom home if splash content exists
                        if (shouldShowWelcomePage(event)) {
                            const finalSegment =
                                url.toString().toLowerCase() === eventCodeOrHash ? 'custom-home' : url.toString();
                            if (finalSegment === 'custom-home') {
                                this.router.navigate(['/', eventCodeOrHash, finalSegment], {
                                    skipLocationChange: true,
                                });
                            }
                        }
                        return of(true);
                    }
                }
                if (event.privacyLevel === PrivacyLevel.PRESENTATION_HASH) {
                    const presentationIdOrHash = next.params.id;
                    if (!eventCodeOrHash) {
                        return of(false);
                    }
                    if (currentUser && _get(currentUser, 'eventIds', []).includes(event.eventId)) {
                        // navigate to custom home if splash content exists
                        if (shouldShowWelcomePage(event)) {
                            const finalSegment =
                                next.url[next.url.length - 1].toString().toLowerCase() === eventCodeOrHash
                                    ? 'custom-home'
                                    : next.url[next.url.length - 1].toString();
                            if (finalSegment === 'custom-home') {
                                this.router.navigate(['/', eventCodeOrHash, finalSegment], {
                                    skipLocationChange: true,
                                });
                            }
                        }
                        return of(true);
                    } else if (presentationIdOrHash) {
                        return this.httpService.getIsValidPresentationHash(presentationIdOrHash);
                    }

                    if (!currentUser) {
                        this.fg1NotificationService.error(
                            'This university is hosting a private Symposium, please sign up to view their Symposium.'
                        );
                        this.prevRouteService.updateCurrentURL(state.url);
                        return of(this.router.createUrlTree(['login']));
                    } else {
                        return this.handleSignupRequisite(event, state);
                    }
                }
            }),
            catchError(() => {
                // console.log('err: ' + err);
                this.fg1NotificationService.warn(PAGE_NOT_FOUND_NOTIF, 'Page Not Found');
                this.router.navigate(['/'], { replaceUrl: true });
                return of(false);
            })
        );
    }

    handleSignupRequisite(event: Event, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (event.eventConfig.isSignupRequired) {
            this.prevRouteService.updateCurrentURL(state.url);
            return of(this.router.createUrlTree([event.eventCode, 'registration']));
        }
        return of(true);
    }

    isEventLaunched(event: Event) {
        const today = moment();
        const eventDate = moment(event.startDate);
        return eventDate.isSameOrBefore(today);
    }
}
