import { Component, Input, OnInit } from '@angular/core';
import { CompleteNgUnsubscribeComponent } from '@utils';
import { DonationConfigService } from '@services';
import { DonationConfig } from '@models';

enum Tabs {
    Introduction = 'app-introduction-message',
    Completion = 'app-completion-message',
}

@Component({
    selector: 'app-message-widget',
    templateUrl: './message-widget.component.html',
    styleUrls: ['./message-widget.component.scss'],
})
export class MessageWidgetComponent extends CompleteNgUnsubscribeComponent implements OnInit {
    readonly tabs = Tabs;
    activeTab = Tabs.Introduction;
    donationConfig: DonationConfig;

    constructor(private donationConfigService: DonationConfigService) {
        super();
    }

    isActiveTab(selector: Tabs): boolean {
        return this.activeTab === selector;
    }

    toggleTab(selector: Tabs) {
        this.activeTab = selector;
    }

    ngOnInit() {
        this.donationConfigService
            .donationConfig()
            .pipe()
            .subscribe(({ donationConfig }) => (this.donationConfig = donationConfig));
    }
}
