import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FG1NotificationService } from '../../services/fg1-notification-service';
import { LoadedImage } from 'ngx-image-cropper/lib/interfaces';
import { PhotoCropOutputItem, PhotoCropperConfig } from '@models';
import { base64ImageToFile } from '@functions';
import { DEFAULT_PHOTO_CROPPER_CONFIG } from '@constants';

@Component({
    selector: 'app-photo-crop-dialog',
    templateUrl: './photo-crop-dialog.component.html',
    styleUrls: ['./photo-crop-dialog.component.scss'],
})
export class PhotoCropDialogComponent implements OnInit {
    public config: PhotoCropperConfig;
    public fileSelected: Event;
    public canCrop = false;
    public cropped: PhotoCropOutputItem;
    private original: PhotoCropOutputItem;

    constructor(
        private fg1NotificationService: FG1NotificationService,
        public dialogRef: MatDialogRef<PhotoCropDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Partial<PhotoCropperConfig>
    ) {
        this.config = data ? { ...DEFAULT_PHOTO_CROPPER_CONFIG, ...data } : DEFAULT_PHOTO_CROPPER_CONFIG;
    }

    ngOnInit() {}

    fileSelectedEvent(event: Event): void {
        this.fileSelected = event;
    }

    /**
     * event fired when the image is cropped
     *
     * @param event
     */
    async imageCropped(event: ImageCroppedEvent) {
        this.cropped = {
            file: await base64ImageToFile(event.base64),
            base64: event.base64,
        };
    }

    /**
     * event fired when the original image is loaded
     *
     * @param image
     */
    async imageLoaded(image: LoadedImage) {
        this.original = {
            base64: this.config.imageBase64,
            file: this.config.imageFile || (await base64ImageToFile(image.original.base64)),
        };
    }

    /**
     * event fired when the photo cropper is ready
     */
    cropperReady() {
        this.canCrop = true;
    }

    /**
     * hide the option to select a file if the mode is set to crop only and we have a file/image input to crop
     * otherwise show the option to select a new file from inside the modal
     */
    get hideChoosePhotoOption() {
        return this.config && this.config.mode === 'crop' && (this.config.imageFile || this.config.imageBase64);
    }

    /**
     * event fired when the loaded file isn't a valid image
     */
    public loadImageFailed() {
        this.fg1NotificationService.error('Error cropping image. Please try again with another file.');
    }

    public crop() {
        this.dialogRef.close({ cropped: this.cropped, original: this.original });
    }

    public close(): void {
        this.dialogRef.close();
    }
}
