import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DonationConfig, Event, Fund } from '@models';
import { AppService, DonationConfigService, FundsService } from '@services';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CompleteNgUnsubscribeComponent } from '@utils';
import { WidgetMode } from '@constants';

@Component({
    selector: 'app-donation-widget',
    templateUrl: './donation-widget.component.html',
    styleUrls: ['./donation-widget.component.scss'],
})
export class DonationWidgetComponent extends CompleteNgUnsubscribeComponent implements OnInit {
    @Input() isAdmin = false;
    @Input() mode: WidgetMode = WidgetMode.Popup;
    @Input() isPopupOpened: boolean = false;
    @Input() showThumbnail: boolean = true;
    @Output() closed = new EventEmitter();
    event: Event;
    donationConfig: DonationConfig;
    private funds: Fund[] = [];

    constructor(
        private donationConfigService: DonationConfigService,
        private appService: AppService,
        private fundsService: FundsService
    ) {
        super();
    }

    ngOnInit() {
        this.appService
            .getEvent()
            .pipe(filter(e => !!e))
            .pipe(tap(event => (this.event = event)))
            .pipe(switchMap(event => this.fundsService.getFunds(event.eventId)))
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(funds => (this.funds = funds.filter(f => f.connected && f.enabled)));

        this.donationConfigService
            .donationConfig()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(resp => {
                this.donationConfig = resp?.donationConfig;
            });
    }

    togglePopup() {
        if (this.mode !== WidgetMode.Popup) {
            return;
        }

        this.isPopupOpened = !this.isPopupOpened;

        // Handle closing popup for mobile navigation purposes
        if (!this.isPopupOpened) {
            this.closed.emit();
        }
    }

    get widgetBody() {
        return this.donationConfig.widgetText;
    }

    get isEditMode() {
        return this.mode === WidgetMode.EditWidgetBody;
    }

    get isPopupMode() {
        return this.mode === WidgetMode.Popup;
    }

    get isThumbnailDisplayed() {
        return this.mode !== WidgetMode.EditWidgetBody;
    }

    get isOpened() {
        return this.isPopupOpened || this.mode === WidgetMode.EditWidgetBody;
    }

    get shouldDisplayWidget() {
        if (!this.event) {
            return false;
        }
        if (this.isAdmin) {
            return true;
        }

        const { donationConfig } = this.event;
        return donationConfig.nonProfit && donationConfig.enabled && this.funds && this.funds.length > 0;
    }
}
