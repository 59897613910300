<form class="content-container" [formGroup]="form">
    <h6>Personal Information</h6>

    <div class="personal-info-wrapper">
        <div class="personal-info-form">
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field class="full-width">
                        <mat-label>First Name</mat-label>
                        <input name="cc-given-name" [formControlName]="supportedFields.firstName" matInput required />
                    </mat-form-field>
                    <p class="error" *ngIf="!isFieldValid(supportedFields.firstName)">First name required</p>
                </div>
                <div class="col-md-3">
                    <mat-form-field class="full-width">
                        <mat-label>Last Name</mat-label>
                        <input name="cc-family-name" [formControlName]="supportedFields.lastName" matInput required />
                    </mat-form-field>
                    <p class="error" *ngIf="!isFieldValid(supportedFields.lastName)">Last name required</p>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="full-width">
                        <mat-label>Email</mat-label>
                        <input type="email" name="email" [formControlName]="supportedFields.email" matInput required />
                    </mat-form-field>
                    <p class="error" *ngIf="!isEmailValid && !hasRequiredError(supportedFields.email)">
                        Invalid email format
                    </p>
                    <p class="error" *ngIf="hasRequiredError(supportedFields.email)">Email required</p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3">
                    <mat-form-field class="full-width">
                        <mat-label>Address</mat-label>
                        <input name="street-address" [formControlName]="supportedFields.address" matInput required />
                    </mat-form-field>
                    <p class="error" *ngIf="!isFieldValid(supportedFields.address)">Address required</p>
                </div>
                <div class="col-md-3">
                    <mat-form-field class="full-width">
                        <mat-label>Postal Code</mat-label>
                        <input name="postal-code" [formControlName]="supportedFields.zip" matInput required />
                    </mat-form-field>
                    <p class="error" *ngIf="!isFieldValid(supportedFields.zip)">Postal code required</p>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="full-width">
                        <mat-label>City</mat-label>
                        <input name="address-level2" [formControlName]="supportedFields.city" matInput required />
                    </mat-form-field>
                    <p class="error" *ngIf="!isFieldValid(supportedFields.city)">City required</p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="full-width">
                        <mat-label>State/Province</mat-label>
                        <input name="address-level1" [formControlName]="supportedFields.state" matInput required />
                    </mat-form-field>
                    <p class="error" *ngIf="!isFieldValid(supportedFields.state)">State required</p>
                </div>
                <div class="col-md-8">
                    <mat-form-field class="full-width">
                        <mat-label>Country</mat-label>
                        <input
                            type="text"
                            name="country"
                            matInput
                            [formControlName]="supportedFields.country"
                            [matAutocomplete]="auto"
                            required
                        />
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let country of filteredCountries$ | async" [value]="country">
                                {{ country }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <p class="error" *ngIf="!hasRequiredError(supportedFields.country) && !isCountryValid">
                        Invalid country name
                    </p>
                    <p class="error" *ngIf="hasRequiredError(supportedFields.country)">Country required</p>
                </div>
                <div class="col-md-4"></div>
            </div>
            <div class="col-md-4"></div>
        </div>
    </div>
</form>
