<div *ngIf="isOpened">
    <app-edit-wrapper
        (hideWrapper)="hide()"
        [isDisabled]="true"
        title="Presentations Editor"
        [descriptions]="
            'This is a preview of the presentation. To make any changes to the presentation, please navigate to the Presentations tab\n' +
            '                and click the pencil icon next to the corresponding presentation.'
        "
    >
        <app-event-admin-dash-presentations-edit
            *ngIf="presenterData"
            [formValues]="presenterData"
            [currentEvent]="currentEvent"
            [isInputDisabled]="true"
        >
        </app-event-admin-dash-presentations-edit>
    </app-edit-wrapper>
</div>
