import { NgModule } from '@angular/core';
import { PlanNamePipe } from './plan-name.pipe';
import { ConsumerNamePipe } from './consumer-name.pipe';
import { HideArchivedPipe } from './hide-archived.pipe';
import { AssignedForms } from './judging.pipe';
import { MsToHoursMinutesPipe } from './ms-to-hours-minutes.pipe';
import { PricePipe } from './price.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { StripHtmlTagsPipe } from './strip-html-tags.pipe';
import { TruncatePipe } from './truncate.pipe';
import { StripeTransactionPricePipe } from './stripe-transaction-price.pipe';
import { ThousandSeparatorPipe } from './thousand-separator.pipe';
import { FileExtensionPipe } from './file-extension.pipe';
import { EventCoverPipe } from './event-cover.pipe';
import { AssetPipe } from './asset.pipe';
import { EndDatePipe } from './end-date.pipe';
import { FormTitlePipe } from './form-title.pipe';
import { LastItemPipe } from './last-item.pipe';
import { FirstItemPipe } from './first-item.pipe';
import { StageStatusPipe } from './stage-status.pipe';
import { PresentationStagePipe } from './presentation-stage.pipe';
import { UtcToLocalDatePipe } from './local-date.pipe';
import { AssignedFormsAbstractPipe } from './assigned-forms.pipe';

@NgModule({
    imports: [],
    declarations: [
        TruncatePipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        HideArchivedPipe,
        StripHtmlTagsPipe,
        PricePipe,
        PlanNamePipe,
        ConsumerNamePipe,
        AssignedFormsAbstractPipe,
        AssignedForms,
        MsToHoursMinutesPipe,
        StripeTransactionPricePipe,
        ThousandSeparatorPipe,
        FileExtensionPipe,
        EventCoverPipe,
        AssetPipe,
        EndDatePipe,
        FormTitlePipe,
        LastItemPipe,
        FirstItemPipe,
        StageStatusPipe,
        PresentationStagePipe,
        UtcToLocalDatePipe,
    ],
    exports: [
        TruncatePipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        HideArchivedPipe,
        StripHtmlTagsPipe,
        PricePipe,
        PlanNamePipe,
        ConsumerNamePipe,
        AssignedFormsAbstractPipe,
        AssignedForms,
        MsToHoursMinutesPipe,
        StripeTransactionPricePipe,
        ThousandSeparatorPipe,
        FileExtensionPipe,
        EventCoverPipe,
        AssetPipe,
        EndDatePipe,
        FormTitlePipe,
        LastItemPipe,
        StageStatusPipe,
        PresentationStagePipe,
        FirstItemPipe,
        UtcToLocalDatePipe,
    ],
})
export class PipesModule {}
