import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EditorStatus } from 'src/app/submissions/interfaces';

@Component({
    selector: 'app-form-preview-likert-scale-field',
    templateUrl: './likert-scale-field.component.html',
    styleUrls: ['./likert-scale-field.component.scss'],
})
export class FormPreviewLikertScaleFieldComponent implements OnInit {
    @Input() uniqueFieldKey: string;
    @Input() label: string;
    @Input() isRequired: boolean;
    @Input() isShownInSummary: boolean;
    @Input() isInputDisabled: boolean;
    @Input() description: string;
    @Input() type: string;
    @Input() optionLabels: Array<string>;
    @Input() data: Record<string, number>;
    @Input() dataFieldName: string;
    @Input() formErrs: any;
    @Input() editorStatus: EditorStatus = { isClean: true };
    @Output() isClean: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit() {
        this.isClean.emit(true);
    }

    public changeIsCleanEditorStatus(value: boolean) {
        this.isClean.emit(value);
        if (!this.editorStatus) {
            this.editorStatus = { isClean: value };
            return;
        }

        this.editorStatus.isClean = value;
    }
}
