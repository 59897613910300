<div id="content-container">
    <header>
        <div class="tab-links-container">
            <nav mat-tab-nav-bar class="no-border">
                <a mat-tab-link (click)="toggleTab(tabs.Introduction)" [active]="isActiveTab(tabs.Introduction)">
                    Introduction message
                </a>

                <a mat-tab-link (click)="toggleTab(tabs.Completion)" [active]="isActiveTab(tabs.Completion)">
                    Completion message
                </a>
            </nav>
        </div>
    </header>
    <app-donation-widget-wrapper *ngIf="donationConfig">
        <app-completion-message *ngIf="isActiveTab(tabs.Completion)"></app-completion-message>
        <app-introduction-message *ngIf="isActiveTab(tabs.Introduction)"></app-introduction-message>
    </app-donation-widget-wrapper>
</div>
