import slugify from 'slugify';
import {
    BaseExtraField,
    CustomInputFormField,
    CustomLikertScaleFormField,
    CustomMultiSelectFormField,
    MediaFieldsConfig,
    PresentationFormConfig,
} from '@models';

import { cloneDeep } from 'lodash';
import { YOUTUBE_VIDEO_ID_REGEX } from '@constants';

/**
 * convert the event name into a slugified version
 * @param event {Event | {name:string}} - event object
 */
export const slugifyEventName = (event: { name: string } = { name: '' }) => {
    return slugify(event.name, {
        lower: true,
    });
};

export const getYouTubeVideoIDFromLink = (link: string): string => {
    const youtubeIDPattern = YOUTUBE_VIDEO_ID_REGEX;
    return link.match(youtubeIDPattern)[1];
};

export const shouldShowFieldOnSubmissionForm = (
    fieldConfig: CustomInputFormField | CustomMultiSelectFormField | CustomLikertScaleFormField | MediaFieldsConfig,
    stage?: number,
    shouldSkipIsDisplayed?: boolean
): boolean => {
    if (!fieldConfig) {
        return false;
    }

    if (!(fieldConfig as any).isDisplayed && !shouldSkipIsDisplayed) {
        return false;
    }

    if (stage && fieldConfig.stage && stage < fieldConfig.stage) {
        return false;
    }

    return true;
};

export const updateSubmissionFormStages = (
    originalConfig: PresentationFormConfig,
    currentLastStage: number,
    targetStage: number
) => {
    const presentationFormConfig = cloneDeep(originalConfig);
    const shouldUpdateStage = (config: BaseExtraField) => config.stage === currentLastStage;
    const updateStage = (config: BaseExtraField) => {
        if (shouldUpdateStage(config)) config.stage = targetStage;
    };

    updateStage(presentationFormConfig.presenterFields.major);
    updateStage(presentationFormConfig.presenterFields.level);
    presentationFormConfig.presenterFields.extraFields.forEach(field => {
        updateStage(field);
    });

    updateStage(presentationFormConfig.presentationFields.title);
    updateStage(presentationFormConfig.presentationFields.subjects);
    updateStage(presentationFormConfig.presentationFields.mentor);
    updateStage(presentationFormConfig.presentationFields.abstract);
    presentationFormConfig.presentationFields.extraFields.forEach(field => {
        updateStage(field);
    });
    return presentationFormConfig;
};
