import { AuthenticationService } from 'src/app/services/authentication.service';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { GlobalFeatureFlag, GlobalFeatureFlagEnum } from '@models';
import { distinctUntilChanged, map } from 'rxjs/operators';

export type FeatureFlagStatus = { [x in GlobalFeatureFlagEnum]?: boolean };
@Injectable({
    providedIn: 'root',
})
export class GlobalFeatureFlagService {
    private featureFlags$: ReplaySubject<GlobalFeatureFlag[]> = new ReplaySubject<GlobalFeatureFlag[]>(1);
    private featureFlagStatus$ = new BehaviorSubject<FeatureFlagStatus>({});

    public featureFlagStatus: FeatureFlagStatus = {};

    constructor(private httpService: HttpService, private authService: AuthenticationService) {
        this.httpService
            .getGlobalFeatureFlags()
            .pipe(
                map(flags =>
                    flags.map(({ featureFlagId, consumers, name, releaseToAll }) => {
                        const typedFlag: GlobalFeatureFlag = {
                            id: Number(featureFlagId),
                            name,
                            releaseToAll,
                            consumers: consumers
                                .replace(/\s/g, '')
                                .split(',')
                                .map(id => Number(id)),
                        };

                        return typedFlag;
                    })
                )
            )
            .subscribe(this.featureFlags$);

        // Record feature flag status
        combineLatest([
            this.featureFlags$,
            this.authService.getCurrentUser().pipe(
                map(user => (user ? user.userId : null)),
                distinctUntilChanged()
            ),
        ]).subscribe(([featureFlags, userId]) => {
            if (!userId) {
                this.featureFlagStatus = {};
            } else {
                for (const flag of featureFlags) {
                    this.featureFlagStatus[flag.name] =
                        flag.releaseToAll || !!this.featureFlagStatus[flag.name] || flag.consumers.includes(userId);
                }
            }

            this.featureFlagStatus$.next(this.featureFlagStatus);
        });
    }

    public getFeatureFlagStatus() {
        return this.featureFlagStatus$;
    }
    public isFeatureEnabled(feature: GlobalFeatureFlagEnum) {
        return !!this.featureFlagStatus[feature];
    }
}
