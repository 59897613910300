import moment from 'moment';
import * as momentTimezone from 'moment-timezone';

// gets max event date by adding subscription uptime to the start date
export const getMaxEventDate = (start: Date, eventUptimeInDays: number): Date => {
    return moment(start).add(eventUptimeInDays, 'days').toDate();
};

export const toUTCDate = (date: moment.Moment, keepLocalTime: boolean = false) => moment(date).utc(keepLocalTime);
export const utcToLocalDate = (date: Date | string) => moment.utc(date).local();

export const isAfterToday = (date: string | Date) => {
    if (!date) {
        return false;
    }
    return moment(new Date()).isAfter(moment(date));
};

export const getLocalTimeZone = () => momentTimezone().tz(momentTimezone.tz.guess()).format('z');
