import { LiveSessionType } from '@models';

export class LiveSession {
    liveSessionId: number;
    networkingEventId: number;
    name: string;
    description: string;
    linkUrl: string;
    recordings: string;
    videoSDKMeetingId: string;
    streamKey: string;
    sessionLinkType: LiveSessionType;
    disableCameras: boolean;
    disableShareScreen: boolean;
    disableMicrophones: boolean;
    askToJoin: boolean;
    moderatorEmails: string[];
    participants: number;
    sessionDate: string;
    sessionStartTime: string;
    sessionEndTime: string;
    sessionStart: Date; // used for CRUD
    sessionEnd: Date; // used for CRUD
    timezone: string;
    liveSessionOrder: number;
    canJoin: boolean;
    withinTime = false;
    sessionId: number;

    constructor(
        name: string,
        description: string,
        linkUrl: string,
        sessionLinkType: LiveSessionType,
        disableCameras: boolean,
        disableShareScreen: boolean,
        disableMicrophones: boolean,
        askToJoin: boolean,
        moderatorEmails: string[],
        participants: number,
        sessionStart: Date,
        sessionEnd: Date,
        sessionId?: number,
        liveSessionOrder?: number
    ) {
        this.name = name;
        this.description = description;
        this.linkUrl = linkUrl;
        this.sessionLinkType = sessionLinkType;
        this.disableCameras = !!disableCameras;
        this.disableShareScreen = !!disableShareScreen;
        this.disableMicrophones = !!disableMicrophones;
        this.askToJoin = !!askToJoin;
        this.moderatorEmails = moderatorEmails || [];
        this.participants = participants;
        this.sessionStart = sessionStart;
        this.sessionEnd = sessionEnd;
        this.liveSessionOrder = liveSessionOrder;
        this.sessionId = sessionId;
    }
}
