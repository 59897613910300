import { NgModule } from '@angular/core';
import { DonationWidgetWrapperComponent } from './donation-widget-wrapper/donation-widget-wrapper.component';

@NgModule({
    imports: [],
    exports: [DonationWidgetWrapperComponent],
    declarations: [DonationWidgetWrapperComponent],
    providers: [],
})
export class DonationWidgetSharedModule {}
