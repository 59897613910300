<div class="content-container" [ngClass]="{ 'mobile-container': isNative }" [formGroup]="form">
    <ng-template [ngIf]="!isSubmitted">
        <div class="content-wrapper">
            <h5>{{ donationConfig.introductionHeading }}</h5>

            <p class="subheader mb-3">{{ donationConfig.introductionBody }}</p>

            <h6>Designate my gift to</h6>

            <mat-radio-group class="radio-group" formControlName="fund">
                <div *ngFor="let fund of funds">
                    <mat-radio-button [value]="fund.fundId" class="radio-button">
                        <mat-label>{{ fund.name }}</mat-label>
                    </mat-radio-button>
                </div>
            </mat-radio-group>

            <div class="pt-2">
                <h6>Gift amount</h6>
                <p class="subheader">Select a standard amount or provide your own custom amount.</p>
            </div>

            <div class="amount-wrapper">
                <div
                    class="amount"
                    [class.active]="selectedAmountIndex === i"
                    (click)="selectAmount(i)"
                    *ngFor="let amount of donationAmounts; let i = index"
                >
                    <span>${{ amount }}</span>
                </div>

                <div
                    class="amount"
                    (click)="selectAmount(donationAmounts.length + 1)"
                    [class.active]="selectedAmountIndex === donationAmounts.length + 1"
                >
                    <span>
                        <input
                            name="amount"
                            [min]="minDonationAmount"
                            [max]="maxDonationAmount"
                            [step]="1"
                            formControlName="amount"
                            placeholder="Other"
                        />
                    </span>
                </div>
            </div>
            <small class="text-error" *ngIf="form.get('amount').errors && form.get('amount').touched">
                <ng-container *ngIf="form.get('amount').errors.min"
                    >The minimum donation amount is
                    {{ form.get('amount').errors.min.min | currency }}
                </ng-container>
                <ng-container *ngIf="form.get('amount').errors.max"
                    >The maximum donation amount is
                    {{ form.get('amount').errors.max.max | currency }}
                </ng-container>
            </small>
            <div *ngIf="!event?.eventFeatureFlags.coverDonationsTransactionFees" class="horizontal-radio mb-3">
                <div class="flex-row">
                    <p class="subheader">Cover the ~3% payment processing fee for this gift?</p>
                    <mat-radio-group class="radio-group" formControlName="coverTransactionFee">
                        <div>
                            <mat-radio-button [value]="false" class="radio-button"></mat-radio-button>
                            <div class="radio-btn-label-container">
                                <div class="radio-btn-label">No</div>
                            </div>
                            <br />
                        </div>
                        <div>
                            <mat-radio-button [value]="true" class="radio-button"></mat-radio-button>
                            <div class="radio-btn-label-container">
                                <div class="radio-btn-label">Yes</div>
                            </div>
                            <br />
                        </div>
                    </mat-radio-group>
                </div>
            </div>

            <h6>Anonymization</h6>
            <div class="horizontal-radio mb-3">
                <div class="flex-row">
                    <p class="subheader">Would you like this gift to be anonymous?</p>
                    <mat-radio-group class="radio-group" formControlName="anonymous">
                        <div>
                            <mat-radio-button [value]="false" class="radio-button"></mat-radio-button>
                            <div class="radio-btn-label-container">
                                <div class="radio-btn-label">No</div>
                            </div>
                            <br />
                        </div>
                        <div>
                            <mat-radio-button [value]="true" class="radio-button"></mat-radio-button>
                            <div class="radio-btn-label-container">
                                <div class="radio-btn-label">Yes</div>
                            </div>
                            <br />
                        </div>
                    </mat-radio-group>
                </div>
            </div>

            <app-personal-information [form]="form"></app-personal-information>

            <div [class.hidden]="!selectedFund" class="pb-4">
                <div class="gap"></div>
                <h6>Card Information</h6>
                <div id="payment-form" class="sr-payment-form">
                    <div class="sr-combo-inputs-row">
                        <div class="sr-input sr-card-element" id="card-element"></div>
                    </div>
                    <div class="sr-field-error" id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
                </div>
            </div>
        </div>

        <div class="summary">
            <div>
                <p class="subheader">Total Payment</p>
                <span class="total-payment-amount">{{
                    form.get('amount').value || 0
                        | stripeTransactionPrice: form.get('coverTransactionFee').value
                        | currency
                }}</span>
            </div>
            <div>
                <button [disabled]="isSubmitDisabled" (click)="donate()" class="btn btn-primary upload">
                    Make gift
                </button>
            </div>
        </div>
    </ng-template>
    <ng-template #thankYou>
        <app-donation-thank-you></app-donation-thank-you>
    </ng-template>
</div>
