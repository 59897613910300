import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolloutFeatureAlertComponent } from './rollout-feature-alert.component';

@NgModule({
    declarations: [RolloutFeatureAlertComponent],
    imports: [CommonModule],
    exports: [RolloutFeatureAlertComponent],
})
export class RolloutFeatureAlertModule {}
