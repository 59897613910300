import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toHttpParams } from '@functions';
import { BccFeedbackEmail } from '@models';

export interface Feedback {
    feedbackId: number;
    eventId: number;
    type: 'judging' | 'abstract';
    stage?: number;
    subject: string;
    body: string;
    signature: string;
    isAnonymous: boolean;
    shouldAttachFeedback: boolean;
    targetPresenters: 'main' | 'all' | 'accepted' | 'rejected';
}

@Injectable({
    providedIn: 'root',
})
export class FeedbackService {
    private readonly url: string = `${environment.SERVICE_BASE_URL}/feedback`;

    constructor(private http: HttpClient) { }

    createFeedback(feedback: Feedback): Observable<Feedback> {
        return this.http.post<Feedback>(this.url, feedback);
    }

    getEventFeedback(eventId: number, type: Feedback['type'], stage: number = null) {
        return this.http.get<{ feedback: Feedback; isSharable: boolean }>(`${this.url}`, {
            params: toHttpParams({ eventId, type, stage }),
        });
    }

    updateFeedback(feedbackId: number, feedback: Partial<Feedback>): Observable<Feedback> {
        return this.http.put<Feedback>(`${this.url}/${feedbackId}`, feedback);
    }

    sendFeedbackEmail(
        feedbackId: number,
        eventId: number,
        type: Feedback['type'],
        isTestEmail?: boolean,
        stage?: number
    ): Observable<Feedback> {
        const param: Record<string, any> = { eventId, type, stage };
        if (isTestEmail) {
            param.test = 1;
        }
        return this.http.post<Feedback>(`${this.url}/${feedbackId}/send`, param);
    }

    public getEmailsForFeedback(feedbackId: number, eventId: number): Observable<BccFeedbackEmail[]> {
        return this.http.get<BccFeedbackEmail[]>(`${this.url}/${feedbackId}/emails?eventId=${eventId}`);
    }

    public addEmailsForFeedback(feedbackId: number, emails: string[], eventId: number): Observable<BccFeedbackEmail[]> {
        return this.http.put<BccFeedbackEmail[]>(`${this.url}/${feedbackId}/emails?eventId=${eventId}`, {
            emails,
            feedbackId,
        });
    }

    public deleteEmailFromFeedback(
        feedbackId: number,
        feedbackEmailId: number,
        eventId: number
    ): Observable<BccFeedbackEmail[]> {
        return this.http.delete<BccFeedbackEmail[]>(
            `${this.url}/${feedbackId}/emails?eventId=${eventId}&feedbackEmailId=${feedbackEmailId}`
        );
    }
}
