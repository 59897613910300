import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsAutocompleteComponent } from './chips-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '@pipes';

@NgModule({
    declarations: [ChipsAutocompleteComponent],
    entryComponents: [],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatChipsModule,
        MatTooltipModule,
        MatAutocompleteModule,
        PipesModule,
    ],
    exports: [ChipsAutocompleteComponent],
})
export class ChipsAutocompleteModule {}
