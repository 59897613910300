import { Fund } from '@models';
import { flatten, sumBy } from 'lodash';

/**
 * calculate total donations for a fund
 * @param fund
 * @return total donations for a fund
 */
export const calculateTotalFundDonations = (fund: Fund) => sumBy(fund.donations || [], 'amount');

/**
 * calculate total donations for an event
 * @return total donations for an event
 * @param funds
 */
export const calculateTotalEventDonations = (funds: Fund[]) => sumBy(funds, 'totalDonations');

/**
 * map donations across different funds into one array of donations
 * @param funds
 * @return donations Donations[]
 */
export const mapFundsToEventDonations = (funds: Fund[]) => flatten(funds.map(fund => fund.donations || []));
