export function  trimNullValues<T extends object>(params: T) {
    const trimmedParams: T = { ...params };

    for (const [key, value] of Object.entries(params)) {
        if (value === null || value === undefined) {
            delete trimmedParams[key];
        }
    }

    return trimmedParams;
}