import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MenuController } from '@ionic/angular';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import {
    AppService,
    AuthenticationService,
    DonationsService,
    HttpService,
    PresentationService,
    RolloutFeatureFlagService,
} from '@services';
import { CurrentUser, Event } from 'src/app/models';
import { environment } from '@env';
import { isAbstractEventJudge, isEventJudge, shouldShowWelcomePage } from '@functions';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'app-custom-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class CustomHeaderComponent implements OnInit, OnDestroy {
    public symposiumLogoV2Path = environment.SYMPOSIUM_LOGO_V2_PATH;
    public eventAssetsRemotePath = environment.EVENT_ASSETS_REMOTE_PATH;
    public consumerAssetsRemotePath = environment.CONSUMER_ASSETS_REMOTE_PATH;

    public menuItemsLeft = [
        {
            name: 'Abstract management',
            description: 'Streamline abstract management workflows from end-to-end',
            scrollToElement: '#presentations-section',
            scrollToFeatureName: 'abstract',
            iconURL: 'assets/images/home/abstract-management-icon.png',
            scrollDuration: 100,
        },
        {
            name: 'Presentations',
            description: 'Display a gallery of presentations for asynchronous viewing and commenting',
            scrollToElement: '#presentations-section',
            scrollToFeatureName: 'presentations',
            iconURL: 'assets/images/home/presentations-icon.png',
            scrollDuration: 100,
        },
        {
            name: 'Meetings',
            description: 'Host engaging video conferences with rich features for collaboration',
            scrollToElement: '#live-sessions-section',
            scrollToFeatureName: 'meeting',
            iconURL: 'assets/images/home/meetings-icon.png',
            scrollDuration: 200,
        },
        {
            name: 'Virtual tables',
            description: 'Facilitate networking and small group discussions',
            scrollToElement: '#live-sessions-section',
            scrollToFeatureName: 'tables',
            iconURL: 'assets/images/home/tables-icon.png',
            scrollDuration: 200,
        },
        {
            name: 'Webinars and live streams',
            description: 'Host keynotes and panel discussions virtually or stream in-person activity online',
            scrollToElement: '#live-sessions-section',
            scrollToFeatureName: 'webinars',
            iconURL: 'assets/images/home/live-sessions-icon.png',
            scrollDuration: 200,
        },
        {
            name: 'Registration',
            description: 'Create a custom registration page and form',
            scrollToElement: '#rich-features-section',
            scrollToFeatureName: null,
            iconURL: 'assets/images/home/registration-ticketing-icon.png',
            scrollDuration: 400,
        },
    ];

    menuItemsRight = [
        {
            name: 'Judging',
            description: 'Streamline judging workflows from end-to-end',
            scrollToElement: '#rich-features-section',
            scrollToFeatureName: null,
            iconURL: 'assets/images/home/judging-icon.png',
            scrollDuration: 400,
        },
        {
            name: 'Donations',
            description: 'Fundraise from event attendees',
            scrollToElement: '#rich-features-section',
            scrollToFeatureName: null,
            iconURL: 'assets/images/home/donations-icon.png',
            scrollDuration: 400,
        },
        {
            name: 'Analytics',
            description: 'Monitor real-time analytics on event activity',
            scrollToElement: '#rich-features-section',
            scrollToFeatureName: null,
            iconURL: 'assets/images/home/data-analytics-icon.png',
            scrollDuration: 400,
        },
        {
            name: 'Digital repository',
            description: 'Preserve event content and interaction data',
            scrollToElement: '#repository-section',
            scrollToFeatureName: 'repository',
            iconURL: 'assets/images/home/repository-icon.png',
            scrollDuration: 300,
        },
        {
            name: 'Abstract booklet',
            description: 'Create a custom abstract booklet that can be exported',
            scrollToElement: '#rich-features-section',
            scrollToFeatureName: null,
            iconURL: 'assets/images/home/abstract-booklet-icon.png',
            scrollDuration: 400,
        },
    ];

    @Input() isHomePage: boolean;

    public isEventLogoLoaded: boolean;
    public shouldShowRolloutAlert = false;
    public currentEvent: Event;
    public isJudgeForEvent: boolean;
    public isAbstractJudge: boolean;
    public currentUser: CurrentUser;
    public isViewerAdmin: boolean;
    public currentEventPage: 'welcome' | 'judging' | 'presentations' | 'live-sessions' | 'my-submissions' | 'abstract';
    public isAdmin: boolean;
    public isMyEventsPage: boolean;
    public isPresentationPage: boolean = false;
    public profileImgName: string;
    public hasPresentations: boolean = false;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    private ngUnsubscribe$ = new Subject();

    constructor(
        public authenticationService: AuthenticationService,
        public presentationService: PresentationService,
        public appService: AppService,
        public httpService: HttpService,
        private donationsService: DonationsService,
        private rolloutFFService: RolloutFeatureFlagService,
        private router: Router,
        private menu: MenuController,
        private location: Location
    ) {}

    ngOnInit(): void {
        // get event/user from shared services
        combineLatest([
            this.appService.getEvent().pipe(takeUntil(this.ngUnsubscribe$)),
            this.authenticationService.getCurrentUser().pipe(
                map(currentUser => {
                    if (currentUser)
                        currentUser.profileImgName = currentUser.profileImgName
                            ? `${currentUser.profileImgName}?timestamp=${new Date().getTime()}`
                            : null;
                    return currentUser;
                }),
                takeUntil(this.ngUnsubscribe$)
            ),
            this.router.events.pipe(startWith(null as string), takeUntil(this.ngUnsubscribe$)),
        ]).subscribe(([event, currentUser, routerEvents]) => {
            this.currentEvent = event;
            if (this.currentEvent) {
                this.hasPresentations = this.currentEvent.presentationsCount > 0;
            }
            this.currentUser = currentUser;
            this.isJudgeForEvent = isEventJudge(event, currentUser);
            this.isAbstractJudge = isAbstractEventJudge(event, currentUser);

            if (
                this.currentUser &&
                ((this.currentUser.adminEventIds && this.currentUser.adminEventIds.length) ||
                    (this.currentUser.subscriptionIds && this.currentUser.subscriptionIds.length))
            ) {
                this.isAdmin = true;
            }

            const routerEventUrl = routerEvents instanceof NavigationEnd ? routerEvents.urlAfterRedirects : null;

            // detect route changes to an event page
            if (routerEventUrl) {
                const currentRouterLink = routerEventUrl.toLowerCase();
                if (this.currentEvent) {
                    const lastSegment = currentRouterLink.split('/').pop();
                    if (lastSegment === this.currentEvent.eventCode || lastSegment === 'custom-home') {
                        this.currentEventPage = 'welcome';
                        // Make sure user is not on the admin portal now
                    } else if (lastSegment === 'presentations' && !currentRouterLink.endsWith('/admin/presentations')) {
                        this.currentEventPage = 'presentations';
                        // Make sure user is not on the admin portal now
                    } else if (lastSegment === 'live-sessions' && !currentRouterLink.endsWith('/admin/live-sessions')) {
                        this.currentEventPage = 'live-sessions';
                    } else if (lastSegment === 'judging' && !currentRouterLink.endsWith('/admin/judging')) {
                        this.currentEventPage = 'judging';
                    } else if (lastSegment === 'abstract') {
                        this.currentEventPage = 'abstract';
                    } else {
                        this.currentEventPage = null;
                    }

                    const segments = currentRouterLink.split('/');
                    this.isPresentationPage = segments.length > 3 && segments[2] === 'presentations';

                    // Non event pages
                    if (!currentRouterLink.startsWith('/' + this.currentEvent.eventCode)) {
                        this.appService.setEvent(null);
                        this.appService.setInstitution(null);
                    }
                } else {
                    this.isMyEventsPage =
                        currentRouterLink.endsWith('/events/admin') || currentRouterLink.endsWith('/events');
                    this.isEventLogoLoaded = false;
                    this.currentEventPage = null;
                }
            }

            // detect if viewer is event admin
            if (this.currentEvent && this.currentUser) {
                this.isViewerAdmin =
                    this.currentUser &&
                    this.currentUser.adminEventIds &&
                    this.currentUser.adminEventIds.includes(this.currentEvent.eventId);
            } else {
                this.isViewerAdmin = false;
            }
        });

        this.loadDonationsConfig();
    }

    loadDonationsConfig() {
        this.appService
            .getEvent()
            .pipe(takeUntil(this.ngUnsubscribe$))
            .pipe(distinctUntilChanged())
            .subscribe(() => this.donationsService.resetDonationForm());
    }

    openPage(page) {
        const routeMap = {
            discover: '/discover',
            login: '/login',
            signup: '/signup',
            pricing: '/pricing',
            contact: '/contact',
            terms: '/terms-of-service',
            privacy: '/privacy-policy',
            account: '/account',
        };

        this.router.navigate([routeMap[page]]);
        this.menu.close('sidebar');
    }

    get profileImgUrl() {
        if (this.currentUser && this.currentUser.profileImgName) {
            return `url(${this.consumerAssetsRemotePath}${this.currentUser.profileImgName})`;
        }
        return 'url(/assets/images/default-avatar_cmp.png)';
    }

    get logoRouteLink(): string {
        if (!this.currentEvent) {
            return '';
        }
        if (this.currentEvent.isHash) {
            return this.currentEvent.hash;
        }
        return this.currentEvent.eventCode;
    }

    logout() {
        this.authenticationService.logout();
        this.appService.setInstitution(null);
        this.router.navigate(['/']);
    }

    goBackwards() {
        this.location.back();
    }

    logoutOnSidebar() {
        this.logout();
        this.menu.close('sidebar');
    }

    exitEvent() {
        this.appService.setEvent(null);
        this.appService.setInstitution(null);
        globalCacheBusterNotifier.next();
        this.router.navigate(['/']);
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(null);
        this.ngUnsubscribe$.complete();
    }

    goMyAccount() {
        this.router.navigate(['account']);
    }

    goMySubmissions() {
        this.router.navigate(['my-submissions']);
    }

    dismissRolloutAlert() {
        this.shouldShowRolloutAlert = false;
        this.rolloutFFService
            .addRolloutShowRecord(this.appService.getVisitorId(), 'header', this.currentUser.userId)
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(_ => {
                this.appService.fetchRolloutFeatureFlags();
            });
    }

    shouldShowWelcomePage(event: Event): boolean {
        return shouldShowWelcomePage(event);
    }

    scrollToFeature(feature: string): void {
        if (feature) {
            this.appService.setScrolledFeature(feature);
        }
    }

    scrollToElement(elementId: string): void {
        const el = document.querySelector(elementId);
        if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            this.trigger.closeMenu();
        }
    }

    get menuItems() {
        return [...this.menuItemsLeft, ...this.menuItemsRight];
    }
}
