import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationFormEditorComponent } from './registration-form-editor/registration-form-editor.component';
import { GeneralizedFormEditorModule, GeneralizedFormPreviewModule } from '@components';
import { RegistrationFormSubmissionComponent } from './registration-form-submission/registration-form-submission.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { RegistrationRoutingModule } from './registration-routing.module';
import { RegistrationPagePreviewComponent } from './registration-page-preview/registration-page-preview.component';
import { SharedModule } from '../shared';
import { PipesModule } from '@pipes';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { EventSocialMediaComponent } from './event-social-media/event-social-media.component';
import { RegistrationPageSidebarComponent } from './registration-page-preview/registration-page-sidebar/registration-page-sidebar.component';
import { RegistrationPageSponsorsComponent } from './registration-page-preview/registration-page-tabs/registration-page-sponsors/registration-page-sponsors.component';
import { RegistrationPageProgramComponent } from './registration-page-preview/registration-page-tabs/registration-page-program/registration-page-program.component';
import { RegistrationPageSpeakersComponent } from './registration-page-preview/registration-page-tabs/registration-page-speakers/registration-page-speakers.component';
import { RegistrationPageTabsComponent } from './registration-page-preview/registration-page-tabs/registration-page-tabs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrationPageAboutComponent } from './registration-page-preview/registration-page-tabs/registration-page-about/registration-page-about.component';
import { NgxTrumbowygModule } from 'ngx-trumbowyg';
import { RegistrationCustomTabComponent } from './registration-page-preview/registration-page-tabs/registration-custom-tab/registration-custom-tab.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RegistrationPagePreviewMobileComponent } from '../shared/components/registration/registration-page-preview-mobile/registration-page-preview-mobile.component';
import { RegistrationSectionComponent } from './registration-page-preview/registration-section/registration-section.component';

@NgModule({
    declarations: [
        RegistrationFormEditorComponent,
        RegistrationFormSubmissionComponent,
        RegistrationPagePreviewComponent,
        EventSocialMediaComponent,
        RegistrationPageSidebarComponent,
        RegistrationPageSponsorsComponent,
        RegistrationPageProgramComponent,
        RegistrationPageSpeakersComponent,
        RegistrationPageTabsComponent,
        RegistrationPageAboutComponent,
        RegistrationCustomTabComponent,
        RegistrationPagePreviewMobileComponent,
        RegistrationSectionComponent,
    ],
    imports: [
        CommonModule,
        GeneralizedFormEditorModule,
        MatProgressSpinnerModule,
        RouterModule,
        GeneralizedFormPreviewModule,
        RegistrationRoutingModule,
        SharedModule,
        PipesModule,
        YouTubePlayerModule,
        MatTabsModule,
        MatIconModule,
        MatCardModule,
        MatSlideToggleModule,
        NgxTrumbowygModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatButtonModule,
        MatDividerModule,
        DragDropModule,
    ],
    exports: [
        RegistrationFormSubmissionComponent,
        RegistrationPagePreviewComponent,
        RegistrationPageTabsComponent,
        RegistrationPageSponsorsComponent,
        RegistrationPageSidebarComponent,
    ],
})
export class RegistrationModule {}
