import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';

// Guards
import { AuthGuard } from './guards/auth-guard.service';
import { EventGuard } from './guards/event-guard.service';
import { SubscriptionGuard } from './guards/subscription.guard';
import { NotLoggedInGuard } from './guards/not-loggedin.guard';
import { setupRoutes } from './utils';
import { ConsentRequiredComponent } from './shared/components/consent-required/consent-required.component';
import { MySubmissionsComponent } from './my-submissions/my-submissions.component';

const routes: Routes = [
    {
        path: 'discover',
        loadChildren: () => import('./discover/discover.module').then(m => m.DiscoverModule),
    },
    {
        path: 'case-studies',
        loadChildren: () => import('./case-studies/case-studies.module').then(m => m.CaseStudiesModule),
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'create-event',
        canActivate: [AuthGuard, SubscriptionGuard],
        loadChildren: () => import('./create-event/create-event.module').then(m => m.CreateEventModule),
    },
    {
        path: 'reset-password',
        canActivate: [NotLoggedInGuard],
        loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    },
    {
        path: 'contact',
        loadChildren: () =>
            import('./pricing/pricing-contact-us/pricing-contact-us.module').then(m => m.PricingContactUsModule),
    },
    {
        path: 'pricing',
        loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule),
    },
    {
        path: 'login',
        canActivate: [NotLoggedInGuard],
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'signup',
        canActivate: [NotLoggedInGuard],
        loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
    },
    {
        path: 'signup-institution',
        loadChildren: () =>
            import('./new-institution-signup/new-institution-signup.module').then(m => m.NewInstitutionSignupMoule),
    },
    {
        path: 'saml-callback',
        loadChildren: () => import('./callback/callback.module').then(m => m.CallbackModule),
    },
    {
        path: 'authentication-handler',
        loadChildren: () =>
            import('./authentication-handler/authentication-handler.module').then(m => m.AuthenticationHandlerModule),
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    },
    {
        path: 'terms-of-service',
        loadChildren: () => import('./terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule),
    },
    {
        path: 'cur',
        loadChildren: () => import('./custom-home-pages/cur-home/cur-home.module').then(m => m.CurHomeModule),
        canActivate: [EventGuard],
    },
    {
        path: 'utkdiscovery',
        loadChildren: () =>
            import('./custom-home-pages/utkdiscovery-home/utkdiscovery-home.module').then(
                m => m.UtkdiscoveryHomeModule
            ),
        canActivate: [EventGuard],
    },
    {
        path: 'stkatesummer',
        loadChildren: () =>
            import('./custom-home-pages/stkatesummer-home/stkatesummer-home.module').then(
                m => m.StkatesummerHomeModule
            ),
        canActivate: [EventGuard],
    },
    {
        path: 'ispne',
        loadChildren: () => import('./custom-home-pages/ispne-home/ispne-home.module').then(m => m.IspneHomeModule),
        canActivate: [EventGuard],
    },
    {
        path: 'stkatefaculty',
        loadChildren: () =>
            import('./custom-home-pages/stkatefaculty-home/stkatefaculty-home.module').then(
                m => m.StkatefacultyHomeModule
            ),
        canActivate: [EventGuard],
    },
    {
        path: 'nhinbre',
        loadChildren: () =>
            import('./custom-home-pages/nhinbre-home/nhinbre-home.module').then(m => m.NhinbreHomeModule),
        canActivate: [EventGuard],
    },
    {
        path: 'caltechsummer',
        loadChildren: () =>
            import('./custom-home-pages/caltechsummer-home/caltechsummer-home.module').then(
                m => m.CaltechsummerHomeModule
            ),
        canActivate: [EventGuard],
    },
    {
        path: 'udel',
        loadChildren: () => import('./custom-home-pages/udel-home/udel-home.module').then(m => m.UdelHomeModule),
        canActivate: [EventGuard],
    },
    {
        path: 'gutt',
        loadChildren: () => import('./custom-home-pages/gutt-home/gutt-home.module').then(m => m.GuttHomeModule),
        canActivate: [EventGuard],
    },
    {
        path: 'events',
        loadChildren: () => import('./my-events/my-events.module').then(m => m.MyEventsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'consent-required',
        component: ConsentRequiredComponent,
    },
    {
        path: 'my-submissions',
        component: MySubmissionsComponent,
    },
    {
        path: ':eventCodeOrHash',
        loadChildren: () => import('./current-event/current-event.module').then(m => m.CurrentEventModule),
    },
    /**
     * symposium.foragerone.com/JHU, symposium.foragerone.com/drexel, etc. will all go through this path.
     * TODO: If the HomeComponent can't find the institution, then maybe it should redirect to a 404 component.
     */
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(setupRoutes(routes), {
            scrollPositionRestoration: 'enabled',
            paramsInheritanceStrategy: 'always',
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
