import { Component, Input, OnInit } from '@angular/core';
import { PresentationEntity } from '@models';

@Component({
    selector: 'presentation-required-fields-status',
    templateUrl: './presentation-required-fields-status.component.html',
    styleUrls: ['./presentation-required-fields-status.component.scss'],
})
export class PresentationRequiredFieldsStatus implements OnInit {
    @Input() presentation: PresentationEntity;

    constructor() {}

    ngOnInit(): void {}
}
