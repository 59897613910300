import { DonationWidgetModule } from './event-admin-dash/donations/donation-widget/donation-widget.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
// Material Imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { MatButtonModule } from '@angular/material/button';
import { IonicModule } from '@ionic/angular';

// Shared
import {
    AuthenticationService,
    HttpErrorInterceptor,
    JwtInterceptor,
    LocalStorageService,
    PreviousRouteService,
    UnauthorizedInterceptor,
} from '@services';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CountdownGlobalConfig } from 'ngx-countdown';
import { first } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Admin Dialogs
import {
    CancelDialogComponent,
    DeleteLiveSessionDialogComponent,
    DeletePresentationDialogComponent,
    DeleteSubmissionAddlPresenterDialogComponent,
    DiscardWelcomePageDialogComponent,
    InfoDialogComponent,
    PhotoCropDialogComponent,
} from '@dialogs';

// Submission Dialogs
import { FooterNewComponent } from './footer-new/footer.component';
import { FooterComponent } from './footer/footer.component';
// Guards
import {
    AbstractJudgeEventGuardService,
    AuthGuard,
    JudgeEventGuard,
    JudgePresentationGuard,
    JudgingPackageEnabledGuard,
    NotLoggedInGuard,
} from '@guards';
import { EventAdminGuard } from './guards/event-admin-guard.service';
import { EventFeatureFlagGuard } from './guards/event-feature-flag-guard.service';
import { EventGuard } from './guards/event-guard.service';
import { SuperAdminGuard } from './guards/super-admin.guard';
import { CustomHeaderComponent } from './header-custom/header.component';
import { ConsumerNamePipe } from './pipes/consumer-name.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { HomeModule } from './home/home.module';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { environment } from '@env';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { RegistrationModule } from './registration/registration.module';
import { SharedModule } from './shared';
import { MySubmissionsComponent } from './my-submissions/my-submissions.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { PipesModule } from '@pipes';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
    declarations: [
        AppComponent,
        CustomHeaderComponent,
        FooterComponent,
        FooterNewComponent,
        CancelDialogComponent,
        DeleteLiveSessionDialogComponent,
        DeletePresentationDialogComponent,
        DiscardWelcomePageDialogComponent,
        DeleteSubmissionAddlPresenterDialogComponent,
        InfoDialogComponent,
        PhotoCropDialogComponent,
        MobileNavComponent,
        MySubmissionsComponent,
    ],
    entryComponents: [],
    imports: [
        CommonModule,
        MatButtonModule,
        SharedModule,
        ImageCropperModule,
        HomeModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxExtendedPdfViewerModule,
        ModalModule.forRoot(),
        IonicModule.forRoot(),
        BrowserAnimationsModule,
        MatDialogModule,
        MatMenuModule,
        MatIconModule,
        SimpleNotificationsModule.forRoot({
            position: ['bottom', 'right'],
            timeOut: 5000,
            pauseOnHover: true,
            theClass: 'simple-notification-selector',
        }),
        BsDropdownModule.forRoot(),
        AngularFireDatabaseModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        MatDividerModule,
        NgxPageScrollCoreModule,
        NgxPageScrollModule,
        DonationWidgetModule,
        RegistrationModule,
        MatTableModule,
        MatSortModule,
        PipesModule,
        MatPaginatorModule,
        A11yModule,
    ],
    providers: [
        PreviousRouteService,
        AuthGuard,
        SuperAdminGuard,
        EventGuard,
        JudgeEventGuard,
        AbstractJudgeEventGuardService,
        JudgingPackageEnabledGuard,
        JudgePresentationGuard,
        EventAdminGuard,
        EventFeatureFlagGuard,
        EventAdminGuard,
        NotLoggedInGuard,
        AuthenticationService,
        ConsumerNamePipe,
        LocalStorageService,
        CountdownGlobalConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: InitApp,
            multi: true,
            deps: [AuthenticationService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
            useValue: undefined,
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    ],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {
    // To store the currentURL variable as soon as possible, it's necessary to inject the service here
    constructor(private previousRouteService: PreviousRouteService) {}
}

// Get the current user then load the app. If the user
export function InitApp(authenticationService: AuthenticationService) {
    return () => {
        // Interceptors will still work. If there will be any auth error
        // the interceptors will handle behind the scene.
        authenticationService.getUserFromToken();
        return authenticationService.getCurrentUser().pipe(first()).toPromise();
    };
}
