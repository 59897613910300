import { CurrentUser, Event } from '@models';

export const isEventJudge = (event: Event, user: CurrentUser) => {
    return (
        event &&
        user &&
        user.consumerEvents &&
        user.consumerEvents[event.eventId] &&
        user.consumerEvents[event.eventId].isJudge
    );
};

export const isAbstractEventJudge = (event: Event, user: CurrentUser) => {
    if (!event || !user) {
        return false;
    }
    const consumerEvent = user.consumerEvents && user.consumerEvents[event.eventId];
    if (!consumerEvent) {
        return false;
    }
    return consumerEvent.isAbstractJudge;
};
