import { Injectable } from '@angular/core';
import { environment } from '@env';
import { FormResponse, FormSubmission, FormType } from '@models';
import { HttpClient } from '@angular/common/http';
import { toHttpParams } from '@functions';
import { Observable } from 'rxjs';
import { CacheBuster } from 'ts-cacheable';
import { abstractCacheBuster$, formCacheBuster$ } from '@constants';

@Injectable({
    providedIn: 'root',
})
export class FormSubmissionsService {
    url = `${environment.SERVICE_BASE_URL}/submissions/event`;

    constructor(private http: HttpClient) {}

    @CacheBuster({
        cacheBusterNotifier: formCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: abstractCacheBuster$,
    })
    createSubmission(payload: {
        formIdOrHash: string | number;
        response: FormResponse;
        presentationId?: number;
    }): Observable<FormSubmission> {
        return this.http.post<FormSubmission>(`${this.url}`, payload);
    }

    @CacheBuster({
        cacheBusterNotifier: abstractCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: formCacheBuster$,
    })
    updateSubmission(submissionId: number, submission: Partial<FormSubmission>): Observable<FormSubmission> {
        return this.http.put<FormSubmission>(`${this.url}/${submissionId}`, submission);
    }

    getSubmission(submissionId: number): Observable<FormSubmission> {
        return this.http.get<FormSubmission>(`${this.url}`, {
            params: toHttpParams({ submissionId }),
        });
    }

    getAllSubmissions(query: { formId: number; eventId: number; type: FormType }): Observable<FormSubmission[]> {
        return this.http.get<FormSubmission[]>(`${this.url}`, {
            params: toHttpParams(query),
        });
    }

    getMySubmissions(formId: number): Observable<FormSubmission[]> {
        return this.http.get<FormSubmission[]>(`${this.url}/my-submissions`, {
            params: toHttpParams({ formId }),
        });
    }

    getAllFinalNotesSubmissions(eventId: number): Observable<FormSubmission[]> {
        return this.http.get<FormSubmission[]>(`${this.url}/final-notes`, {
            params: toHttpParams({ eventId }),
        });
    }

    @CacheBuster({
        cacheBusterNotifier: abstractCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: formCacheBuster$,
    })
    deleteSubmission(submissionId: number): Observable<FormSubmission> {
        return this.http.delete<FormSubmission>(`${this.url}/${submissionId}`);
    }
}
