<h1 mat-dialog-title>
    {{ data.title }}
</h1>

<mat-dialog-content class="mat-typography">
    <p>{{ data.message }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
