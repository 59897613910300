export function numberWithCommas(v: number | string): string {
    let x: number;
    if (typeof v === 'number') {
        x = v;
    } else if (typeof v === 'string') {
        x = Number(v);
        if (Number.isNaN(x)) return v;
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
