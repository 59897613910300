import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { toHttpParams } from '@functions';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { PresentationJudges } from './judging.service';
import {
    DEFAULT_FINAL_NOTES_CONFIG,
    EvaluationForm,
    Event,
    Form,
    FormResponse,
    FormStats,
    FormSubmission,
    FormType,
    PaginationParams,
    PresentationEvaluationSubmission,
} from '@models';
import { ConsumerNamePipe } from '../pipes/consumer-name.pipe';
import { saveAs } from 'file-saver';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import {
    abstractCacheBuster$,
    formCacheBuster$,
    judgingCacheBuster$,
    MAX_CACHE_COUNT,
    TWO_MINUTES_CACHE,
} from '@constants';
import { FG1NotificationService } from './fg1-notification-service';
import { ActivatedRoute, Router } from '@angular/router';
import { set } from 'lodash';

export interface EvaluationFormResponsesDto extends PresentationEvaluationSubmission {
    responseNumber: number;
    judgeName: string;
    formName: string;
    formId: number;
    presentationTitle: string;
    presentationId: number;
}

interface UpdateEvaluationFormSubmissionPayload {
    eventId: number;
    presentationEvaluationId: number;
    response: Record<string, any>;
}

interface GroupEvaluationFormResponsesByPresentationId {
    [presentationId: number]: number[];
}

interface GetFormsParams extends PaginationParams {
    eventId: number;
    stage?: number;
    type?: FormType;
    relations?: string[];
}

const groupEvaluationFormResponsesByPresentationId = (
    presentationEvaluationSubmission: PresentationEvaluationSubmission[]
): GroupEvaluationFormResponsesByPresentationId => {
    return presentationEvaluationSubmission.reduce((acc, e) => {
        const {
            presentationJudge: { presentation },
            presentationEvaluationId,
        } = e;

        if (!acc[presentation.presentationId]) {
            acc[presentation.presentationId] = [];
        }

        acc[presentation.presentationId].push(presentationEvaluationId);
        return acc;
    }, {});
};

export const conditionalsToQuestionAnswerPairs = (form: Partial<Form>): Partial<Form> => {
    for (const conditional of form?.conditionals || []) {
        for (const impactedQuestion of conditional.impactedQuestions) {
            for (let i = 0; i < form?.config?.extraFields.length; i++) {
                if (impactedQuestion === form.config.extraFields[i].hash) {
                    const conditionalQuestionAnswerPairs =
                        form.config.extraFields[i].conditionalQuestionAnswerPairs || {};
                    let pairsForCurrentQuestion = conditionalQuestionAnswerPairs[conditional.watchedQuestion] || [];
                    if (!Array.isArray(pairsForCurrentQuestion)) {
                        pairsForCurrentQuestion = [conditionalQuestionAnswerPairs[conditional.watchedQuestion]];
                    }
                    pairsForCurrentQuestion.push(conditional.targetValue);
                    conditionalQuestionAnswerPairs[conditional.watchedQuestion] = pairsForCurrentQuestion;
                    form.config.extraFields[i].conditionalQuestionAnswerPairs = conditionalQuestionAnswerPairs;
                }
            }
        }
    }
    return form;
};

@Injectable({
    providedIn: 'root',
})
export class FormsService {
    private url = `${environment.SERVICE_BASE_URL}/forms`;
    private readonly submissionUrl: string = `${environment.SERVICE_BASE_URL}/submissions`;

    private _formEffected$: BehaviorSubject<number[]> = new BehaviorSubject(null);

    private _previewForm = new Subject<Form>();

    constructor(
        private http: HttpClient,
        private fg1NotificationService: FG1NotificationService,
        private consumerName: ConsumerNamePipe,
        private router: Router
    ) {}

    public openForm(form: Form, action: 'preview' | 'edit', route: ActivatedRoute) {
        return this.router.navigate(['../', 'abstract-management', 'forms', form.formId, action], {
            relativeTo: route,
            state: { isPreview: action === 'preview' },
        });
    }

    get previewForm(): Observable<Form> {
        return this._previewForm.asObservable();
    }

    public updatePreviewForm(form: Form) {
        return this._previewForm.next(form);
    }

    public getFormEffected(): Observable<number[]> {
        return this._formEffected$;
    }

    public setFormEffected(effectedIds: number[] | number) {
        this._formEffected$.next([].concat(effectedIds));
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: formCacheBuster$,
    })
    getForms(params: Partial<GetFormsParams>): Observable<{ forms: Form[]; total: number }> {
        return this.http.get<{ forms: Form[]; total: number }>(this.url, { params: toHttpParams(params) });
    }

    getForm(formIdOrHash: string | number, relations: string[] = []): Observable<Form> {
        return this.http.get<Form>(`${this.url}/${formIdOrHash}`, { params: toHttpParams(relations) });
    }

    isFormSubmitted(formId: number): Observable<{ hasSubmitted: boolean }> {
        return this.http.get<{ hasSubmitted: boolean }>(`${this.url}/${formId}/hasSubmitted`);
    }

    isFormSubmittedForPresentation(formId: number, presentationId: number): Observable<{ hasSubmitted: boolean }> {
        return this.http.get<{ hasSubmitted: boolean }>(
            `${this.url}/${formId}/hasSubmittedForPresentation/${presentationId}`
        );
    }

    getFormStats(id: number): Observable<FormStats> {
        return this.http.get<FormStats>(`${this.url}/${id}/stats`);
    }

    getEvaluationForm(evaluationFormId: number) {
        return this.http.get<EvaluationForm>(`${this.url}/evaluation/${evaluationFormId}`);
    }

    haveISubmittedPresentationForm(evaluationFormId: number): Observable<boolean> {
        return this.http.get<boolean>(`${this.url}/have-i-submitted-evaluation-form/${evaluationFormId}`);
    }

    getEvaluationFormResponses(eventId: number): Observable<EvaluationFormResponsesDto[]> {
        return this.http
            .get<PresentationEvaluationSubmission[]>(`${this.submissionUrl}/evaluation`, {
                params: toHttpParams({ eventId }),
            })
            .pipe(
                map(evaluationFormResponses => {
                    // prepare the data and add response number
                    const groupedResponses = groupEvaluationFormResponsesByPresentationId(evaluationFormResponses);
                    return evaluationFormResponses.map(e => this.evaluationFormResponsesDto(e, groupedResponses));
                })
            );
    }

    deleteEvaluationFormResponse(id: number) {
        return this.http.delete(`${this.submissionUrl}/evaluation/${id}`);
    }

    storeEvaluationFormSubmission(evaluationFormId: number, response: FormResponse) {
        return this.http.post<EvaluationForm>(`${this.submissionUrl}/evaluation`, {
            evaluationFormId,
            response,
        });
    }

    register(eventId: number, response: Object) {
        return this.http.post<EvaluationForm>(`${this.submissionUrl}/registration`, {
            eventId,
            response,
        });
    }

    isRegistered(eventId: number, consumerId: number) {
        return this.http.get<boolean>(`${this.submissionUrl}/registration/submitted`, {
            params: toHttpParams({
                eventId,
                consumerId,
            }),
        });
    }

    updateEvaluationFormSubmission({
        presentationEvaluationId,
        response,
        eventId,
    }: UpdateEvaluationFormSubmissionPayload) {
        return this.http.put<EvaluationForm>(`${this.submissionUrl}/evaluation`, {
            eventId,
            presentationEvaluationId,
            response,
        });
    }

    getRecruitmentForm(eventId: number): Observable<Form> {
        const params = toHttpParams({
            eventId,
        });
        return this.http.get<Form>(`${this.url}/recruitment`, { params });
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: formCacheBuster$,
    })
    getFormByType(
        eventId: number,
        type: FormType,
        withSubmissionsCount: boolean = false,
        relations: string[] = [],
        stage: number = null
    ): Observable<Form> {
        const params = {
            eventId,
            type,
            relations,
            withSubmissionsCount,
        };
        if (stage) {
            set(params, 'stage', stage);
        }
        return this.http.get<Form>(`${this.url}/by-type`, { params: toHttpParams(params) });
    }

    getFinalNotesForm(eventId: number): Observable<Form> {
        const params = toHttpParams({
            eventId,
        });
        return this.http.get<Form>(`${this.url}/final-notes-survey`, { params });
    }

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: formCacheBuster$,
    })
    getFormSubmissions(formId: number, eventId: number, relations: string[] = []) {
        const params = toHttpParams({
            relations,
            eventId,
        });
        return this.http.get<{ submissions: FormSubmission[]; total: number }>(`${this.url}/${formId}/submissions`, {
            params,
        });
    }

    @CacheBuster({
        cacheBusterNotifier: abstractCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: formCacheBuster$,
    })
    createForm(form: Partial<Form>): Observable<Form> {
        return this.http.post<Form>(this.url, form);
    }

    @CacheBuster({
        cacheBusterNotifier: abstractCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: formCacheBuster$,
    })
    updateForm(id: number, form: Partial<Form>): Observable<Form> {
        return this.http.put<Form>(`${this.url}/${id}`, form);
    }

    @CacheBuster({
        cacheBusterNotifier: abstractCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: formCacheBuster$,
    })
    deleteForm(id: number) {
        return this.http.delete(`${this.url}/${id}`);
    }

    public createFinalNotesForm(event: Event, stage: number, type: FormType = 'abstract_notes') {
        return this.getFormByType(event.eventId, type, true, [], stage).pipe(
            switchMap(form => {
                return form
                    ? of(form)
                    : this.createForm({
                          stage,
                          type,
                          name: `${event.name} Abstract Notes`,
                          eventId: event.eventId,
                          enabled: false,
                          config: DEFAULT_FINAL_NOTES_CONFIG,
                      });
            })
        );
    }

    exportFormResponses(form: Form) {
        return this.http
            .get(`${this.url}/${form.formId}/export`, { responseType: 'blob' })
            .pipe(tap(blob => saveAs(blob, `${form.name} Export.csv`)));
    }

    public exportFormAssets(form: Form) {
        return this.http
            .get<{ url }>(`${this.url}/${form.formId}/export-assets`)
            .pipe(tap(({ url }) => window.open(url, '_blank')));
    }

    @CacheBuster({
        cacheBusterNotifier: abstractCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: judgingCacheBuster$,
    })
    @CacheBuster({
        cacheBusterNotifier: formCacheBuster$,
    })
    public assignFormToPresentations(
        payload: { presentationId: number; formId: number }[]
    ): Observable<PresentationJudges[]> {
        return this.http.post<PresentationJudges[]>(`${this.url}/assignFormToPresentations`, payload);
    }

    private evaluationFormResponsesDto(
        presentationEvaluationSubmission: PresentationEvaluationSubmission,
        gropedResponses: GroupEvaluationFormResponsesByPresentationId
    ): EvaluationFormResponsesDto {
        const {
            presentationEvaluationId,
            presentationJudge: { consumer, presentation },
        } = presentationEvaluationSubmission;

        const responseNumber = gropedResponses[presentation.presentationId].indexOf(presentationEvaluationId) + 1;

        return {
            ...presentationEvaluationSubmission,
            responseNumber,
            judgeName: this.consumerName.transform(consumer),
            formName: presentationEvaluationSubmission.form.name,
            presentationTitle: presentation.title,
            formId: presentationEvaluationSubmission.formId,
            presentationId: presentation.presentationId,
        };
    }
}
