<app-edit-wrapper
    (hideWrapper)="onClose($event)"
    [allEntities]="allEntitiesDto"
    [currentIndex]="currentIndex"
    [selectedEntity]="selectedEntityDto"
    (navNext)="onToggleResponse($event)"
    (navPrev)="onToggleResponse($event)"
    title="{{ currentResponse.form | formTitle }} Form Response Preview"
    [isPreview]="true"
>
    <div class="content-body">
        <app-generalized-form-preview
            [form]="currentResponse.form"
            [formValues]="currentResponse.response"
            [formErrs]="formErrs"
            [isWrapped]="true"
        ></app-generalized-form-preview>
    </div>
</app-edit-wrapper>
