import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { Observable, Subject } from 'rxjs';
import { ClientSector, PlansResponse, StripePlan } from '@models';
import { Cacheable } from 'ts-cacheable';
import { DEFAULT_CACHE_OPTIONS } from '@constants';

declare var Stripe: any;

export interface StripePaymentSession {
    id: string;
}

const paymentCacheBuster$ = new Subject<void>();

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    BASE_URL = `${environment.SERVICE_BASE_URL}/payments`;
    stripe: any;

    constructor(private http: HttpClient) {
        this.stripe = Stripe(environment.STRIPE_API_KEY);
    }

    createStripePaymentSession(payload: {
        priceId: string;
        fee: string;
        institutionId: number;
    }): Observable<StripePaymentSession> {
        return this.http.post<StripePaymentSession>(`${this.BASE_URL}/create-session`, payload);
    }

    public displayEventUptimeInDays(days: number): string {
        switch (days) {
            case null:
                return 'Unlimited';
            case 3:
                return '3 days';
            case 7:
                return '1 week';
            case 14:
                return '2 weeks';
            case 365:
                return 'Up to 1 year';

            default:
                return `${days} days`;
        }
    }

    plan(id: string): Observable<StripePlan> {
        return this.http.get<StripePlan>(`${this.BASE_URL}/plans/${id}`);
    }

    @Cacheable({
        ...DEFAULT_CACHE_OPTIONS,
        cacheBusterObserver: paymentCacheBuster$,
    })
    plans(sector: ClientSector): Observable<PlansResponse> {
        return this.http.get<PlansResponse>(`${this.BASE_URL}/plans`, {
            params: { sector },
        });
    }
}
