import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { FG1NotificationService } from '@services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubscriptionService } from '../services/subscription.service';

@Injectable()
export class SuperAdminGuard implements CanActivate {
    constructor(
        private subscriptionService: SubscriptionService,
        private router: Router,
        private fg1NotificationService: FG1NotificationService
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.subscriptionService.getSubscriptionAdmin().pipe(
            map(subscription => {
                if (subscription && subscription.isSuperAdmin) {
                    return true;
                }

                this.fg1NotificationService.warn("You're not authorized to access this page.", 'Unauthorized');
                return this.router.createUrlTree(['/']);
            })
        );
    }
}
