import { NgModule } from '@angular/core';
import { MessageWidgetComponent } from './message-widget.component';
import { IntroductionMessageComponent } from './tabs/introduction-message/introduction-message.component';
import { CompletionMessageComponent } from './tabs/completion-message/completion-message.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { DonationWidgetSharedModule } from '../shared/donation-widget-shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [MatTabsModule, CommonModule, DonationWidgetSharedModule, ReactiveFormsModule],
    exports: [MessageWidgetComponent, IntroductionMessageComponent, CompletionMessageComponent],
    declarations: [MessageWidgetComponent, IntroductionMessageComponent, CompletionMessageComponent],
    providers: [],
})
export class MessageWidgetModule {}
