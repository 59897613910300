import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, zip } from 'rxjs';
import { AuthenticationService, PresentationService } from '@services';
import { catchError, first, switchMap } from 'rxjs/operators';
import { CurrentUser, PresentationEntity } from '@models';

@Injectable({
    providedIn: 'root',
})
export class EditSubmissionGuard implements CanActivate {
    constructor(
        private authenticationService: AuthenticationService,
        private presentationService: PresentationService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const presentationId = route.params.id;

        return zip(
            this.authenticationService.getCurrentUser().pipe(first()),
            this.presentationService.getPresentation(presentationId).pipe(first())
        ).pipe(
            switchMap(([currentUser, presentation]: [CurrentUser, PresentationEntity]) => {
                const isPresentationOwner =
                    currentUser && presentation && currentUser.userId === presentation.consumerId;
                const consumerEvent = currentUser.consumerEvents[presentation.eventId];
                return of(isPresentationOwner || (consumerEvent && consumerEvent.isAdmin));
            }),
            catchError(() => this.router.navigate(['/'], { replaceUrl: true }))
        );
    }
}
