import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Event, PresentationEntity } from '@models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { CompleteNgUnsubscribeComponent } from '@utils';
import { getLocalTimeZone, utcToLocalDate } from '@functions';
import { last, sortBy } from 'lodash';
import moment from 'moment';

@Component({
    selector: 'app-presentations-table',
    templateUrl: './presentations-table.component.html',
    styleUrls: ['./presentations-table.component.scss'],
})
export class PresentationsTableComponent extends CompleteNgUnsubscribeComponent implements OnInit, OnChanges {
    @Input() displayedColumns;
    @Input() loading: boolean;
    @Input() presentations: PresentationEntity[] = [];
    @ViewChild(MatSort) sort: MatSort;
    @Output() onEditPresentation = new EventEmitter<PresentationEntity>();
    timezone = getLocalTimeZone();

    requiredFieldsCompletedText = `Does your submission have all required fields completed? For events where there is a multi-stage submission and review process, there may be new fields available to edit if you are advanced to the next stage.`;
    abstractStageStatusText = `For events with administrators using multi-stage submission and review-process, this column indicates what stage of review submissions are currently in and whether your submission is accepted or rejected for the next stage or to present at the event.`;

    public dataSource: MatTableDataSource<PresentationEntity>;

    constructor() {
        super();
    }

    ngOnInit() {
        this.initTable();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateTable(changes.presentations.currentValue);
    }

    getLockedState(presentation: PresentationEntity): 'rejected' | 'locked' | 'none' {
        if (!presentation || !presentation.event || !presentation.event.eventConfig) {
            return 'none';
        }
        if (presentation.presentationStatuses) {
            const sortedStatuses = sortBy(presentation.presentationStatuses, stageStatus => stageStatus.stage);
            const finalStatus = last(sortedStatuses);
            if (
                (presentation.event.eventConfig.currentAbstractStage &&
                    finalStatus.stage < presentation.event.eventConfig.currentAbstractStage) ||
                finalStatus.status === 'rejected'
            ) {
                return 'rejected';
            }
        }
        if (presentation.event.eventConfig.isCurrentStageLocked) {
            return 'locked';
        }
        return 'none';
    }

    getLockedTooltipText(presentation: PresentationEntity): string {
        switch (this.getLockedState(presentation)) {
            case 'none':
                return '';
            case 'locked':
                return "Additional edits for this presentation have been locked by the organizer at this time. You may still preview your submission thus far.";
            case 'rejected':
                return 'Additional edits for this presentation have been locked by the organizer. You may still preview your submission thus far.';
        }
    }

    public onEditPresentationClick(presentation: PresentationEntity) {
        if (!presentation || !presentation.event || !presentation.event.eventConfig) {
            return;
        }
        this.onEditPresentation.emit(presentation);
    }

    private initTable() {
        const presentations = this.presentations || [];
        this.dataSource = new MatTableDataSource(presentations);
    }

    // util as can't join directly in template
    joinIfArray(templateObj: any): string {
        if (typeof templateObj === 'string') {
            return templateObj;
        }
        const arr = [];
        for (const idx in templateObj) {
            if (templateObj.hasOwnProperty(idx)) {
                arr.push(templateObj[idx]);
            }
        }
        return arr.join(', ');
    }

    private updateTable(presentations: PresentationEntity[]) {
        if (!this.dataSource) {
            this.initTable();
        }
        this.dataSource.data = presentations || this.presentations;
    }

    sortData(sort: Sort) {
        if (!this.dataSource) {
            this.initTable();
        }
        if (!sort.active || sort.direction === '') {
            this.updateTable(null);
            return;
        }

        this.dataSource.data = this.presentations.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'createDate':
                    return compare(utcToLocalDate(a.createDate), utcToLocalDate(b.createDate), isAsc);
                case 'title':
                    return compare(a.title, b.title, isAsc);
                case 'event':
                    return compare(a.event && a.event.name, b.event && b.event.name, isAsc);
                case 'areRequiredFieldsCompleted':
                    const aLockedState = this.getLockedState(a);
                    const aCompletedFieldsText =
                        aLockedState === 'rejected' ? 'Rejected' : a.completed ? 'Complete' : 'Incomplete';
                    const bLockedState = this.getLockedState(b);
                    const bCompletedFieldsText =
                        bLockedState === 'rejected' ? 'Rejected' : b.completed ? 'Complete' : 'Incomplete';
                    return compare(aCompletedFieldsText, bCompletedFieldsText, isAsc);
                default:
                    return 0;
            }
        });
    }
}

function compare(a: number | string | moment.Moment, b: number | string | moment.Moment, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
