<div class="content-container">
    <div class="col-md-12">
        <img src="assets/images/care-img_cmp.png" alt="Care" />

        <form [formGroup]="form" class="input-button-group-sm" autocomplete="off">
            <div class="form-group input-group-md">
                <label for="heading">Heading</label>

                <input
                    formControlName="completionHeading"
                    [value]="donationConfig.completionHeading"
                    [maxLength]="maxCompletionHeadingLength"
                    type="text"
                    class="form-control"
                    id="heading"
                    aria-describedby="eventNameHelp"
                    style="width: 100%"
                />
                <small class="form-text text-muted chars-info">
                    <span class="typed-chars">{{ completionHeadingLength }}</span
                    >/{{ maxCompletionHeadingLength }} characters
                </small>
            </div>

            <div class="form-group input-group-md">
                <label for="body">Body</label>

                <textarea
                    formControlName="completionBody"
                    [value]="donationConfig.completionBody"
                    [maxLength]="maxCompletionBodyLength"
                    class="form-control"
                    id="body"
                    rows="5"
                ></textarea>
                <small class="form-text text-muted chars-info">
                    <span class="typed-chars">{{ completionBodyLength }}</span
                    >/{{ maxCompletionBodyLength }} characters
                </small>
            </div>
        </form>

        <div class="save-button">
            <button class="btn btn-primary" (click)="submit()">Save changes</button>
        </div>
    </div>
</div>
