<div mat-form-field *ngIf="type === 'radio'" class="form-field-wrapper" floatLabel="always">
    <div [id]="uniqueFieldKey" class="help-tooltip-icon-container">
        <mat-label>{{ label }}<span *ngIf="isRequired" class="required-star">*</span></mat-label>
        <mat-icon *ngIf="isShownInSummary" [matTooltip]="isShownInSummaryTooltipText" matTooltipPosition="right"
            >help</mat-icon
        >
    </div>
    <div class="description">{{ description }}</div>
    <mat-radio-group
        [disabled]="isInputDisabled"
        [(ngModel)]="data[dataFieldName]"
        (ngModelChange)="changeIsCleanEditorStatus(false)"
        class="radio-group"
    >
        <div *ngFor="let option of optionLabels">
            <mat-radio-button
                [id]="uniqueFieldKey + '_' + transform(option) | lowercase"
                [value]="option"
                [checked]="data[dataFieldName] === option"
                class="radio-button"
            ></mat-radio-button>
            <div class="radio-btn-label-container">
                <div class="radio-btn-label">{{ option }}</div>
            </div>
            <br />
        </div>
    </mat-radio-group>
</div>
<div *ngIf="type === 'checkbox'" class="form-field-wrapper checkbox-group">
    <div [id]="uniqueFieldKey" class="help-tooltip-icon-container">
        <mat-label>{{ label }}<span *ngIf="isRequired" class="required-star">*</span></mat-label>
        <mat-icon *ngIf="isShownInSummary" [matTooltip]="isShownInSummaryTooltipText" matTooltipPosition="right"
            >help</mat-icon
        >
    </div>
    <div class="description">{{ description }}</div>
    <mat-selection-list
        [disabled]="isInputDisabled"
        [(ngModel)]="data[dataFieldName]"
        (ngModelChange)="changeIsCleanEditorStatus(false)"
    >
        <mat-list-option *ngFor="let option of options" [value]="option.value" checkboxPosition="before" disableRipple>
            {{ option.label }}
        </mat-list-option>
    </mat-selection-list>
</div>
<div class="clear-button-container">
    <button
        class="btn btn-secondary"
        [id]="uniqueFieldKey + '_' + transform(label) | lowercase"
        aria-label="Clear"
        (click)="handleClear()"
        [disabled]="isClearable"
    >
        Clear
    </button>
</div>

<div *ngIf="isInvalid && formErrs[uniqueFieldKey]">
    <small class="err-text">This field is required!</small>
</div>
