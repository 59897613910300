import { AppService, JudgingService } from '@services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { getEventCodeFromParams } from '@functions';
import { Event } from '@models';

@Injectable()
export class JudgingPackageEnabledGuard implements CanActivate {
    constructor(private judgingService: JudgingService, private appService: AppService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
        const eventCodeOrHash = getEventCodeFromParams(next) || next.url.slice(-1)[0].toString();

        return this.appService.getEventIfChanged(eventCodeOrHash).pipe(
            first(),
            switchMap((event: Event) => this.judgingService.getJudgingEnabledStatus(event.eventId)),
            first(),
            map(({ isEnabled }) => !!isEnabled),
            catchError(err => {
                this.router.navigate(['/', eventCodeOrHash, 'admin', 'judging'], { replaceUrl: true });
                return of(false);
            })
        );
    }
}
