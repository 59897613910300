<div class="app-event-admin-dash-presentations-edit-container">
    <div class="edit-header-nav-bar">
        <div class="edit-header-nav-bar-hide-btn-container">
            <div class="edit-header-nav-bar-hide-btn" (click)="hide()">
                <span class="material-icons">arrow_forward_ios</span>
                <span class="material-icons">arrow_back_ios</span>
                <div><div>HIDE</div></div>
            </div>
        </div>
        <div class="edit-header-nav-bar-controls-container">
            <span [innerText]="title"></span><br />

            <ng-template [ngIf]="!isDisabled" [ngIfElse]="enabled">
                <span class="edit-header-nav-bar-subheader"
                    >Submission {{ currentIndex + 1 }} of {{ total }}</span
                >
                <button
                    [disabled]="currentIndex === 0"
                    (click)="navPrevPres()"
                    mat-fab
                    mat-icon-button
                    class="edit-header-nav-bar-prev fab-icon-button"
                    aria-label="go to previous presentation"
                >
                    <span class="material-icons">arrow_back_ios</span>
                </button>
                <button
                    [disabled]="isLoading || currentIndex === (entitiesCount || allEntities.length - 1)"
                    (click)="navNextPres()"
                    mat-fab
                    mat-icon-button
                    class="edit-header-nav-bar-next fab-icon-button"
                    aria-label="go to next presentation"
                >
                    <span class="material-icons">arrow_forward_ios</span>
                </button>
            </ng-template>

            <ng-template #enabled>
                <span class="preview-description" [innerHTML]="descriptions"> </span>
            </ng-template>
        </div>
    </div>

    <div class="edit-body-wrapper">
        <ng-content> </ng-content>
    </div>

    <div class="edit-footer-nav-bar" *ngIf="!isDisabled">
        <!-- cancel, delete and edit buttons -->
        <div class="row submit-btn-container" *ngIf="!isPreview">
            <div class="col-6 col-sm-4">
                <button class="btn btn-primary cancel-btn" (click)="cancel()">Cancel Changes</button>
            </div>
            <div class="col-sm-3 d-none d-sm-block"></div>
            <div class="col-6 col-sm-4">
                <button class="btn btn-tertiary submit-btn" type="button" (click)="onSubmit()" [disabled]="isLoading || disableSubmit">
                    Save Changes
                </button>
            </div>
        </div>
        <!-- end buttons -->
    </div>
</div>
