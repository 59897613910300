import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { toHttpParams } from '@functions';
import { Observable, Subject } from 'rxjs';
import { Speaker } from '@models';
import { Cacheable } from 'ts-cacheable';
import { MAX_CACHE_COUNT, TWO_MINUTES_CACHE } from '@constants';

const speakersCacheBuster = new Subject<void>();

@Injectable({
    providedIn: 'root',
})
export class SpeakersService {
    private url: string = `${environment.SERVICE_BASE_URL}/speakers`;

    constructor(private http: HttpClient) {}

    @Cacheable({
        maxAge: TWO_MINUTES_CACHE,
        maxCacheCount: MAX_CACHE_COUNT,
        cacheBusterObserver: speakersCacheBuster,
    })
    getSpeakers(eventId: number): Observable<Speaker[]> {
        return this.http.get<Speaker[]>(this.url, { params: toHttpParams({ eventId }) });
    }
}
