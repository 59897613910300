import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, FormResponseSubmission } from '@models';
import { isArray, isEmpty } from 'lodash';
import { FORM_INPUT_TYPES } from '@constants';
import { conditionalsToQuestionAnswerPairs } from '@services';

@Component({
    selector: 'app-generalized-form-preview',
    templateUrl: './generalized-form-preview.component.html',
    styleUrls: ['./generalized-form-preview.component.scss'],
})
export class GeneralizedFormPreviewComponent implements OnInit {
    @Input() form: Partial<Form>;
    @Input() formValues?: Record<string, any> = {};
    @Input() formErrs?: any = {};
    @Input() isWrapped: boolean;
    @Input() isDisabled: boolean = false;
    @Input() isPreview: boolean = false;
    @Input() submitText: string = 'Submit';
    @Output() submit: EventEmitter<FormResponseSubmission> = new EventEmitter();
    @Output() isClean: EventEmitter<boolean> = new EventEmitter<boolean>();
    inputTypes = FORM_INPUT_TYPES;

    files: { hash: string; file: File }[] = [];

    editorStatus = { isClean: true };
    quillModulesGeneralConfig = {
        toolbar: [
            ['bold', 'italic', 'underline'], // toggled formatting buttons
            ['link'], // links
            ['clean'], // remove formatting button
        ],
    };

    constructor() {}

    ngOnInit() {
        this.form = conditionalsToQuestionAnswerPairs(this.form);
        for (const extraField of this.form.config.extraFields) {
            this.validateConditionals(extraField?.conditionalQuestionAnswerPairs);
        }
    }

    onIsCleanUpdated(isClean: boolean) {
        this.isClean.emit(isClean);
    }

    onSubmit() {
        this.validateFormData();
        if (Object.values(this.formErrs).length > 0) {
            return;
        }

        this.submit.emit({
            response: this.formValues,
            files: this.files,
        });
    }

    validateFormData() {
        // TODO implement normal form field end link angular validation with formErrs
        this.formErrs = {};

        // check is required
        this.form.config.extraFields.forEach((field, index) => {
            const { hash, isRequired, isDisplayed } = field;

            const areConditionalsValid = this.validateConditionals(field.conditionalQuestionAnswerPairs);
            if (!areConditionalsValid) {
                this.formValues[hash] = undefined;
            }

            const respValue = this.formValues[hash];

            if (
                (!respValue || (isArray(respValue) && isEmpty(respValue))) &&
                isRequired &&
                isDisplayed &&
                areConditionalsValid
            ) {
                this.formErrs[`presentation_extra_${index}`] = { isRequired: true };
            }
        });
    }

    validateConditionals(conditionalQuestionAnswerPairs: {}) {
        if (!conditionalQuestionAnswerPairs) {
            return true;
        }
        for (const [questionHash, answer] of Object.entries(conditionalQuestionAnswerPairs)) {
            if (Array.isArray(this.formValues[questionHash]) && Array.isArray(answer)) {
                for (const specificAnswer of answer) {
                    if (this.formValues[questionHash].includes(specificAnswer)) {
                        return true;
                    }
                }
            } else if (Array.isArray(this.formValues[questionHash]) && !Array.isArray(answer)) {
                return this.formValues[questionHash].includes(answer);
            } else if (!Array.isArray(this.formValues[questionHash]) && Array.isArray(answer)) {
                return answer.includes(this.formValues[questionHash]);
            } else {
                return this.formValues[questionHash] === answer;
            }
        }
        return false;
    }

    onFileSelect(item: { hash: string; file: File }) {
        this.files = this.files.filter(file => file.hash !== item.hash);
        this.files.push(item);
    }
}
