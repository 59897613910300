<div class="donation-popup-wrapper">
    <app-donation-widget
        [mode]="widgetMode.Popup"
        [showThumbnail]="false"
        [isPopupOpened]="isDonationPopupOpened"
        (closed)="closeDonationPopup()"
    ></app-donation-widget>
</div>
<nav class="mobile-nav">
    <ul class="menu" [ngClass]="{ 'is-popup-opened': isDonationPopupOpened }">
        <ng-container *ngIf="currentEvent">
            <li *ngIf="shouldDisplayWelcomePage">
                <a
                    [routerLink]="['/', currentEvent.eventCode, 'custom-home']"
                    class="link"
                    (click)="closeDonationPopup()"
                    routerLinkActive="is-active"
                >
                    <mat-icon aria-hidden="false" aria-label="Presentations">home</mat-icon>
                    <label>Welcome</label>
                </a>
            </li>
            <li>
                <a
                    [routerLink]="['/', currentEvent.eventCode, 'presentations']"
                    class="link"
                    (click)="closeDonationPopup()"
                    routerLinkActive="is-active"
                >
                    <mat-icon aria-hidden="false" aria-label="Presentations">slideshow</mat-icon>
                    <label>Presentations</label>
                </a>
            </li>
            <li>
                <a
                    [routerLink]="['/', currentEvent.eventCode, 'live-sessions']"
                    class="link"
                    (click)="closeDonationPopup()"
                    routerLinkActive="is-active"
                >
                    <mat-icon aria-hidden="false" aria-label="Sessions">videocam</mat-icon>
                    <label>Sessions</label>
                </a>
            </li>
            <li (click)="openDonationPopup()" *ngIf="shouldDisplayDonateButton">
                <a class="link" [ngClass]="{ 'is-donate-active': isDonationPopupOpened }">
                    <mat-icon aria-hidden="false" aria-label="Donate">volunteer_activism</mat-icon>
                    <label>Donate</label>
                </a>
            </li>
            <li *ngIf="isJudgeForEvent">
                <a
                    [routerLink]="['/', currentEvent.eventCode, 'judging']"
                    class="link"
                    routerLinkActive="is-active"
                    (click)="closeDonationPopup()"
                >
                    <mat-icon aria-hidden="false" aria-label="Judging">gavel</mat-icon>
                    <label>Judging</label>
                </a>
            </li>
        </ng-container>
    </ul>
</nav>
