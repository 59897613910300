import { isNil, orderBy } from 'lodash';
import { EventSort } from 'src/app/models/sort';
import { Event } from 'src/app/models/event';

export function getInitialSortOptions(): EventSort[] {
    return [
        { label: 'Most recent', fieldName: 'startDate', order: 'desc' },
        { label: 'Event name', fieldName: 'name', order: 'asc' },
        {
            label: 'Institution/Organization',
            fieldName: 'institution.name',
            order: 'asc',
        },
    ];
}

export function sortEvents(order: boolean | 'asc' | 'desc', fieldName: string, events: Event[]): Event[] {
    return orderBy(events, [event => (!isNil(event[fieldName]) ? event[fieldName] : new Date('1970-01-01'))], [order]);
}
