import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { from, Observable } from 'rxjs';
import { Presentation } from '@models';
import { FG1NotificationService, HttpService } from '@services';
import { HttpErrorResponse } from '@angular/common/http';
import { getEventCodeFromParams } from '@functions';

@Injectable({
    providedIn: 'root',
})
export class PresentationResolverService {
    constructor(
        private httpService: HttpService,
        private notificationsService: FG1NotificationService,
        private router: Router
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Presentation> | Observable<never> {
        // resolve presentation by presentationId route param
        return from(this.getPresentation(getEventCodeFromParams(route), route.params.presentationId));
    }

    async getPresentation(eventCode: string, presentationId: number) {
        let presentation: Presentation;
        let error: HttpErrorResponse;
        try {
            presentation = await this.httpService.getSinglePresentation(presentationId).toPromise();
        } catch (e) {
            error = e as HttpErrorResponse;
        }
        if (error || !presentation) {
            // forward to event admin presentations main route with notify service message
            this.router.navigate([`/${eventCode}/admin/presentations`], { replaceUrl: true });
            this.notificationsService.info('The requested presentation could not be found.', 'Not Found');
            return;
        }

        // success
        return presentation;
    }
}
