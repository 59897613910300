import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

const BIG_TEXT_SIZE = 250;
const GREATER_PERCENTAGE = 1.5;

@Component({
    selector: 'fg-read-more',
    templateUrl: './read-more.component.html',
    styleUrls: ['./read-more.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadMoreComponent implements OnInit {
    private text$ = new BehaviorSubject<string>('');
    @Input() set text(value: string) {
        this.text$.next(value);
    }

    private maxLen$ = new BehaviorSubject<number>(BIG_TEXT_SIZE);
    @Input() set maxLen(value: number) {
        this.maxLen$.next(value);
    }

    private enabled$ = new BehaviorSubject<boolean>(false);

    public state$: Observable<State>;

    constructor() {}

    ngOnInit() {
        this.state$ = combineLatest([this.enabled$, this.text$, this.maxLen$]).pipe(map(toState));
    }

    public readMore() {
        this.enabled$.next(!this.enabled$.value);
    }
}

function toState([enabled, text, maxLen]: [boolean, string, number]): State {
    const state: State = {
        enabled,
        buttonText: enabledToButtonText(enabled),
        isBigText: isBigText(text, maxLen),
    };
    state.text = toText(state.enabled, state.isBigText, text, maxLen);
    return state;
}

function toText(enabled: boolean, isBig: boolean, text: string, maxLen: number): string {
    return isBig ? (enabled ? text : text.substr(0, maxLen || BIG_TEXT_SIZE)) : text;
}

function isBigText(text: string, maxLen: number) {
    return text && text.length >= (maxLen || BIG_TEXT_SIZE) * GREATER_PERCENTAGE;
}

/**
 *
 * @param enabled if show more button is clicked, which means that enabled is ture
 * the text to display on button will be 'Show less' otherwise text will be 'Show more'
 */
function enabledToButtonText(enabled: boolean) {
    return enabled ? 'Show less' : 'Show more';
}

export interface State {
    enabled: boolean;
    buttonText?: 'Show more' | 'Show less';
    text?: string;
    isBigText?: boolean;
}
