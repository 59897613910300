import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormEditorMultiSelectComponent } from './multi-select-field.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DragDropModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatButtonModule,
        MatRadioModule,
        MatTooltipModule,
        MatIconModule,
    ],
    exports: [FormEditorMultiSelectComponent],
    declarations: [FormEditorMultiSelectComponent],
})
export class FormEditorMultiSelectModule {}
