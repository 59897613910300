import { Pipe, PipeTransform } from '@angular/core';
import { Consumer } from '@models';

@Pipe({
    name: 'consumerName',
})
export class ConsumerNamePipe implements PipeTransform {
    transform(consumer: Partial<Consumer>): string {
        return `${consumer.firstName} ${consumer.lastName}`;
    }
}
