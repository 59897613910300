import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppService, EventService } from '@services';
import { Event } from '@models';
import { getEventCodeFromParams } from '@functions';

@Injectable({
    providedIn: 'root',
})
export class NotLaunchedGuard implements CanActivate {
    constructor(private router: Router, private appService: AppService, private eventService: EventService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const eventCodeOrHash = getEventCodeFromParams(route) || route.url.slice(-1)[0].toString();
        return this.appService.getEvent().pipe(
            filter(e => !!e),
            take(1),
            map((event: Event) => {
                // if event has been launched go to home page
                if (this.eventService.isLaunched(event)) {
                    this.router.navigate([eventCodeOrHash], { replaceUrl: true });
                    return false;
                }

                return true;
            })
        );
    }
}
