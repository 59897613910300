import { AppService, AuthenticationService, FundsService } from '@services';
import { NavigationEnd, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { Event, Fund } from '@models';
import { WidgetMode } from '@constants';
import { shouldShowWelcomePage } from '@functions';
import { first } from 'rxjs/operators';
import { CompleteNgUnsubscribeComponent } from '@utils';
import { globalCacheBusterNotifier } from 'ts-cacheable';

@Component({
    selector: 'app-mobile-nav',
    templateUrl: './mobile-nav.component.html',
    styleUrls: ['./mobile-nav.component.scss'],
})
export class MobileNavComponent extends CompleteNgUnsubscribeComponent implements OnInit {
    @Input() set event(event: Event) {
        this.currentEvent = event;
        this.loadFunds();
    }
    @Input() isMyEventsPage: boolean;
    @Input() isJudgeForEvent: boolean;
    public isDonationPopupOpened = false;
    public widgetMode = WidgetMode;
    public currentEvent: Event;
    private funds: Fund[] = [];

    constructor(
        public appService: AppService,
        public authenticationService: AuthenticationService,
        public router: Router,
        public fundsService: FundsService,
    ) {
        super();
        router.events.subscribe(e => {
            if(e instanceof NavigationEnd) {
              this.isDonationPopupOpened = false;
            }});
    }

    ngOnInit(): void {
    }

    loadFunds() {
        if (this.currentEvent) {
            this.fundsService.getFunds(this.currentEvent.eventId)
            .pipe(first())
            .subscribe(funds => {
                this.funds = funds.filter(f => f.connected && f.enabled);
            });
        }
    }

    logout() {
        this.authenticationService.logout();
        this.appService.setInstitution(null);
        this.router.navigate(['/']);
    }

    exitEvent() {
        this.appService.setEvent(null);
        this.appService.setInstitution(null);
        globalCacheBusterNotifier.next();
        this.router.navigate(['/']);
    }

    goMyAccount() {
        this.router.navigate(['account']);
    }

    onGoOutClick() {
        if (this.currentEvent) {
            this.exitEvent();
        } else {
            this.logout();
        }
    }

    openDonationPopup() {
        this.isDonationPopupOpened = true;
    }

    closeDonationPopup() {
        this.isDonationPopupOpened = false;
    }

    get homeLink(): string {
        if (!this.currentEvent) {
            return '/';
        }
        if (this.currentEvent.isHash) {
            return this.currentEvent.hash;
        }
        return this.currentEvent.eventCode;
    }

    get shouldDisplayDonateButton() {
        if (!this.currentEvent) {
            return false;
        }

        const { donationConfig } = this.currentEvent;
        return donationConfig.nonProfit && donationConfig.enabled && this.funds && this.funds.length > 0;
    }

    get shouldDisplayWelcomePage() {
        return this.currentEvent && shouldShowWelcomePage(this.currentEvent);
    }
}
