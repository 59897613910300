import { NgModule } from '@angular/core';
import { EditWrapperComponent } from './edit-wrapper.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [MatIconModule, MatButtonModule, CommonModule],
    exports: [EditWrapperComponent],
    declarations: [EditWrapperComponent],
    providers: [],
})
export class EditWrapperModule {}
