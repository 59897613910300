import { Injectable } from '@angular/core';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { DonationConfig, Event } from '@models';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from './app.service';
import { filter } from 'rxjs/operators';
import { EventService } from './event.service';
import { FG1NotificationService } from './fg1-notification-service';

interface DonationConfigState {
    donationConfig: DonationConfig;
    previousDonationConfig: DonationConfig | {};
}

@Injectable({
    providedIn: 'root',
})
export class DonationConfigService {
    private donationConfig$: BehaviorSubject<DonationConfigState> = new BehaviorSubject<DonationConfigState>(null);
    private readonly url: string = `${environment.SERVICE_BASE_URL}/donations`;
    private _state: DonationConfig;
    private _event: Event;

    constructor(
        private http: HttpClient,
        private appService: AppService,
        private eventService: EventService,
        private fg1Notification: FG1NotificationService
    ) {
        this.appService
            .getEvent()
            .pipe(filter(e => !!e))
            .subscribe(event => this.init(event));
    }

    public donationConfig(): Observable<DonationConfigState> {
        return this.donationConfig$;
    }

    public updateDonationConfig(state: Partial<DonationConfig>) {
        const _state = {
            ...this._state,
            ...state,
        };

        this.donationConfig$.next({
            previousDonationConfig: this._state,
            donationConfig: _state,
        });

        this._state = _state;
    }

    private init(event: Event) {
        this._event = event;
        this._state = event.donationConfig;

        this.donationConfig$.next({
            previousDonationConfig: {},
            donationConfig: this._state,
        });
    }

    public saveDonationConfig(donationConfig: Partial<DonationConfig>): Observable<DonationConfig> {
        return this.eventService.updateDonationConfig(this._event.eventId, donationConfig);
    }
}
