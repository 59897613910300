import { Component, OnInit, Input } from '@angular/core';
import { PresenterFieldsConfig, PresenterData } from '@models';
import { shouldShowFieldOnSubmissionForm } from '@functions';

@Component({
    selector: 'app-form-preview-presenter-fields',
    templateUrl: './presenter-fields.component.html',
    styleUrls: ['./presenter-fields.component.scss'],
})
export class FormPreviewPresenterFieldsComponent implements OnInit {
    @Input() presenterFieldKey: string;
    @Input() presenterConfig: PresenterFieldsConfig;
    @Input() presenterData: PresenterData;
    @Input() formErrs: any;
    @Input() editorStatus: any;
    @Input() isInputDisabled: boolean;
    @Input() currentStage: number;

    // ngx-quill configurations
    quillModulesGeneralConfig = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled formatting buttons

            [{ align: [] }], // text alignment

            [{ color: [] }, { background: [] }], // dropdown with default colors from theme

            [{ font: [] }], // font

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

            [{ header: [1, 2, 3, 4, 5, 6, false] }], // html headers

            [{ list: 'bullet' }, { list: 'ordered' }], // lists

            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

            ['blockquote'], // quote

            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

            ['link'], // links

            ['clean'], // remove formatting button
        ],
    };

    shouldShowFieldOnSubmissionForm(config) {
        return shouldShowFieldOnSubmissionForm(config, this.currentStage);
    }

    constructor() {}

    ngOnInit() {}
}
