import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { GeneralInterface, Presentation } from '@models';

@Component({
    selector: 'app-edit-wrapper',
    templateUrl: './edit-wrapper.component.html',
    styleUrls: ['./edit-wrapper.component.scss'],
})
export class EditWrapperComponent<T> implements OnInit, OnDestroy {
    @Output() hideWrapper: EventEmitter<T> = new EventEmitter();
    @Output() navNext: EventEmitter<T> = new EventEmitter();
    @Output() navPrev: EventEmitter<T> = new EventEmitter();
    @Output() submitForm: EventEmitter<T> = new EventEmitter();
    @Output() cancelForm: EventEmitter<T> = new EventEmitter();

    @Input() allEntities: GeneralInterface<T>[];
    @Input() entitiesCount: number = 0;
    @Input() selectedEntity: GeneralInterface<T>;
    @Input() isDisabled: boolean;
    @Input() title: string;
    @Input() descriptions: string;
    @Input() isPreview = false;
    @Input() disableSubmit: boolean = false;
    @Input() currentIndex: number;

    _selectedEntity: GeneralInterface<T>;
    isLoading: boolean;
    formValues: Record<string, any>;
    formErrs: any = {};
    presentation: Presentation;

    private ngUnsubscribe$ = new Subject();

    constructor() {}

    ngOnInit() {
        if (!this.isDisabled) {
            this._selectedEntity = this.cloneEntity(this.selectedEntity);
            this.currentIndex = this.getCurrentIndex();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe$.next(null);
        this.ngUnsubscribe$.complete();
    }

    hide() {
        this.hideWrapper.emit(this._selectedEntity ? this._selectedEntity.entity : null);
    }

    getCurrentIndex() {
        return this.allEntities.findIndex(r => r.id === this.selectedEntity.id);
    }

    navNextPres() {
        this.currentIndex = this.getCurrentIndex();

        const selectedEntity =
            this.currentIndex + 1 === this.total
                ? this.cloneEntity(this.allEntities[this.currentIndex])
                : this.cloneEntity(this.allEntities[this.currentIndex + 1]);

        this.navNext.emit(selectedEntity.entity);
    }

    get total() {
        return this.entitiesCount || this.allEntities.length;
    }
    navPrevPres() {
        this.currentIndex = this.getCurrentIndex();

        const selectedEntity =
            this.currentIndex === 0
                ? this.allEntities[this.allEntities.length - 1]
                : this.allEntities[this.currentIndex - 1];

        this.navPrev.emit(selectedEntity.entity);
    }

    onSubmit() {
        // change previous version
        this._selectedEntity.entity = this.selectedEntity.entity;
        this.submitForm.emit(this.selectedEntity.entity);
    }

    cancel() {
        // send previous version to restore changed object
        this.cancelForm.emit(this._selectedEntity.entity);
    }

    private cloneEntity<U>(entity: U): U {
        return JSON.parse(JSON.stringify(entity));
    }
}
